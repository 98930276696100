import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TrendratingTable } from "../../../../../../../../components/table/TrendratingTable";
import { deepClone } from "../../../../../../../../deepClone";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import TagFilters from "./TagFilters";
import Subscribable from "./Subscribable";
import { CellComponent, ColumnDefinition } from "tabulator-tables";

type Props = {
  store: any;
  subscribe: Function;
  unsubscribe: Function;
  pubSubType: any /*"strategy" | "systematicPortfolio"*/;
  callback?: Function;
};

export default function StrategiesTab({
  store,
  subscribe,
  unsubscribe,
  pubSubType,
  callback,
}: Props) {
  const environment = useEnvironment();
  const setup = environment.get("setup");
  const [tags, setTags] = useState(setup.tags);
  const productID = useMemo(() => {
    return setup?.["account"]?.["product"]?.["id"] ?? null;
  }, [setup]);
  const { t } = useTranslation();

  const [selectedTags, setSelectedTags] = useState<string[]>(() => {
    let arrTags: any[] = [];
    tags.forEach((item) => arrTags.push(...item.filters));
    let selected: string[] = [];
    arrTags.forEach((item) => {
      if (item?.selected != null && item?.selected === true) {
        selected.push(item.id);
      }
    });
    return selected;
  });

  const [strategyCollection, setStrategyCollection] = useState([]);

  const mapTags = useMemo(() => {
    var mapTags = {};
    if (setup.tags != null) {
      for (let i = 0; i < setup.tags.length; i++) {
        var item = setup.tags[i];
        mapTags[item.id] = item;
        for (let j = 0; j < item.filters.length; j++) {
          var filter = item.filters[j];
          mapTags[filter.id] = filter;
        }
      }
    }
    return mapTags;
  }, [setup.tags]);

  const tableRef = useRef<any>(null);

  useEffect(() => {
    var _store = deepClone(store);

    if (_store == null) {
      return;
    }
    const tagDataMap = {};
    for (const tag of setup.tags) {
      if ("filters" in tag && tag.filters != null) {
        for (const filter of tag.filters) {
          tagDataMap[filter?.id ?? "unknown_filter"] = 0;
        }
      }
    }

    var _strategyCollection = _store
      .filter((item) =>
        _filterCollection(
          selectedTags,
          pubSubType /*strategy or systematics*/,
          item,
          tagDataMap,
          tags,
          productID
        )
      )
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else if (b.name > a.name) {
          return -1;
        }

        return 0;
      });

    let prepared = _prepareData(_strategyCollection, tags, mapTags, t);
    setStrategyCollection(prepared);

    const filteredTags = deepClone(setup.tags);
    for (const tag of filteredTags) {
      if ("filters" in tag && tag.filters != null) {
        tag.filters = tag.filters.filter((item) => {
          if (item.id in tagDataMap && tagDataMap[item.id] > 0) {
            return true;
          } else return false;
        });
      }
    }
    let tempTags = deepClone(filteredTags);
    tempTags.forEach((group) => {
      group.filters.forEach((filter) => {
        if (!selectedTags.includes(filter.id)) {
          if (filter.selected != null) {
            delete filter.selected;
          }
        } else {
          filter.selected = true;
        }
      });
    });

    if (JSON.stringify(tags) !== JSON.stringify(tempTags)) {
      setTags(tempTags);
    }
  }, [
    mapTags,
    productID,
    pubSubType,
    selectedTags,
    setup.tags,
    store,
    t,
    tags,
  ]);

  const subscribeUnsubscribeHandler = useCallback(
    (actionType: boolean, strategyID: number, ownerID: number) => {
      /*
      !function that handle the subscribe / unsibscribe 
    */
      if (actionType === true) {
        subscribe(strategyID);
      } else if (actionType === false) {
        unsubscribe(strategyID, callback);
      } else {
        console.error("ERROR!");
      }
    },
    [callback, subscribe, unsubscribe]
  );

  const renderCheckboxCell = useCallback(
    (cell) => {
      let row = cell.getRow();
      const data = row.getData();
      let inp: HTMLInputElement = document.createElement("input");
      inp.type = "checkbox";
      inp.checked = data.isSubscribed;
      inp.addEventListener("change", (e: any) =>
        subscribeUnsubscribeHandler(e?.target?.checked, data.id, data.ownerId)
      );
      return inp;
    },
    [subscribeUnsubscribeHandler]
  );

  let columns: ColumnDefinition[] = useMemo(() => {
    let cols: any = [
      {
        field: "isSubscribed",
        title: "",
        formatter: (cell) => renderCheckboxCell(cell),
        width: 40,
        hozAlign: "center",
        headerHozAlign: "center",
        sortable: true,
      },
      {
        field: "name",
        title: "Name",
        sortable: true,
      },
    ];

    // Inject dynamic tags configuration
    for (let i = 0; i < tags.length; i++) {
      const tagGroup = tags[i];
      if (
        tagGroup.display != null &&
        tagGroup.display.indexOf("table") !== -1
      ) {
        const fieldId = "_s_tag-" + tagGroup.id;
        const fieldIdFound = "_s_tag-" + tagGroup.id + "-found";
        cols.push({
          field: fieldId,
          title: tagGroup.name,
          formatter: (e) => _renderTag(e, fieldIdFound),
          sortable: true,
        });
      }
    }

    return cols;
  }, [renderCheckboxCell, tags]);

  //!----------
  const options = useMemo(() => {
    function mapLists(item) {
      var option = {
        checked: item.isSubscribed,
        label: item.name,
        value: item._s_id,
      };
      return option;
    }

    const tagDataMap = {};
    for (const tag of setup.tags) {
      if ("filters" in tag && tag.filters != null) {
        for (const filter of tag.filters) {
          tagDataMap[filter?.id ?? "unknown_filter"] = 0;
        }
      }
    }

    return store?
    store.filter((item) =>
        _filterCollection(
          selectedTags,
          pubSubType /*strategy or systematics*/,
          item,
          tagDataMap,
          tags,
          productID
        )
      )
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else if (b.name > a.name) {
          return -1;
        }

        return 0;
      })
      .map(mapLists): []
  }, [productID, pubSubType, selectedTags, setup.tags, store, tags]);

  return (
    <Box display={"flex"} height={"100%"}>
      {(pubSubType === "strategy" || pubSubType === "systematicPortfolio") && (
        <>
          <Box width={"25%"} overflow={"auto"} p={2}>
            <TagFilters
              tags={tags}
              // setTags={setTags}
              setSelectedTags={setSelectedTags}
              forceMultiple={true}
              filterKey={"filter"}
            />
          </Box>

          <Box width={"75%"} p={2}>
            <TrendratingTable
              disableDefaultRowClick={true}
              ref={tableRef}
              columns={columns}
              data={strategyCollection}
            />
          </Box>
        </>
      )}
      {(pubSubType === "combinedStrategy" ||
        pubSubType === "combinedProduct") &&
        (options.length > 0 ? (
          <Subscribable
            unsubscribe={(id) => unsubscribe(id, callback)}
            subscribe={(arg) =>
              subscribe(arg).then(() => {
                if (callback) {
                  callback();
                }
              })
            }
            input={options}
          />
        ) : (
          <Typography p={2}>Nothing has been shared yet</Typography>
        ))}
    </Box>
  );
}

const _filterCollection = (
  selectedTags,
  pubSubType,
  item,
  counterMap,
  _tags,
  productId
) => {
  if (pubSubType == null) {
    if (appConfig.isDebug) {
      console.warn("Missing pubSubType setting: please set");
    }
    return false;
  }

  if (item.pubSubType !== pubSubType) {
    return false;
  }

  // Build selected tags
  var groups = {};
  for (let i = 0; i < _tags.length; i++) {
    var group = _tags[i];
    groups[group.id] = {
      count: 0,
      tags: {},
    };

    for (let j = 0; j < group.filters.length; j++) {
      var tag = group.filters[j];

      if (selectedTags.indexOf(tag.id) !== -1) {
        groups[group.id].count++;
        groups[group.id].tags[tag.id] = true;
      } else {
        groups[group.id].tags[tag.id] = false;
      }
    }
  }

  var tags: any = null;
  if (item.tags != null && item.tags.type === "list") {
    tags = item.tags.data;
  }
  if (tags != null) {
    var itemGroups: any = [];
    if (tags.length === 0) {
      return false;
    }
    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i];
      // Don't clone counterMap because it's a reference
      counterMap[tag]++;
      var groupId: any = _getTagGroupId(tag, _tags);
      if (groupId != null && groups[groupId] != null) {
        if (groupId === "group-product") {
          if (!tags.includes(JSON.stringify(productId))) {
            return false;
          }
        } else {
          itemGroups.push(groupId);
          // Only check if filter is selected
          if (groups[groupId].count > 0) {
            // If there is at least one filter selected
            // check if tag is part of that
            if (groups[groupId].tags[tag] === false) {
              // Missing tag, not needed to
              // continue checking
              return false;
            }
          }
        }
      }
    }
    // Check for all
    for (const key in groups) {
      // Already checked, ignore group
      if (itemGroups.indexOf(key) !== -1) {
        continue;
      }

      if (groups[key].count > 0) {
        return false;
      }
    }
    return true;
  } else {
    // If there at least one tag active, and there is
    // no tags configured on the item, just filter out
    for (const key in groups) {
      if (groups[key].count > 0) {
        return false;
      }
    }
  }

  return true;
};

const _getTagGroupId = (tagId, tags) => {
  for (let i = 0; i < tags.length; i++) {
    var group = tags[i];
    for (let j = 0; j < group.filters.length; j++) {
      if (group.filters[j].id === tagId) {
        return group.id;
      }
    }
  }
  return null;
};

const _renderTag = (cell: CellComponent, fieldIdFound) => {
  const row = cell.getRow();
  const data = row.getData();
  if (data[fieldIdFound]) {
    cell.getElement().classList.add("isTagEnabled");
  } else {
    cell.getElement().classList.add("noTagEnabled");
  }
  return cell.getValue();
};

const _prepareData = (data, _tags, _mapTags, t) => {
  // Cloning fetchSync result because the data is not copied but
  // referenced directly from the source Store
  var items = deepClone(data);
  var tags = _tags;

  if (tags == null) {
    return;
  }

  var preparedItems: any = [];
  for (let i = 0; i < items.length; i++) {
    var item = items[i];
    var itemTags = null;
    if (item.tags != null && item.tags.type === "list") {
      itemTags = item.tags.data;
    }

    // Inject dynamic tags configuration
    for (let j = 0; j < tags.length; j++) {
      var tag = tags[j];
      var syntheticId = "_s_tag-" + tag.id;
      var syntheticIdFound = "_s_tag-" + tag.id + "-found";
      var preparedTagData = _prepareDataTag(tag.id, itemTags, _mapTags, t);
      // Needs to be separated so sorting can work correctly
      item[syntheticId] = preparedTagData.label;
      item[syntheticIdFound] = preparedTagData.found;
    }
    preparedItems.push(item);
  }

  return preparedItems;
};
const _prepareDataTag = (parentId, tags, _mapTags, t) => {
  // Get the correct tag name, use cell_name as first because
  // we are displaying the tag data inside a table cell
  function getTagName(tag) {
    return tag.cell_name != null ? tag.cell_name : tag.name;
  }

  var mapTags = _mapTags;

  // Decode
  var parent = mapTags[parentId];
  var filters: any = [];
  for (let i = 0; i < parent.filters.length; i++) {
    filters.push(parent.filters[i].id);
  }

  var tagName = parent.allName;
  var found = false; // Check for problematic configuration
  if (tags != null) {
    for (let i = 0; i < tags.length; i++) {
      const tag = mapTags[tags[i]];
      if (tag != null && filters.indexOf(tag.id) !== -1) {
        if (parent.type === "single") {
          if (found) {
            // Error, already found another tag, cannot have twice

            tagName = "(error)";
            break;
          } else {
            found = true;
            tagName = getTagName(tag);
          }
        } else {
          if (found) {
            tagName += "<br>" + getTagName(tag);
          } else {
            found = true;
            tagName = getTagName(tag);
          }
        }
      }
    }
  }
  return {
    found: found,
    label: tagName,
  };
};
