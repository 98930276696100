import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";

type Props = {
    value: boolean;
    handler: any;
};

export default function UpcomingRebalances({value, handler}: Props) {
  return (
    <Card sx={{ flex: 1, boxShadow:3 }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box pb={1}>
          <Typography sx={{ lineHeight: 1 }} variant="subtitle1">
            Upcoming Rebalances
          </Typography>
        </Box>
        <Divider
          sx={{ width: "100%", display: "block", m: 0 }}
          variant="middle"
        />
        <Box pt={1}>
          <FormGroup>
            <FormControlLabel
                          control={<Checkbox checked={value} onChange={ handler} size="small" />}
              label="Auto-rebalance"
            />
          </FormGroup>
        </Box>
      </CardContent>
    </Card>
  );
}
