import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  Button,
  Card,
  CardContent,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ErrorBoundary } from "../../../../../ErrorBoundary";
import { handleTitle } from "../../../../../Utility/DocumentTitleHanlder";
import { CombinedProducts } from "../../../../../api/compute/CombinedProducts";
import { CombinedStrategies } from "../../../../../api/compute/CombinedStrategies";
import { Instruments } from "../../../../../api/compute/Instruments";
import { Lists } from "../../../../../api/compute/Lists";
import { Strategies } from "../../../../../api/compute/Strategies";
import { Subscriptions } from "../../../../../api/compute/Subscriptions";
import { SystematicProducts } from "../../../../../api/compute/SystematicProducts";
import Modal from "../../../../../components/Modal/Modal";
import { PanelForLists } from "../../../../../components/PanelForLists/PanelForLists";
import { RapidMenu } from "../../../../../components/RapidMenu/RapidMenu";
import Benchmark from "../../../../../components/RuleWizzard/StrategyRulesWizzard/components/Benchmark";
import Currency from "../../../../../components/RuleWizzard/StrategyRulesWizzard/components/Currency";
import { deepClone } from "../../../../../deepClone";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../../hooks/useEventBus";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { Currency as CurrencyType } from "../../../../../types/Api";
import { config } from "../../../config-ts";
import { CombinedProductsStorage } from "../../../storage/CombineProductsStorage";
import { CombinedStrategiesStorage } from "../../../storage/CombinedStrategiesStorage";
import { DialogSaveComponent } from "../../../ui/commons/DialogSave/DialogSaveComponent";
import { messageError, messageSuccess, removeLoader } from "../../../utils";
import ReportButton from "../../../widgets/app-infrastructure/workflowBar/actions/report/ReportButton";
import { InputStubExport } from "../../analysisLists/widgets/PortfolioHome";
import { Loader } from "../builder/Loader";
import {
  LoaderContext,
  STRATEGIES_LOADER_EVENTS,
} from "../builder/StrategiesBuilder";
import PreviewCard from "../builder/editors/Advanced/PreviewCard";
import Result from "../builder/editors/Advanced/Result/Result";
import {
  UI_CONSTRAINTS_TYPE,
  getYesterdayMinus_N_Years,
} from "../builder/editors/Advanced/utils";
import Period from "../builder/editors/advancedWidgets/AdvancedFieldControllers/BacktestingController/components/Period";
import styles from "./CombineStrategies.module.scss";
import { useBroadcast } from "../../../../../hooks/useBroadcast";
import {
  DialogRelations,
  Remove,
} from "../../../components/app-infrastructure/workflowBar/actions/remove/Remove";
import { TableWrapper } from "../../../components/app-infrastructure/workflowBar/actions/export/Export";
import { Export } from "./actions/Export";
import SaveActionCombinedStrategies from "../../../widgets/app-infrastructure/workflowBar/actions/save/SaveActionCombinedStrategies";
import { styled } from "@mui/system";

type CombineListProps = {
  handleListItemClick: (value) => any;
  handleMenuActions: (action: string, resource) => any;
  type: "strategies" | "products";
  refreshTrigger: {};
};

const StyledBtn = styled((props: any) => (
  <Button
    title={`Open ${
      props.type === "strategies" ? "strategy" : "Systematic portfolio"
    } in a new tab`}
    className={props.className}
    onClick={(e) => {
      e.stopPropagation();
      props.openStrategy(props.row.id);
    }}
    sx={{ height: "auto" }}
  >
    {props.children}
  </Button>
))({
  borderRadius: "4px!important",
  borderColor: "1px solid #2a7090!important",
  backgroundColor: "white!important",
  padding: "3px!important",
  minWidth: "0!important",
  height: "auto",
});

type CombineEntitiesProps = {
  type: "strategies" | "products";
};

type CombinedListMap = { [id: string]: any };

type EditorPanelProps = {
  ediorType: "strategies" | "backtest";
  onCancel: Function;
};

type TableDataType = { id: string | undefined; weight: number | undefined }[];

type StrategySelectorProps = {
  data: TableDataType;
  setData: (value) => void;
};

type WeightSelectorProps = {
  weight?: number;
  updateWeight: (weight) => void;
};

type EditorContextType = {
  strategiesData: TableDataType;
  setStrategiesData: (value) => void;
  backtest: {
    benchmark: string | null;
    currency: string;
    period: any;
  };
  setBacktestData: (data) => void;
  type: "strategies" | "products";
};

const EditorContext = createContext<EditorContextType>(undefined!);

export const REPORT_COLUM_EVENET_NAME = "update-report-with-columns";

export function CombineStrategies({ type }: CombineEntitiesProps) {
  useEffect(() => {
    removeLoader();
  }, []);

  const [workflowStatus, setWorkflowStatus] = useState("beforeRun");
  const { id, action } = useParams();
  const formatter = useFormatter();
  const environment = useEnvironment();
  const { dispatch, on, remove } = useEventBus();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const combinedStrategiesAPI = useMemo(
    () =>
      type === "products"
        ? new CombinedProducts(appSetup)
        : new CombinedStrategies(appSetup),
    [appSetup, type]
  );
  const navigate = useNavigate();
  // const { t } = useTranslation();
  const strategiesAPI = useMemo(() => new Strategies(appSetup), [appSetup]);
  const instrumentsAPI = useMemo(() => new Instruments(appSetup), [appSetup]);
  const listsAPI = useMemo(() => new Lists(appSetup), [appSetup]);
  const subsAPI = useMemo(() => new Subscriptions(appSetup), [appSetup]);

  const smsAPI = useMemo(() => new SystematicProducts(appSetup), [appSetup]);
  const [refreshTrigger, setRefreshTrigger] = useState({});
  const userId = useMemo(
    () => environment.get("account")["user"]["id"],
    [environment]
  );

  const { broadcast } = useBroadcast();

  const strategyBuilderConfig = useMemo(() => {
    return environment.get("account").product?.configuration?.strategy_builder
      .form_long_short;
  }, [environment]);

  const helpEnabled = useMemo(() => {
    return strategyBuilderConfig?.resultTab?.insights ?? false;
  }, [strategyBuilderConfig?.resultTab?.insights]);

  const refreshList = useCallback(() => {
    setRefreshTrigger({});
  }, []);

  const loadingBehaviours = useMemo(
    () => ({
      startNew: (obj) => dispatch(STRATEGIES_LOADER_EVENTS.newProgram, obj),
      update: (id, point) =>
        dispatch(STRATEGIES_LOADER_EVENTS.updateProgram, { id, point }),
      complete: (obj) =>
        dispatch(STRATEGIES_LOADER_EVENTS.completeProgram, obj),
      stopPreload: () => dispatch(STRATEGIES_LOADER_EVENTS.stopPreload),
    }),
    [dispatch]
  );

  const actionsInputStorage = useRef<any>({ export: undefined });

  const storeActionInput = useCallback((key, value) => {
    actionsInputStorage.current[key] = value;
  }, []);

  const runAPI = useMemo(() => {
    const engine = {
      strategies: new CombinedStrategiesStorage(appSetup, loadingBehaviours),
      products: new CombinedProductsStorage(appSetup, loadingBehaviours),
    };

    return engine[type];
  }, [appSetup, loadingBehaviours, type]);
  const combinedStrategies = useRef<CombinedListMap>({});

  const updateCombinedStrategies = useCallback((key, value) => {
    combinedStrategies.current[key] = value;
  }, []);
  const getCombinedStrategy = useCallback((key) => {
    return combinedStrategies.current[key]
      ? deepClone(combinedStrategies.current[key])
      : undefined;
  }, []);

  const [itemToRename, setItemToRename] = useState<any>();
  const [error, setError] = useState<undefined | string>();
  const [loadingNavigatorOpts, setLoadingNavigatorOpts] = useState(false);
  const [loadedStrategyId, setLoadedStrategyId] = useState<string>();
  const [activeEditor, setActiveEditor] = useState<
    "strategies" | "backtest" | null
  >(null);
  const [formattedBenchmark, setFormattedBenchmark] = useState("Not Set");
  const [strategiesNames, setStrategiesNames] = useState<{
    [id: string]: { name: string; readOnly: boolean };
  }>({});
  const [tableData, setTableData] = useState<
    { id: undefined | string; weight: undefined | number }[]
  >([
    {
      id: undefined,
      weight: 1,
    },
    {
      id: undefined,
      weight: 1,
    },
  ]);
  const [backtest, setBacktest] = useState<{
    benchmark: string | null;
    currency: CurrencyType;
    period: {
      period: { type: "DAY" | "YEAR"; value: number | string };
    };
  }>({
    benchmark: null,
    currency: "local",
    period: {
      period: { type: "YEAR", value: 8 },
    },
  });
  const [results, setResults] = useState();
  const [readyToRun, setReadyToRun] = useState(false);

  useEffect(() => {
    setReadyToRun(false);
    setWorkflowStatus("beforeRun");
  }, [loadedStrategyId]);

  useEffect(() => {
    if (readyToRun) {
      const names = Object.values(strategiesNames)
        .map((item) => item.name)
        .join(" - ");

      const context = {
        strategy: {
          params: {
            strategy: {
              currency: backtest.currency,
              benchmark: backtest.benchmark,
            },
          },
          name: names,

          chartTab: {
            strategies: strategiesNames,
          },
        },
      };

      const benchmarkInfo = runAPI.benchmarkInfo;
      const results: any = deepClone(context);

      results.strategyInstrumentBenchmark = benchmarkInfo;

      setResults(results);
    } else {
      setResults(undefined);
      setWorkflowStatus("beforeRun");
    }
  }, [
    backtest.benchmark,
    backtest.currency,
    readyToRun,
    runAPI.benchmarkInfo,
    strategiesNames,
  ]);

  const selectedStrategyName = useMemo(() => {
    if (loadedStrategyId) {
      const combined = getCombinedStrategy(loadedStrategyId);

      return combined?.name ?? "";
    }

    return type === "strategies"
      ? `Backtest Combined strategies`
      : "Combine Systematic Portfolios";
  }, [getCombinedStrategy, loadedStrategyId, type]);

  const getStrategy = useCallback(
    async (id) => {
      return await combinedStrategiesAPI.get(id);
    },
    [combinedStrategiesAPI]
  );

  const getStrategies = useCallback(
    async (id?) => {
      if (id) {
        return await getStrategy(id);
      } else {
        const userCollection = await combinedStrategiesAPI.get();
        const keyType =
          type === "products" ? "combinedProduct" : "combinedStrategy";
        const subs = await subsAPI.get({ type: keyType });
        const _subs = await subsAPI.getDetails("subscriptions", subs);
        const combinedList = [...userCollection, ..._subs];

        return combinedList;
      }
    },
    [combinedStrategiesAPI, getStrategy, subsAPI, type]
  );
  const getCombined = useCallback(
    async (id?: string) => {
      try {
        const combinedList = await getStrategies(id);
        handleTitle(combinedList);

        if (combinedList) {
          if (id) {
            // If an id was passed the get method returns a single object
            updateCombinedStrategies(id, combinedList);

            // Called here to trigger a rerender and use the changes of the id to read data without reload strategies every time
            setLoadedStrategyId(id);
          } else {
            for (const combinedStrategy of combinedList) {
              updateCombinedStrategies(combinedStrategy.id, combinedStrategy);
            }
          }
        }
      } catch (error) {
        const [channel, msg] = messageError(error);
        broadcast(channel as string, msg);
      }
    },
    [broadcast, getStrategies, updateCombinedStrategies]
  );

  const onOpenCombinedSelect = useCallback(async () => {
    setLoadingNavigatorOpts(true);

    try {
      await getCombined();
    } catch (error) {
      const [channel, msg] = messageError(error);
      broadcast(channel as string, msg);
    } finally {
      setLoadingNavigatorOpts(false);
    }
  }, [broadcast, getCombined]);

  const onSelectCombined = useCallback(
    (id) => {
      if (type === "strategies") {
        navigate(`/app/strategies/combined/${id}`);
      } else {
        setLoadedStrategyId(id);
      }
    },
    [navigate, type]
  );

  const handleStrategyLoad = useCallback(async () => {
    const selectedCombined = getCombinedStrategy(loadedStrategyId);

    const keyResolver = {
      strategies: "strategy",
      products: "product",
    };

    if (selectedCombined) {
      let objectToLoad: any = null;
      if (
        "isReadOnly" in selectedCombined &&
        selectedCombined.isReadOnly === true
      ) {
        objectToLoad = await combinedStrategiesAPI.get(selectedCombined.id);
      } else {
        objectToLoad = selectedCombined;
      }

      const dataForTable: {
        id: undefined | string;
        weight: undefined | number;
      }[] = [];

      for (const key in objectToLoad) {
        if (key.startsWith(keyResolver[type])) {
          if (objectToLoad[key] != null) {
            dataForTable.push({
              id: objectToLoad[key]["id"],
              weight: objectToLoad[key]["value"],
            });
          }
        }
      }

      let benchmarkString: any = null;

      if (selectedCombined?.benchmark) {
        if (typeof selectedCombined?.benchmark === "string") {
          benchmarkString = selectedCombined?.benchmark;
        } else {
          benchmarkString = selectedCombined.benchmark?.item?.symbol ?? null;
        }
      }

      const backtestParams = {
        benchmark: benchmarkString,
        currency: selectedCombined.currency,
        period: {
          period: {
            type: objectToLoad?.period?.type ?? "YEAR",
            value: objectToLoad?.period?.value ?? 8,
          },
        },
      };

      setBacktest(backtestParams);
      setTableData(dataForTable);
    }
  }, [combinedStrategiesAPI, getCombinedStrategy, loadedStrategyId, type]);

  useEffect(() => {
    handleStrategyLoad();

    setActiveEditor(null);
  }, [handleStrategyLoad]);

  const mapStrategiesIntoNames = useCallback(async () => {
    const ids: any = [];

    for (const resource of tableData) {
      if (resource.id != null) {
        ids.push(resource.id);
      }
    }

    if (ids.length) {
      if (type === "strategies") {
        const resourceResponse = await strategiesAPI.fetch(ids, [
          "name",
          "object.entity_type",
          "type",
          "ownerId",
        ]);

        const response = resourceResponse?.data?.[0]?.rows;

        if (response) {
          const info = response.reduce((prev, current) => {
            prev[current.id] = {
              name: current.name,
              readOnly: current.ownerId !== userId,
              //! these keys are used in openStrategy
              type: current.type,
              entity_type: current.entity_type,
              //!------------------------------------
            };

            return prev;
          }, {});

          setStrategiesNames(info);
        }
      } else if (type === "products") {
        const resourceResponse = await smsAPI.fetch({
          ids,
          properties: ["name", "ownerId"],
        });

        if (resourceResponse) {
          const info = resourceResponse.reduce((prev, current) => {
            prev[current.id] = {
              name: current.name,
              readOnly: current.ownerId !== userId,
              type: "SYSTEMATIC_PRODUCT",
            };

            return prev;
          }, {});

          setStrategiesNames(info);
        }
      }
    }
  }, [smsAPI, strategiesAPI, tableData, type, userId]);

  const formatPercentage = useCallback(
    (value) =>
      formatter.custom("number", {
        options: {
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "-",
          },
        },
        output: "TEXT",
        value,
        valueHelper: null,
      }),
    [formatter]
  );

  const formatBenchmarkName = useCallback(async () => {
    if (backtest.benchmark) {
      const symbol: string = backtest.benchmark;
      let benchmarkName = "Not Set";

      switch (symbol) {
        case "TRENDRATING_NEUTRAL_STRATEGY": {
          benchmarkName = "Neutral Strategy";
          break;
        }

        case "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED": {
          benchmarkName = "Neutral Strategy Equal Weighted";
          break;
        }

        default: {
          let isBlendedBenchmark = false;
          const explodedSymbol = symbol.split(":");
          if (explodedSymbol && explodedSymbol[0] === "COLLECTION") {
            isBlendedBenchmark = true;
          }

          try {
            if (isBlendedBenchmark) {
              const list = await listsAPI.portfolioFetch(
                [parseInt(explodedSymbol[1])],
                ["name"]
              );

              benchmarkName = list?.[0]?.name ?? "";
            } else {
              const instrument = await instrumentsAPI.fetch({
                type: "security",
                symbols: [symbol],
                properties: [{ date: null, property: "name" }],
              });

              if (
                instrument &&
                "data" in instrument &&
                instrument.data.length
              ) {
                benchmarkName = instrument.data[0]?.name ?? "";
              }
            }
          } catch (error) {
            benchmarkName = "Unknown Benchmark";
          }
        }
      }

      setFormattedBenchmark(benchmarkName);
    } else {
      setFormattedBenchmark("Not Set");
    }
  }, [backtest.benchmark, instrumentsAPI, listsAPI]);

  const listenerRemoveCombine = useCallback(
    (combinedStrategy) => {
      var message = {
        from: "COMBINE_STRATEGIES",
        content: {
          type: "success",
          text: `<strong>${combinedStrategy.name}</strong> has been deleted successfully`,
        },
      };
      broadcast(config["channels"]["feedback"]["input"], message);

      if (type === "strategies") {
        const url = "/app/strategies/";
        navigate(url);
      } else {
        setLoadedStrategyId(undefined);
      }

      refreshList();
    },
    [broadcast, navigate, refreshList, type]
  );

  const _listenerActionSave = useCallback(
    (combined) => {
      refreshList();
      var message = {
        from: "COMBINE_STRATEGIES",
        content: {
          type: "success",
          text: `<strong>${combined.name}</strong> has been saved successfully`,
        },
      };
      broadcast(config["channels"]["feedback"]["input"], message);
    },
    [broadcast, refreshList]
  );

  const run = useCallback(async () => {
    setReadyToRun(false);
    try {
      setWorkflowStatus("isRunning");
      dispatch(STRATEGIES_LOADER_EVENTS.startPreload);
      await runAPI.prepareEntitiesForCombine();
      const curves = await runAPI.getCurves();

      storeActionInput("export", curves);

      setReadyToRun(true);
      setWorkflowStatus("afterRun");
    } catch (error: any) {
      dispatch(STRATEGIES_LOADER_EVENTS.stopPreload);
      const errorCode = error?.errorDetails?.code ?? null;

      if (errorCode) {
        if (errorCode === "UNIVERSE_EMPTY") {
          setError(
            "The universe is empty, please check the rules you defined, maybe you forgot to select a strategy"
          );
        }
      } else {
        setError(
          "An unknown error has occured, please refresh the page and try again, if the issue persist contact our customers support"
        );
      }

      setWorkflowStatus("beforeRun");
      setReadyToRun(false);
    }
  }, [dispatch, runAPI, storeActionInput]);

  const handleUrlParams = useCallback(async () => {
    if (id) {
      await getCombined(id);

      if (action && action === "run") {
        let combined = getCombinedStrategy(id);
        if (combined) {
          //#region - if shared get full data
          if ("isReadOnly" in combined && combined.isReadOnly === true) {
            combined = await combinedStrategiesAPI.get(combined.id);
          }
          //#endregion

          const strategiesForRun: {
            id: undefined | string;
            weight: undefined | number;
          }[] = [];

          for (const key in combined) {
            if (key.startsWith("strategy")) {
              strategiesForRun.push({
                id: combined[key].id,
                weight: combined[key].value,
              });
            }
          }

          runAPI.setEntitites(strategiesForRun);
          run();
        }
      }
    }
  }, [
    action,
    combinedStrategiesAPI,
    getCombined,
    getCombinedStrategy,
    id,
    run,
    runAPI,
  ]);

  useEffect(() => {
    mapStrategiesIntoNames();
  }, [mapStrategiesIntoNames]);

  useEffect(() => {
    formatBenchmarkName();
  }, [formatBenchmarkName]);

  useEffect(() => {
    handleUrlParams();
  }, [handleUrlParams]);

  useEffect(() => {
    runAPI.setCombineParams(backtest);
  }, [backtest, runAPI]);

  useEffect(() => {
    runAPI.setEntitites(tableData);
  }, [runAPI, tableData]);

  useEffect(() => {
    setReadyToRun(false);
  }, [tableData, backtest]);

  const tabs: any = useMemo(
    () => ["keyFacts", "chart", "analytics", "holdings"],
    []
  );

  const [holdingTabTableColumns, setHoldingTabTableColumns] = useState(null);

  var inputStubExport = new InputStubExport({
    viewer: {
      columns: holdingTabTableColumns,
    },
    tools: [],
  });

  const setReportHoldingTableColumns = useCallback((event) => {
    const columns = event.detail.columns;
    setHoldingTabTableColumns(columns);
  }, []);

  useEffect(() => {
    on(REPORT_COLUM_EVENET_NAME, setReportHoldingTableColumns);
    return () => {
      remove(REPORT_COLUM_EVENET_NAME, setReportHoldingTableColumns);
    };
  }, [on, remove, setReportHoldingTableColumns]);

  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [saveDialog, setSaveDialog] = useState<any>(null);
  const listenerSave = useCallback(
    (data, inputValue) => {
      var item = data;
      if (item["type"] == null) {
        alert("Save: item has no type");
        return;
      }

      var request: any = null;
      switch (item["type"]) {
        case "COMBINED_PRODUCT": {
          // if (item["id"] == null) {
          if (inputValue != null) {
            item.name = inputValue;
            request = combinedStrategiesAPI.create(item);
          } else {
            request = combinedStrategiesAPI.update(item);
          }

          break;
        }
        case "COMBINED_STRATEGY": {
          if (inputValue != null) {
            item.name = inputValue;
            request = combinedStrategiesAPI.create(item);
          } else {
            request = combinedStrategiesAPI.update(item);
          }

          break;
        }
        case "COMPARED_STRATEGY": {
          if (inputValue != null) {
            item.name = inputValue;
            request = combinedStrategiesAPI.create(item);
          } else {
            request = combinedStrategiesAPI.update(item);
          }

          break;
        }
        default: {
          return;
        }
      }

      request.then(
        (response) => {
          _listenerActionSave(response);
          setShowSaveDialog(false);
        },
        (error) => {
          let errorMessage = "";
          if (error && error.response.error === "OBJECT_ALREADY_EXISTING") {
            errorMessage = "A strategy with this name already exists.";
          } else if (error) {
            errorMessage = "Unknown error, please retry later.";
          }
          const [channel, msg] = messageError(errorMessage);
          broadcast(channel as string, msg);
        }
      );
    },
    [_listenerActionSave, broadcast, combinedStrategiesAPI]
  );
  const onClickSave = useCallback(
    (combinedStrategy) => {
      setShowSaveDialog(true);
      setSaveDialog(
        <DialogSaveComponent
          item={{
            name: combinedStrategy?.name ?? "",
          }}
          dialogType={"Combined Strategy"}
          onSave={() => listenerSave(combinedStrategy, null)}
          onSaveAs={(newName) => listenerSave(combinedStrategy, newName)}
          onRename={null}
          hide={() => setShowSaveDialog(false)}
        />
      );
    },
    [listenerSave]
  );

  // Workflow Actions
  useEffect(() => {
    let actions: any = [];
    let action: any = null;

    switch (workflowStatus) {
      case "beforeRun": {
        action = {
          componentJSX: (
            <li className="menu__item" onClick={() => run()}>
              Run
            </li>
          ),
        };
        actions.push(action);

        if (loadedStrategyId) {
          const combined = getCombinedStrategy(loadedStrategyId);

          if (userId === combined.ownerId) {
            action = {
              componentJSX: (
                <Remove
                  item={combined}
                  onRemoveDone={(event) => listenerRemoveCombine(event.value)}
                  label={"Delete"}
                />
              ),
            };

            actions.push(action);
          }
        }

        break;
      }

      case "isRunning": {
        actions = [];

        break;
      }

      case "afterRun": {
        action = {
          componentJSX: (
            <li className="menu__item" onClick={() => run()}>
              Run
            </li>
          ),
        };
        actions.push(action);

        if (id) {
          const combined = getCombinedStrategy(id);

          if (combined != null && userId === combined.ownerId) {
            action = {
              componentJSX: (
                <Remove
                  item={combined}
                  onRemoveDone={(event) => listenerRemoveCombine(event.value)}
                  label={"Delete"}
                />
              ),
            };
            actions.push(action);
          }
        }

        const itemType =
          type === "products" ? "COMBINED_PRODUCT" : "COMBINED_STRATEGY";
        const key = type === "products" ? "product" : "strategy";

        const combinedStrategy = {
          benchmark: backtest.benchmark,
          currency: backtest.currency,
          id: loadedStrategyId ? parseInt(loadedStrategyId) : null,
          name: loadedStrategyId
            ? getCombinedStrategy(loadedStrategyId)?.name ?? ""
            : Object.values(strategiesNames)
                .map((item) => item.name)
                .join(" - "),
          period: {
            type: backtest.period.period.type,
            value: backtest.period.period.value,
          },
          type: itemType,
        };

        for (const [i, row] of tableData.entries()) {
          combinedStrategy[`${key}${i + 1}`] = {
            id: parseInt(row.id!),
            value: row.weight,
          };
        }

        action = {
          componentJSX: (
            <SaveActionCombinedStrategies
              onClickCallback={() => onClickSave(combinedStrategy)}
              itemToSave={combinedStrategy}
            />
          ),
        };
        actions.push(action);

        // export
        action = {
          componentJSX: (
            <Export
              data={{
                result: { combined: actionsInputStorage.current.export },
                strategy: combinedStrategy,
              }}
            />
          ),
        };

        actions.push(action);

        action = {
          componentJSX: (
            <ReportButton
              widgets={
                holdingTabTableColumns != null
                  ? {
                      table: new TableWrapper({
                        filter: inputStubExport.filter,
                        grid: inputStubExport.viewer,
                        tools: inputStubExport.tools,
                      }),
                    }
                  : undefined
              }
              page="strategyLongShort"
              rankingCache={null}
              storage={runAPI}
              target={combinedStrategy}
              title={combinedStrategy.name}
              usage={{
                function: "STRATEGY_LONG_SHORT",
              }}
            />
          ),
        };

        actions.push(action);

        break;
      }
    }

    var message = {
      from: "COMBINE STRATEGY",
      content: {
        actions: actions,
      },
    };
    broadcast(config["channels"]["workflow"]["input"], message);
  }, [
    _listenerActionSave,
    backtest.benchmark,
    backtest.currency,
    backtest.period.period.type,
    backtest.period.period.value,
    broadcast,
    getCombinedStrategy,
    holdingTabTableColumns,
    id,
    inputStubExport.filter,
    inputStubExport.tools,
    inputStubExport.viewer,
    listenerRemoveCombine,
    loadedStrategyId,
    onClickSave,
    run,
    runAPI,
    strategiesNames,
    tableData,
    type,
    userId,
    workflowStatus,
  ]);

  const resultsMemoized = useMemo(() => {
    return (
      <Box flex={1}>
        {results && (
          <Result
            helpEnabled={helpEnabled}
            hideRationaleBtn={true}
            value={results}
            runManager={runAPI}
            tabs={tabs}
            dashboard={{
              V1: undefined,
              V2: undefined,
              status: "loading",
            }}
          />
        )}
      </Box>
    );
  }, [helpEnabled, results, runAPI, tabs]);

  const selectStrategyFromList = useCallback(
    async (resource) => {
      if (type === "strategies") {
        onSelectCombined(resource.id);
      } else {
        await getCombined(resource.id);
        setLoadedStrategyId(resource.id);
      }
    },
    [getCombined, onSelectCombined, type]
  );

  const [removeTarget, setRemoveTarget] = useState<any>();

  const showRelationsDialog = useMemo(
    () => removeTarget != null,
    [removeTarget]
  );

  const closeRelationDialog = useCallback(() => setRemoveTarget(undefined), []);

  const menuDispatcher = useCallback(
    async (action, resource) => {
      switch (action) {
        case "actionRun": {
          if (type === "strategies") {
            navigate(`/app/strategies/combined/${resource.id}/run`);
          } else {
            if (resource) {
              const dataForTable: {
                id: undefined | string;
                weight: undefined | number;
              }[] = [];
              let _resource = resource;

              //#region - if shared get full data
              if ("isReadOnly" in resource && resource.isReadOnly === true) {
                _resource = await combinedStrategiesAPI.get(resource.id);
              }
              //#endregion

              for (const key in _resource) {
                if (key.startsWith("product")) {
                  dataForTable.push({
                    id: _resource[key]["id"],
                    weight: _resource[key]["value"],
                  });
                }
              }

              runAPI.setEntitites(dataForTable);

              const backtestParams = {
                benchmark: _resource.benchmark?.item?.symbol ?? null,
                currency: _resource.currency,
                period: {
                  period: {
                    type: _resource?.period?.type ?? "YEAR",
                    value: _resource?.period?.value ?? 8,
                  },
                },
              };

              runAPI.setCombineParams(backtestParams);
              selectStrategyFromList(_resource);

              run();
            }
          }

          break;
        }

        case "actionRename": {
          setItemToRename(resource);

          break;
        }

        case "actionDelete": {
          setRemoveTarget(resource);

          // const action = new ActionDelete({
          //   item: resource,
          //   label: "Delete",
          // });
          // action.startup();
          // if (!action.get("disabled")) {
          //   action.on("remove-done", () => listenerRemoveCombine(resource));
          // }

          // action.doAction();

          break;
        }
      }
    },
    [combinedStrategiesAPI, navigate, run, runAPI, selectStrategyFromList, type]
  );

  const closeRenameDialog = useCallback(() => {
    setItemToRename(undefined);
  }, []);

  const onRename = useCallback(
    async (name) => {
      const resourceToRename = { ...itemToRename };
      resourceToRename.name = name;

      try {
        await combinedStrategiesAPI.update(resourceToRename);
        closeRenameDialog();
        const [channel, msg] = messageSuccess(`${name} saved successfully`);
        broadcast(channel as string, msg);
        refreshList();
      } catch (error) {
        console.log(error);
        const [channel, msg] = messageError(`Failed saving ${name}`);
        broadcast(channel as string, msg);
      }
    },
    [
      broadcast,
      closeRenameDialog,
      combinedStrategiesAPI,
      itemToRename,
      refreshList,
    ]
  );

  const openStrategy = useCallback(
    async (id) => {
      const temp: any = deepClone(strategiesNames[id]);
      const getType = () => {
        switch (temp.type) {
          case "PREFERENCE_INDEX":
            return "strategies";
          case "SYSTEMATIC_PRODUCT":
            return "systematic-portfolios";
        }
      };
      const getEntityType = () => {
        switch (temp.entity_type) {
          case "SMART_BETA":
            return "smart-beta";
          case "BUILDER":
            return "advanced";
          case "ALPHA":
            return "alpha";
          case "SECTOR_ROTATION":
            return "macro-rotation";
        }
      };
      let url: any = null;
      const typeOfObj = getType();
      switch (typeOfObj) {
        case "strategies":
          url = `/${typeOfObj}/builder/${getEntityType()}/${id}`;
          break;
        case "systematic-portfolios":
          url = `/${typeOfObj}/${id}`;
          break;
      }
      if (url) {
        const baseURL = window.location.origin + "/app";
        const tempLink = document.createElement("a");
        tempLink.href = baseURL + url;
        tempLink.target = "_blank";
        tempLink.click();
        tempLink.remove();
      }
    },
    [strategiesNames]
  );

  const closeEditor = useCallback(() => setActiveEditor(null), []);

  return (
    <ErrorBoundary
      fallback={
        <Typography>
          An Error occures during your last operation, please refresh the page
          and try again, if the issue persist, contact our customers support
        </Typography>
      }
    >
      {showRelationsDialog ? (
        <DialogRelations
          close={closeRelationDialog}
          itemToDelete={removeTarget}
          onRemoveDone={(e) => listenerRemoveCombine(e.value)}
          isUnsubscribe={false}
        />
      ) : (
        <></>
      )}
      <LoaderContext.Provider
        value={{
          availableEvents: STRATEGIES_LOADER_EVENTS,
          on,
          remove,
          dispatch,
        }}
      >
        {itemToRename && (
          <DialogSaveComponent
            item={{
              name: itemToRename?.name ?? "",
            }}
            dialogType={"Compare Strategy"}
            onSave={null}
            onSaveAs={null}
            onRename={onRename}
            hide={closeRenameDialog}
          />
        )}
        {showSaveDialog && saveDialog}
        {error && (
          <Modal
            buttonsEnalbed={true}
            closeIcon={false}
            buttons={[
              {
                name: "Cancel",
                callback: () => setError(undefined),
                variant: "cancel",
              },
            ]}
          >
            <Typography>{error}</Typography>
          </Modal>
        )}
        <Box className={styles.pageWrapper}>
          <Box className={styles.editorCol}>
            <Box>
              <Box className={styles.navigatorWidgetBox}>
                <Typography variant="subtitle1">
                  {selectedStrategyName}
                </Typography>
                <Select
                  size="small"
                  variant="outlined"
                  defaultValue={"load"}
                  placeholder="Load"
                  onOpen={onOpenCombinedSelect}
                >
                  <MenuItem disabled sx={{ display: "none" }} value={"load"}>
                    Load
                  </MenuItem>
                  {loadingNavigatorOpts ? (
                    <DropdownSkeleton />
                  ) : (
                    <Box>
                      {Object.keys(combinedStrategies.current).map((key) => (
                        <MenuItem
                          key={uuidv4()}
                          value={key}
                          onClick={() => onSelectCombined(key)}
                        >
                          <Typography>
                            {combinedStrategies.current[key].name}
                            &nbsp;&nbsp;
                            {combinedStrategies.current[key].isReadOnly && (
                              <div className="sharedObjectIndicator"></div>
                            )}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Box>
                  )}
                </Select>
              </Box>
            </Box>

            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <PreviewCard
                title={
                  type === "strategies" ? "Strategies" : "Systematic Portfolios"
                }
                cardHandler={() => setActiveEditor("strategies")}
                content={
                  <Box width={"100%"}>
                    <table className={styles.previewTable}>
                      <thead>
                        <tr>
                          <td></td>
                          <td style={{ textAlign: "right" }}>Allocation</td>
                          <td style={{ textAlign: "right" }}>Weight</td>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((row) => {
                          return (
                            <tr key={uuidv4()}>
                              <td>
                                {row.id ? (
                                  <>
                                    <strong>
                                      {strategiesNames?.[row.id]?.name ??
                                        "Not Set"}
                                    </strong>{" "}
                                    {strategiesNames?.[row.id]?.readOnly ===
                                      true && (
                                      <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
                                    )}
                                  </>
                                ) : (
                                  <strong>Not Set</strong>
                                )}
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  color:
                                    (row.weight ?? 1) >= 0 ? "green" : "red",
                                }}
                              >
                                <strong>
                                  {(row.weight ?? 1) >= 0 ? "Long" : "Short"}
                                </strong>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <strong>{formatPercentage(row.weight)}</strong>
                              </td>
                              {strategiesNames?.[row.id!]?.name && (
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "right",
                                  }}
                                >
                                  <StyledBtn
                                    openStrategy={openStrategy}
                                    row={row}
                                    type={type}
                                  >
                                    <OpenInNewIcon
                                      fontSize={"small"}
                                      sx={{
                                        fontSize: "0.7vw !important",
                                        color: "#2a7090",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </StyledBtn>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Box>
                }
              />
              <PreviewCard
                title={"Backtest"}
                cardHandler={() => setActiveEditor("backtest")}
                content={
                  <Box width={"100%"}>
                    <table width={"100%"}>
                      <tbody>
                        <tr>
                          <td>Benchmark</td>
                          <td>
                            <strong>{formattedBenchmark}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Currency</td>
                          <td>
                            <strong>{backtest.currency}</strong>
                          </td>
                        </tr>
                        {type !== "products" && (
                          <tr>
                            <td>
                              {backtest.period.period.type === "YEAR"
                                ? "Period"
                                : "Period from"}
                            </td>
                            <td>
                              <strong>
                                {backtest.period.period.type === "YEAR"
                                  ? `${backtest.period.period.value} years`
                                  : backtest.period.period.value}
                              </strong>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Box>
                }
              />
            </Box>

            <CombineList
              refreshTrigger={refreshTrigger}
              type={type}
              handleListItemClick={selectStrategyFromList}
              handleMenuActions={menuDispatcher}
            />
          </Box>

          <EditorContext.Provider
            value={{
              strategiesData: tableData,
              setStrategiesData: setTableData,
              backtest,
              setBacktestData: setBacktest,
              type,
            }}
          >
            <Box className={styles.resultCol}>
              {activeEditor != null ? (
                <EditorPanel onCancel={closeEditor} ediorType={activeEditor!} />
              ) : (
                <>{resultsMemoized}</>
              )}
            </Box>
          </EditorContext.Provider>
          <Loader />
        </Box>
      </LoaderContext.Provider>
    </ErrorBoundary>
  );
}

const DropdownSkeleton = () => {
  return (
    <div>
      <Typography component="div" variant={"caption"}>
        <Skeleton />
      </Typography>
      <Typography component="div" variant={"caption"}>
        <Skeleton />
      </Typography>
      <Typography component="div" variant={"caption"}>
        <Skeleton />
      </Typography>
      <Typography component="div" variant={"caption"}>
        <Skeleton />
      </Typography>
      <Typography component="div" variant={"caption"}>
        <Skeleton />
      </Typography>
    </div>
  );
};

const WeightSelector = ({ weight, updateWeight }: WeightSelectorProps) => {
  const [isLong, setIsLong] = useState((weight ?? 1) > 0);
  const [weightValue, setWeightValue] = useState<undefined | string>(
    `${(weight ?? 1) * 100}`
  );

  const wrapUpdateWeights = useCallback((e) => {
    const value = e.target.value;
    setWeightValue(e.target.value);

    if (value) {
      setIsLong(value >= 0);
    }
  }, []);

  const toggleIsLong = useCallback(() => {
    setWeightValue((oldValue) => {
      if (oldValue) {
        const numberValue = parseInt(oldValue);

        if (!isNaN(numberValue)) {
          return `${numberValue * -1}`;
        }
      }
      return oldValue;
    });
    setIsLong((prev) => !prev);
  }, []);

  useEffect(() => {
    const updatedWeight = parseInt(weightValue ?? "0") / 100;
    updateWeight(updatedWeight);
  }, [isLong, updateWeight, weightValue]);

  return (
    <Box className={styles.weightSelector}>
      <Box className={styles.weightSelectorLabel}>
        <Typography
          onClick={toggleIsLong}
          className={isLong ? `${styles.selected}` : ""}
        >
          Long
        </Typography>
        <Typography className={styles.selected}>/</Typography>
        <Typography
          onClick={toggleIsLong}
          className={!isLong ? `${styles.selected}` : ""}
        >
          Short
        </Typography>
      </Box>
      <TextField
        inputProps={{ className: styles.tr_small }}
        InputProps={{ endAdornment: "%" }}
        type="number"
        value={weightValue}
        onChange={wrapUpdateWeights}
        sx={{
          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
            {
              WebkitAppearance: "none",
              margin: 0,
            },
          "input[type=number]": {
            MozAppearance: "textfield",
          },
        }}
      />
    </Box>
  );
};

const EditorPanel = memo(({ ediorType, onCancel }: EditorPanelProps) => {
  const { dispatch } = useEventBus();
  const { strategiesData, setStrategiesData } = useContext(EditorContext);

  const onApply = useCallback(() => {
    dispatch("apply-changes");
    onCancel();
  }, [dispatch, onCancel]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          flexDirection: "column",
        }}
        style={{
          width: "100%",
          backgroundColor: "transparent",
        }}
      >
        <Card sx={{ boxShadow: 3 }}>
          <CardContent
            sx={{
              pb: "16px !important",
              display: "flex",
              gap: 2,
            }}
          >
            {ediorType === "strategies" && (
              <StrategySelector
                setData={setStrategiesData}
                data={strategiesData}
              />
            )}
            {ediorType === "backtest" && <BacktestEditor />}
          </CardContent>
        </Card>

        <Card sx={{ overflow: "unset", boxShadow: 3 }}>
          <CardContent
            sx={{
              pb: "16px !important",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button type="button" onClick={() => onApply()}>
              Apply rule
            </Button>
            <Button
              sx={{
                ml: 2,
              }}
              variant="tr_button_cancel"
              type="button"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </CardContent>
        </Card>
      </Box>
    </>
  );
});

const BacktestEditor = () => {
  const { backtest, setBacktestData, type } = useContext(EditorContext);

  const initValue = deepClone(backtest);

  const [benchmark, setBenchmark] = useState<string | null>(
    initValue.benchmark
  );
  const [currency, setCurrency] = useState<string | null>(initValue.currency);
  const [period, setPeriod] = useState<{
    period: { type: "DAY" | "YEAR"; value: number | string };
  }>(initValue.period);

  const environment = useEnvironment();
  const { on, remove } = useEventBus();

  const onApplyChanges = useCallback(() => {
    const updatedBacktest = {
      benchmark,
      currency,
      period,
    };

    setBacktestData(updatedBacktest);
  }, [benchmark, currency, period, setBacktestData]);

  useEffect(() => {
    on("apply-changes", onApplyChanges);

    return () => remove("apply-changes", onApplyChanges);
  }, [on, onApplyChanges, remove]);

  //#region -- getting ui contraints based on the product
  const UI_CONSTRAINTS: UI_CONSTRAINTS_TYPE = useMemo(() => {
    const config =
      environment.get("account").product?.configuration?.strategy_builder
        .form_advanced.uiOptions;

    // Disable some benchmarks for the combine
    config.strategy.benchmarks.neutral.enabled = false;
    config.strategy.benchmarks.neutralEqual.enabled = false;
    config.strategy.benchmarks.blended.enabled = true;

    return config;
  }, [environment]);
  //#endregion

  const onBenchmarkChange = useCallback(
    (event) => {
      const benchmarkValue = event.payload;
      if (benchmark !== benchmarkValue) {
        setBenchmark(benchmarkValue);
      }
    },
    [benchmark]
  );

  const onCurrencyChange = useCallback((event) => {
    const CurrencyValue = event.payload;

    setCurrency(CurrencyValue);
  }, []);

  const onChangePeriodType = useCallback((value) => {
    setPeriod((oldState) => {
      const newState = { ...oldState };

      newState["period"]["type"] = value;

      if (value === "YEAR") {
        newState["period"]["value"] = 8;
      } else {
        newState["period"]["value"] = getYesterdayMinus_N_Years(
          oldState["period"]["value"] as number
        );
      }

      return newState;
    });
  }, []);

  const handlePeriodActions = useCallback((action) => {
    switch (action.type) {
      case "SET_PERIOD_VALUE": {
        setPeriod((oldState) => {
          const newState = { ...oldState };

          newState["period"]["value"] = action.payload;

          return newState;
        });
        break;
      }
    }
  }, []);

  return (
    <Box display={"flex"} flex={1}>
      <Box className={styles.editorBacktestFirstRow}>
        <Box flex={1}>
          <Benchmark
            strategyDispatch={onBenchmarkChange}
            input={benchmark}
            UI_CONSTRAINTS={UI_CONSTRAINTS}
          />
        </Box>
        <Box flex={1}>
          <Currency strategyDispatch={onCurrencyChange} input={currency} />
        </Box>
        {type !== "products" && (
          <Box flex={3}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Period
                  onChangeType={onChangePeriodType}
                  periodInputType={period.period.type}
                  state={period}
                  dispatcher={handlePeriodActions}
                />
              </CardContent>
            </Card>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export function CombineList({
  handleListItemClick,
  handleMenuActions,
  type,
  refreshTrigger,
}: CombineListProps) {
  const environment = useEnvironment();
  const { t } = useTranslation();
  const [compareList, setCompareList] = useState<any>([]);
  const { on, remove } = useEventBus();
  const { broadcast } = useBroadcast();

  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const compareAPI = useMemo(
    () =>
      type === "strategies"
        ? new CombinedStrategies(appSetup)
        : new CombinedProducts(appSetup),
    [appSetup, type]
  );

  const baseClass = "ListStrategies";
  const cssClassListItem = baseClass + "-listItem";
  const subsAPI = useMemo(() => new Subscriptions(appSetup), [appSetup]);

  const getComparedList = useCallback(async () => {
    try {
      const userCollection = await compareAPI.get();
      const keyType =
        type === "products" ? "combinedProduct" : "combinedStrategy";
      const subs = await subsAPI.get({ type: keyType });
      const _subs = await subsAPI.getDetails("subscriptions", subs);
      const combinedList = [...userCollection, ..._subs];

      setCompareList(combinedList);
    } catch (error) {
      const [channel, msg] = messageError(error);
      broadcast(channel as string, msg);
    }
  }, [broadcast, compareAPI, subsAPI, type]);

  useEffect(() => {
    getComparedList();
  }, [getComparedList, refreshTrigger]);

  useEffect(() => {
    on("refresh-compare-list", getComparedList);

    return () => {
      remove("refresh-compare-list", getComparedList);
    };
  }, [getComparedList, on, remove]);

  const actions: any = useMemo(
    () => [
      {
        label: "Run",
        type: "item",
        value: "actionRun",
      },
      {
        label: "Delete",
        type: "item",
        value: "actionDelete",
      },
      {
        label: "Rename",
        type: "item",
        value: "actionRename",
      },
    ],
    []
  );

  const wrapMenuActionHandler = useCallback(
    async (event, resource) => {
      const action = event.value.action;

      handleMenuActions(action, resource);
    },
    [handleMenuActions]
  );

  return (
    <Box minHeight={0} display={"flex"} overflow={"auto"} flex={1}>
      <ol
        className="ListStrategies-list"
        style={{ marginTop: "8px" }}
        data-dojo-attach-point="nodeList"
      >
        {compareList.map((resource) => (
          <li
            key={uuidv4()}
            className={cssClassListItem}
            title={resource.isReadOnly ? t("Show") : t("Edit")}
            onClick={() => handleListItemClick(resource)}
          >
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box display={"flex"} alignItems={"center"} gap={1}>
                {resource.name}
                {resource.isReadOnly && (
                  <div className="sharedObjectIndicator"></div>
                )}
              </Box>
              <RapidMenu
                smallBtn
                onOptionClickHandler={(evt) =>
                  wrapMenuActionHandler(evt, resource)
                }
                options={actions}
                isReadOnly={resource.isReadOnly}
                availableForSharedObject={["actionRun"]}
              />
            </Box>
          </li>
        ))}
      </ol>
    </Box>
  );
}

const StrategySelector = memo(function ({
  data,
  setData,
}: StrategySelectorProps) {
  const { type } = useContext(EditorContext);
  const { on, remove } = useEventBus();
  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const smsAPI = useMemo(() => new SystematicProducts(appSetup), [appSetup]);
  const strategiesAPI = useMemo(() => new Strategies(appSetup), [appSetup]);
  const userId = useMemo(
    () => appSetup.account.user.id,
    [appSetup.account.user.id]
  );
  const [list, setList] = useState([]);
  const [loadingLists, setLoadingLists] = useState(true);
  const [openPanel, setOpenPanel] = useState(false);

  const [tableInfo, setTableInfo] = useState<any>([]);
  const weights = useRef<any>({});
  const activeRowIndex = useRef<number | null>(null);

  const setWeights = useCallback((data) => {
    weights.current = data;
  }, []);

  const storeInitialWeights = useCallback(
    (data) => {
      const weightsMap = {};

      for (let i = 0; i < data.length; i++) {
        weightsMap[i] = data[i].weight;
      }

      setWeights(weightsMap);
    },
    [setWeights]
  );

  const getTableInfo = useCallback(async () => {
    const ids: any = [];
    const tableData: any = [];

    for (const row of data) {
      if (row.id != null) {
        ids.push(row.id);
      }

      tableData.push({
        id: row.id,
        name: undefined,
        isReadOnly: false,
      });
    }

    storeInitialWeights(data);

    if (ids.length) {
      const dataAsMap = tableData.reduce((prev, current, index) => {
        // Use index to keep the sort through trasformation from array to map
        prev[index] = { ...current, index };

        return prev;
      }, {});

      if (type === "products") {
        const fetchResponse = await smsAPI.fetch({
          ids,
          properties: ["name", "ownerId"],
        });

        const responseMap = fetchResponse.reduce((prev, current) => {
          prev[current.id] = { ...current };

          return prev;
        }, {});

        for (const key in dataAsMap) {
          dataAsMap[key] = {
            name: responseMap[dataAsMap[key]["id"]]["name"],
            isReadOnly: responseMap[dataAsMap[key]["id"]]["ownerId"] !== userId,
            id: responseMap[dataAsMap[key]["id"]]["id"],
            index: key,
          };
        }

        return Object.values(dataAsMap).sort((a: any, b: any) =>
          a.index > b.index ? 1 : -1
        );
      } else {
        const resourceResponse = await strategiesAPI.fetch(ids, [
          "name",
          "ownerId",
        ]);

        const response = resourceResponse?.data?.[0]?.rows;

        if (response) {
          const responseMap = response.reduce((prev, current) => {
            prev[current.id] = { ...current };

            return prev;
          }, {});

          for (const key in dataAsMap) {
            dataAsMap[key] = {
              name: responseMap[dataAsMap[key]["id"]]["name"],
              isReadOnly:
                responseMap[dataAsMap[key]["id"]]["ownerId"] !== userId,
              id: responseMap[dataAsMap[key]["id"]]["id"],
              index: key,
            };
          }

          return Object.values(dataAsMap).sort((a: any, b: any) =>
            a.index > b.index ? 1 : -1
          );
        }
      }
    }

    return tableData;
  }, [data, smsAPI, storeInitialWeights, strategiesAPI, type, userId]);

  const renderTable = useCallback(
    (tableSchema) => setTableInfo(tableSchema),
    []
  );

  const onComponentMount = useCallback(async () => {
    const tableData = await getTableInfo();
    renderTable(tableData);
  }, [getTableInfo, renderTable]);

  const tableTitle = useMemo(
    () => (type === "strategies" ? "Strategies" : "Systematic Portfolios"),
    [type]
  );

  const getProducts = useCallback(async () => {
    return await smsAPI.getList();
  }, [smsAPI]);
  const getStrategies = useCallback(async () => {
    return await strategiesAPI.getList();
  }, [strategiesAPI]);

  const formatAsList = useCallback(
    (rawList) => {
      return rawList.map((list) => {
        return {
          id: list.id,
          name: list.name,
          isSubscribed: list.isReadOnly,
          type: type === "products" ? "Product" : "Strategy",
        };
      });
    },
    [type]
  );

  const onDeleteRow = useCallback(
    (idx) => {
      const tableInfoCopy = JSON.parse(JSON.stringify(tableInfo));
      const weightsCopy = { ...weights.current };

      delete weightsCopy[idx];

      setWeights(weightsCopy);
      tableInfoCopy.splice(idx, 1);

      renderTable(tableInfoCopy);
    },
    [renderTable, setWeights, tableInfo]
  );

  const onAddRow = useCallback(() => {
    const tableInfoCopy = JSON.parse(JSON.stringify(tableInfo));

    setWeights({ ...weights.current, [tableInfoCopy.length]: 1 });
    tableInfoCopy.push({
      id: undefined,
      weight: 1,
      isReadOnly: false,
      name: undefined,
    });

    renderTable(tableInfoCopy);
  }, [renderTable, setWeights, tableInfo]);

  const onOpenPanel = useCallback(
    async (rowIndex) => {
      activeRowIndex.current = rowIndex;
      setLoadingLists(true);
      setOpenPanel(true);
      const collections =
        type === "products" ? await getProducts() : await getStrategies();

      setList(formatAsList(collections));
      setLoadingLists(false);
    },
    [formatAsList, getProducts, getStrategies, type]
  );

  const updateTable = useCallback(
    (row, rowIndex) => {
      // Deep copy of state
      const table = [...JSON.parse(JSON.stringify(tableInfo))];

      table.splice(rowIndex, 1, row);
      renderTable(table);
    },
    [renderTable, tableInfo]
  );

  const closePanel = useCallback(() => {
    setOpenPanel(false);
    activeRowIndex.current = null;
  }, []);

  const onSelectResource = useCallback(
    (id) => {
      if (list != null && list.length) {
        const collection: any = list.find((r: any) => r.id === id);

        if (collection) {
          const rowIndex = activeRowIndex.current;

          if (rowIndex != null) {
            const row = { ...tableInfo[rowIndex] };

            row["id"] = id;
            row["name"] = collection.name;
            row["isReadOnly"] = collection.isSubscribed;

            updateTable(row, rowIndex);
          }
        }
      }

      closePanel();
    },
    [list, closePanel, tableInfo, updateTable]
  );

  const updateWeight = useCallback(
    (value, rowIdx) => {
      setWeights({ ...weights.current, [rowIdx]: value });
    },
    [setWeights]
  );

  const applyChanges = useCallback(() => {
    const data: any = [];
    let row: any = null;

    for (let i = 0; i < tableInfo.length; i++) {
      row = tableInfo[i];
      data.push({ id: row.id, weight: weights.current[i] });
    }

    setData(data);
  }, [setData, tableInfo, weights]);

  useEffect(() => {
    onComponentMount();
  }, [onComponentMount]);

  useEffect(() => {
    on("apply-changes", applyChanges);

    return () => remove("apply-changes", applyChanges);
  }, [applyChanges, on, remove]);

  return (
    <Card sx={{ width: "100%" }}>
      <CardContent>
        <PanelForLists
          headerTitle={`Select ${
            type === "products" ? "Systematic Portfolio" : "Strategy"
          }`}
          selectItem={onSelectResource}
          showDialog={openPanel}
          isLoadingData={loadingLists}
          closeDialog={closePanel}
          list={list}
          sectionsTag={[type === "products" ? "Product" : "Strategy"]}
        />
        <Box className={styles.strategySelectorListbox} mb={1}>
          <Box className={styles.strategySelectorHeader}>
            <h3>{tableTitle}</h3>
          </Box>
          <ul className={styles.strategySelector}>
            {tableInfo.map((row, index) => (
              <li key={uuidv4()} className={styles.strategySelectorListItem}>
                <Box flex={1} className={styles.strategyNameCell}>{`${
                  tableTitle === "Systematic Portfolios"
                    ? "Systematic Portfolio"
                    : "Strategy"
                } ${index + 1}`}</Box>
                <Box
                  flex={1}
                  className={styles.strategyBoxHoverable}
                  onClick={() => onOpenPanel(index)}
                >
                  <Typography>
                    {row.name ??
                      `Select ${
                        tableTitle === "Systematic Portfolios"
                          ? "systematic portfolio"
                          : "strategy"
                      }`}{" "}
                    {row["isReadOnly"] === true ? (
                      <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
                    ) : (
                      <></>
                    )}
                  </Typography>
                </Box>
                <Box flex={1}>
                  <WeightSelector
                    weight={weights.current[index]}
                    updateWeight={(weight) => updateWeight(weight, index)}
                  />
                </Box>
                <Box flex={1}>
                  {index + 1 > 2 ? (
                    <button
                      className={styles.deleteButton}
                      onClick={() => onDeleteRow(index)}
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        className="i-delete"
                      ></span>
                    </button>
                  ) : (
                    ""
                  )}
                </Box>
              </li>
            ))}
          </ul>
        </Box>
        <Button
          onClick={onAddRow}
          variant="contained"
          disabled={tableInfo.length > 6}
        >
          Add {type === "strategies" ? "Strategy" : "Product"}
        </Button>
      </CardContent>
    </Card>
  );
});
