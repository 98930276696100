import { Box, LinearProgress, Tab, Tabs, Typography } from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import Modal from "../../../../../../../../components/Modal/Modal";
import { deepClone } from "../../../../../../../../deepClone";
import { StrategiesInterface } from "../../../../../../storage/StrategiesStorage";
import { Product } from "../../../../../../storage/SystematicPortfoliosStorage";
import { LoaderContext } from "../../../StrategiesBuilder";
import { Analytics } from "./tabs/Analytics/Analytics";
import Chart from "./tabs/Chart/Chart";
import { HistoricalAllocation } from "./tabs/HistoricalAllocation/HistoricalAllocation";
import Holdings from "./tabs/Holdings/Holdings";
import { Dashboards, Insights } from "./tabs/Insights/Insights";
import KeyFacts from "./tabs/KeyFacts/KeyFacts";
import { ProductHoldings } from "./tabs/ProductHoldings/ProductHoldings";
import { ProductKeyFacts } from "./tabs/ProductKeyFacts/ProductKeyFacts";
import { ProductOverviewTab } from "./tabs/ProductOverviewTab/ProductOverviewTab";
import { StrategyDefinition } from "./tabs/StrategyDefinition/StrategyDefinition";

type RunError = {
  title: string;
  message: string;
} | null;

type TabsValue =
  | "keyFacts"
  | "chart"
  | "analytics"
  | "holdings"
  | "definition"
  | "productOverview"
  | "productKeyFacts"
  | "productAllocation"
  | "productHoldings"
  | "historicalAllocation"
  | "insights";

type PromiseError =
  | { data: undefined; status: number; errorDetails: any }
  | undefined;

type Props = {
  value: any;
  runManager: StrategiesInterface;
  helpEnabled?: boolean;
  cleanup?: Function;
  hideRationaleBtn?: boolean;
  tabs?: TabsValue[];
  initialTab?: TabsValue;
  getCurrentTab?: (tab: StrategyComputedTabType) => void;
  page?: string;
  askBeforeInsightsRedirect?: boolean;
  dashboard: Dashboards;
};
export type StrategyComputedTabType =
  | "keyFacts"
  | "chart"
  | "analytics"
  | "holdings"
  | "strategyPerformances"
  | "definition"
  | "productOverview"
  | "productKeyFacts"
  | "productAllocation"
  | "productHoldings"
  | "historicalAllocation"
  | "insights";

export default function Result({
  value,
  runManager,
  cleanup,
  hideRationaleBtn = false,
  helpEnabled = false,
  tabs = ["keyFacts", "chart", "analytics", "holdings", "insights"],
  initialTab,
  getCurrentTab,
  page,
  askBeforeInsightsRedirect = false,
  dashboard,
}: Props) {
  //TODO: find a way to remove value (probably is redundant)
  const [choosenTab, setChoosenTab] = useState<StrategyComputedTabType>(
    initialTab ?? "keyFacts"
  );
  const [sectionToRender, setSectionToRender] = useState<any>(null);
  const [runErrorModal, setRunErrorModal] = useState<RunError>(null);
  const { dispatch, availableEvents } = useContext(LoaderContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (getCurrentTab) {
      getCurrentTab(choosenTab);
    }
  }, [choosenTab, getCurrentTab]);

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: StrategyComputedTabType) => {
      setChoosenTab(newValue);
    },
    []
  );

  const onFinishRun = useCallback(() => {
    dispatch("run-finish", false);
  }, [dispatch]);

  const clearProgresses = useCallback(() => {
    dispatch(availableEvents.clearProgresses);
  }, [availableEvents.clearProgresses, dispatch]);

  const handleErrors = useCallback(
    async (process: Promise<PromiseError>) => {
      const error = await process;
      if (!error) {
        // Everything is ok no error found during the process
        return;
      }

      // Stop loading process
      dispatch(availableEvents.stopPreload);
      // Clear from pending loading processes
      dispatch(availableEvents.clearProgresses);

      let title = t("Error_running_strategy");
      let message = t("Unknown_error");

      if (error.status === 500) {
        if (error.errorDetails != null && error.errorDetails.code != null) {
          message += ": " + error.errorDetails.code;
          if (error.errorDetails.code === "UNIVERSE_EMPTY") {
            title = t("Universe_is_empty");
            message = t("There_are_no_instruments_in_selected_universe");
          }
        } else {
          // Raw error object fallback
          const errorMessage = (error as any)?.response?.code;

          title = "Error";
          message = errorMessage;
        }
      } else if (error.status === 400) {
        title = t("Error_running_strategy");
        message = t("Unknown_error");
        if (value?.strategy?.params?.hedging != null) {
          message = "Unknown error maybe caused by hedging settings";
        }
      }

      setRunErrorModal({ title: title, message: message });
      dispatch("run-finish", true);
    },
    [
      availableEvents.clearProgresses,
      availableEvents.stopPreload,
      dispatch,
      t,
      value?.strategy?.params?.hedging,
    ]
  );

  const dataGetKeyFacts: (
    storage: StrategiesInterface,
    choosenTab,
    page?: string
  ) => Promise<PromiseError> = useCallback(
    async (storage, choosenTab) => {
      try {
        const keyFacts = await storage.getAnalytics("keyFacts");
        if (keyFacts) {
          onFinishRun();
          setSectionToRender(
            <KeyFacts
              isUsedInCompareStrategies={page === "compare"}
              value={keyFacts?.[choosenTab] as any}
            />
          );
        }
      } catch (error) {
        return error as PromiseError;
      }
    },
    [onFinishRun, page]
  );

  const dataGetProductKeyFacts = useCallback(
    async (storage: Product, render) => {
      const keyFacts = await storage.getAnalytics("keyFacts");
      const analytics = await storage.getAnalytics("analytics");

      let analyticsProps = deepClone(value);
      analyticsProps.analytics = {
        strategy: value.strategy,
        strategyInstrumentBenchmark: value.strategyInstrumentBenchmark,
        analytics,
      };

      render(
        <ProductKeyFacts
          analyticsProps={analyticsProps}
          keyFactsProps={keyFacts.keyFacts}
        />
      );
    },
    [value]
  );

  const dataGetProductHoldings = useCallback(
    async (storage: Product, render) => {
      const product = await storage.holdings();
      const productWithSnapshot = await storage.snapshot();
      const systematicProduct = storage.info();

      render(
        <ProductHoldings
          dataForExport={productWithSnapshot.snapShot}
          holdings={product.holdings}
          systematicPortfolio={systematicProduct}
        />
      );
    },
    []
  );

  useEffect(() => {
    setSectionToRender(<Loader />);

    switch (choosenTab) {
      case "keyFacts":
        handleErrors(dataGetKeyFacts(runManager, choosenTab));

        break;
      case "chart":
        dataGetCurves(value, runManager, setSectionToRender, onFinishRun);

        break;
      case "productAllocation":
      case "holdings":
        handleErrors(
          dataGetHoldingsHistory(
            value,

            runManager,
            setSectionToRender,
            hideRationaleBtn,
            onFinishRun
          )
        );

        break;
      case "analytics":
        handleErrors(
          dataGetAnalytics(
            value,
            runManager,
            setSectionToRender,
            onFinishRun,
            page
          )
        );

        break;

      case "definition": {
        dataGetStrategyDefinition(value, setSectionToRender);
        onFinishRun();
        dispatch(availableEvents.stopPreload);
        break;
      }

      case "productOverview": {
        setSectionToRender(
          <ProductOverviewTab product={runManager as Product} />
        );

        break;
      }

      case "productHoldings": {
        dataGetProductHoldings(runManager as Product, setSectionToRender);

        break;
      }

      case "productKeyFacts": {
        dataGetProductKeyFacts(runManager as Product, setSectionToRender);

        break;
      }

      case "historicalAllocation": {
        setSectionToRender(
          <HistoricalAllocation product={runManager as Product} />
        );

        break;
      }

      case "insights": {
        setSectionToRender(
          <Insights
            askBeforeInsightsRedirect={askBeforeInsightsRedirect}
            dashboards={dashboard}
          />
        );

        break;
      }

      default:
        setSectionToRender(<>ERROR</>);
        break;
    }

    clearProgresses();
  }, [
    askBeforeInsightsRedirect,
    availableEvents.stopPreload,
    choosenTab,
    clearProgresses,
    dashboard,
    dataGetKeyFacts,
    dataGetProductHoldings,
    dataGetProductKeyFacts,
    dispatch,
    handleErrors,
    hideRationaleBtn,
    onFinishRun,
    page,
    runManager,
    value,
  ]);

  const onCloseErrorModal = useCallback(() => {
    setRunErrorModal(null);

    if (cleanup) {
      cleanup();
    }
  }, [cleanup]);

  const tabLabelsDict = useMemo(
    () => ({
      keyFacts: "Key facts",
      chart: "Chart",
      analytics: "Analytics",
      holdings: "Holdings",
      definition: "Definition",
      productOverview: "Overview",
      productKeyFacts: "Analytics",
      productAllocation: "Allocation",
      productHoldings: "Holdings",
      historicalAllocation: "Historical portfolio",
      insights: "Insights",
    }),
    []
  );

  return (
    <Box
      sx={{ width: "100%" }}
      height={"100%"}
      overflow={"hidden"}
      display="flex"
      flexDirection={"column"}
    >
      {runErrorModal != null ? (
        <Modal
          closeIcon={false}
          onClose={onCloseErrorModal}
          buttons={[
            {
              name: "Cancel",
              callback: () => setRunErrorModal(null),
              variant: "cancel",
            },
          ]}
          headerConfig={{ headerContent: runErrorModal.title }}
        >
          {runErrorModal.message}
        </Modal>
      ) : null}
      <Box px={1}>
        <Tabs
          sx={{ borderBottom: 1, borderColor: "divider" }}
          value={choosenTab}
          onChange={handleChange}
        >
          {/* //! default color must be black otherwise using grey the tab seems to be disabled */}

          {tabs.map((tab) => {
            if (tab === "insights") {
              if (helpEnabled) {
                return (
                  <Tab
                    key={uuidv4()}
                    label={tabLabelsDict[tab]}
                    sx={{ color: "#000" }}
                    value={tab}
                  />
                );
              }
              return null;
            }

            return (
              <Tab
                key={uuidv4()}
                label={tabLabelsDict[tab]}
                sx={{ color: "#000" }}
                value={tab}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box width={"100%"} height={"100%"} overflow={"auto"} p={1}>
        {sectionToRender}
      </Box>
    </Box>
  );
}

const dataGetAnalytics: (
  results,
  runManager: StrategiesInterface,
  render,
  onFinishRun,
  page
) => Promise<PromiseError> = async (
  results,
  runManager: StrategiesInterface,
  render,
  onFinishRun,
  page
) => {
  try {
    const analytics = await runManager.getAnalytics("analytics");

    var newState = deepClone(results);
    newState.analytics = {
      strategy: results.strategy,
      strategyInstrumentBenchmark: results.strategyInstrumentBenchmark,
      analytics,
    };

    onFinishRun();
    render(<Analytics data={newState} isUsedInCompare={page === "compare"} />);
  } catch (error) {
    return error as PromiseError;
  }
};

const dataGetCurves = async (
  results,
  runManager: StrategiesInterface,
  set,
  onRunFinish
) => {
  const returningValue = {
    strategy: results.strategy,
    strategyInstrumentBenchmark: results.strategyInstrumentBenchmark,
  };

  const curves = await runManager.getCurves();
  const data = returningValue;
  data["historyStrategy"] = curves?.CURVES?.H ?? [];
  data["historyBenchmark"] = curves?.CURVES?.B ?? [];

  if ("long" in curves.CURVES && curves.CURVES.long.length) {
    if (!data["components"]) {
      data["components"] = {};
    }
    data["components"]["long"] = curves.CURVES.long;
  }

  if ("short" in curves.CURVES && curves.CURVES.short.length) {
    if (!data["components"]) {
      data["components"] = {};
    }
    data["components"]["short"] = curves.CURVES.short;
  }

  onRunFinish();
  set(<Chart data={data} />);
};

const dataGetHoldingsHistory: (
  results,
  runManager: StrategiesInterface,
  render,
  isCombinedStrategy,
  onFinishRun
) => Promise<PromiseError> = async (
  results,
  runManager: StrategiesInterface,
  render,
  hideRationaleBtn,
  onFinishRun
) => {
  try {
    const holdingsHistory = await runManager.getAnalytics("holdings");
    const curves = await runManager.getCurves();

    const strategyResults = {
      CURVES: deepClone(curves.CURVES),
      POS: curves.POS.H ? deepClone(curves.POS.H) : [],
      BPOS: curves.POS.B ? deepClone(curves.POS.B) : [],
    };

    let newState = deepClone(results);
    newState.holdings = {
      data: holdingsHistory,
      strategy: results.strategy,
    };
    newState["strategyResult"] = strategyResults;
    onFinishRun();

    render(<Holdings data={newState} hideRationaleBtn={hideRationaleBtn} />);
  } catch (error) {
    return error as PromiseError;
  }
};

const dataGetStrategyDefinition = (ctx, render) => {
  const id = ctx?.compare?.ids?.strategyId ?? null;
  const id2 = ctx?.compare?.ids?.strategyId2 ?? null;

  if (id && id2) {
    render(<StrategyDefinition id={id} id2={id2} />);
  }
};

const Loader = () => (
  <Box
    bgcolor={"white"}
    flex={1}
    display={"flex"}
    height={"100%"}
    width={"100%"}
    alignItems={"center"}
    justifyContent={"center"}
  >
    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
      <Typography align="center">Data is loading, please wait.</Typography>
      <LinearProgress />
    </Box>
  </Box>
);
