import {
  ReleaseNote,
  ReleaseNoteItem,
  ReleaseNoteSection,
} from "./HelpContentReleaseNotes";

type HelpContentReleaseNotesSystematicProps = {
  assetsUrl: string;
};

export const HelpContentReleaseNotesSystematic = ({
  assetsUrl,
}: HelpContentReleaseNotesSystematicProps) => {
  return (
    <div className="releaseNotes product-systematicEngine">
      <h1 className="releaseNotes-title">Release notes</h1>
      <ReleaseNote
        title="15 July 2024 Version 2.50 - What's new"
        filename={`${assetsUrl}/Trendrating_premium--RELEASE_NOTES--v2.50.pdf`}
      >
        <ReleaseNoteSection type="New" title="Popular Screens">
          <ReleaseNoteItem>
            Introducing the new ‘Popular Screens’ functionality in the
            ‘Screening’ section, designed to quickly identify investment
            opportunities and risks across US, Developed Market Europe and Asia
            Pacific.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>
      <ReleaseNote
        title="26 March 2024 Version 2.48 - What's new"
        filename={`${assetsUrl}/Trendrating_premium--RELEASE_NOTES--v2.48.pdf`}
      >
        <ReleaseNoteSection type="New" title="Strategy Builder Insights">
          <ReleaseNoteItem>
            Introducing the new insights section in the strategy builder
            functionality. It is now possible to analyze and compare the alpha
            contributions of any parameter of your choosing. Explore alternative
            sequences and discover the optimal combination to maximize your
            performance. Select a strategy and customize it to your preference
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>
      <ReleaseNote
        title="25 January 2024 Version 2.47 - What's new"
        filename={`${assetsUrl}/Trendrating_premium--RELEASE_NOTES--v2.47.pdf`}
      >
        <ReleaseNoteSection type="New" title="Reports Hub">
          <ReleaseNoteItem>
            Introducing the new Reports Hub, designed to streamline report
            creation through pre-defined templates tailored for specific
            markets, sectors, and individual portfolios. Whether analyzing
            dispersion and market trends, gaining sector-specific insights, or
            ranking individual portfolios, the Reports Hub ensures a seamless
            experience with its user-friendly interface. This efficient and
            resourceful solution offers a quick, convenient way to generate
            professional reports, perfectly aligning with the unique needs of
            specific market segments
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="06 September 2023 Version 2.46 - What's new"
        filename={`${assetsUrl}/Trendrating_premium--RELEASE_NOTES--v2.46.pdf`}
      >
        <ReleaseNoteSection
          type="Improvement"
          title="Customize table fields in the 'Strategy Builder' and 'Systematic
                    Portfolios' sections"
        >
          <ReleaseNoteItem>
            Now, it is possible to configure the columns on the 'Holdings' tab
            by clicking the pencil icon.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
        <ReleaseNoteSection type="New" title="Combine Strategies">
          <ReleaseNoteItem>
            Now, it is possible to seamlessly integrate strategies by creating
            customized combinations of weights for both long and short
            positions.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
        <ReleaseNoteSection
          type="Improvement"
          title="Improved data visualization within ‘Holdings’ tab in the Strategy Builder"
        >
          <ReleaseNoteItem>
            In the Holdings tab, you can now observe both positive and negative
            weights when dealing with long/short combinations.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Combine Strategies">
          <ReleaseNoteItem>
            It is now possible to combine two strategies together in the
            'Strategy Builder' section.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection
          type="New"
          title="Turnover data has been incorporated into the “Strategy Tracker”
          (previously “Systematic Portfolios”) Analytics section."
        ></ReleaseNoteSection>

        <ReleaseNoteSection
          type="New"
          title="Import historical positions in the “Strategy Tracker” (previously
“Systematic Portfolios”)."
        >
          <ReleaseNoteItem>
            Now, it is possible to copy and utilize historical holdings from a
            specific date without the necessity of reimporting each holding from
            Excel. Simply click on "Import" to access the window for this
            process.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection
          type="Improvement"
          title="Enhanced Strategy Sorting in the Strategy Builder"
        >
          <ReleaseNoteItem>
            We have introduced a new and convenient feature within the 'Strategy
            Builder' section: the ability to sort your saved strategies.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="13 February 2023 Version 2.45 - What's new"
        filename={`${assetsUrl}/Trendrating_premium--RELEASE_NOTES--v2.45.pdf`}
      >
        <ReleaseNoteSection type="New" title="Addition of Screening Rules ">
          <ReleaseNoteItem>
            The Screening now supports a larger set of settings to perform
            refined searches for Stocks, Sectors, ETFs, Indices, Currencies and
            Commodities.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
        <ReleaseNoteSection
          type="New"
          title="Benchmark comparison vs. Rating History of all the stocks"
        >
          <ReleaseNoteItem>
            Inside Markets panel, the Rating History now allows for the
            comparison of the AB vs CD rating evolution with a benchmark of
            interest.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
        <ReleaseNoteSection
          type="Improvement"
          title="Report Drag & Drop Functionalities"
        >
          <ReleaseNoteItem>
            The Report Builder now includes a drag and drop approach to
            customize report sections quickly and efficiently.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="22 November 2022 Version 2.44 - What's new"
        filename={`${assetsUrl}/Trendrating--RELEASE_NOTES--v2.44.pdf`}
      >
        <ReleaseNoteSection type="New" title="Market">
          <ReleaseNoteItem>
            The new ETF Dashboard provides a comprehensive view of trend
            developments across the global ETF.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
        <ReleaseNoteSection type="New" title="Strategy builder (advanced)">
          <ReleaseNoteItem>
            The holding rules now allow you to define which positions must be
            kept in the portfolio independently of the selection rules for new
            constituents.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
        <ReleaseNoteSection type="New" title="Systematic Portfolios">
          <ReleaseNoteItem>
            The definition of the systematic portfolio may indicate the annual
            management fees which will be discounted from the historical
            performance of the portfolio.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
        <ReleaseNoteSection type="New" title="Systematic Portfolios">
          <ReleaseNoteItem>
            The definition of the systematic portfolio may indicate the annual
            management fees which will be discounted from the historical
            performance of the portfolio.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
        <ReleaseNoteSection type="Improvement" title="Portfolio Analysis">
          <ReleaseNoteItem>
            The improve tab shows which securities can be valid alternatives to
            risky positions, showing for each negatively rated security a
            similar but positively rated security.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote title="6 July 2022 Version 2.43 - What's new">
        <ReleaseNoteSection type="New" title="Strategy builder (advanced)">
          <ReleaseNoteItem>
            In the definition of the investment universe it is possible to add
            in a black list the instruments that are to be excluded from the
            selection process.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
        <ReleaseNoteSection type="New" title="Strategy builder (advanced)">
          <ReleaseNoteItem>
            As a strategy benchmark it is now possible to configure a blended
            benchmark (ads example 30% SPX + 70% STOXX).
          </ReleaseNoteItem>
        </ReleaseNoteSection>
        <ReleaseNoteSection type="New" title="Portfolio Analysis">
          <ReleaseNoteItem>
            It is now possible to analyze the performance dispersion of a basket
            of stocks.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="3 March 2022 Version 2.42 - What's new"
        filename={`${assetsUrl}/Trendrating--RELEASE_NOTES--v2.42.pdf`}
      >
        <ReleaseNoteSection
          type="New"
          title="Performance dispersion by country, sector, industry and size"
        >
          <ReleaseNoteItem>
            It is now possible to analyze the performance dispersion via tables
            and bar charts to compare the statistics of top and bottom
            performers across markets, sectors, industries and Market Cap
            Universes.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
        <ReleaseNoteSection type="New" title="Portfolio and basket comparison">
          <ReleaseNoteItem>
            Compare each portfolio and basket with another portfolio and analyze
            the differences in ratings, alerts, performance and exposure.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
        <ReleaseNoteSection
          type="Improvement"
          title="Portfolio import from file"
        >
          <ReleaseNoteItem>
            When importing a portfolio or basket from file, it is now possible
            to indicate not only the column that contains the identifier, but
            also any columns that contain the market or currency. This allows
            you to automatically resolve the matching of tickers or ISINs in
            different markets.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="January 2022 Version 2.41 - What's new"
        filename={`${assetsUrl}/Trendrating_SMS--RELEASE_NOTES--v2.41.pdf`}
      >
        <ReleaseNoteSection type="New" title="Alerts Page">
          <ReleaseNoteItem>
            The Alerts page has been added! It includes in a fresh way all
            alerts across your account.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="1 December 2021 Version 2.40 - What's new"
        filename={`${assetsUrl}/Trendrating--RELEASE_NOTES--v2.40.pdf`}
      >
        <ReleaseNoteSection
          type="New"
          title="Performance dispersion and % of captured top and bottom performers"
        >
          <ReleaseNoteItem>
            The updated stats of the % of captured top and bottom performers
            have been added in the performance dispersion section on the Markets
            page.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Track and compare the numbers across markets.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Portfolio Alerts">
          <ReleaseNoteItem>
            Spot in time portfolios which are deteriorating their exposure to
            the trend. Capture major TCR ® changes of portfolios and baskets
            over different time horizons.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Spot emerging upgrades and downgrades across global markets,
            regions, sectors and industries inside your portfolios and baskets.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection
          type="New"
          title="Upgrades/Downgrades report template"
        >
          <ReleaseNoteItem>
            Print the report to get and share all the upgrades and downgrades on
            individual portfolio and basket holdings. Act in time to avoid
            substantial losses.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Tutorials">
          <ReleaseNoteItem>
            Online on-screen tutorials have been added in the Help page. Get the
            opportunity to gain a variety of knowledge bases and to develop
            themes and workflows.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="17 June 2021 Version 2.36 - What's new"
        filename={`${assetsUrl}/Trendrating_SMS--RELEASE_NOTES--v2.36.pdf`}
      >
        <ReleaseNoteSection type="New" title="Strategy Builder">
          <ReleaseNoteItem>
            From today you can backtest strategies at total return level.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Systematic Portfolios">
          <ReleaseNoteItem>
            Create a Systematic Portfolio using a total return strategy to
            compute the portfolio at total return level.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Rank">
          <ReleaseNoteItem>
            Trendrating now incorporate 6 new default rankings criteria's
            templates that combine fundamentals and trend validation metrics to
            boost your analysis.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            An important improvement occurred in the default ranking template.
            Retracement is now entered as a threshold, to enhance the monitoring
            of instruments.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Show ranking changes over a selected period has been moved on the
            top right of the table.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="Improvement" title="Report">
          <ReleaseNoteItem>
            Print current view template has been added to the instruments table
            section in the Screening, Portfolio Analysis and Rank pages.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="Improvement" title="Help">
          <ReleaseNoteItem>
            Rank section with Documents and Excel template is now available in
            the Help page.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="11 May 2021 Version 2.34 - What's new"
        filename={`${assetsUrl}/Trendrating_SMS--RELEASE_NOTES--v2.34.pdf`}
      >
        <ReleaseNoteSection type="New" title="Fundamental analytics">
          <ReleaseNoteItem>
            Trendrating platform now includes a broad variety of fundamental
            data in a straightforward, user-friendly format.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            The ability to look at these two different types of data together
            and perform meaningful analysis with them often leads to significant
            insights.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Click on the button with the pencil icon above the Screening,
            Portfolio Analysis and Rank table to insert them.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            The analytics can be used as selection and ranking parameters to
            design, test and validate advanced strategies.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Rank page">
          <ReleaseNoteItem>
            Create automatic and fast rankings through the new "Rank" page
            available in the platform.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Generate fast rankings on major Indexes through the new rankings in
            subscription available in the settings panel.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="Improvement" title="Report">
          <ReleaseNoteItem>
            Print what you see on the screen functionality has been added to the
            holdings table section.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="01 March 2021 Version 2.30 - What's new"
        filename={`${assetsUrl}/Trendrating_SMS--RELEASE_NOTES--v2.30.pdf`}
      >
        <ReleaseNoteSection
          type="New"
          title="Advanced strategy Builder - Fundamental Factors"
        >
          <ReleaseNoteItem>
            Fundamental factors now available as additional selection, ranking
            and smart beta rules
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection
          type="New"
          title="Advanced strategy Builder - Strategy Comparison"
        >
          <ReleaseNoteItem>
            Compare the performance and key characteristics of two strategies
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Markets">
          <ReleaseNoteItem>
            In depth analysis of trend evolution, dispersion and constituents of
            region, market or sector
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Evaluate by sector classification, geography and size
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Portfolio Analysis">
          <ReleaseNoteItem>
            The portfolio weights can be fixed or drifting
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>

      <ReleaseNote
        title="18 January 2021 Version 2.28 - What's new"
        filename={`${assetsUrl}/Trendrating--RELEASE_NOTES--v2.28.pdf`}
      >
        <ReleaseNoteSection type="New" title="Market Report">
          <ReleaseNoteItem>
            From today, you'll notice a new Report button on the Markets page.
            You can easily generate a PDF on any market or sector of your
            interest!
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>
      <ReleaseNote
        title="9 December 2020 Version 2.26 - What's new"
        filename={`${assetsUrl}/Trendrating--RELEASE_NOTES--v2.26--SMS.pdf`}
      >
        <ReleaseNoteSection type="New" title="Combine Multiple Strategies">
          <ReleaseNoteItem>Added Save functionality.</ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Systematic Portfolios">
          <ReleaseNoteItem>Added Automatic rebalancing.</ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="New" title="Market page">
          <ReleaseNoteItem>
            Capture AB% changes over a day, week and a month.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Added Up/Downgrades over a day, a week and a month.
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            Catch which industries are pulling the trend at the sector level by
            expanding the view.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection type="Improvement" title="Systematic Portfolios">
          <ReleaseNoteItem>
            Split overview among Subscribed SP and Custom SP.
          </ReleaseNoteItem>
        </ReleaseNoteSection>
      </ReleaseNote>
      <ReleaseNote title="23 July 2020 Version 2.12 - What's new">
        <ReleaseNoteSection type="New" title="Systematic Portfolios Reporting">
          <ReleaseNoteItem>
            New set of features to generate on-demand and customized reporting
          </ReleaseNoteItem>
          <ReleaseNoteItem>
            New set of default reporting templates
          </ReleaseNoteItem>
          <ReleaseNoteItem>Save report templates</ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection
          type="Improvement"
          title="Import historical portfolios"
        ></ReleaseNoteSection>
      </ReleaseNote>
      <ReleaseNote title="8 July 2020 Version 2.10 - What's new">
        <ReleaseNoteSection type="New" title="Combine Multiple Strategies">
          <ReleaseNoteItem>
            Combine two strategies into one big strategy.
          </ReleaseNoteItem>
        </ReleaseNoteSection>

        <ReleaseNoteSection
          type="Improvement"
          title="Various performance improvements"
        ></ReleaseNoteSection>

        <ReleaseNoteSection
          type="Updated"
          title="User guides"
        ></ReleaseNoteSection>
      </ReleaseNote>
    </div>
  );
};
