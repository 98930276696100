/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/generator/strategyHoldings
 * @summary Strategy - Holdings
 *
 */

import { escapeEntity, getStyleForProperty } from "./Generator";

export function sectionsHoldings(input, section, datum, formatter) {
    const _sections: any = [];
    if (section["content"]["headline"]["isEnabled"]) {
        const _section = {
            data: {
                text: escapeEntity(datum["headline"].toUpperCase()),
            },
            type: "header1",
        };
        _sections.push(_section);
    }

    const _section = sectionsHoldingsTable(input, section, datum, formatter);
    _sections.push(_section);

    return _sections;
}

function sectionsHoldingsTable(input, section, data, formatter) {
    const format = formatter;
    const table: any = {
        data: {
            body: [],
            head: [[]],
        },
        type: "table",
    };

    // Set table header and order of properties to use later
    const columns = section["presentation"]["columns"];
    const columnsProperties: any = [];

    for (let i = 0, length = columns.length; i < length; i++) {
        table["data"]["head"][0].push({
            style: getStyleForProperty(columns[i]["property"]),
            value: columns[i]["label"],
        });
        columnsProperties.push(columns[i]["property"]);
    }

    let topWeight = 0;
    let top = -1; // -1 means disabled
    if (section["content"]["top"]["isEnabled"]) {
        top = parseInt(section["content"]["top"]["content"], 10);
        if (isNaN(top) || top <= 0) {
            top = -1; // Disable anyway if it is not valid
        }
    }

    // Sorting support
    let sortBy;
    if (
        section["content"]["sortBy"] != null &&
        section["content"]["sortBy"]["property"] != null
    ) {
        for (let i = 0; i < columns.length; i++) {
            if (
                columns[i]["property"] ===
                section["content"]["sortBy"]["property"]
            ) {
                // Found! Can use it
                sortBy = columns[i]["property"];
                break; // Stop
            } else if (columns[i]["property"] === "weight") {
                // Check if at least weight exists
                sortBy = "weight";
            }
        }
    }
    if (sortBy != null) {
        if (section["content"]["sortBy"]["descending"]) {
            data["holdings"].sort(function (a, b) {
                return a[sortBy] < b[sortBy]
                    ? 1
                    : a[sortBy] > b[sortBy]
                    ? -1
                    : 0;
            });
        } else {
            data["holdings"].sort(function (a, b) {
                return a[sortBy] < b[sortBy]
                    ? -1
                    : a[sortBy] > b[sortBy]
                    ? 1
                    : 0;
            });
        }
    }

    for (let i = 0; i < data["holdings"].length; i++) {
        const tableRow: any = [];
        for (let j = 0; j < columnsProperties.length; j++) {
            const instrument = data["holdings"][i];
            const property = columnsProperties[j];

            if (property === "weight" && i < top) {
                topWeight += instrument[property];
            }

            if (i === top) {
                const specialTableRow: any = [];
                for (let k = 0; k < columnsProperties.length; k++) {
                    // Only fill weight and name fields
                    if (columnsProperties[k] === "weight") {
                        specialTableRow.push({
                            style: getStyleForProperty(columnsProperties[k]),
                            value:
                                "<strong>" +
                                format.pdf(
                                    columnsProperties[k],
                                    "table",
                                    instrument[columnsProperties[k]],
                                    {
                                        weight: topWeight,
                                    },
                                    instrument["type"]
                                ) +
                                "</strong>",
                        });
                    } else if (columnsProperties[k] === "name") {
                        specialTableRow.push({
                            style: getStyleForProperty(columnsProperties[k]),
                            value: "<strong>TOP " + top + "</strong>",
                        });
                    } else {
                        specialTableRow.push({
                            style: null,
                            value: "",
                        });
                    }
                }
                table["data"]["body"].push(specialTableRow);
                top = -1; // disable top (not needed anymore)
                // Break here, don't show anymore
                return table;
            }
            tableRow.push({
                style: getStyleForProperty(property),
                value:
                    "" +
                    format.pdf(
                        property,
                        "table",
                        instrument[property],
                        instrument,
                        instrument["type"]
                    ),
            });
        }
        // Add row to table
        table["data"]["body"].push(tableRow);
    }

    return table;
}
