import { Box, TextField, Typography } from "@mui/material";
import Tippy from "@tippyjs/react";
import { useCallback, useRef, useState } from "react";

type Props = {
  value: any;
  min: number;
  max: number;
  step?: number;
  stateSetter: Function;
  disabled?: boolean;
};

export default function MinMaxInputNumer({
  value,
  stateSetter,
  min,
  step = 1,
  max,
  disabled = false,
}: Props) {
  const [securitiesToHoldInputKey, setSecuritiesToHoldInputKey] = useState(
    Date.now()
  );
  const [showSecuritiesToHoldHelperText, setShowSecuritiesToHoldHelperText] =
    useState(false);
  const securitiesToHoldHelperTextTimeoutRef = useRef<any>(null);

  const showAndHideSecuritiesToHoldHelperText = useCallback(
    (value) => {
      clearTimeout(securitiesToHoldHelperTextTimeoutRef.current);
      setShowSecuritiesToHoldHelperText(true);
      securitiesToHoldHelperTextTimeoutRef.current = setTimeout(() => {
        setShowSecuritiesToHoldHelperText(false);
        stateSetter(value);
      }, 2500);
    },
    [stateSetter]
  );
  const securitiesToHoldInputHandler = useCallback(
    (e) => {
      let value = parseInt(e.target.value);
      if (isNaN(value)) {
        value = 0;
      }

      if (value >= min && value <= max) {
        setShowSecuritiesToHoldHelperText(false);
        clearTimeout(securitiesToHoldHelperTextTimeoutRef.current);
        stateSetter(value);
      } else if (value < min) {
        showAndHideSecuritiesToHoldHelperText(min);
      } else if (value > max) {
        showAndHideSecuritiesToHoldHelperText(max);
      }
    },
    [max, min, showAndHideSecuritiesToHoldHelperText, stateSetter]
  );


  return (
    <Tippy
      key={securitiesToHoldInputKey}
      theme="security-tooltip"
      visible={showSecuritiesToHoldHelperText}
      content={
        <Box p={1}>
          <Typography color={"red"}>
            min:{min}, max:{max}
          </Typography>
        </Box>
      }
    >
      <TextField
        disabled={disabled}
        error={showSecuritiesToHoldHelperText}
        inputProps={{ step: step, min: min, max: max }}
        sx={{ width: "7em" }}
        type="number"
        defaultValue={value}
        size="small"
        onBlur={(e) => {
          let _value = parseInt(e.target.value);
          if (isNaN(_value)) {
            _value = 0;
          }
          if (_value >= min && _value <= max) {
            stateSetter(_value);
          } else if (_value < min) {
            stateSetter(min);
          } else if (_value > max) {
            stateSetter(max);
          }
          setSecuritiesToHoldInputKey(Date.now());
          setShowSecuritiesToHoldHelperText(false);
        }}
        onChange={securitiesToHoldInputHandler}
      />
    </Tippy>
  );
}
