import { Box, Button, Card, CardContent } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useEnvironment } from "../../../../../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../../../../../hooks/useFormatter";
import ReactDialogRationale from "../../../../../../../../../widgets/dialog/rationale/ReactDialogRationale";
import {
  _formatPeerName,
  _formatPercentage,
  _getHoldingsWeightedPerformanceNumDen,
} from "./utils";

type AllocationProps = {
  state: any;
  getRationaleData: any;
  getRationaleBase: any;
  hideRationaleBtn?: boolean;
};

export default function Allocation({
  state,
  getRationaleData,
  getRationaleBase,
  hideRationaleBtn = false,
}: AllocationProps) {
  const [rationale, setRationale] = useState(null);
  const [loadingRationale, setLoadingRationale] = useState(false);
  const [showRationale, setShowRationale] = useState(false);
  const environment = useEnvironment();
  const format = useFormatter();
  const component = useMemo(
    () => _renderBoxAllocation(state, format, environment),
    [environment, format, state]
  );

  const getDataForRationale = useCallback(async () => {
    setShowRationale(true);
    setLoadingRationale(true);
    try {
      const response = await getRationaleData(state, getRationaleBase);
      setRationale(response);
      setLoadingRationale(false);
    } catch (error) {
      console.log(error);
      setLoadingRationale(false);
    }
  }, [getRationaleData, state, getRationaleBase]);
  return (
    <Card sx={{ boxShadow: 3, flex: 2 }}>
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 1,
          pb: "8px !important",
        }}
      >
        <Box display={"flex"} flexDirection="column">
          {component.map((item, index) => (
            <React.Fragment key={index}>{item}</React.Fragment>
          ))}
        </Box>
        {hideRationaleBtn === false && (
          <Button onClick={getDataForRationale} size="small">
            Rationale
          </Button>
        )}
      </CardContent>
      {showRationale && (
        <ReactDialogRationale
          closeFn={() => setShowRationale(false)}
          isLoading={loadingRationale}
          tableData={rationale}
        />
      )}
    </Card>
  );
}

const _renderBoxAllocation = (state, format, environment) => {
  var frame = state?.frame;
  let output: React.ReactElement[] = [];
  if (frame) {
    output.push(
      <div>
        Allocation:{" "}
        <strong>{_formatPercentage(frame.allocation, format)}</strong>
      </div>
    );
  } else {
    output.push(_formatPercentage(0, format));
  }

  var peer = state?.contributionsFiltered?.peer;
  var holdings = state?.contributionsFiltered?.holdings;
  if (peer && holdings) {
    var numDen = _getHoldingsWeightedPerformanceNumDen(holdings);

    output.push(
      <div>
        {_formatPeerName(peer, environment)}
        {": "} <strong>{_formatPercentage(numDen.denominator, format)}</strong>
      </div>
    );
  }
  return output;
};
