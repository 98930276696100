import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridOnIcon from "@mui/icons-material/GridOn";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ErrorBoundary } from "../../../../ErrorBoundary";
import { Lists } from "../../../../api/compute/Lists";
import { Strategies } from "../../../../api/compute/Strategies";
import { deepClone } from "../../../../deepClone";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../hooks/useEventBus";
import { config } from "../../config-ts";
import { SystematicPortfoliosStorage } from "../../storage/SystematicPortfoliosStorage";
import { messageError, messageSuccess, removeLoader } from "../../utils";
import { useActionModal } from "../../utils/useActionModal";
import { CombineSystematicProducts } from "./CombineSystematicProducts/CombineSystematicProducts";
import GridViewer from "./GridViewer/GridViewer";
import { SystematicList } from "./SystematicList/SystematicList";
import styles from "./SystematicPortfolios.module.scss";
import { DialogCreate } from "./dialog/DialogCreate";
import { ProductPage } from "./product/ProductPage";
import Modal from "../../../../components/Modal/Modal";
import { useBroadcast } from "../../../../hooks/useBroadcast";
import { appCookie } from "../../Cookies";

type SmsTabs = "userItems" | "subscribedItems" | "combinedItems";
type ChangeViewBtnProps = {
  viewType: "table" | "tile";
  changeView: (type: "table" | "tile") => void;
};
type ViewType = "table" | "tile";

export const SystematicPortfolioContext =
  createContext<SystematicPortfoliosStorage>(undefined!);

export function SystematicPortfolios() {
  const [workflow, setWorkflow] = useState("s0");
  const [currentTab, changeTab] = useState<SmsTabs>("userItems");
  const [view, changeView] = useState<ViewType>(
    appCookie("SMS_viewermode", null) ?? "tile"
  );
  const [productListKey, setProductListKey] = useState(
    new Date().getMilliseconds()
  );
  const navigate = useNavigate();
  const params = useParams();

  const { t } = useTranslation();
  const environment = useEnvironment();
  const { setContent, setShowModal /*setModalTitle*/ } = useActionModal();
  const { dispatch } = useEventBus();
  const { broadcast } = useBroadcast();

  //#region - setting tab title
  useEffect(() => {
    const account = environment.get("account");
    const configuration = account?.product?.configuration.systematic_portfolios;
    let title = configuration.menu_label.toLowerCase();
    const titleCapitalized = title.charAt(0).toUpperCase() + title.slice(1);
    document.title = titleCapitalized;
  }, [environment]);
  //#endregion

  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const smsStorageAPI = useMemo(
    () => new SystematicPortfoliosStorage(appSetup),
    [appSetup]
  );
  const strategiesAPI = useMemo(() => new Strategies(appSetup), [appSetup]);
  const listsAPI = useMemo(() => new Lists(appSetup), [appSetup]);
  const tabs = useMemo(() => smsStorageAPI.getTabs(t), [smsStorageAPI, t]);
  const showShared = useMemo(() => {
    const cases = {
      userItems: false,
      subscribedItems: true,
      combinedItems: false,
    };

    return cases[currentTab];
  }, [currentTab]);

  useEffect(() => {
    removeLoader();
  }, []);

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, tabKey: SmsTabs) => {
      changeTab(tabKey);

      const workflowMap = {
        userItems: "s0",
        subscribedItems: "s1",
        combinedItems: "s3",
      };

      setWorkflow(workflowMap[tabKey]);
    },
    []
  );

  const handleChangeView = useCallback((newView: ViewType) => {
    appCookie("SMS_viewermode", newView);
    changeView(newView);
  }, []);

  const handleGoToProductSection = useCallback(
    (id) => {
      navigate(`/app/systematic-portfolios/${id}`);
    },
    [navigate]
  );

  const progressListener = useCallback(
    (status) => {
      var message = "";
      switch (status["message"]) {
        case "T_SYSTEMATIC_PRODUCT_EX_ANTE_LIST_COMPUTING": {
          message = t("c_feedback_systematic_product_ex_ante_list_computing");

          break;
        }
        case "T_SYSTEMATIC_PRODUCT_EX_ANTE_LIST_REFRESHING": {
          message = t("c_feedback_systematic_product_ex_ante_list_refreshing");

          break;
        }
        case "T_SYSTEMATIC_PRODUCT_SAVING": {
          message = t("c_feedback_systematic_product_saving");

          break;
        }
        case "T_SYSTEMATIC_PRODUCT_REBALANCING": {
          message = t("c_feedback_systematic_product_rebalancing");

          break;
        }
        case "T_SYSTEMATIC_PRODUCT_STRATEGY_COMPUTING": {
          message = t("c_feedback_systematic_product_strategy_computing");

          break;
        } // no default
      }

      setContent(
        <Box
          p={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          gap={1}
        >
          <Typography>{message}</Typography>
          <CircularProgress sx={{ color: "#2a7090" }} />
        </Box>
      );
    },
    [setContent, t]
  );

  const feedbackSuccessCreate = useCallback(
    (product) => {
      const text = `<strong>${product.name}</strong> has been created.`;

      const [channel, msg] = messageSuccess(text);
      broadcast(channel as string, msg);
    },
    [broadcast]
  );

  const refreshList = useCallback(() => {
    setProductListKey(new Date().getMilliseconds());
  }, []);

  const productCreate = useCallback(
    async (params, response?) => {
      if (response != null) {
        params["todayList"]["list"]["list"] = response;
        delete params["todayList"]["list"]["id"];
      }

      params["listeners"] = {
        progress: progressListener,
      };

      try {
        const responseCreate = await smsStorageAPI.smsAPI.create(params);
        setShowModal(false);
        feedbackSuccessCreate(responseCreate);

        smsStorageAPI.invalidateCache();
        refreshList();
      } catch (error) {
        setShowModal(false);
        const [channel, msg] = messageError(
          "The Product has not be created due to an error."
        );
        broadcast(channel as string, msg);
      }
    },
    [
      broadcast,
      feedbackSuccessCreate,
      progressListener,
      refreshList,
      setShowModal,
      smsStorageAPI,
    ]
  );

  const createNew = useCallback(
    async (createModalState) => {
      var params = deepClone(createModalState);

      // getting strategy
      var strategyId = params["basic"]["strategyId"];
      var strategy = await strategiesAPI.getById(strategyId);
      delete params["basic"]["strategyId"];
      delete params["priceLevel"]["fromDate"];
      params["basic"]["strategy"] = strategy;
      // getting list
      if (params["todayList"]["list"] != null) {
        const response = await listsAPI.get(params["todayList"]["list"]["id"]);
        productCreate(params, response);
      } else {
        productCreate(params);
      }
    },
    [listsAPI, productCreate, strategiesAPI]
  );

  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const openCreateDialog = useCallback(() => {
    setShowCreateDialog(true);
  }, []);

  const listenerActionManageSubscriptions = useCallback(() => {
    dispatch("open-preferences", {
      preferencesTab: "subscriptions",
      subscriptionsTab: "systematic",
    });
  }, [dispatch]);

  useEffect(() => {
    const actions: any = [];
    let action: any = null;

    // "s0" on landing
    // "s3 on tab change"
    switch (workflow) {
      case "s0": {
        action = {
          componentJSX: (
            <li className={"menu__item"} onClick={openCreateDialog}>
              Create
            </li>
          ),
        };

        actions.push(action);

        break;
      }
      case "s3": {
        return
      }

      case "s1": {
        action = {
          componentJSX: (
            <li
              className="menu__item"
              onClick={listenerActionManageSubscriptions}
            >
              Manage Subscriptions
            </li>
          ),
        };

        actions.push(action);

        break;
      }
    }

    var message = {
      from: "analysisMarkets",
      content: {
        actions: actions,
      },
    };

    // Handle workflow only on landing page because the handling of the workflow is given to children when they're instantiated
    if (params?.id == null) {
      broadcast(config["channels"]["workflow"]["input"], message);
    }
  }, [
    broadcast,
    listenerActionManageSubscriptions,
    openCreateDialog,
    params?.id,
    workflow,
  ]);

  return (
    <ErrorBoundary fallback={<ErrorComponet />}>
      <SystematicPortfolioContext.Provider value={smsStorageAPI}>
        <Box className={styles.systematicPortfoliosWrapper}>
          {params?.id == null ? (
            <>
              {currentTab !== "combinedItems" && (
                <ChangeViewBtn viewType={view} changeView={handleChangeView} />
              )}
              <Tabs
                sx={{ borderBottom: 1, borderColor: "divider" }}
                value={currentTab}
                onChange={handleTabChange}
              >
                {tabs.map((tab) => {
                  return (
                    <Tab
                      key={uuidv4()}
                      label={tab.label}
                      sx={{ color: "#000" }}
                      value={tab.value}
                    />
                  );
                })}
              </Tabs>
              <Box className={styles.systematicPortfoliosContent}>
                {view === "table" ? (
                  <ErrorBoundary fallback={<ErrorComponet />}>
                    {currentTab === "combinedItems" ? (
                      <CombineSystematicProducts />
                    ) : (
                      <GridViewer
                        key={productListKey}
                        showShared={showShared}
                        storage={smsStorageAPI}
                      />
                    )}
                  </ErrorBoundary>
                ) : (
                  <>
                    {currentTab === "combinedItems" ? (
                      <CombineSystematicProducts />
                    ) : (
                      <SystematicList
                        key={productListKey}
                        onClickItem={handleGoToProductSection}
                        showShared={showShared}
                      />
                    )}
                  </>
                )}
              </Box>
            </>
          ) : (
            <ProductPage productId={params.id!} />
          )}
        </Box>
        {showCreateDialog && (
          <Modal bodyCustomClass={styles.modal} closeIcon={false}>
            <DialogCreate
              closeModal={() => setShowCreateDialog(false)}
              onDialogOk={createNew}
            />
          </Modal>
        )}
      </SystematicPortfolioContext.Provider>
    </ErrorBoundary>
  );
}

const ChangeViewBtn = ({ changeView, viewType }: ChangeViewBtnProps) => {
  const changeTab = useCallback(
    (e) => {
      const newTab = viewType === "table" ? "tile" : "table";
      changeView(newTab);
    },
    [changeView, viewType]
  );

  return (
    <Button
      variant="outlined"
      onClick={changeTab}
      className={styles.changeViewBtn}
    >
      {viewType === "table" ? (
        <FormatListBulletedIcon sx={{ color: "#2a7090" }} />
      ) : (
        <GridOnIcon sx={{ color: "#2a7090" }} />
      )}
    </Button>
  );
};

const ErrorComponet = () => {
  return (
    <Box className={styles.errorBoxSms}>
      <Card>
        <CardContent
          sx={{
            display: "felx",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>
            An error is blocking the page loading. Please refresh the browser,
            if the error persist contact our customers support
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};
