import MenuIcon from "@mui/icons-material/Menu";
import {
  Button,
  Divider,
  Fade,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { Fragment, useCallback, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

type RapidMenuProps = {
  onOptionClickHandler: Function;
  options: Options;
  isReadOnly: boolean;
  smallBtn?: boolean;
  availableForSharedObject?: string[];
};

type DropdownMenuProps = {
  options: any;
  isReadOnly: boolean;
  onClickHandler: Function;
  availableForSharedObject: string[];
};

type Options = {
  label: string | null;
  type: "item" | "separator";
  value: string | null;
}[];

const StyledBtn = styled((props: any) => (
  <Button
    className={props.className}
    id="fade-button"
    aria-controls={props.open ? "fade-menu" : undefined}
    aria-haspopup="true"
    aria-expanded={props.open ? "true" : undefined}
    onClick={props.handleClick}
  >
    {props.children}
  </Button>
))({
  borderRadius: "4px!important",
  borderColor: "1px solid #2a7090!important",
  backgroundColor: "white!important",
  padding: "3px!important",
  minWidth: "0!important",
  height: "auto",
});

export function RapidMenu({
  onOptionClickHandler,
  options,
  isReadOnly,
  smallBtn,
  availableForSharedObject = [], // Action unavailable for shared objects (save, delete, update for exaple)
}: RapidMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onClickMenuOption = useCallback(
    (e, value) => {
      onOptionClickHandler(value);
      handleClose(e);
    },
    [onOptionClickHandler]
  );
  return (
    <div>
      <StyledBtn handleClick={handleClick} open={open}>
        <MenuIcon
          sx={
            smallBtn === true
              ? { color: "#2a7090", fontSize: "0.7vw" }
              : { color: "#2a7090", fontSize: "1vw" }
          }
        />
      </StyledBtn>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <DropdownMenu
          options={options}
          isReadOnly={isReadOnly}
          onClickHandler={onClickMenuOption}
          availableForSharedObject={availableForSharedObject}
        />
      </Menu>
    </div>
  );
}

const DropdownMenu = ({
  options,
  isReadOnly,
  onClickHandler,
  availableForSharedObject,
}: DropdownMenuProps) => {
  const preparedOptions = useMemo(() => {
    // Prevent duplicate separators
    const newOptions: any = [];
    let option: any = null;
    // Prepare new options
    if (isReadOnly) {
      var previousWasSeparator = false;
      for (let i = 0, length = options.length; i < length; i++) {
        option = options[i];
        if (
          option["type"] === "item" &&
          availableForSharedObject.indexOf(option["value"]) === -1
        ) {
          continue; // Ignore non-read only fields actions
        }
        if (option["type"] === "separator") {
          if (previousWasSeparator) {
            continue; // Previous was already a separator, just move to the next elements
          }
          previousWasSeparator = true;
        } else {
          previousWasSeparator = false;
        }
        newOptions.push(option);
      }
      do {
        if (newOptions[newOptions.length - 1]["type"] === "separator") {
          newOptions.pop(); // Remove trailing separator
        } else {
          break;
        }
      } while (newOptions.length > 0);

      return newOptions;
    }

    return options;
  }, [isReadOnly, options, availableForSharedObject]);

  const onClickOption = useCallback(
    (e, value) => {
      const event = e;
      event["value"] = { action: value };
      onClickHandler(e, event);
    },
    [onClickHandler]
  );

  return (
    <>
      {preparedOptions.map((option) => {
        switch (option.type) {
          case "item": {
            return (
              <MenuItem
                onClick={(e) => onClickOption(e, option.value)}
                key={uuidv4()}
              >
                <Typography>{option.label}</Typography>
              </MenuItem>
            );
          }

          case "separator": {
            return (
              <Divider key={uuidv4()} sx={{ display: "block!important" }} />
            );
          }

          default:
            return <Fragment key={uuidv4()}></Fragment>;
        }
      })}
    </>
  );
};
