import { Box, Card, CardContent } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Instruments } from "../../../../../../../../../../api/compute/Instruments";
import { extractSymbols } from "../../../../../../../../../../api/compute/commons";
import { InstrumentsTable } from "../../../../../../../../../../components/InstrumentsTable/InstrumentsTable";
import RatingCheckbox from "../../../../../../../../../../components/RatingCheckbox/RatingCheckbox";
import { useEnvironment } from "../../../../../../../../../../hooks/useEnvironment";
import { FormOptions } from "../../../../../../../../../trendrating-widgets/form/FormOptions";
import { FilterCard } from "../../../../../../../screening/FilterBar/FilterRowContraints";
import { OptionsFilter } from "../../../../../../../screening/FilterBar/FilterWidgets/OptionFilter/OptionFilter";
import { FilterBarHelper } from "../../../../../../../screening/FilterBar/FilterBarHelper";
import { InputStubExport } from "../../../../../../../analysisLists/widgets/PortfolioHome";
import { config } from "../../../../../../../../config-ts";
import ReportButton from "../../../../../../../../widgets/app-infrastructure/workflowBar/actions/report/ReportButton";
import { SystematicProduct } from "../../../../../../../../../../types/Api";
import { useBroadcast } from "../../../../../../../../../../hooks/useBroadcast";
import {
  Export,
  TableWrapper,
} from "../../../../../../../../components/app-infrastructure/workflowBar/actions/export/Export";

type ProductHoldingsProps = {
  holdings: any[];
  dataForExport: any;
  systematicPortfolio: SystematicProduct;
};

export function ProductHoldings({
  holdings,
  dataForExport,
  systematicPortfolio,
}: ProductHoldingsProps) {
  const [data, setData] = useState<any>([]);
  const [sorter, setSorter] = useState({ field: "marketcap", rev: false });
  const [currentCols, setCurrentCols] = useState<any>([]);
  const [ratings, setRatings] = useState<any>([]);
  const [dataTotalCount, setDataTottalCount] = useState(holdings?.length ?? 0);
  const [alerts, setAlerts] = useState("None");
  const [activeFilters, setActiveFilters] = useState<any>([]);

  const itemsPerPage = useMemo(() => 100, []);
  const { broadcast } = useBroadcast();
  const environment = useEnvironment();
  const configuration = useMemo(
    () =>
      environment.get("account")?.product?.configuration?.[
        "systematic_portfolios"
      ]?.["tabs"],
    [environment]
  );

  const holdingsTabConfig = useMemo(() => {
    const holdingsTabSettings = configuration.find(
      (item) => item.id === "holdings"
    );

    if (holdingsTabSettings) {
      const widgetsSettings = holdingsTabSettings?.widgets?.viewer;

      return widgetsSettings;
    }
  }, [configuration]);

  const appSetup = useMemo(() => environment.get("setup"), [environment]);

  const optionsAlerts = useMemo(() => FormOptions.get("ALERT"), []);

  const instrumentsAPI = useMemo(() => new Instruments(appSetup), [appSetup]);

  const filterBarHelper = useMemo(() => new FilterBarHelper(), []);

  const ratingDict = useMemo(
    () => ({
      A: 2,
      B: 1,
      C: -1,
      D: -2,
    }),
    []
  );

  const createHoldingsMap = useCallback(
    () =>
      holdings.reduce((prev, current) => {
        prev[current.symbol] = current;

        return prev;
      }, {}),
    [holdings]
  );

  const fetchInstrumentsFields = useCallback(
    async (fields: string[], symbols: string[], page: number) => {
      const payload: any = {
        constraints: [
          [
            {
              dimension: "symbol",
              operator: "equals",
              segments: symbols,
            },
          ],
        ],
        page: { page, rows: itemsPerPage },
        sort: [sorter],
      };

      const rcSegments: any = [];

      Object.keys(ratings).forEach((key) => {
        if (ratings[key] === true) {
          rcSegments.push(ratingDict[key]);
        }
      });

      if (rcSegments.length) {
        payload.constraints[0].push({
          dimension: "rc",
          operator: "equals",
          segments: rcSegments,
        });
      }

      const alertsFilters = filterBarHelper.formatWidgetState("alert")(alerts);

      if (alertsFilters?.filter && alertsFilters?.filter.length) {
        const filtersToAdd = instrumentsAPI.convertToIndexBuilderSyntax({
          ranges: alertsFilters.filter,
        } as any);

        payload.constraints = [
          payload.constraints[0].concat(filtersToAdd.constraints[0]),
        ];
      }

      const screeningResponse = await instrumentsAPI.screening(payload, true);

      const properties = fields.map((field) => ({
        date: null,
        property: field,
      }));

      const response = await instrumentsAPI.fetch({
        properties,
        symbols: screeningResponse.data,
        type: "security",
      });

      return response?.data ?? undefined;
    },
    [
      alerts,
      filterBarHelper,
      instrumentsAPI,
      itemsPerPage,
      ratingDict,
      ratings,
      sorter,
    ]
  );

  const getFieldsToFetch = useCallback(
    (columns) => {
      const holdingsMap = createHoldingsMap();

      const firstItem = holdingsMap[holdings[0].symbol];

      // Fields related to the context (contribution, contributiionCurrency, weight ecc.)
      const contextualFieldsMap = Object.keys(firstItem).reduce(
        (prev, current) => {
          prev[current] = true;
          return prev;
        },
        {}
      );

      const fieldsTofetch: any = [];
      let field: any = null;

      columns.forEach((column) => {
        field = column.field;

        if (!(field in contextualFieldsMap)) {
          fieldsTofetch.push(field);
        }
      });

      return fieldsTofetch;
    },
    [createHoldingsMap, holdings]
  );

  const onColumnsChange = useCallback(
    async (columns, pageNumber) => {
      if (!columns || !holdings || holdings.length === 0) {
        return;
      }

      const holdingsMap = createHoldingsMap();

      const fields = getFieldsToFetch(columns);
      const symbols = extractSymbols(holdings);

      const data = await fetchInstrumentsFields(fields, symbols, pageNumber);

      const mergedData = data.map((row) => ({
        ...row,
        ...holdingsMap[row.symbol],
      }));

      setData(mergedData ?? []);
    },
    [createHoldingsMap, fetchInstrumentsFields, getFieldsToFetch, holdings]
  );

  const updateColumns = useCallback(
    (columns) => {
      setCurrentCols(columns);
      onColumnsChange(columns, 1);
    },
    [onColumnsChange]
  );

  const mergeHoldingsFields = useCallback(
    (data) => {
      if (holdings) {
        const holdingsMap = createHoldingsMap();

        const mergedData = data.map((row) => ({
          ...row,
          ...holdingsMap[row.symbol],
        }));

        return mergedData;
      }

      return [];
    },
    [createHoldingsMap, holdings]
  );

  const onDataSorted = useCallback(
    (dataSorted, field, dir) => {
      const data = mergeHoldingsFields(dataSorted);

      setSorter({ field, rev: dir !== "asc" });
      setData(data);
    },
    [mergeHoldingsFields]
  );

  const onPageChange = useCallback(
    (page) => {
      onColumnsChange(currentCols, page);
    },
    [currentCols, onColumnsChange]
  );

  const filterData = useCallback(
    async (ratingFilter) => {
      const fields = getFieldsToFetch(currentCols);
      const symbols = extractSymbols(holdings);

      const rcSegments: any = [];

      Object.keys(ratingFilter).forEach((key) => {
        if (ratingFilter[key] === true) {
          rcSegments.push(ratingDict[key]);
        }
      });

      setRatings(rcSegments);

      const payload: any = {
        constraints: [
          [
            {
              dimension: "symbol",
              operator: "equals",
              segments: symbols,
            },
          ],
        ],
        page: { page: 1, rows: itemsPerPage },
        sort: [sorter],
      };

      if (rcSegments.length) {
        payload.constraints[0].push({
          dimension: "rc",
          operator: "equals",
          segments: rcSegments,
        });
      }

      const alertsFilters = filterBarHelper.formatWidgetState("alert")(alerts);

      if (alertsFilters?.filter && alertsFilters?.filter.length) {
        const filtersToAdd = instrumentsAPI.convertToIndexBuilderSyntax({
          ranges: alertsFilters.filter,
        } as any);

        payload.constraints = [
          payload.constraints[0].concat(filtersToAdd.constraints[0]),
        ];
      }

      const screeningResponse = await instrumentsAPI.screening(payload, true);

      const properties = fields.map((field) => ({
        date: null,
        property: field,
      }));

      const response = await instrumentsAPI.fetch({
        properties,
        symbols: screeningResponse.data,
        type: "security",
      });

      setDataTottalCount(screeningResponse?.dataTotalCount ?? 0);
      setData(mergeHoldingsFields(response?.data) ?? []);
    },
    [
      alerts,
      currentCols,
      filterBarHelper,
      getFieldsToFetch,
      holdings,
      instrumentsAPI,
      itemsPerPage,
      mergeHoldingsFields,
      ratingDict,
      sorter,
    ]
  );

  const filterByAlerts = useCallback(
    async (alertsFilters) => {
      const filters = filterBarHelper.formatWidgetState("alert")(alertsFilters);

      const fields = getFieldsToFetch(currentCols);
      const symbols = extractSymbols(holdings);

      setAlerts(alertsFilters);

      const payload: any = {
        constraints: [
          [
            {
              dimension: "symbol",
              operator: "equals",
              segments: symbols,
            },
          ],
        ],
        page: { page: 1, rows: itemsPerPage },
        sort: [sorter],
      };

      if (ratings && ratings.length) {
        payload.constraints[0].push({
          dimension: "rc",
          operator: "equals",
          segments: ratings,
        });
      }

      if (filters?.filter && filters?.filter.length) {
        const alertsFilters = instrumentsAPI.convertToIndexBuilderSyntax({
          ranges: filters.filter,
        } as any);

        payload.constraints = [
          payload.constraints[0].concat(alertsFilters.constraints[0]),
        ];
      }

      const screeningResponse = await instrumentsAPI.screening(payload, true);

      const properties = fields.map((field) => ({
        date: null,
        property: field,
      }));

      const response = await instrumentsAPI.fetch({
        properties,
        symbols: screeningResponse.data,
        type: "security",
      });

      setDataTottalCount(screeningResponse?.dataTotalCount ?? 0);
      setData(mergeHoldingsFields(response?.data) ?? []);
    },
    [
      currentCols,
      filterBarHelper,
      getFieldsToFetch,
      holdings,
      instrumentsAPI,
      itemsPerPage,
      mergeHoldingsFields,
      ratings,
      sorter,
    ]
  );

  useEffect(() => {
    const newFilters: any = [];

    if (ratings.length) {
      newFilters.push({
        filter: [{ dimension: "rc", segments: ratings }],
        type: "filters",
      });
    }

    const alertsFilters = filterBarHelper.formatWidgetState("alert")(alerts);

    if (alertsFilters?.filter && alertsFilters?.filter.length) {
      newFilters.push(alertsFilters);
    }

    setActiveFilters(newFilters);
  }, [alerts, filterBarHelper, ratings]);

  useEffect(() => {
    const actions: any = [];
    let action: any = null;
    const colsToExport = currentCols.map((col) =>
      "label" in col
        ? col
        : {
            ...col,
            label: col.title,
          }
    );

    const exportList = {
      ...dataForExport,
      positions: [...data],
      positionsIndex: {},
    };

    let holding: any = null;

    for (let i = 0; i < exportList.positions.length; i++) {
      holding = exportList.positions[i];
      exportList.positionsIndex[holding.symbol] = i;
    }

    var inputStubExport = new InputStubExport({
      list: exportList,
      tools: [],
      viewer: {
        columns: colsToExport,
      },
      total: data.length,
    });

    var fileName = systematicPortfolio.name + ".csv";

    action = {
      componentJSX: (
        <Export
          rankingCache={undefined}
          fileName={fileName}
          list={exportList}
          logFunction={(event) => {
            // **************** USAGE ******************
            var usage = window.App.usage;
            var info = {
              action: "EXPORT",
              actionParam: systematicPortfolio.id,
              function: "SYSTEMATIC_PORTFOLIOS",
            };
            usage.record(info);
            // **************** USAGE ******************
          }}
          widgets={{
            table: new TableWrapper({
              filter: inputStubExport.filter,
              grid: inputStubExport.viewer,
              tools: inputStubExport.tools,
            }),
          }}
        />
      ),
    };

    actions.push(action);

    action = {
      componentJSX: (
        <ReportButton
          page={"systematicPortfolios"}
          target={systematicPortfolio}
          title={`Create a PDF report for ${systematicPortfolio.name}`}
          usage={window.App.usage}
          widgets={{
            table: new TableWrapper({
              filter: inputStubExport.filter,
              grid: inputStubExport.viewer,
              tools: inputStubExport.tools,
            }),
          }}
        />
      ),
    };

    actions.push(action);

    var message = {
      from: "systematicProducts",
      content: {
        actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [broadcast, currentCols, data, dataForExport, systematicPortfolio]);

  return holdingsTabConfig ? (
    <Box p={1} height={"98%"} gap={1} display={"flex"} flexDirection={"column"}>
      <Box display={"flex"} gap={1}>
        <Box display={"flex"}>
          <FilterCard label="Rating">
            <RatingCheckbox stateGetter={filterData} />
          </FilterCard>
        </Box>
        <Box>
          <FilterCard label={"Alert"}>
            <OptionsFilter
              value={alerts}
              setValue={filterByAlerts}
              widgetParams={{ options: optionsAlerts }}
              handleClickOnGroupLabel={(v) => console.log(v)}
            />
          </FilterCard>
        </Box>
      </Box>
      <Card sx={{ height: "100%" }}>
        <CardContent sx={{ height: "100%", display: "flex" }}>
          <InstrumentsTable
            useAutoSort={true}
            tableData={data}
            tableProps={{
              autoResize: false,
              options: {
                ajaxSorting: false,
                footerElement: `<span>Number of positions: ${
                  holdings?.length ?? 0
                } &nbsp; &nbsp; Contributions and performances are calculated from the rebalancing date.</span>`,
              },
              sorting: {
                field: sorter.field,
                direction: sorter.rev === false ? "desc" : "asc",
              },
            }}
            tools={{
              configurator: {
                hasToSkipLastApplied: false,
                defaultTemplateNameBase: "DEFAULT_SYSTEMATIC_PRODUCT_HOLDING",
                configurations: holdingsTabConfig.table,
                securityType: "security",
                isSaveLastUsedConfigurationColumnsEnabled: true,
              },
              pagination: {
                dataTotalCount,
                sortUniverse: holdings ?? [],
                itemsPerPage,
                changePage: onPageChange,
              },
              viewAsListButton: true,
            }}
            getInitColumns={updateColumns}
            onColumnsChange={updateColumns}
            handleSortResult={onDataSorted}
            activeFilters={activeFilters}
          />
        </CardContent>
      </Card>
    </Box>
  ) : (
    <></>
  );
}
