import { Box, Card, CardContent, Tab, Tabs, Typography } from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ErrorBoundary } from "../../../../ErrorBoundary";
import { handleTitle } from "../../../../Utility/DocumentTitleHanlder";
import { Instruments } from "../../../../api/compute/Instruments";
import { RankingHelper } from "../../../../components/InstrumentsTable/Helpers/RankingHelper";
import { TableHelpers } from "../../../../components/InstrumentsTable/Helpers/TableHelpers";
import { InstrumentsTable } from "../../../../components/InstrumentsTable/InstrumentsTable";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../hooks/useEventBus";
import { config } from "../../config-ts";
import { DialogSaveComponent } from "../../ui/commons/DialogSave/DialogSaveComponent";
import { messageError, messageSuccess, removeLoader } from "../../utils";
import ReportButton from "../../widgets/app-infrastructure/workflowBar/actions/report/ReportButton";
import { TableSkeleton } from "../analysisMarkets/detail/PeerDetail";
import { RankDialog } from "../rank/RankDialog/RankDialog";
import { RankOutput } from "../rank/Ranking";
import { ActionRank } from "../rank/actions/ActionRank";
import { ActionRankContext } from "../rank/actions/ActionRankContext/ActionRankContext";
import { FilterRowContraints } from "./FilterBar/FilterRowContraints";
import styles from "./Screening.module.scss";
import { DeleteScreeningDialog } from "./actions/ActionDeleteScreening/DeleteScreeningDialog";
import { FundamentalsTable } from "./widgets/FundamentalsTable";
import { SecurityPanel } from "./widgets/SecurityPanel";
import { ColumnDefinition } from "tabulator-tables";
import { useBroadcast } from "../../../../hooks/useBroadcast";
import { Export } from "../../components/app-infrastructure/workflowBar/actions/export/Export";
import { useWhyDidYouUpdate } from "../../../../hooks/useWhyDidYouUpdate";

const decodeTypeParam = (type) => {
  const map = {
    etf: "ETF",
    index: "Index",
    currency: "Currency",
    sector: "Sector",
    stock: "Stock",
    commodity: "Commodity",
  };

  return map?.[type] ?? "Stock";
};

const encodeTypeParam = (value) => {
  const map = {
    ETF: "etf",
    Index: "index",
    Currency: "currency",
    Sector: "sector",
    Stock: "stock",
    Commodity: "commodity",
  };

  return map?.[value] ?? "stock";
};

export function Screening() {
  const params = useParams();

  const [keyRefesh, setKeyRefresh] = useState(Date.now());

  /**
   * By using this ref at the first render the key is not changed so the component is not rerendered in an unuseful way
   */
  const tabRef = useRef(params.type);

  /**
   * This is done to avoid table problems on tab change the page is entirely rerendered on tab change
   */
  useEffect(() => {
    if (params.type !== tabRef.current) {
      tabRef.current = params.type;
      setKeyRefresh(Date.now());
    }
  }, [params.type]);

  return <ScreeningContent key={keyRefesh} params={params} />;
}

const ScreeningContent = ({ params }: any) => {
  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const configuration = appSetup["configuration"];
  const configurationScreening = configuration.get("screening");
  const [activeTemplate, setActiveTemplate] = useState<{
    name: string;
    id: number;
    isReadOnly: boolean;
  }>();
  const [filters, setFilters] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [dataTotalCount, setDataTotalCount] = useState(0);
  const instrumentsApi = useMemo(() => new Instruments(appSetup), [appSetup]);
  const [tableColumns, setTableColumns] = useState<ColumnDefinition[]>([]);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [expand, setExpand] = useState(false);
  const [showTemplateSaveDialog, setShowTemplateSaveDialog] = useState(false);
  const [showDeleteTemplateDialog, setShowDeleteTemplateDialog] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sorter, setSorter] = useState({ field: "marketcap", rev: true });
  const [rankingTableConfig, setRankingTableConfig] = useState<any>(undefined);
  const [isRankOn, setIsRankOn] = useState(false);
  const [invalidateRank, setInvalidateRank] = useState(false);
  const [showRankDialog, setShowRankDialog] = useState(false);
  const [tableKey, setTableKey] = useState(Date.now());

  const { rank, rankReducer, rankResults, rankingCache } =
    useContext(ActionRankContext);

  const refreshTable = useCallback(() => {
    setTableKey(Date.now());
  }, []);

  const onFirstRankFinish = useCallback(() => {
    setIsRankOn(true);
    setSorter({ field: "rank", rev: false });
    refreshTable();
  }, [refreshTable]);

  const openRankDialog = useCallback(() => {
    setShowRankDialog(true);
  }, []);
  const closeRankDialog = useCallback(() => setShowRankDialog(false), []);

  const handleRankFinish = useCallback((result: RankOutput) => {
    if (result.data && result.data.length) {
      setData(result.data as any);
    }

    if (result.columns && result.columns.length) {
      setRankingTableConfig(result.columns);
    }
  }, []);

  const onRank = useCallback(
    async (params) => {
      setIsLoading(true);
      await rank(params);

      setIsLoading(false);
    },
    [rank]
  );

  const { on, remove } = useEventBus();

  const tab = useMemo(() => decodeTypeParam(params.type), [params.type]);

  const navigate = useNavigate();

  const optionsList = useMemo(() => {
    const defaultOpts = [
      {
        label: "Stocks",
        value: "Stock",
      },
      {
        label: "Sectors",
        value: "Sector",
      },
      {
        label: "ETFs",
        value: "ETF",
      },
      {
        label: "Indices",
        value: "Index",
      },
      {
        label: "Currencies",
        value: "Currency",
      },
      {
        label: "Commodities",
        value: "Commodity",
      },
    ];

    const options: any = [];

    const configuration =
      environment.get("account")?.product?.configuration?.screening;

    for (const option of defaultOpts) {
      if (configuration?.tabs[option.value].enabled === true) {
        options.push(option);
      }
    }

    return options;
  }, [environment]);

  useEffect(() => {
    removeLoader();
  }, []);

  // Handle tab title
  useEffect(() => {
    handleTitle({ type: "SCREENING" });
  }, []);

  const prepareScreeningReq = useCallback(
    (filters: any) => {
      const body = {
        filters: [
          {
            dimension: "type",
            segments: [tab],
          },
        ],
        page: { page, rows: itemsPerPage },
        sort: [{ dimension: sorter.field, rev: sorter.rev }],
      };

      // This multisort avoid problems on fields that has n/a value in redis
      // if it's not used the server give and empty response on pages that are filled with securities
      // that has no value for the field that they were sorted by
      if (sorter.field !== "marketcap") {
        body.sort.push({ dimension: "marketcap", rev: true });
      }

      if (filters) {
        for (const filter of filters) {
          const { filter: filterValue, type } = filter;

          if (type in body) {
            body[type] = [...body[type], ...filterValue];
          } else {
            body[type] = [...filterValue];
          }
        }
      }

      return body;
    },
    [itemsPerPage, page, sorter.field, sorter.rev, tab]
  );

  useWhyDidYouUpdate("", {
    itemsPerPage,
    page,
    sf: sorter.field,
    sr: sorter.rev,
    tab,
  });

  const prepareFiltersForRank = useCallback(
    (filters: any) => {
      const body = {
        filters: [
          {
            dimension: "type",
            segments: [tab],
          },
        ],
      };

      if (filters) {
        for (const filter of filters) {
          const { filter: filterValue, type } = filter;

          if (type in body) {
            body[type] = [...body[type], ...filterValue];
          } else {
            body[type] = [...filterValue];
          }
        }
      }

      return body;
    },
    [tab]
  );

  const propertiesCols = useRef(tableColumns);

  const getPropertiesFromCols = useCallback(() => {
    const properties: any = [{ date: null, property: "type" }];

    for (const col of propertiesCols.current) {
      if (col.field) {
        properties.push({ date: null, property: col.field });
      }
    }

    return properties;
  }, []);

  const isReady = useMemo(() => tableColumns.length > 0, [tableColumns.length]);

  const screen = useCallback(
    async (filters) => {
      if (isReady && !isRankOn) {
        setIsLoading(true);

        try {
          const payload = prepareScreeningReq(filters);
          const rows = await instrumentsApi.screening(payload);
          const properties = getPropertiesFromCols();
          setDataTotalCount(rows.dataTotalCount);

          const fetchRows = await instrumentsApi.fetch(
            {
              properties,
              symbols: rows?.data ?? [],
              type: "security",
            },
            true
          );

          setSelectedRow(fetchRows?.data?.[0]);
          setData(fetchRows?.data);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [
      getPropertiesFromCols,
      instrumentsApi,
      isRankOn,
      isReady,
      prepareScreeningReq,
    ]
  );

  const removeRankColumns = useCallback(() => {
    const currentColumns = tableColumns;

    const columnsToSet: any = [];

    if (currentColumns.length) {
      for (const col of currentColumns) {
        columnsToSet.push(col.field);
      }

      // Must to replace rank columns with the old columns, if we pass undefined to the rankingConf (this should be the best behaviour)
      // the table will not update the columns
      setRankingTableConfig(columnsToSet);
      setIsRankOn(false);
      rankReducer({ type: "SET_CURRENT_TEMPLATE_ID", payload: undefined });
      rankReducer({ type: "SET_RANKING_RULES", payload: [] });
    }
  }, [rankReducer, tableColumns]);

  const { broadcast } = useBroadcast();

  const onSelectItemPerPage = useCallback(
    (value) => {
      setItemsPerPage(value);
      setPage(1);

      if (isRankOn) {
        onRank({ itemsPerPage: value, page: 1 });
      }
    },
    [isRankOn, onRank]
  );

  const onChangePage = useCallback(
    (value) => {
      setPage(value);

      if (isRankOn) {
        onRank({ page: value });
      }
    },
    [isRankOn, onRank]
  );

  useEffect(() => {
    if (filters != null) {
      screen(filters);
    }
  }, [filters, screen]);

  const handleTabChange = useCallback(
    (evt, value) => {
      const tabUriValue = encodeTypeParam(value);
      navigate(`/app/screening/${tabUriValue}`);
    },
    [navigate]
  );

  const prepareRow = useCallback(() => {
    const filterWidgetsConf = configurationScreening["widgets"]["viewerFilter"];
    const commonFilters = filterWidgetsConf?.[1] ?? [];
    const filtersByTab = filterWidgetsConf?.[0]?.[tab] ?? [];

    return [...filtersByTab, ...commonFilters];
  }, [configurationScreening, tab]);

  const onFiltersChange = useCallback((value) => {
    setFilters(value);
    setPage(1);
    setInvalidateRank(true);
  }, []);

  const onDataSorted = useCallback(
    ({ field, direction }) => {
      setSorter({ field, rev: direction === "desc" });
      setPage(1);

      if (isRankOn) {
        onRank({
          rev: direction === "desc",
          sortField: field,
          page: 1,
        });
      }
    },
    [isRankOn, onRank]
  );

  const onExpandTable = useCallback(() => {
    setExpand(!expand);

    // trick to reflow the table and to redraw it to fit into the container when this one
    // change his dimensions on expand event.
    // this trick resize the table but data are non afflicted and no server call is made
    setData((data) => {
      const newData = [...data];

      return newData;
    });
  }, [expand]);

  const rowClickOverride = useCallback((event) => {
    const data = event.value.getData();
    setSelectedRow(data);
  }, []);

  const tableEventsHandlersOverrides = useMemo(
    () => ({
      rowClick: rowClickOverride,
    }),
    [rowClickOverride]
  );

  const changeRankDateFrom = useCallback(
    (value) => {
      onRank({
        rev: false,
        sortField: "rank",
        page: 1,
        fromDate: value,
      });
    },
    [onRank]
  );

  const mergeRankColumns = useCallback(
    (columns) => {
      if (rankingTableConfig) {
        const rankingColumnsHelper = new RankingHelper(
          environment.get("setup")
        );
        const rankColumnsConfig = rankingTableConfig.filter(
          (col) =>
            col != null && typeof col !== "string" && /^rank/.test(col.field)
        );

        for (const config of rankColumnsConfig) {
          rankingColumnsHelper.configureAsRankCol(
            config,
            columns,
            undefined,
            false
          );
        }
      }
    },
    [environment, rankingTableConfig]
  );

  const getWidgetInfo = useCallback(
    (
      infoType:
        | "columns"
        | "constraints"
        | "pagination"
        | "sortBy"
        | "dataTotalCount"
    ) => {
      switch (infoType) {
        case "pagination":
          return {
            page,
            rows: itemsPerPage,
          };

        case "sortBy":
          return {
            property: sorter.field,
            descending: sorter.rev,
          };
        case "dataTotalCount":
          return dataTotalCount;

        case "constraints":
          return prepareScreeningReq(filters);

        case "columns": {
          let _column: any = null;
          let _columns: any = [...tableColumns];
          let column: any = null;
          let columns: any = [];

          if (isRankOn) {
            mergeRankColumns(_columns);
          }

          for (let i = 0, length = _columns.length; i < length; i++) {
            _column = _columns[i];
            if (_column["field"] !== undefined && _column["field"] != null) {
              column = {
                label: (_column?.["title"] ?? _column?.["label"] ?? "")
                  .replace(/<br\/>/gi, " - ")
                  .replace(/<br>/gi, " "),
                property: _column["field"],
              };
              columns.push(column);
            }
          }

          return columns;
        }

        default:
          console.log(`Unrecognized widget type: ${infoType}`);
      }
    },
    [
      page,
      itemsPerPage,
      sorter.field,
      sorter.rev,
      dataTotalCount,
      prepareScreeningReq,
      filters,
      tableColumns,
      isRankOn,
      mergeRankColumns,
    ]
  );

  const onSaveTemplate = useCallback(
    async (newName?: string) => {
      const wysiwyg = prepareScreeningReq(filters) ?? null;
      const templateInfo = { ...activeTemplate };

      if (templateInfo.id && wysiwyg) {
        const payload = instrumentsApi.convertToIndexBuilderSyntax(wysiwyg);

        const screeningTemplateJSON = {
          data: payload,
          assetClass: tab ? tab.toLowerCase() : "unknown",
          name: newName && newName.length ? newName : templateInfo.name,
          id: templateInfo.id,
        };

        try {
          await instrumentsApi.update(screeningTemplateJSON, "SCREENING");

          const message = {
            from: "Routes",
            content: {
              type: "success",
              text: `<strong>${templateInfo.name}</strong> successfully saved`,
            },
          };

          setShowTemplateSaveDialog(false);
          const [channel, msg] = messageSuccess(message.content.text);
          broadcast(channel as string, msg);
        } catch (error) {
          const message = {
            from: "Routes",
            content: {
              type: "error",
              text: `Something gone wrong cannot save <strong>${templateInfo.name}</strong> `,
            },
          };

          const [channel, msg] = messageError(message.content.text);
          broadcast(channel as string, msg);

          setShowTemplateSaveDialog(false);
        }
      }
    },
    [
      activeTemplate,
      broadcast,
      filters,
      instrumentsApi,
      prepareScreeningReq,
      tab,
    ]
  );

  const onCreateTemplate = useCallback(
    async (name: string) => {
      // wysiwyg state
      const filtersOldSyntax = prepareScreeningReq(filters) ?? null;

      if (filtersOldSyntax) {
        const newScreeningSyntaxConstraints =
          instrumentsApi.convertToIndexBuilderSyntax(filtersOldSyntax);

        const screeningTemplateJSON = {
          data: newScreeningSyntaxConstraints,
          assetClass: tab ? tab.toLowerCase() : "unknown",
          name,
          id: null,
        };

        try {
          await instrumentsApi.create(screeningTemplateJSON, "SCREENING");

          const message = {
            from: "Routes",
            content: {
              type: "success",
              text: `<strong>${name}</strong> successfully saved`,
            },
          };

          setShowTemplateSaveDialog(false);
          const [channel, msg] = messageSuccess(message.content.text);
          broadcast(channel as string, msg);
        } catch (error) {
          const message = {
            from: "Routes",
            content: {
              type: "error",
              text: `Something gone wrong cannot save <strong>${name}</strong> `,
            },
          };

          setShowTemplateSaveDialog(false);
          const [channel, msg] = messageError(message.content.text);
          broadcast(channel as string, msg);
        }
      }
    },
    [broadcast, filters, instrumentsApi, prepareScreeningReq, tab]
  );

  const onColumnsChange = useCallback(
    (columns) => {
      setTableColumns(columns);
      propertiesCols.current = columns;
      setRankingTableConfig(columns);

      if (isRankOn) {
        onRank({
          sortField: "rank",
          rev: false,
          page: 1,
          columns: columns.map((col) => col.field),
        });
      } else {
        screen(filters);
      }
    },
    [filters, isRankOn, onRank, screen]
  );

  const getActiveTemplate = useCallback(() => activeTemplate, [activeTemplate]);

  useEffect(() => {
    on("expand-screening-table", onExpandTable);

    return () => remove("expand-screening-table", onExpandTable);
  }, [on, onExpandTable, remove]);

  useEffect(() => {
    let actions: any = [];
    let action: any = null;

    const exportFileName = "trendrating-screening.csv";

    let target = {
      columns: getWidgetInfo("columns"),
      constraints: getWidgetInfo("constraints"),

      // IMPORTANT: it is just a placeholder
      // NOT GUARANTEES that the id is unique!
      //
      // Could happens that another user generate the same
      // id in exactly the same time
      //
      id: "screening_" + new Date().getTime(),

      name: "Screening",
      page: getWidgetInfo("pagination"),
      sortBy: getWidgetInfo("sortBy"),
    };

    if (isRankOn) {
      const rankingParams = rankingCache;
      action = {
        componentJSX: (
          <Export
            widgets={{
              table: { get: getWidgetInfo },
            }}
            fileName={exportFileName}
            rankingCache={rankingCache}
          />
        ),
      };
      actions.push(action);

      action = {
        componentJSX: (
          <ReportButton
            page={"screening"}
            target={target}
            rankingCache={rankingParams}
            title={target.name}
            usage={window.App.usage}
            widgets={{ table: { get: getWidgetInfo } }}
          />
        ),
      };

      actions.push(action);

      action = {
        componentJSX: <ActionRank openRankDialog={openRankDialog} />,
      };

      actions.push(action);

      action = {
        componentJSX: (
          <li onClick={() => setInvalidateRank(true)} className="menu__item">
            Remove Ranking
          </li>
        ),
      };

      actions.push(action);
    } else {
      action = {
        componentJSX: (
          <Export
            widgets={{
              table: { get: getWidgetInfo },
            }}
            fileName={exportFileName}
            rankingCache={rankingCache}
          />
        ),
      };
      actions.push(action);

      action = {
        componentJSX: (
          <ReportButton
            page={"screening"}
            target={target}
            title={target.name}
            usage={window.App.usage}
            widgets={{ table: { get: getWidgetInfo } }}
          />
        ),
      };

      actions.push(action);

      action = {
        componentJSX: <ActionRank openRankDialog={openRankDialog} />,
      };

      actions.push(action);
    }

    action = {
      componentJSX: (
        <li
          title="Save screening template"
          className="menu__item"
          onClick={() => setShowTemplateSaveDialog(true)}
        >
          Save
        </li>
      ),
    };

    actions.push(action);

    action = {
      componentJSX: (
        <li
          title="Delete screening template"
          className="menu__item menu__item--delete"
          onClick={() => setShowDeleteTemplateDialog(true)}
        >
          Delete
        </li>
      ),
    };

    if (activeTemplate && !activeTemplate.isReadOnly) {
      actions.push(action);
    }

    var message = {
      from: "screening",
      content: {
        actions: actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [
    activeTemplate,
    broadcast,
    getWidgetInfo,
    isRankOn,
    openRankDialog,
    rankingCache,
  ]);

  useEffect(() => {
    if (invalidateRank === true) {
      removeRankColumns();
      setSorter({ field: "marketcap", rev: true });

      setInvalidateRank(false);
    }
  }, [invalidateRank, removeRankColumns]);

  useEffect(() => {
    if (rankResults) {
      handleRankFinish(rankResults);
    }
  }, [handleRankFinish, rankResults]);

  useEffect(() => {
    rankReducer({ type: "SET_COLUMNS", payload: tableColumns });
  }, [rankReducer, tableColumns]);

  useEffect(() => {
    rankReducer({
      type: "SET_CONSTRAINTS",
      payload: prepareFiltersForRank(filters),
    });
  }, [filters, prepareFiltersForRank, rankReducer]);

  const previousAddedCols = useRef<any>([]);

  const addToColumns = useCallback(
    (value: any) => {
      const columns: any = [...tableColumns];

      const helper = new TableHelpers(environment.get("setup"));
      const newCols = [
        ...helper.generateFromFields(
          value.map((item) => item.field),
          () => 0
        ),
      ];

      let newColumns: any = [];

      const previouslyAddedMap = previousAddedCols.current.reduce(
        (prev, current) => {
          return { ...prev, [current.field]: true };
        },
        {}
      );

      for (const col of columns) {
        if (!(col.field in previouslyAddedMap)) {
          newColumns.push(col);
        }
      }

      const currentFieldsMap = newColumns.reduce((prev, current) => {
        return { ...prev, [current.field]: true };
      }, {});

      previousAddedCols.current = [];

      for (const col of newCols) {
        if (!(col.field in currentFieldsMap)) {
          newColumns.push(col);
          previousAddedCols.current.push(col);
        }
      }

      propertiesCols.current = newColumns;
      setTableColumns(newColumns);
    },
    [environment, tableColumns]
  );

  const setColumns = useCallback((cols) => {
    setTableColumns(cols);
    propertiesCols.current = cols;
  }, []);

  const filtersRow = useMemo(() => {
    return prepareRow();
  }, [prepareRow]);

  const FiltersRowComponent = useMemo(() => {
    return (
      <Box>
        <FilterRowContraints
          rowToRender={filtersRow}
          onRowChange={onFiltersChange}
          tabType={tab}
          updateTableColumns={addToColumns}
          setActiveTemplate={setActiveTemplate}
          getActiveTemplate={getActiveTemplate}
          uriParams={params as any}
        />
      </Box>
    );
  }, [
    addToColumns,
    filtersRow,
    getActiveTemplate,
    onFiltersChange,
    params,
    tab,
  ]);

  return (
    <ErrorBoundary
      fallback={
        <Typography>
          An unknown error occures during page loading, please refresh the page,
          if the error persists contact our customers support
        </Typography>
      }
    >
      {showRankDialog ? (
        <RankDialog
          page={"screening"}
          closeDialog={closeRankDialog}
          onRankedCallback={onFirstRankFinish}
        />
      ) : (
        <></>
      )}
      {showTemplateSaveDialog ? (
        <DialogSaveComponent
          onSave={
            activeTemplate?.id != null && activeTemplate.isReadOnly === false
              ? onSaveTemplate
              : null
          }
          onSaveAs={onCreateTemplate}
          onRename={
            activeTemplate?.id != null && activeTemplate.isReadOnly === false
              ? onSaveTemplate
              : null
          }
          item={{ name: activeTemplate?.name ?? "" }}
          dialogType="Screening rules"
          hide={() => setShowTemplateSaveDialog(false)}
        />
      ) : (
        <></>
      )}
      {showDeleteTemplateDialog ? (
        <DeleteScreeningDialog
          item={activeTemplate!}
          onClose={() => setShowDeleteTemplateDialog(false)}
        />
      ) : (
        <></>
      )}
      <Box className={styles.mainWrapper}>
        <Tabs value={tab} onChange={handleTabChange}>
          {optionsList.map((item) => (
            <Tab key={uuidv4()} label={item.label} value={item.value} />
          ))}
        </Tabs>
        <Box className={styles.tabContent}>
          {FiltersRowComponent}
          <Box className={styles.contentWrapper}>
            <Box
              className={styles.tablePanel}
              sx={{ width: expand === false ? "80%" : "100%" }}
            >
              <ScreeningTable
                tableKey={tableKey}
                tableEventsHandlersOverrides={tableEventsHandlersOverrides}
                data={data}
                configurationScreening={configurationScreening}
                tab={tab}
                dataTotalCount={dataTotalCount}
                itemsPerPage={itemsPerPage}
                onChangePage={onChangePage}
                onSelectItemPerPage={onSelectItemPerPage}
                isRankOn={isRankOn}
                changeRankDateFrom={changeRankDateFrom}
                rankingTableConfig={rankingTableConfig}
                setTableColumns={setColumns}
                onColumnsChange={onColumnsChange}
                onDataSorted={onDataSorted}
                isLoading={isLoading}
                sorterField={sorter.field}
                sorterDir={sorter.rev === false ? "desc" : "asc"}
              />
            </Box>
            {expand === false && (
              <Box className={styles.sidePanel}>
                <></>
                <Card sx={{ flex: 1, minHeight: 0, display: "flex" }}>
                  {isLoading ? (
                    <CardContent
                      sx={{ flex: 1, minHeight: 0, display: "flex" }}
                    >
                      <TableSkeleton rows={4} />
                    </CardContent>
                  ) : (
                    <CardContent
                      sx={{ flex: 1, minHeight: 0, display: "flex" }}
                    >
                      {selectedRow && (
                        <SecurityPanel symbol={selectedRow.symbol} />
                      )}
                    </CardContent>
                  )}
                </Card>

                {isLoading ? (
                  <Card sx={{ flex: 1, minHeight: 0, display: "flex" }}>
                    <CardContent
                      sx={{ flex: 1, minHeight: 0, display: "flex" }}
                    >
                      <TableSkeleton rows={4} />
                    </CardContent>
                  </Card>
                ) : (
                  <>
                    {selectedRow && (
                      <FundamentalsTable
                        tab={tab}
                        data={selectedRow}
                        availableColumns={
                          configurationScreening["widgets"]["viewer"][
                            "columns_available"
                          ]
                        }
                      />
                    )}
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

const ScreeningTable = React.memo(function ScreeningTable({
  tableKey,
  tableEventsHandlersOverrides,
  data,
  configurationScreening,
  tab,
  dataTotalCount,
  itemsPerPage,
  onChangePage,
  onSelectItemPerPage,
  isRankOn,
  changeRankDateFrom,
  rankingTableConfig,
  setTableColumns,
  onColumnsChange,
  onDataSorted,
  isLoading,
  sorterField,
  sorterDir,
}: any) {
  return (
    <Card sx={{ flex: 1, minHeight: 0, display: "flex", minWidth: 0 }}>
      <CardContent sx={{ flex: 1, minHeight: 0, display: "flex", minWidth: 0 }}>
        <InstrumentsTable
          key={tableKey}
          tableHandlersOverride={tableEventsHandlersOverrides}
          tableData={data}
          tableProps={{
            disableDefaultRowClick: true,
            tooltip: { actions: { info: { enabled: true } } },
            autoResize: true,
            sorting: { field: sorterField, direction: sorterDir },
            options: {
              ajaxSorting: false,
            },
          }}
          tools={{
            addToButton: true,
            expandTable: {
              eventName: "expand-screening-table",
            },
            configurator: {
              hasToSkipLastApplied: false,
              defaultTemplateNameBase: "DEFAULT_SCREENING",
              configurations: configurationScreening.widgets.viewer,
              securityType: tab,
              isSaveLastUsedConfigurationColumnsEnabled: true,
            },
            pagination: {
              dataTotalCount,
              itemsPerPage,
              changePage: onChangePage,
            },
            viewAsListButton: true,
            rowsNumberSelect: {
              label: "Securities per page",
              onChangeItemsPerPage: onSelectItemPerPage,
            },
            rank: {
              showRankTools: isRankOn,
              onChangeRankDate: changeRankDateFrom,
            },
          }}
          columns={rankingTableConfig}
          getInitColumns={setTableColumns}
          onColumnsChange={onColumnsChange}
          useAutoSort={false}
          handleSortManually={onDataSorted}
          loading={isLoading}
        />
      </CardContent>
    </Card>
  );
});
