/* eslint-disable no-template-curly-in-string */
import { Box, Card, CardContent } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TrendratingTable } from "../../../../../components/table/TrendratingTable";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { SystematicPortfoliosStorage } from "../../../storage/SystematicPortfoliosStorage";
import { rebalance, rebalanceInfo } from "./utils";
import { TDate } from "../../../../../trendrating/date/TDate";
import { format as fmtDate } from "date-fns";
import { SortBy } from "../../strategies/widgets/StrategiesList/StrategiesList";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { ColumnDefinition } from "tabulator-tables";

type GridViewerProps = {
  showShared: boolean | null;
  storage: SystematicPortfoliosStorage;
};

// Server side preference
const SORT_STATE_PATH = ["systematicProducts", "sort"];

export default function GridViewer({ showShared, storage }: GridViewerProps) {
  const [products, setProducts] = useState<any>([]);
  const [sort, setSort] = useState<
    undefined | "name" | "name_rev" | "recent_first" | "recent_bottom"
  >(undefined);
  const { t } = useTranslation();
  const environment = useEnvironment();
  const pageStatusAPI = useMemo(
    () => environment.get("preferenceStatus"),
    [environment]
  );

  const preferencesReady = useMemo(() => {
    return sort != null;
  }, [sort]);

  const prepareRebalanceInfo = useCallback((response) => {
    if (!response) {
      return undefined;
    }

    var data = {
      next: response["nextRebalance"],
      previous: response["previousRebalance"],
      status: response["rebalanceStatus"].toLowerCase(),
      today: response["today"],
    };

    return data;
  }, []);

  const getSorter = useCallback((sort) => {
    let property = "name";
    let rev = false;

    switch (sort) {
      case "name":
      default:
        break;

      case "name_rev":
        property = "name";
        rev = true;

        break;

      case "recent_bottom":
        property = "creationTime";
        rev = false;

        break;

      case "recent_first":
        property = "creationTime";
        rev = true;

        break;
    }

    return { property, rev };
  }, []);

  const getData = useCallback(async () => {
    let productIds: number[] = [];

    if (preferencesReady) {
      if (showShared) {
        productIds = await storage.getSubscribedProducts();
      } else {
        productIds = await storage.getUserProducts();
      }

      const { property, rev } = getSorter(sort);

      const fields = [
        "name",
        "reviewGranularity",
        "updateTime",
        "currency",
        "autorebalance",
        "calendarRebalance",
      ];

      if (!fields.includes(property)) {
        fields.push(property);
      }

      const data = await storage.smsAPI.fetch({
        ids: productIds,
        properties: fields,
      });
      let formattedData = data.map((obj) => {
        obj["calendarRebalance"] = prepareRebalanceInfo(
          obj["calendarRebalance"]
        );
        return obj;
      });

      let aValue: any = null;
      let bValue: any = null;

      formattedData = formattedData.sort((a, b) => {
        aValue =
          property === "creationTime" ? a[property] : a[property].toLowerCase();
        bValue =
          property === "creationTime" ? b[property] : b[property].toLowerCase();

        if (aValue > bValue) {
          return rev === false ? 1 : -1;
        } else if (aValue < bValue) {
          return rev === false ? -1 : 1;
        }

        return 0;
      });

      setProducts(formattedData);
    }
  }, [
    getSorter,
    preferencesReady,
    prepareRebalanceInfo,
    showShared,
    sort,
    storage,
  ]);

  useEffect(() => {
    try {
      getData();
    } catch (error) {
      console.log(error);
    }

    return () => {
      setProducts([]);
    };
  }, [getData]);

  const setInitialSort = useCallback(async () => {
    const savedSort = await pageStatusAPI.get(SORT_STATE_PATH);
    let sortValue: any = "name";

    if (savedSort) {
      const field = savedSort?.property ?? "name";
      const rev = savedSort?.rev ?? false;

      if (field.startsWith("name")) {
        if (field === "name") {
          if (rev === true) {
            sortValue = "name_rev";
          } else {
            sortValue = "name";
          }
        }
      } else {
        if (rev === true) {
          sortValue = "recent_first";
        } else {
          sortValue = "recent_bottom";
        }
      }
    }

    setSort(sortValue);
  }, [pageStatusAPI]);

  const onSortChange = useCallback(
    async (sortValue) => {
      const sorter = getSorter(sortValue);
      await pageStatusAPI.patch(SORT_STATE_PATH, sorter);

      setSort(sortValue);
    },
    [getSorter, pageStatusAPI]
  );

  useEffect(() => {
    setInitialSort();
  }, [setInitialSort]);

  const format = useFormatter();
  const columns: ColumnDefinition[] = [
    {
      title: "Name",
      field: "name",
      cssClass: "no-col-borders",
      headerHozAlign: "left",
      hozAlign: "left",
    },
    {
      title: "Rebalance",
      field: "reviewGranularity",
      cssClass: "no-col-borders",
      hozAlign: "left",
      formatter: (cell) => {
        return rebalance(cell.getData()["reviewGranularity"], t);
      },
    },
    {
      title: "Last edited",
      field: "updateTime",
      cssClass: "no-col-borders",
      hozAlign: "left",
      formatter: (cell) => {
        return fmtDate(
          TDate.daysToDate(
            TDate.millisecondsToDays(cell.getData()["updateTime"])
          ),
          "dd LLL yyyy"
        );
      },
    },
    {
      title: "Currency",
      field: "currency",
      cssClass: "no-col-borders",
      hozAlign: "left",
    },
    {
      title: "Auto-rebalance",
      field: "autorebalance",
      cssClass: "no-col-borders",
      hozAlign: "center",

      formatter: (cell) => {
        if (cell.getData()["autorebalance"]) {
          return "On";
        } else {
          return "Off";
        }
      },
    },
    {
      title: "Rebalance status",
      field: "calendarRebalance.status",
      cssClass: "no-col-borders",
      hozAlign: "left",
      formatter: (cell) => {
        if (cell.getData()["calendarRebalance"]["status"] === "overdue") {
          cell.getElement().style.color = "red";
        } else if (
          cell.getData()["calendarRebalance"]["status"] === "rebalance"
        ) {
          cell.getElement().style.color = "#ffc001";
        }
        return rebalanceInfo(cell.getData()["calendarRebalance"], t, format);
      },
    },
  ];

  const handleEventCallback = useCallback((event) => {
    if (event.type === "rowClick") {
      const w = window as any;
      w.__page_navigate(`/systematic-portfolios/${event.value.getData().id}`);
    }
  }, []);

  return (
    <>
      {showShared !== null && products != null && (
        <Box p={1} width={"100%"}>
          <Box pb={1} width={"10%"}>
            <SortBy sort={sort ?? "name"} setSort={onSortChange} />
          </Box>
          <Card sx={{ width: "100%" }}>
            <CardContent>
              <TrendratingTable
                data={products}
                autoResize={true}
                columns={columns}
                eventCallback={handleEventCallback}
                options={{
                  height: "0px",
                  ajaxSorting: false,
                }}
              />
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
}
