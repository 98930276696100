import { CellComponent } from "tabulator-tables";
import { Formatter } from "../../../trendrating/utils/Formatter";
import { AppEnvironment } from "../../../types/Defaults";
import { TableHelperCommon } from "./TableHelperCommon";

export class ColumnsHelper extends TableHelperCommon {
  formatter: Formatter;

  constructor(environment: AppEnvironment) {
    super(environment);

    this.formatter = environment.formatter;
  }

  tabulatorColumn(col, sortHandler) {
    if ("skipTransformation" in col && col.skipTransformation === true) {
      delete col.skipTransformation;

      return col;
    }

    return {
      field: col.field,
      title: col.label,
      formatter: (cell) => this.getFormatter(cell, col.field),
      sorter: () => 0, // () => 0 is a fake sorter used to avoid tabulator to sort data even if the sort is handled server side
      headerClick: this.prepareHeaderClickTapListener(sortHandler),
      headerSortStartingDir: this.getSortInitialDirection(
        col.field,
        "security"
      ),
      isPointInTime: "isPointInTime" in col ? col.isPointInTime : false,
    };
  }

  getFormatter(cell: CellComponent, property) {
    if (property === "contribution") {
      return this.formatter.custom("bar", {
        options: {
          hasPositiveSign: true,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
          width: "2.25em",
        },
        output: "HTML",
        value: (cell.getData() as any).contribution,
        valueHelper: {
          normalizationThreshold: cell.getData()["_s_normalizationThreshold"],
        },
      });
    }

    const cellData = cell.getData();

    try {
      if (property) {
        const value = this.formatter.table(property, "table", cellData);

        return value;
      }

      return "";
    } catch (error) {
      console.log(error);
      return "";
    }
  }

  addCustomColumnSorter(col, sorter) {
    return { ...col, headerClick: this.prepareHeaderClickTapListener(sorter) };
  }
}
