import {
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import SecurityChartModal from "../../../../../../../../components/SecurityChartModal/SecurityChartModal";
import SecurityTooltip from "../../../../../../../../components/SecurityTooltip/SecurityTooltip";
import { useDetectOutsideClick } from "../../../../../../../../hooks/useDetectOutsideClick";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../../hooks/useFormatter";
import styles from "./PerformerBox.module.scss";
import { Portal } from "@mui/material";

type PerformerBoxProps = {
  data: any;
  itemsToDisplay: number;
  timeframe: string;
};

type PerformerBoxListItemProps = {
  rowData: any;
  timeframe: string;
  readSecurity: (value) => void;
  setShowModal: (value) => void;
};

type PerformanceBarProps = { value: any; valueRescaled: any };

type ShowMoreButtonProps = {
  data: any;
  timeframe: string;
  readSecurity: (value) => void;
  setShowModal: (value) => void;
};

type OverlayListProps = {
  data: any;
  timeframe: string;
  nodeTarget: RefObject<HTMLButtonElement>;
  readSecurity: (value) => void;
  setShowModal: (value) => void;
};

type PerformerBoxListProps = {
  data: any;
  timeframe: string;
  readSecurity: (value) => void;
  setShowModal: (value) => void;
};

// !Props passed throw components are repetitive a better work can be done by using Context

export default function PerformerBoxTable({
  data,
  itemsToDisplay,
  timeframe,
}: PerformerBoxProps) {
  const environment = useEnvironment();
  const [showModal, setShowModal] = useState(false);
  const [securityFocus, setSecurityFocus] = useState<any>();
  const length = Math.min(data?.length, itemsToDisplay ?? 0);
  const rowToDisplay =
    length !== data.length
      ? data?.filter((row) => data.indexOf(row) < length)
      : data;
  const moreData =
    length !== data.length
      ? data?.filter((row) => data.indexOf(row) > length - 1)
      : [];

  const onCloseHandler = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      <SecurityChartModal
        environment={environment}
        security={securityFocus}
        showModal={showModal}
        onClose={onCloseHandler}
      />
      <div className={styles["tAnalysisOverview-performerBox"]}>
        <PerformerBoxList
          data={rowToDisplay}
          timeframe={timeframe}
          readSecurity={setSecurityFocus}
          setShowModal={setShowModal}
        />
        {moreData.length !== 0 && (
          <ShowMoreButton
            timeframe={timeframe}
            data={moreData}
            readSecurity={setSecurityFocus}
            setShowModal={setShowModal}
          />
        )}
      </div>
    </>
  );
}

const PerformerBoxList = ({
  timeframe,
  data,
  readSecurity,
  setShowModal,
}: PerformerBoxListProps) => {
  return (
    <ul>
      {data.map((row, index) => (
        <PerformerBoxListItem
          setShowModal={setShowModal}
          readSecurity={readSecurity}
          timeframe={timeframe}
          rowData={row}
          key={`${row?.["ticker"] ?? index}__${row?.["name"] ?? index}`}
        />
      ))}
    </ul>
  );
};

const PerformerBoxListItem = ({
  rowData,
  timeframe,
  readSecurity,
  setShowModal,
}: PerformerBoxListItemProps) => {
  const formatter = useFormatter();
  const [isTooltipIconVisible, setIsTooltipIconVisible] = useState(false);

  const onClickChartButton = () => {
    setShowModal(true);
    setIsTooltipIconVisible(false);
  };

  return (
    <li
      className={styles["tAnalysisOverview-performerBoxRow"]}
      title={rowData?.["name"]}
    >
      <div className={styles["tAnalysisOverview-performerBoxLabel"]}>
        <div
          onMouseEnter={() => setIsTooltipIconVisible(true)}
          onMouseLeave={() => setIsTooltipIconVisible(false)}
          className={styles["tAnalysisOverview-performerBoxLabelValue"]}
        >
          <strong style={{ marginRight: 5 }}>{rowData?.["ticker"]}</strong>
          {rowData?.["name"]}{" "}
          <SecurityTooltip
            symbol={rowData?.["symbol"] ?? ""}
            chartButtonHandler={onClickChartButton}
            setSecurity={readSecurity}
          >
            <span
              style={
                isTooltipIconVisible
                  ? { cursor: "pointer" }
                  : { visibility: "hidden" }
              }
              className="i-info"
            ></span>
          </SecurityTooltip>
        </div>
      </div>
      <div className={styles["tAnalysisOverview-performerBoxValue"]}>
        <div
          dangerouslySetInnerHTML={{
            __html: formatter.custom("number", {
              options: {
                colored: "positive",
                isPercentage: true,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "HTML",
              value: rowData[timeframe],
              valueHelper: null,
            }),
          }}
        ></div>
        <div className={styles["tAnalysisOverview-performerBoxBar"]}>
          <PerformanceBar
            value={rowData[timeframe]}
            valueRescaled={rowData["_" + timeframe]}
          />
        </div>
      </div>
    </li>
  );
};

const PerformanceBar = ({ value, valueRescaled }: PerformanceBarProps) => {
  return (
    <div className="percentage-bar">
      <span className="percentage-bar__negative">
        {valueRescaled < 0 && (
          <span
            className={`percentage-bar__value percentage-bar__value--negative ${
              value < -1 ? "percentage-bar__value--outlierNegative" : ""
            }`}
            style={{ width: `${Math.abs(valueRescaled)}%` }}
          ></span>
        )}
      </span>
      <span className="percentage-bar__positive">
        {valueRescaled > 0 && (
          <span
            className={`percentage-bar__value percentage-bar__value--positive ${
              value > 1 ? "percentage-bar__value--outlierPositive" : ""
            }`}
            style={{ width: `${Math.abs(valueRescaled)}%` }}
          ></span>
        )}
      </span>
    </div>
  );
};

const ShowMoreButton = ({
  data,
  timeframe,
  readSecurity,
  setShowModal,
}: ShowMoreButtonProps) => {
  const [showMoreItems, setShowMoreItems] = useState(false);
  const overlayRef = useRef<any>(null);

  const toggleShowMore = useCallback(() => {
    setShowMoreItems(!showMoreItems);
  }, [showMoreItems]);
  const overlayAttachment = useRef<HTMLButtonElement>(null);

  const clickOutsideHandler = (e) => {
    const overlay = overlayRef?.current;
    if (!overlay?.contains(e.target)) {
      if (e.target?.textContent.toLowerCase() === "chart") {
        setTimeout(() => setShowMoreItems(false), 500);

        return;
      }

      setShowMoreItems(false);
    }
  };

  useDetectOutsideClick(clickOutsideHandler);

  return (
    <>
      <button
        ref={overlayAttachment}
        style={{
          cursor: "pointer",
          alignSelf: "flex-end",
          marginLeft: "100%",
          transform: "translateX(-100%)",
        }}
        className="tAnalysisOverview-performerBoxButtonMore"
        onClick={toggleShowMore}
      >
        <span className="i-down"></span>
      </button>
      {showMoreItems && (
        <OverlayList
          readSecurity={readSecurity}
          setShowModal={setShowModal}
          ref={overlayRef}
          data={data}
          timeframe={timeframe}
          nodeTarget={overlayAttachment}
        />
      )}
    </>
  );
};

const OverlayList = forwardRef<HTMLDivElement, OverlayListProps>(
  ({ data, timeframe, nodeTarget, readSecurity, setShowModal }, ref) => {
    const overlayRef = ref != null && typeof ref !== "function" ? ref : null;

    useEffect(() => {
      const button = nodeTarget?.current ?? null;
      const overlayWindow = overlayRef?.current ?? null;

      if (button && overlayWindow) {
        const buttonDims = button.getBoundingClientRect();
        const { left, top, width } = buttonDims;
        const overlayWindowDims = overlayWindow.getBoundingClientRect();
        const winHeight = window.innerHeight;
        const winWidth = window.innerWidth;
        let topPosition = top;
        let leftPosition = left + width;

        if (overlayWindowDims.height + top > winHeight) {
          topPosition = top - overlayWindowDims.height;
        }

        if (left + overlayWindowDims.width > winWidth) {
          leftPosition = left - width - overlayWindowDims.width;
        }

        overlayWindow.style.top = `${topPosition}px`;
        overlayWindow.style.left = `${leftPosition}px`;
      }
    }, [nodeTarget, overlayRef]);

    return (
      <Portal>
        <div className="overlay" ref={ref}>
          <PerformerBoxList
            data={data}
            timeframe={timeframe}
            readSecurity={readSecurity}
            setShowModal={setShowModal}
          />
        </div>
      </Portal>
    );
  }
);
