import { Box, Card, CardContent, Tab, Tabs, Typography } from "@mui/material";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useImmerReducer } from "use-immer";
import { advancedReducer } from "./AdvancedReducer";
import Allocation from "./previews/Allocation";
import Backtesting from "./previews/Backtesting";
import Hedging from "./previews/Hedging";
import HoldingRules from "./previews/HoldingRules";
import InvestmentUniverse from "./previews/InvestmentUniverse";
import RankingRules from "./previews/RankingRules";
import SelectionRules from "./previews/SelectionRules";
import Strategy from "./previews/Strategy";
import WeightingRules from "./previews/WeightingRules";

import { ErrorBoundary } from "../../../../../../../ErrorBoundary";
import { handleTitle } from "../../../../../../../Utility/DocumentTitleHanlder";
import RankingRulesWizzard from "../../../../../../../components/RuleWizzard/RankingRulesWizzard/RankingRulesWizzard";
import SelectionRulesWizzard from "../../../../../../../components/RuleWizzard/SelectionRulesWizzard/SelectionRulesWizzard";
import StrategyRulesWizzard from "../../../../../../../components/RuleWizzard/StrategyRulesWizzard/StrategyRulesWizzard";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../../../../hooks/useEventBus";
import { StrategiesStorage } from "../../../../../storage/StrategiesStorage";
import StrategyNavigator from "../../../widgets/header/StrategyNavigator";
import AllocationController from "../advancedWidgets/AdvancedFieldControllers/AllocationController/AllocationController";
import BacktestingController from "../advancedWidgets/AdvancedFieldControllers/BacktestingController/BacktestingController";
import HedgingController from "../advancedWidgets/AdvancedFieldControllers/HedgingController/HedgingController";
import { InvestmentUniverseController } from "../advancedWidgets/AdvancedFieldControllers/InvestmentUniverseController/InvestmentUniverseController";
import WeightingRulesController from "../advancedWidgets/AdvancedFieldControllers/WeightingRulesController/WeightingRulesController";
import PreviewCard from "./PreviewCard";
import Result from "./Result/Result";
import { Dashboards, Insights } from "./Result/tabs/Insights/Insights";
import { UI_CONSTRAINTS_TYPE, defaultStrategy } from "./utils";

type ReactEditorAdvancedProps = {
  value: any;
  configurationBuilder: any;
  runResults: any;
  strategyNavigatorProperties: any;
  runManager: StrategiesStorage;
  cleanup?: Function;
  dashboard: Dashboards;
  loadedStrategyId: undefined | string;
  strategyName: string;
  readOnlyStrategy: boolean;
  setIsRunReady: (value: boolean) => void;
};

const Advanced = forwardRef(
  (
    {
      value,
      configurationBuilder,
      runResults,
      runManager,
      strategyNavigatorProperties,
      cleanup,
      dashboard,
      loadedStrategyId,
      strategyName,
      readOnlyStrategy,
      setIsRunReady,
    }: ReactEditorAdvancedProps,
    ref
  ) => {
    //TODO:A HOC architecture maybe the best one because this functionality is repeated for all the editors of index builder
    const [hideEditor, setHideEditor] = useState(false);

    const { on, remove } = useEventBus();

    const tableExpandListener = useCallback(
      (e) => {
        if (e.detail && e.detail.value != null) {
          setHideEditor(e.detail.value);
        } else {
          setHideEditor(!hideEditor);
        }
      },
      [hideEditor]
    );

    useEffect(() => {
      on("expand-holding-result-table", tableExpandListener);

      return () => remove("expand-holding-result-table", tableExpandListener);
    }, [on, remove, tableExpandListener]);

    const initValue = useMemo(() => {
      if (value != null) {
        return value;
      }
      return defaultStrategy;
    }, [value]);

    const environment = useEnvironment();
    const strategyBuilderConfig = useMemo(() => {
      return environment.get("account").product?.configuration?.strategy_builder
        .form_advanced;
    }, [environment]);

    const resultHelpEnabled = useMemo(() => {
      return strategyBuilderConfig?.resultTab?.insights ?? false;
    }, [strategyBuilderConfig?.resultTab?.insights]);

    const helpEnabled = useMemo(() => {
      const isEnabled = strategyBuilderConfig?.resultTab?.insights ?? false;
      const _loadedStrategyId =
        loadedStrategyId != null && loadedStrategyId !== "new"
          ? parseInt(loadedStrategyId)
          : null;
      let isDashboardStrategy = false;

      if (dashboard?.V1 != null) {
        for (const strategy of dashboard.V1) {
          if (
            loadedStrategyId != null &&
            _loadedStrategyId === (strategy?.info?.id ?? null)
          ) {
            isDashboardStrategy = true;

            break;
          }
        }
      }

      return (_loadedStrategyId == null || isDashboardStrategy) && isEnabled;
    }, [
      dashboard,
      loadedStrategyId,
      strategyBuilderConfig?.resultTab?.insights,
    ]);

    //#region -- getting ui contraints based on the product
    const UI_CONSTRAINTS: UI_CONSTRAINTS_TYPE = useMemo(
      () => strategyBuilderConfig.uiOptions,
      [strategyBuilderConfig.uiOptions]
    );

    //#endregion

    useEffect(() => {
      handleTitle({ type: "PREFERENCE_INDEX", name: strategyName });
    }, [strategyName]);

    const [state, dispatcher] = useImmerReducer(advancedReducer, initValue);
    //! resetting state when value param changes
    useEffect(() => {
      if (dispatcher != null) {
        dispatcher({ type: "RESET", payload: initValue });
      }
    }, [dispatcher, initValue]);
    //! ---------------------------------------

    // useEffect(() => {
    //   setOutterState(state);
    // }, [setOutterState, state]);
    useImperativeHandle(
      ref,
      () => ({
        getState: () => state,
        getDispatcher: () => dispatcher,
      }),
      [dispatcher, state]
    );

    const [widgetToShow, setWidgetToShow] = useState<any>(null);

    useEffect(() => {
      setIsRunReady(widgetToShow == null);
    }, [setIsRunReady, widgetToShow]);

    const optionsSelectionUniverse = useMemo(() => {
      return {
        create:
          configurationBuilder["formAdvanced"]["widgets"]["selectionUniverse"][
            "create"
          ]["options"],
        edit: configurationBuilder["formAdvanced"]["widgets"][
          "selectionUniverse"
        ]["edit"]["options"],
      };
    }, [configurationBuilder]);
    const optionsSelection = useMemo(() => {
      return {
        create: configurationBuilder["selection"]["create"]["options"],
        edit: configurationBuilder["selection"]["edit"]["options"],
      };
    }, [configurationBuilder]);
    const optionsRanking = useMemo(() => {
      return {
        create: configurationBuilder["ranking"]["create"]["options"],
        edit: configurationBuilder["ranking"]["edit"]["options"],
      };
    }, [configurationBuilder]);
    const optionsSmartBeta = useMemo(() => {
      return configurationBuilder["formAdvanced"]["widgets"]["smartBeta"][
        "options"
      ];
    }, [configurationBuilder]);
    const optionsHedging = useMemo(() => {
      return {
        create: configurationBuilder["hedging"]["create"]["options"],
        edit: configurationBuilder["hedging"]["edit"]["options"],
      };
    }, [configurationBuilder]);

    const etfUniverse = useMemo(() => {
      return state?.universe?.screening?.instrumentType === "etf";
    }, [state?.universe?.screening?.instrumentType]);

    //#region //! used for handle Insights redirects
    const compareInputAndOutput = useCallback(
      (input, output) => {
        if (JSON.stringify(input) !== JSON.stringify(output)) {
          dispatcher({ type: "SET_ASKBEFOREINSIGHTSREDIRECT", payload: true });
        } else {
          dispatcher({ type: "SET_ASKBEFOREINSIGHTSREDIRECT", payload: false });
        }
      },
      [dispatcher]
    );
    //#endregion

    const getWidgetToShow = useCallback(
      (cardID) => {
        if (widgetToShow != null) {
          setWidgetToShow(null);
        }
        switch (cardID) {
          case "strategy":
            setWidgetToShow(
              <StrategyRulesWizzard
                UI_CONSTRAINTS={UI_CONSTRAINTS}
                key={Date.now()}
                value={state.strategy}
                onClose={(val) => {
                  dispatcher({ type: "SET_STRATEGY", payload: val });
                  compareInputAndOutput(state.strategy, val);
                  setWidgetToShow(null);
                }}
                onCancel={() => setWidgetToShow(null)}
              />
            );
            break;
          case "universe":
            setWidgetToShow(
              <InvestmentUniverseController
                selectionOptions={optionsSelectionUniverse}
                titleRuleList={""}
                caller={"SELECTION_RULES"}
                onCloseSelection={(val) => {
                  dispatcher({ type: "SET_UNIVERSE", payload: val });
                  compareInputAndOutput(state.universe, val);
                  setWidgetToShow(null);
                }}
                onCancel={() => setWidgetToShow(null)}
                actualStrategyConfig={state.universe}
                UI_CONSTRAINTS={UI_CONSTRAINTS}
              />
            );
            break;
          case "selection":
            setWidgetToShow(
              <SelectionRulesWizzard
                options={optionsSelection}
                titleRuleList={"Selection Rules"}
                onClose={(val) => {
                  dispatcher({ type: "SET_SELECTION", payload: val });
                  compareInputAndOutput(state.selection, val);
                  setWidgetToShow(null);
                }}
                onCancel={() => {
                  setWidgetToShow(null);
                }}
                rules={state.selection}
                caller={"SELECTION_RULES"}
              />
            );
            break;
          case "ranking":
            setWidgetToShow(
              <RankingRulesWizzard
                rules={state.ranking}
                options={optionsRanking}
                titleRuleList={"Ranking Rules"}
                caller={"RANKING_RULES"}
                onClose={(val) => {
                  dispatcher({ type: "SET_RANKING", payload: val });
                  compareInputAndOutput(state.ranking, val);

                  setWidgetToShow(null);
                }}
                onCancel={() => setWidgetToShow(null)}
                properties={undefined}
              />
            );
            break;
          case "holding":
            setWidgetToShow(
              <SelectionRulesWizzard
                options={optionsSelection}
                titleRuleList={"Holding Rules"}
                onClose={(val) => {
                  dispatcher({ type: "SET_HOLDING", payload: val });
                  compareInputAndOutput(state.holding, val);

                  setWidgetToShow(null);
                }}
                onCancel={() => setWidgetToShow(null)}
                rules={state.holding}
                caller={"HOLDING_RULES"}
              />
            );
            break;
          case "weighting":
            setWidgetToShow(
              <WeightingRulesController
                currentConfiguration={state.weighting}
                options={optionsSmartBeta}
                onCloseSelection={(val) => {
                  dispatcher({ type: "SET_WEIGHTING", payload: val });
                  compareInputAndOutput(state.weighting, val);

                  setWidgetToShow(null);
                }}
                onCancel={() => setWidgetToShow(null)}
                UI_CONSTRAINTS={UI_CONSTRAINTS}
              />
            );
            break;
          case "allocation":
            setWidgetToShow(
              <AllocationController
                isEtfUniverse={etfUniverse}
                value={state.allocation}
                onCancel={() => setWidgetToShow(null)}
                onClose={(val) => {
                  dispatcher({ type: "SET_ALLOCATION", payload: val });
                  compareInputAndOutput(state.allocation, val);

                  setWidgetToShow(null);
                }}
              />
            );
            break;
          case "backtesting":
            setWidgetToShow(
              <BacktestingController
                input={state.backtesting}
                onClose={(val) => {
                  dispatcher({ type: "SET_BACKTESTING", payload: val });
                  compareInputAndOutput(state.backtesting, val);

                  setWidgetToShow(null);
                }}
                onCancel={() => setWidgetToShow(null)}
                UI_CONSTRAINTS={UI_CONSTRAINTS}
              />
            );
            break;
          case "hedging":
            setWidgetToShow(
              <HedgingController
                options={optionsHedging}
                valueFromOutside={state.hedging}
                onClose={(val) => {
                  dispatcher({ type: "SET_HEDGING", payload: val });
                  compareInputAndOutput(state.hedging, val);

                  setWidgetToShow(null);
                }}
                onCancel={() => setWidgetToShow(null)}
              />
            );
            break;
        }
      },
      [
        UI_CONSTRAINTS,
        compareInputAndOutput,
        dispatcher,
        etfUniverse,
        optionsHedging,
        optionsRanking,
        optionsSelection,
        optionsSelectionUniverse,
        optionsSmartBeta,
        state.allocation,
        state.backtesting,
        state.hedging,
        state.holding,
        state.ranking,
        state.selection,
        state.strategy,
        state.universe,
        state.weighting,
        widgetToShow,
      ]
    );

    return (
      <ErrorBoundary
        fallback={
          <Card>
            <CardContent>
              <Typography>Strategy is corrupted</Typography>
            </CardContent>
          </Card>
        }
      >
        <Box width={"100%"} display="flex" height={"100%"} overflow={"hidden"}>
          {!hideEditor && (
            <Box
              position={"relative"}
              width={"30%"}
              display="flex"
              flexDirection={"column"}
              height={"100%"}
            >
              <Box p={1} gap={2} display="flex" alignItems={"center"}>
                {strategyName != null ? (
                  <Typography variant="subtitle1">
                    {strategyName === "" ? (
                      "Strategy"
                    ) : (
                      <span
                        dangerouslySetInnerHTML={{ __html: strategyName }}
                      ></span>
                    )}
                    {readOnlyStrategy === true && (
                      <>
                        &nbsp;&nbsp;
                        <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
                      </>
                    )}
                  </Typography>
                ) : (
                  <Typography variant="subtitle1">Strategy</Typography>
                )}
                <StrategyNavigator
                  strategyType={strategyNavigatorProperties.strategyType}
                  listenerChangeStrategy={
                    strategyNavigatorProperties.listenerChangeStrategy
                  }
                />
              </Box>

              <Box
                sx={{ p: 1, pt: 0, overflowY: "auto" }}
                width={"100%"}
                height={"100%"}
              >
                <Box display="flex" flexDirection={"column"} gap={1}>
                  <PreviewCard
                    cardHandler={() => getWidgetToShow("strategy")}
                    title="Strategy"
                    content={<Strategy value={state.strategy} />}
                  />
                  <PreviewCard
                    cardHandler={() => getWidgetToShow("universe")}
                    title="Investment Universe"
                    content={
                      <InvestmentUniverse
                        UI_CONSTRAINTS={UI_CONSTRAINTS}
                        value={state.universe}
                      />
                    }
                  />
                  <PreviewCard
                    cardHandler={() => getWidgetToShow("selection")}
                    title="Selection rules"
                    content={<SelectionRules value={state.selection} />}
                  />
                  <PreviewCard
                    cardHandler={() => getWidgetToShow("ranking")}
                    title="Ranking rules"
                    content={<RankingRules value={state.ranking} />}
                  />
                  {UI_CONSTRAINTS.holdingRules.enabled === true && (
                    <PreviewCard
                      cardHandler={() => getWidgetToShow("holding")}
                      title="Holding rules"
                      content={<HoldingRules value={state.holding} />}
                    />
                  )}
                  <PreviewCard
                    cardHandler={() => getWidgetToShow("weighting")}
                    title="Weighting rules"
                    content={
                      <WeightingRules
                        UI_CONSTRAINTS={UI_CONSTRAINTS}
                        value={state.weighting}
                      />
                    }
                  />
                  <PreviewCard
                    cardHandler={() => getWidgetToShow("allocation")}
                    title="Allocation constraints"
                    content={
                      <Allocation
                        value={state.allocation}
                        isETF={etfUniverse}
                      />
                    }
                  />
                  <PreviewCard
                    cardHandler={() => getWidgetToShow("backtesting")}
                    title="Backtesting"
                    content={
                      <Backtesting
                        UI_CONSTRAINTS={UI_CONSTRAINTS}
                        value={state.backtesting}
                      />
                    }
                  />
                  {UI_CONSTRAINTS.hedging.enabled === true && (
                    <PreviewCard
                      cardHandler={() => getWidgetToShow("hedging")}
                      title="Hedging"
                      content={<Hedging value={state.hedging} />}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          )}
          <Box width={hideEditor === true ? "100%" : "70%"} height={"100%"}>
            {runResults != null && widgetToShow == null ? (
              <Result
                askBeforeInsightsRedirect={state.askBeforeInsightsRedirect}
                helpEnabled={resultHelpEnabled}
                cleanup={cleanup}
                runManager={runManager}
                value={runResults}
                dashboard={dashboard}
              />
            ) : (
              <>
                {helpEnabled && widgetToShow == null && (
                  <Box
                    sx={{ width: "100%" }}
                    height={"100%"}
                    overflow={"hidden"}
                    display="flex"
                    flexDirection={"column"}
                  >
                    <Box px={1}>
                      <Tabs
                        sx={{ borderBottom: 1, borderColor: "divider" }}
                        value={"insights"}
                      >
                        {/* //! default color must be black otherwise using grey the tab seems to be disabled */}

                        <Tab
                          label={"insights"}
                          sx={{ color: "#000" }}
                          value={"insights"}
                        />
                      </Tabs>
                    </Box>
                    <Box width={"100%"} height={"100%"} overflow={"auto"} p={1}>
                      <Insights
                        dashboards={dashboard}
                        askBeforeInsightsRedirect={
                          state.askBeforeInsightsRedirect
                        }
                      />
                    </Box>
                  </Box>
                )}
              </>
            )}
            {widgetToShow != null ? <div>{widgetToShow}</div> : null}
          </Box>
        </Box>
      </ErrorBoundary>
    );
  }
);

export default Advanced;
