import { useCallback, useEffect, useMemo, useState } from "react";
import { ColumnDefinition } from "tabulator-tables";
import { ErrorBoundary } from "../../../../../../ErrorBoundary";
import { Instruments } from "../../../../../../api/compute/Instruments";
import { extractSymbols } from "../../../../../../api/compute/commons";
import { InstrumentsTable } from "../../../../../../components/InstrumentsTable/InstrumentsTable";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";

type HoldingsResultTableProps = {
  data: any[];
  rowClickHandler: (e) => any;
  onTableColsChangeHandler: Function;
  setColumnsFields: (cols: ColumnDefinition[]) => any;
  expandEventId: string;
};

export function HoldingsResultTable({
  data,
  rowClickHandler,
  onTableColsChangeHandler,
  setColumnsFields,
  expandEventId,
}: HoldingsResultTableProps) {
  const environment = useEnvironment();
  const [dataTable, setDataTable] = useState<any>(data ?? []);

  const [sorter, setSorter] = useState<any>({
    field: "contribution",
    rev: true,
  });
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (data && data.length) {
      setDataTable(data);
    }
  }, [data]);

  const apiInstruments = useMemo(() => {
    return new Instruments(environment.get("setup"));
  }, [environment]);

  const properties = useMemo(() => {
    const properties = environment.get("properties");
    const propertiesMap = {};

    for (const propertySection of Object.values<any>(properties.properties)) {
      for (const key in propertySection) {
        propertiesMap[key] = propertySection[key];
      }
    }

    return propertiesMap;
  }, [environment]);

  const config = useMemo(
    () =>
      environment.get("account")?.product?.configuration?.strategy_builder
        ?.tabs ?? null,
    [environment]
  );
  const tabsConfigMap = useMemo(() => {
    if (config) {
      return config.reduce((prev, current) => {
        return { ...prev, [current.id]: current };
      }, {});
    }

    return null;
  }, [config]);

  const editorColumnsConfiguration = useMemo(() => {
    if (tabsConfigMap && "allocation" in tabsConfigMap) {
      return tabsConfigMap["allocation"]?.widgets?.viewer ?? null;
    }

    return null;
  }, [tabsConfigMap]);

  const onClickHandler = useCallback(
    (e) => {
      if (e.type === "rowClick") {
        const value = e.value.getData();

        if (rowClickHandler != null) {
          rowClickHandler(value);
        }
      }
    },
    [rowClickHandler]
  );

  const onTableColsChange = useCallback(
    (tableColumns) => {
      onTableColsChangeHandler(tableColumns);
      setColumns(tableColumns);
    },
    [onTableColsChangeHandler]
  );

  const updateTableData = useCallback((sortedSecurities, field, direction) => {
    const symbols = extractSymbols(sortedSecurities);

    setDataTable((current) => {
      const currentMap = current.reduce((prev, acc) => {
        prev[acc.symbol] = acc;
        return prev;
      }, {});

      const newState: any = [];

      symbols.forEach((symbol) => {
        newState.push(currentMap[symbol]);
      });

      setSorter({ field, rev: direction === "desc" });

      return newState;
    });
  }, []);

  const onColSortHandler = useCallback(
    async ({ field, direction }) => {
      const pointInTimeFieldsMap = (columns as any).reduce((prev, current) => {
        prev[current.field] = current.isPointInTime;

        return prev;
      }, {});

      const requireInjection = properties?.[field]?.requireInjection ?? false;
      const hasToInject = pointInTimeFieldsMap[field] || requireInjection;

      const symbols = extractSymbols(dataTable);

      const payload = {
        constraints: [
          [
            {
              dimension: "symbol",
              operator: "equals",
              segments: symbols,
            },
          ],
        ],
        page: {
          page: 1,
          rows: 30000,
        },
      };

      const defaultSort = [
        {
          dimension: properties[field]["backendPropertySort"],
          rev: direction === "desc",
        },
        {
          dimension: "marketcap",
          rev: true,
        },
      ];

      if (!hasToInject) {
        payload["sort"] = defaultSort;
      } else {
        const fieldSortType = "number";
        const sortId = `${field}${Date.now()}:${field}`;
        payload["sort"] = [
          { dimension: sortId, rev: direction === "desc" },
          { dimension: "marketcap", rev: true },
        ];

        payload["injestion"] = {
          data: dataTable.map((item) => ({
            symbol: item.symbol,
            value: JSON.stringify(item[field]),
          })),
          type: fieldSortType,
          field: sortId,
        };
      }

      const screeningResponse = await apiInstruments.screening(payload, true);
      const orderedData = screeningResponse?.data?.map((row) => ({
        symbol: row,
      }));
      updateTableData(orderedData, field, direction);
    },

    [apiInstruments, columns, dataTable, properties, updateTableData]
  );

  const tableHandlersOverrides = useMemo(
    () => ({ rowClick: onClickHandler }),
    [onClickHandler]
  );

  const getInitCols = useCallback(
    (columns) => {
      setColumnsFields(columns);
      setColumns(columns);
    },
    [setColumnsFields]
  );

  const sorting = useMemo(() => {
    return {
      field: sorter.field,
      direction: sorter.rev === false ? "desc" : "asc",
    };
  }, [sorter.field, sorter.rev]);

  const tableRows = useMemo(() => {
    return dataTable.map((row) => ({ ...row, rrr: null, lr: null }));
  }, [dataTable]);

  return (
    <ErrorBoundary fallback={<></>}>
      {/* {dataTable && dataTable.length ? ( */}
      <InstrumentsTable
        tools={{
          configurator: {
            hasToSkipLastApplied: false,
            defaultTemplateNameBase: "DEFAULT_SYSTEMATIC_PRODUCT_ALLOCATION",
            configurations: editorColumnsConfiguration.table,
            securityType: "security",
            isSaveLastUsedConfigurationColumnsEnabled: true,
          },
          expandTable: {
            eventName: expandEventId,
          },
        }}
        tableProps={{
          autoResize: false,
          options: { ajaxSorting: false },
          disableDefaultRowClick: true,
          sorting,
          tooltip: {
            actions: {
              info: {
                enabled: true,
              },
            },
          },
        }}
        useAutoSort={false}
        onColumnsChange={onTableColsChange}
        tableData={tableRows}
        tableHandlersOverride={tableHandlersOverrides}
        getInitColumns={getInitCols}
        // handleSortResult={onColSortHandler}
        handleSortManually={onColSortHandler}
      />
      {/* ) : (
        <></>
      )} */}
    </ErrorBoundary>
  );
}
