import { ColumnDefinition } from "tabulator-tables";
import { Properties } from "../../../../api/Properties";
import { Instruments } from "../../../../api/compute/Instruments";
import { Lists } from "../../../../api/compute/Lists";
import { Rankings } from "../../../../api/compute/Rankings";
import { TableHelpers } from "../../../../components/InstrumentsTable/Helpers/TableHelpers";
import { AppEnvironment } from "../../../../types/Defaults";

export type RankingUniverseConstraintsType = {
  filters: {
    dimension: string;
    segments: string[];
  }[];
  ranges?: any;
  page?: { page: number; rows: number };
  sort?: { dimension: string; rev: boolean }[];
  relations?: {
    domain: number[];
    range: "PORTFOLIO" | "BASKET" | "COLLECTION";
  }[];
};

export type RankOutput = {
  columns: ColumnDefinition[];
  data: { [key: string]: any }[];
  dataTotalCount: number;
  universeDefinition: RankingUniverseConstraintsType;
};

export type RankingParams = {
  page?: number;
  itemsPerPage?: number;
  sortField?: string;
  sortDirection?: boolean;
};

const MAX_UNIVERSE_SIZE = 3000;
const DEFAULT_PAGE = 1;
const DEFAULT_ITEMS_PER_PAGE = 25;
const DEFAULT_SORT_FIELD = "rank";
const DEFAULT_SORT_DIR = false;

const RANKING_PROPERTY_FOR_LIST = "rankList";
const RANKING_PROPERTY_DATE_FROM = "rankFromDate";
const RANKING_PROPERTY_DELTA = "rankDelta";

export class Ranking {
  private universeIds: string[] | undefined;
  private againstList: any;
  private rankedSymbols: any[] | undefined;
  private rankingCache: any;
  private universeConstraints?: RankingUniverseConstraintsType;
  private againstUniverse?: RankingUniverseConstraintsType;
  private rankingTemplate?: any[];
  private rankingParams: RankingParams;
  private securityFields?: string[];
  private injectionFields?: { [key: string]: boolean };
  private highlightListId: number | undefined;
  private fromDate:
    | undefined
    | "PREVIOUS_DAY"
    | "PREVIOUS_WEEK"
    | "PREVIOUS_2_WEEKS"
    | "PREVIOUS_MONTH"
    | "PREVIOUS_3_MONTHS";

  /**
   * APIs
   */
  private instrumentsAPI: Instruments;
  private rankingAPI: Rankings;
  private listAPI: Lists;
  private propertiesAPI: Properties;
  private tableHelpersAPI: TableHelpers;

  constructor(
    environment: AppEnvironment,
    constraints?: RankingUniverseConstraintsType,
    template?: any[],
    fields?: string[],
    rankingParams?: RankingParams
  ) {
    this.universeIds = undefined;
    this.rankedSymbols = undefined;

    this.universeConstraints = constraints ?? undefined;
    this.rankingTemplate = template;

    this.instrumentsAPI = new Instruments(environment);
    this.rankingAPI = new Rankings(environment);
    this.listAPI = new Lists(environment);
    this.propertiesAPI = new Properties(environment);
    this.tableHelpersAPI = new TableHelpers(environment);

    this.rankingParams = {
      page: rankingParams?.page ?? DEFAULT_PAGE,
      itemsPerPage: rankingParams?.itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
      sortField: rankingParams?.sortField ?? DEFAULT_SORT_FIELD,
      sortDirection: rankingParams?.sortDirection ?? DEFAULT_SORT_DIR,
    };
    this.securityFields = fields;
  }

  async rank() {
    if (
      this.universeConstraints != null &&
      this.rankingTemplate != null &&
      this.securityFields != null
    ) {
      try {
        // 1. Get the universe of symbol by using the constraints
        const universe = await this.getUniverse();

        // 2. Rank the universe
        const rankedSymbols = await this.getRankedSymbols();

        const rankedUniverseMap = {};

        for (const row of rankedSymbols) {
          rankedUniverseMap[row.symbol] = { ...row };
        }

        // 3. Sort the securities of the universe using the rank result
        const sortById = this.rankingParams.sortField ?? "";
        const propertiesClass = this.propertiesAPI;
        const propertiesMap = propertiesClass["properties"];
        const sortField =
          propertiesMap?.["security"]?.[sortById]?.["backendPropertySort"] ??
          sortById;

        let hasToInject =
          sortField in this.injectionFields! ||
          sortField === "weight" ||
          sortField === RANKING_PROPERTY_FOR_LIST ||
          sortField === RANKING_PROPERTY_DATE_FROM ||
          sortField === RANKING_PROPERTY_DELTA;

        const screeningPayload = {
          constraints: [
            [
              {
                dimension: "symbol",
                operator: "equals",
                segments: universe,
              },
            ],
          ],
          page: {
            page: this.rankingParams.page,
            rows: this.rankingParams.itemsPerPage,
          },
          sort: [
            {
              dimension: sortField,
              rev: this.rankingParams.sortDirection,
            },
            {
              dimension: "marketcap",
              rev: true,
            },
          ],
        };

        const againstListSymbolsMap = await this.getAgainstList();

        const hasWeightColumn = this.securityFields.some(
          (column) => column === "weight"
        );

        // To understand if a whitelist is selected as universe check in the constraints if
        // relations key is assigned and check if the list ID is only 1. If multiple list are selected
        // avoid weight calculation (empty weight column in UI)
        const constraintsSyntax =
          "constraints" in this.universeConstraints ? "screening" : "select";
        const screeningRelationFilter =
          (this.universeConstraints as any)?.constraints?.[0]?.find(
            (item) => item.operator === "relation"
          ) ?? undefined;
        const hasWhiteListAsUniverse =
          constraintsSyntax === "select"
            ? "relations" in this.universeConstraints &&
              this.universeConstraints.relations?.[0]?.domain?.length === 1
            : screeningRelationFilter?.segments?.length === 1 ?? false;

        let weights: any = undefined;

        if (hasWeightColumn && hasWhiteListAsUniverse) {
          const whitelistId =
            constraintsSyntax === "select"
              ? this.universeConstraints.relations?.[0]?.domain[0]
              : screeningRelationFilter.segments[0];

          if (whitelistId) {
            weights = {};

            const fetchResponse = await this.listAPI.portfolioFetch(
              [whitelistId],
              ["positionsToday"]
            );

            const portfolioAllocations = fetchResponse?.[0]?.positionsToday;

            for (const allocation of portfolioAllocations) {
              weights[allocation.symbol] = allocation.weight;
            }
          }
        }

        if (hasToInject) {
          const sortId = `${sortField}${Date.now()}:${sortField}`;
          const injectionData: any = [];

          let fieldToInject = sortField;

          let valueToInject: any = null;

          if (fieldToInject === RANKING_PROPERTY_FOR_LIST) {
            for (const row of rankedSymbols) {
              if (row.symbol in againstListSymbolsMap?.positionsToday) {
                valueToInject = JSON.stringify(row["rank"]);
              } else {
                valueToInject = "100000";
              }

              injectionData.push({
                symbol: row.symbol,
                value: valueToInject,
              });
            }
          } else if (fieldToInject === "weight") {
            let weight: any = null;

            if (weights != null) {
              for (const row of rankedSymbols) {
                weight = weights[row.symbol];
                injectionData.push({
                  symbol: row.symbol,
                  value: JSON.stringify(weight),
                });
              }
            }
          } else {
            for (const row of rankedSymbols) {
              injectionData.push({
                symbol: row.symbol,
                value:
                  row?.[fieldToInject!] != null
                    ? JSON.stringify(row?.[fieldToInject!])
                    : "null",
              });
            }
          }

          screeningPayload["injestion"] = {
            data: injectionData,
            field: sortId,
            type: "number",
          };

          screeningPayload["sort"][0]["dimension"] = sortId;
        } else {
          // Inject rank to add it as second field of rank
          const rankInjection: any = [];
          const sortId = `rank${Date.now()}:rank`;

          for (const row of rankedSymbols) {
            rankInjection.push({
              symbol: row.symbol,
              value:
                row?.["rank"] != null ? JSON.stringify(row?.["rank"!]) : "null",
            });
          }

          screeningPayload["injestion"] = {
            data: rankInjection,
            field: sortId,
            type: "number",
          };

          screeningPayload["sort"].splice(1, 0, {
            dimension: sortId,
            rev: false,
          });
        }

        const rankedUniverse = await this.instrumentsAPI.screening(
          screeningPayload,
          true
        );

        // 4. Fetch the security fields according to the UI active columns
        const properties: any[] = [];
        for (const field of this.securityFields) {
          if (field) {
            properties.push({
              date: null,
              property: field,
            });
          }
        }
        const rankedData = await this.instrumentsAPI.fetch({
          properties,
          type: "security",
          symbols: rankedUniverse?.data,
        });

        const rankedTableRows = rankedData?.data;

        let symbol: any = null;
        let rule: any = null;
        let row: any = null;

        for (let i = 0; i < rankedTableRows.length; i++) {
          row = rankedTableRows[i];
          symbol = row.symbol;

          for (let j = 0; j < this.rankingTemplate.length; j++) {
            rule = this.rankingTemplate[j];
            row[`rankValue${j}`] =
              rankedUniverseMap?.[symbol]?.[`rankValue${j}`];
            row["rank"] = rankedUniverseMap?.[symbol]?.["rank"];
          }

          if (againstListSymbolsMap != null) {
            row[RANKING_PROPERTY_FOR_LIST] =
              symbol in againstListSymbolsMap["positionsToday"]
                ? againstListSymbolsMap["positionsToday"][symbol]?.["weight"]
                : undefined;
          }

          if (this.fromDate != null) {
            row[RANKING_PROPERTY_DATE_FROM] =
              rankedUniverseMap?.[symbol]?.[RANKING_PROPERTY_DATE_FROM];
            row[RANKING_PROPERTY_DELTA] =
              rankedUniverseMap?.[symbol]?.[RANKING_PROPERTY_DELTA];
          }

          if (weights != null) {
            row["weight"] = weights[symbol];
          }
        }

        // Put the UI columns in the output
        const outputColumns: any = [...this.securityFields];

        outputColumns.push({
          field: "rank",
          function: "rank",
          property: "rank",
          customColConfiguration: "rankConfiguration",
          rankRuleIndex: null,
          dataTotalCount: rankedUniverse.dataTotalCount,
        });

        let rankColumnConfig: any = null;

        if (this.highlightListId != null) {
          outputColumns.push({
            field: RANKING_PROPERTY_FOR_LIST,
            function: "rank",
            property: RANKING_PROPERTY_FOR_LIST,
            customColConfiguration: "rankConfiguration",
            rankRuleIndex: null,
            dataTotalCount: rankedUniverse.dataTotalCount,
            options: {
              listName: againstListSymbolsMap.name,
              listType: againstListSymbolsMap.type,
            },
          });
        }

        if (this.fromDate != null) {
          outputColumns.push({
            field: RANKING_PROPERTY_DATE_FROM,
            function: "rank",
            property: RANKING_PROPERTY_DATE_FROM,
            customColConfiguration: "rankConfiguration",
            rankRuleIndex: null,
            dataTotalCount: rankedUniverse.dataTotalCount,
            fromDate: this.rankingAPI.getFromDateAsDays(this.fromDate),
            options: {
              formatterDateOptions: {
                format: ["M", "D", "Y"],
                notAvailable: {
                  input: null,
                  output: "",
                },
                separator: " ",
              },
            },
          });
        } else {
          for (let r = 0; r < this.rankingTemplate.length; r++) {
            rule = this.rankingTemplate[r];
            rankColumnConfig = {
              field: `rankValue${r}`,
              property: rule.property,
              function: rule.function,
              customColConfiguration: "rankConfiguration",
              rankRuleIndex: r,
              dataTotalCount: rankedUniverse.dataTotalCount,
              operatorParams: rule["operatorParams"],
            };

            if (rule.functionParams) {
              rankColumnConfig["functionParams"] = {
                value: rule["functionParams"]["value"],
              };
            }

            outputColumns.push({ ...rankColumnConfig });
          }
        }

        this.prepareRankingCache(outputColumns);

        const rankOutput = {
          columns: outputColumns,
          data: rankedTableRows,
          dataTotalCount: rankedUniverse.dataTotalCount,
          universeDefinition: this.universeConstraints,
        };

        return rankOutput;
      } catch (error) {
        throw new Error((error as any).message);
      }
    } else {
      throw new Error("Missing rank params cannot continue");
    }
  }

  private universeExtension(constraints: RankingUniverseConstraintsType) {
    return {
      ...constraints,
      sort: [{ dimension: "marketcap", rev: false }],
      page: {
        page: 1,
        rows: MAX_UNIVERSE_SIZE,
      },
    };
  }

  private async getAgainstList() {
    if (this.againstList != null) {
      return this.againstList;
    }

    const againstList = await this.getAgainstListColInfo();
    this.againstList = againstList;

    return this.againstList;
  }

  private async getAgainstUniverse() {
    if (!this.againstUniverse) {
      return;
    }

    const universeConstraints = this.universeExtension(this.againstUniverse);

    const useScreeningSyntax = "constraints" in universeConstraints;

    const universe = await this.instrumentsAPI.screening(
      universeConstraints,
      useScreeningSyntax
    );

    if (universe?.dataTotalCount > 3000) {
      throw new Error("code_1");
    }

    if (universe?.dataTotalCount === 0) {
      throw new Error("code_2");
    }

    return universe?.data;
  }

  private async getUniverse() {
    if (this.universeIds) {
      return this.universeIds;
    }

    const universeConstraints = this.universeExtension(
      this.universeConstraints!
    );

    const useScreeningSyntax = "constraints" in universeConstraints;

    const universe = await this.instrumentsAPI.screening(
      universeConstraints,
      useScreeningSyntax
    );

    if (universe?.dataTotalCount > 3000) {
      throw new Error("code_1");
    }

    if (universe?.dataTotalCount === 0) {
      throw new Error("code_2");
    }

    this.universeIds = universe.data;

    return this.universeIds;
  }

  private async getAgainstListColInfo() {
    if (this.highlightListId != null) {
      try {
        // Fetch the name and the positionsToday of the list
        const response = await this.listAPI.portfolioFetch(
          [this.highlightListId],
          ["name", "positionsToday", "type"]
        );

        const againstList = response?.[0];

        if (!againstList) {
          return;
        }

        againstList["positions"] = [...againstList["positionsToday"]];
        againstList["positionsToday"] = againstList["positionsToday"].reduce(
          (prev, current) => {
            prev[current.symbol] = { ...current };

            return prev;
          },
          {}
        );

        return againstList;
      } catch (error) {
        console.log(error);
      }
    }
  }

  private async getRankedSymbols() {
    if (this.rankedSymbols) {
      return this.rankedSymbols;
    }

    let instruments = await this.getUniverse();

    const againstUniverse = await this.getAgainstUniverse();

    // If the against universe exists merge the symbols with the current universe for the rank
    if (againstUniverse != null) {
      instruments = [...new Set([...instruments!, ...againstUniverse])];
    }

    const rankingRequest = {
      instruments: instruments?.map((symbol) => ({ symbol })),
      rules: this.rankingTemplate,
    };

    if (this.fromDate != null) {
      const fromDateValue = this.rankingAPI.getFromDateAsDays(this.fromDate);

      if (fromDateValue) {
        rankingRequest["fromDate"] = fromDateValue;
      }
    }

    const rankedSymbols = await this.rankingAPI.ranking(rankingRequest);

    this.rankedSymbols = rankedSymbols.data;

    return rankedSymbols.data;
  }

  private prepareRankingCache(columns) {
    const colHelper = this.tableHelpersAPI.get("rank");
    const inputColumns = colHelper.prepareInputColumns(columns);
    let tabulatorColumns = this.getColumns(inputColumns);

    const rankingColumns: any = [];

    let column = {};

    for (const col of tabulatorColumns) {
      column = {};

      if ("cssClass" in column) {
        column["className"] = col["cssClass"];
      }

      column["field"] = col["field"];
      column["label"] = col["title"];
      column["renderCell"] = col["formatter"];
      column["sortable"] = false;

      rankingColumns.push({ ...column });
    }

    const index = this.rankedSymbols?.reduce((prev, current) => {
      prev[current.symbol] = current["rank"];

      return prev;
    }, {});

    const againstList = this.againstList;
    const rankedSymbols = [...this.rankedSymbols!];

    const rankedSymbolsMap = rankedSymbols.reduce((prev, current, index) => {
      prev[current.symbol] = index;

      return prev;
    }, {});

    if (againstList) {
      const positionsIndex = {};

      let pos: any = null;

      for (let i = 0; i < againstList.positions.length; i++) {
        pos = againstList.positions[i];
        positionsIndex[pos.symbol] = i;

        if (pos.symbol in rankedSymbolsMap) {
          rankedSymbols[rankedSymbolsMap[pos.symbol]][
            RANKING_PROPERTY_FOR_LIST
          ] = rankedSymbols[rankedSymbolsMap[pos.symbol]]?.rank ?? null;
        }
      }

      againstList["positionsIndex"] = positionsIndex;
    }

    const cache = {
      rankingColumns: rankingColumns,
      rankingParams: {
        againstList,
        constraints: this.universeConstraints,
        fromDate: this.fromDate,
        rules: this.rankingTemplate,
      },
      rankingType: "instruments",
      rankingResult: {
        index,
        dataTotalCount: this.rankedSymbols?.length,
        data: rankedSymbols,
      },
    };

    this.rankingCache = cache;
  }

  private getColumns(sourceCols) {
    const rankColumns = this.tableHelpersAPI.get("rank");
    const common = this.tableHelpersAPI.get("columns");
    const targetCols: any = [];

    if (sourceCols) {
      const tableColumns = sourceCols;

      const fakeSorter = () => 0; //Is not used by reports

      for (const viewerCol of tableColumns) {
        if (viewerCol) {
          if ("customColConfiguration" in viewerCol) {
            switch (viewerCol.customColConfiguration) {
              case "rankConfiguration": {
                rankColumns.configureAsRankCol(
                  viewerCol,
                  targetCols,
                  fakeSorter,
                  false
                );

                break;
              }
              default:
                console.warn(
                  `${viewerCol.customColConfiguration} is not a valid configuration for columns`
                );
            }
          } else {
            targetCols.push(common.tabulatorColumn(viewerCol, fakeSorter));
          }
        }
      }
    }

    return targetCols;
  }

  public clearRakedSymbols() {
    this.rankedSymbols = undefined;
  }

  public clearUniverseIds() {
    this.universeIds = undefined;
    this.clearRakedSymbols();
  }

  public setPage(page: number) {
    this.rankingParams.page = page;
  }

  public setItemsPerPage(itemsPerPge: number) {
    this.rankingParams.itemsPerPage = itemsPerPge;
  }

  public setSortField(sortField: string) {
    this.rankingParams.sortField = sortField;
  }

  public setSortDirection(direction: boolean) {
    this.rankingParams.sortDirection = direction;
  }

  public setFromDate(value: typeof this.fromDate) {
    this.clearRakedSymbols();
    this.fromDate = value;
  }

  public setColumns(cols: string[]) {
    this.securityFields = cols;
  }

  public setRules(rules: any[] | undefined) {
    this.rankingTemplate = rules;

    const injectionMap = {
      rank: true,
    };

    if (this.rankingTemplate) {
      for (let i = 0; i < this.rankingTemplate.length; i++) {
        injectionMap[`rankValue${i}`] = true;
      }

      this.injectionFields = injectionMap;
    }
  }

  public setOptionalConstraints(
    constraints: RankingUniverseConstraintsType | undefined
  ) {
    this.clearUniverseIds();
    this.againstUniverse = constraints;
  }

  public setConstraints(
    constraints: RankingUniverseConstraintsType | undefined
  ) {
    this.clearUniverseIds();
    this.universeConstraints = constraints;
  }

  public setHighlightListId(id: number | undefined) {
    this.againstList = undefined;
    this.highlightListId = id;
  }

  public getRankingCache() {
    return this.rankingCache;
  }

  public getConstraints() {
    return { ...this.universeConstraints };
  }

  public getRules() {
    if (this.rankingTemplate) {
      return [...this.rankingTemplate];
    }
  }

  public getsetHighlightListId() {
    return this.highlightListId;
  }

  public getFromDate() {
    return this.fromDate;
  }

  public getCurrentColumns() {
    return this.securityFields;
  }
}
