import { Box, Card, CardContent } from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TableHelpers } from "../../../../../../../components/InstrumentsTable/Helpers/TableHelpers";
import { InstrumentsTable } from "../../../../../../../components/InstrumentsTable/InstrumentsTable";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { useResizer } from "../../../../../../../hooks/useResizer";
import { config } from "../../../../../config-ts";
import { removeLoader } from "../../../../../utils";
import ReportButton from "../../../../../widgets/app-infrastructure/workflowBar/actions/report/ReportButton";
import { RankDialog } from "../../../../rank/RankDialog/RankDialog";
import {
  ActionRankContext,
  RankContextProvider,
} from "../../../../rank/actions/ActionRankContext/ActionRankContext";
import { useBroadcast } from "../../../../../../../hooks/useBroadcast";
import { Export } from "../../../../../components/app-infrastructure/workflowBar/actions/export/Export";

type PortfolioRankingProps = {
  portfolioId: any;
  portfolioName: string;
  portfolioType: "PORTFOLIO" | "BASKET";
  portfolioPositions: any;
};

/**
 * This wrapper is used to make availabe rank context functions
 */
export function PortfolioRanking({
  portfolioId,
  portfolioName,
  portfolioType,
  portfolioPositions,
}: PortfolioRankingProps) {
  useEffect(() => {
    removeLoader();
  }, []);

  return (
    <RankContextProvider>
      <PortfolioRankingTab
        portfolioPositions={portfolioPositions}
        portfolioId={portfolioId}
        portfolioName={portfolioName}
        portfolioType={portfolioType}
      />
    </RankContextProvider>
  );
}

const PortfolioRankingTab = ({
  portfolioId,
  portfolioName,
  portfolioType,
  portfolioPositions,
}: PortfolioRankingProps) => {
  const [showDialogRank, setShowDialogRank] = useState(true);
  const [tableColumns, setTableColumns] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sorter, setSorter] = useState<{ field: string; rev: boolean }>({
    field: "rank",
    rev: false,
  });
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [loading, setIsLoading] = useState(false);
  const [tableKey, setTableKey] = useState(`tableWidget_${Date.now()}`);
  const [rankFrom, setRankFrom] = useState<
    | "PREVIOUS_DAY"
    | "PREVIOUS_WEEK"
    | "PREVIOUS_2_WEEKS"
    | "PREVIOUS_MONTH"
    | "PREVIOUS_3_MONTHS"
    | undefined
  >();

  const { rankResults, rank, rankReducer, rankingCache } =
    useContext(ActionRankContext);
  const { broadcast } = useBroadcast();

  const containerRef = useRef<HTMLDivElement>(null);

  const environment = useEnvironment();

  useResizer({ ref: containerRef });

  const appEnvironment = useMemo(() => {
    return environment.get("setup");
  }, [environment]);
  const configuration = useMemo(
    () => appEnvironment["configuration"],
    [appEnvironment]
  );
  const configurationScreening = useMemo(
    () => configuration.get("ranking"),
    [configuration]
  );

  const listType = useMemo(() => portfolioType, [portfolioType]);
  const listName = useMemo(() => portfolioName, [portfolioName]);
  const listId = useMemo(() => portfolioId, [portfolioId]);

  const openRankDialog = useCallback(() => {
    setShowDialogRank(true);
  }, []);

  const closeRankDialog = useCallback(() => {
    setShowDialogRank(false);
  }, []);

  const refreshRank = useCallback(
    async (rankingParams) => {
      setIsLoading(true);

      try {
        await rank(rankingParams);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [rank]
  );

  const onChangePage = useCallback(
    (page) => {
      setPage(page);

      refreshRank({
        page,
        sortField: sorter.field,
        rev: sorter.rev,
      });
    },
    [refreshRank, sorter.field, sorter.rev]
  );

  const onSelectItemPerPage = useCallback(
    (itemsPerPageValue) => {
      setItemsPerPage(itemsPerPageValue);
      setPage(1);

      refreshRank({ itemsPerPage: itemsPerPageValue, page: 1 });
    },
    [refreshRank]
  );

  const refreshTable = useCallback(() => {
    // This is not a trick, table is an uncontrolled component so to refresh sort arrow or page number ecc is needed a refresh of the component
    // so to trigger it we need to update the key of the component
    setTableKey(`tableWidget_${Date.now()}`);
  }, []);

  const refreshSorter = useCallback(() => {
    setSorter({ field: "rank", rev: false });
    refreshTable();
  }, [refreshTable]);

  const changeRankDateFrom = useCallback(
    (dateId) => {
      setPage(1);
      setRankFrom(dateId);
      refreshSorter();

      refreshRank({
        rev: false,
        sortField: "rank",
        page: 1,
        fromDate: dateId,
      });
    },
    [refreshRank, refreshSorter]
  );

  const onDataSorted = useCallback(
    async ({ field, direction }) => {
      setSorter({ field, rev: direction === "desc" });
      setPage(1);

      await refreshRank({
        rev: direction === "desc",
        sortField: field,
        page: 1,
      });
    },
    [refreshRank]
  );

  const getColumns = useCallback(
    (_columns) => {
      const helper = new TableHelpers(environment.get("setup"));

      const rankColumns = helper.get("rank");
      const common = helper.get("columns");
      let cols = common.prepareInputColumns(_columns);

      let columnsToSet: any = [];

      if (cols) {
        const tableColumns = cols;

        columnsToSet = [];

        for (const viewerCol of tableColumns) {
          if ("customColConfiguration" in viewerCol) {
            switch (viewerCol.customColConfiguration) {
              case "rankConfiguration": {
                rankColumns.configureAsRankCol(
                  viewerCol,
                  columnsToSet,
                  () => {},
                  false
                );

                break;
              }
              default:
                console.warn(
                  `${viewerCol.customColConfiguration} is not a valid configuration for columns`
                );
            }
          } else {
            const sortHandler = () => 0;

            columnsToSet.push(common.tabulatorColumn(viewerCol, sortHandler));
          }
        }
      }

      return columnsToSet;
    },
    [environment]
  );

  const getWidgetInfo = useCallback(
    (infoType: "columns" | "pagination" | "sortBy" | "dataTotalCount") => {
      switch (infoType) {
        case "pagination":
          return {
            page,
            rows: itemsPerPage,
          };

        case "sortBy":
          return {
            property: sorter.field,
            descending: sorter.rev,
          };
        case "dataTotalCount":
          return rankResults.dataTotalCount;

        case "columns": {
          let _column: any = null;
          let _columns: any = [...tableColumns];
          let column: any = null;
          let columns: any = [];

          _columns = getColumns(_columns);

          for (let i = 0, length = _columns.length; i < length; i++) {
            _column = _columns[i];

            if (_column["field"] !== undefined && _column["field"] != null) {
              column = {
                label: (_column?.["title"] ?? _column?.["label"] ?? "")
                  .replace(/<br\/>/gi, " - ")
                  .replace(/<br>/gi, " "),
                property: _column["field"],
              };
              columns.push(column);
            }
          }

          return columns;
        }

        default:
          console.warn(`Unrecognized widget type: ${infoType}`);
      }
    },
    [
      getColumns,
      itemsPerPage,
      page,
      rankResults.dataTotalCount,
      sorter.field,
      sorter.rev,
      tableColumns,
    ]
  );

  const updateColumns = useCallback(
    (columns) => {
      rankReducer({
        type: "SET_COLUMNS",
        payload: columns,
      });
      setTableColumns(columns);
    },
    [rankReducer]
  );

  const onColumnsChange = useCallback(
    (columns) => {
      updateColumns(columns);

      refreshRank({
        sortField: sorter.field,
        rev: sorter.rev,
        page,
        columns: columns.map((col) => col.field),
      });
    },
    [page, refreshRank, sorter.field, sorter.rev, updateColumns]
  );

  /**
   * Load the current portfolio holdings as universe
   */
  useEffect(() => {
    if (listId) {
      const payload: any = {
        constraints: [
          [
            {
              dimension: "COLLECTION",
              operator: "relation",
              segments: [
                typeof listId === "number" ? listId : parseInt(listId),
              ],
            },
            {
              dimension: "type",
              logicalOperator: "not",
              operator: "equals",
              segments: ["ExpiredStock"],
            },
          ],
        ],
        page: {
          page: 1,
          rows: 20000,
        },
      };

      rankReducer({ type: "SET_CONSTRAINTS", payload });
    }
  }, [listId, rankReducer]);

  /**
   * Sync columns
   */
  useEffect(() => {
    setTableColumns(rankResults.columns);
  }, [rankResults.columns]);

  /**
   * Manage workflow bar
   */
  useEffect(() => {
    let actions: any = [];

    let action: any = null;

    action = {
      componentJSX: (
        <li className="menu__item" onClick={openRankDialog}>
          Edit Rank
        </li>
      ),
    };

    actions.push(action);

    const exportFileName = `${listName}.csv`;

    action = {
      componentJSX: (
        <Export
          rankingCache={rankingCache}
          fileName={exportFileName}
          label={"Export"}
          list={{
            id: typeof listId === "number" ? listId : parseInt(listId),
            type: listType,
          }}
          widgets={{
            table: { get: getWidgetInfo },
          }}
          logFunction={(event) => {
            //**************** USAGE *******************
            var usage = window.App.usage;
            var info = {
              action: "EXPORT",
              actionParam: listId,
              function: "PORTFOLIO_RANKING",
            };
            usage.record(info);
            //**************** USAGE *******************
          }}
        />
      ),
    };

    actions.push(action);

    const listObjForReport = {
      id: listId,
      // name: portfolioName,
    };

    action = {
      componentJSX: (
        <ReportButton
          page={"analysisList"}
          target={listObjForReport}
          rankingCache={rankingCache}
          title={portfolioName ?? ""}
          usage={window.App.usage}
          widgets={{ table: { get: getWidgetInfo } }}
        />
      ),
    };

    actions.push(action);

    var message = {
      from: "analysisListsRank",
      content: {
        actions: actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [
    broadcast,
    getWidgetInfo,
    listId,
    listName,
    listType,
    openRankDialog,
    portfolioName,
    rankingCache,
  ]);

  return (
    <Box ref={containerRef}>
      {showDialogRank && (
        <RankDialog closeDialog={closeRankDialog} page={"portfolioAnalysis"} />
      )}
      <Card>
        <CardContent>
          <InstrumentsTable
            key={tableKey}
            tableProps={{
              sorting: {
                field: sorter.field,
                direction: sorter.rev === false ? "desc" : "asc",
              },
              correction: 40,
              tooltip: { actions: { info: { enabled: true } } },
              options: {
                ajaxSorting: false,
              },
            }}
            tools={{
              configurator: {
                hasToSkipLastApplied: false,
                defaultTemplateNameBase: "DEFAULT_SCREENING",
                configurations: configurationScreening.widgets.viewer,
                securityType: "security",
                isSaveLastUsedConfigurationColumnsEnabled: true,
              },
              addToButton: true,
              pagination: {
                dataTotalCount: rankResults.dataTotalCount,
                itemsPerPage,
                changePage: onChangePage,
              },
              viewAsListButton: true,
              rowsNumberSelect: {
                label: "Securities per page",
                onChangeItemsPerPage: onSelectItemPerPage,
                initialValue: itemsPerPage,
              },
              rank: {
                showRankTools: true,
                onChangeRankDate: changeRankDateFrom,
                dateInitValue: rankFrom,
              },
            }}
            columns={tableColumns}
            tableData={rankResults?.data ?? []}
            getInitColumns={updateColumns}
            onColumnsChange={onColumnsChange}
            useAutoSort={false}
            handleSortManually={onDataSorted}
            loading={loading}
          />
        </CardContent>
      </Card>
    </Box>
  );
};
