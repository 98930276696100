import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { useBroadcast } from "../../hooks/useBroadcast";
import { config } from "./config-ts";

type WorkflowProps = {
  channelInput: string;
};

type WorkflowBarButtonProps = {
  onClickHandler: React.MouseEventHandler<HTMLLIElement>;
  label: string;
  className?: string;
};

export function Workflow({ channelInput }: WorkflowProps) {
  const [actions, setActions] = useState<any>([]);
  const containerRef = useRef<HTMLUListElement>(null);
  const [reactActions, setReactActions] = useState<any>([]);

  const { subscribe, unsubscribe } = useBroadcast();

  const updateAction = useCallback((message) => {
    setActions(message.content.actions);
  }, []);

  useLayoutEffect(() => {
    subscribe(config["channels"]["workflow"]["input"], updateAction);

    return () =>
      unsubscribe(config["channels"]["workflow"]["input"], updateAction);
  }, [channelInput, subscribe, unsubscribe, updateAction]);

  useEffect(() => {
    const previousActions = actions;
    const jsxActions: any = [];
    for (const action of actions) {
      if (action.placeAt) {
        action.placeAt(containerRef.current);
      } else {
        if (action.hasOwnProperty("componentJSX")) {
          // When react will be updated to react 18 this method will be deprecated and must be changed into createRoot method
          jsxActions.push(action.componentJSX);
        } else {
          console.log(
            "Cannot placeAt action: The widget is NOT migrated in React and is not a Dojo widget"
          );
        }
      }
    }

    setReactActions(jsxActions);

    return () => {
      for (const action of previousActions) {
        if (action.destroy) {
          action.destroy();
        }
      }
    };
  }, [actions]);

  return (
    <ul
      className="app__workflow menu menu--horizontal menu--workflow"
      id="app-workflow"
      ref={containerRef}
    >
      {reactActions.map((act) => (
        <Fragment key={uuidv4()}>{act}</Fragment>
      ))}
    </ul>
  );
}

export const WorkflowBarButton = ({
  onClickHandler,
  label,
  className,
}: WorkflowBarButtonProps) => {
  return (
    <li className={`menu__item ${className ?? ""}`} onClick={onClickHandler}>
      {label}
    </li>
  );
};
