import { useState } from "react";
import { useEnvironment } from "../../hooks/useEnvironment";
import SecurityTooltipCore from "./SecurityTooltipCore";
import { Environment } from "../../Environment";
import { CellComponent } from "tabulator-tables";

type SecurityTooltipProps = {
  symbol: string;
  chartButtonHandler: (security) => void;
  children?: JSX.Element;
  setSecurity: (value) => void;
};

type SecurityTooltipForTablesProps = {
  cell?: CellComponent;
  environment: Environment;
  chartButtonHandler: (security) => void;
  enableAddTo?: boolean;
  setSecurity: (value) => void;
  labelKey?: string;
  customFormatter?: (cell: CellComponent | undefined) => string;
};

export default function SecurityTooltip({
  symbol,
  chartButtonHandler,
  children,
  setSecurity,
}: SecurityTooltipProps) {
  const environment = useEnvironment();
  return (
    <SecurityTooltipCore
      symbol={symbol}
      environment={environment}
      chartButtonHandler={chartButtonHandler}
      setSecurityData={setSecurity}
    >
      {children}
    </SecurityTooltipCore>
  );
}

// const tooltipIconStyle = {
//   justifyContent: "center",
//   display: "flex",
//   alignItems: "center",
// };

export function SecurityTooltipForTables({
  cell,
  environment,
  chartButtonHandler,
  enableAddTo,
  setSecurity,
  labelKey,
  customFormatter,
}: SecurityTooltipForTablesProps) {
  const cellData = (cell?.getData() as any) ?? null;
  const symbol = cellData?.symbol ?? null;
  const label =
    customFormatter != null
      ? customFormatter(cell)
      : cellData?.[labelKey ?? "ticker"];
  const [iconVisible, setIconVisible] = useState(false);

  const handleIconVisibility = (value) => {
    setIconVisible(value);
  };

  return (
    <span
      style={{ display: "flex", justifyContent: "space-between" }}
      onMouseEnter={() => handleIconVisibility(true)}
      onMouseLeave={() => handleIconVisibility(false)}
    >
      <p>{label}</p>
      {/* <span
        className={"alerts-info-tooltip-icon i-info"}
        style={
          !iconVisible
            ? { ...tooltipIconStyle, visibility: "hidden" }
            : tooltipIconStyle
        }
      ></span> */}
      <SecurityTooltipCore
        symbol={symbol}
        environment={environment}
        chartButtonHandler={(security) => {
          handleIconVisibility(false);
          chartButtonHandler(security);
        }}
        enableAddToAction={enableAddTo}
        isIconVisible={iconVisible}
        setSecurityData={setSecurity}
      />
    </span>
  );
}
