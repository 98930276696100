/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/formatter/Color
 * @summary Color utilities
 *
 */

export class Color {
    private formatter;

    constructor(params: any) {
        if (
            params === undefined ||
            params == null ||
            params["formatter"] === undefined ||
            params["formatter"] == null
        ) {
            console.warn("Missing parameters: formatter");
        }
        this.formatter = params["formatter"];
    }

    color(percentage: any) {
        const percentColors = [
            { percentage: 0.0, color: { r: 0, g: 255, b: 0 } }, // GREEN
            { percentage: 0.5, color: { r: 255, g: 255, b: 255 } }, // WHITE
            { percentage: 1.0, color: { r: 255, g: 0, b: 0 } }, // RED
        ];

        function contrast(R: any, G: any, B: any) {
            const r = R / 255;
            const g = G / 255;
            const b = B / 255;
            return 0.2126 *
                (r <= 0.03928
                    ? r / 12.92
                    : Math.pow((r + 0.055) / 1.055, 2.4)) +
                0.7152 *
                    (g <= 0.03928
                        ? g / 12.92
                        : Math.pow((g + 0.055) / 1.055, 2.4)) +
                0.0722 *
                    (b <= 0.03928
                        ? b / 12.92
                        : Math.pow((b + 0.055) / 1.055, 2.4)) >
                0.179
                ? {
                      r: 0,
                      g: 0,
                      b: 0,
                  }
                : {
                      r: 255,
                      g: 255,
                      b: 255,
                  };
        }

        const i = percentage < percentColors[1].percentage ? 1 : 2;
        const lower = percentColors[i - 1];
        const upper = percentColors[i];
        const pctUpper =
            (percentage - lower.percentage) /
            (upper.percentage - lower.percentage);
        const pctLower = 1 - pctUpper;
        const color = {
            r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
            g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
            b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
        };

        return {
            backgroundColor: color,
            color: contrast(color.r, color.g, color.b),
        };
    }

    addStyle(node: any, colorObject: any, alphaChannel: any) {
        alphaChannel =
            alphaChannel == null
                ? {
                      background: 1,
                      color: 1,
                  }
                : alphaChannel;
        const colorBack = colorObject["backgroundColor"];
        const colorFore = colorObject["color"];

        node.style.backgroundColor = `rgba(
                ${colorBack["r"]},
                ${colorBack["g"]},
                ${colorBack["b"]},
                ${alphaChannel["background"]}
            )`;
        node.style.color = `rgba(
                ${colorFore["r"]},
                ${colorFore["g"]},
                ${colorFore["b"]},
                ${alphaChannel["color"]}
            )`;
    }

    highlightRankingCell(
        rankProperty: string,
        rankRule: string,
        isHighlighted: boolean,
        cardinality: number,
        object: object,
        value: any,
        node: Element
        /* options */
    ) {
        let alphaChannel: any = null;
        let color: any = null;
        let innerHTML = "";

        if (rankProperty === "rank") {
            innerHTML = value + 1;

            if (isHighlighted) {
                color = this.ranking(value, cardinality);
                alphaChannel = {
                    background: 0.4,
                    color: 1,
                };
                this.addStyle(node, color, alphaChannel);
            }

            node.classList.add("ranking-rank");
        } else {
            switch (rankRule["function"]) {
                case "outlier": {
                    if (value === 1) {
                        innerHTML = "";
                    } else {
                        innerHTML = "outlier";

                        if (isHighlighted) {
                            color = this.outlier(value);
                            alphaChannel = {
                                background: 0.4,
                                color: 1,
                            };
                            this.addStyle(node, color, alphaChannel);
                        }
                    }

                    node.classList.add("ranking-outlier");

                    break;
                }
                case "quantile": {
                    innerHTML = value != null ? "q:" + value : "";

                    if (isHighlighted) {
                        color = this.quantile(
                            value,
                            rankRule["functionParams"]["value"]
                        );
                        alphaChannel = {
                            background: 0.4,
                            color: 1,
                        };
                        this.addStyle(node, color, alphaChannel);
                    }

                    node.classList.add("ranking-quatile"); // TODO TYPO

                    break;
                }
                case "threshold": {
                    if (value === 1) {
                        innerHTML = "over";
                    } else {
                        innerHTML = "under";
                    }

                    node.classList.add("ranking-threshold");

                    break;
                }
                case "value": {
                    // prints exactly the server value
                    // innerHTML = this.formatter.rankingProperty(
                    //     rankRule["property"],
                    //     "table",
                    //     object[rankProperty],
                    //     object,
                    //     object["type"]
                    // );
                    innerHTML = this.formatter.html(
                        rankRule["property"],
                        "table",
                        object[rankProperty],
                        object,
                        object["type"]
                    );

                    node.classList.add("ranking-value");

                    break;
                }
                default: {
                    innerHTML = object[rankProperty];
                }
            }
        }

        node.innerHTML = innerHTML;
    }

    outlier(value: any) {
        if (value === undefined || value == null) {
            // black on white
            return {
                backgroundColor: {
                    r: 255,
                    g: 255,
                    b: 255,
                },
                color: {
                    r: 0,
                    g: 0,
                    b: 0,
                },
            };
        } else {
            // blank on yellow
            return {
                backgroundColor: {
                    r: 255,
                    g: 255,
                    b: 0,
                },
                color: {
                    r: 0,
                    g: 0,
                    b: 0,
                },
            };
        }
    }

    quantile(value: any, totalValue: any) {
        return this._colorScale(value, totalValue);
    }

    ranking(value: any, totalValue: any) {
        return this._colorScale(value, totalValue);
    }

    _colorScale(value: any, totalValue: any) {
        const color = this.color(value / totalValue);
        return color;
    }
}
