export const _formatPercentage = (value, format) => {
  return format.custom("number", {
    options: {
      isPercentage: true,
      notAvailable: {
        input: null,
        output: "",
      },
    },
    output: "HTML",
    value: value,
    valueHelper: null,
  });
};

export const _getHoldingsWeightedPerformanceNumDen = (holdings) => {
  var numerator = 0; // sum of weight * performance
  var denominator = 0; // sum of weight

  var holding: any = null;
  var performance: any = null;
  var weight: any = null;
  for (let i = 0, length = holdings.length; i < length; i++) {
    holding = holdings[i];

    weight = holding.weight;
    performance = holding.performance;

    numerator = numerator + weight * performance;
    denominator = denominator + weight;
  }

  return {
    denominator: denominator,
    numerator: numerator,
  };
};

export const _formatPeerName = (peer, environment) => {
  const field = peer?.field ?? "";
  const txFields = environment.get("setup")["taxonomyFields"];
  const rawTaxonomies = environment.get("setup")["taxonomies"];
  let taxonomyKey = txFields["security"]["country"];

  switch (field) {
    case "etfgeo":
      taxonomyKey = txFields["ETF"]["etfgeo"];
      break;
    case "Region":
    case "Area":
    case "Country":
    default:
      break;

    case "AssetClass":
    case "Specialty":
    case "Theme":
      taxonomyKey = txFields["ETF"]["etfclass"];

      break;

    case "1 Industry":
    case "3 Sector":
      taxonomyKey = txFields["security"]["sector"];

      break;
    case "3 Level": {
      taxonomyKey = "SizeClassification";
    }
  }

  var taxonomy = rawTaxonomies[taxonomyKey];

  return taxonomy?.[peer.id]?.name ?? peer.id;
};
