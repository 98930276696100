import { Box, Typography } from "@mui/material";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useImmerReducer } from "use-immer";
import { handleTitle } from "../../../../../../../Utility/DocumentTitleHanlder";
import { deepClone } from "../../../../../../../deepClone";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { StrategiesStorage } from "../../../../../storage/StrategiesStorage";
import StrategyNavigator from "../../../widgets/header/StrategyNavigator";
import Help from "../../help/Help";
import Result from "../Advanced/Result/Result";
import HoldingRules from "./HoldingRules";
import { SelectUniverse } from "./SelectUniverse";
import SelectionRules from "./SelectionRules";
import Tracking from "./Tracking";
import {
  editorReducer,
  initialEditorState,
  universeTabConfigHelper,
} from "./editorReducer";
import { Dashboards } from "../Advanced/Result/tabs/Insights/Insights";

type EditorAlphaProps = {
  // readonly strategy: any;
  // stateHandler: Function | null;
  strategyNavigatorProperties: any;
  runResults: any;
  runManager: StrategiesStorage;
  cleanup?: Function;
  dashboard: Dashboards;
  isReadOnlyStrategy: boolean;
  strategyParams: any;
  strategyName: string;
  strategyType: string;
};

export const EditorAlpha = forwardRef(
  (
    {
      // strategy,
      strategyNavigatorProperties,
      runResults,
      runManager,
      cleanup,
      dashboard,
      isReadOnlyStrategy,
      strategyParams,
      strategyName,
      strategyType,
    }: EditorAlphaProps,
    ref
  ) => {
    const [state, dispatch] = useImmerReducer(
      editorReducer,
      universeTabConfigHelper(initialEditorState, strategyParams)
    );
    //! **
    const [universeTabIndex, setUniverseTabIndex] = useState(() => {
      if (strategyParams != null) {
        if (strategyParams.universe.screening != null) {
          return 1;
        } else {
          return 0;
        }
      }
      return 0;
    });

    // const strategyName = useMemo(() => strategy?.name, [strategy?.name]);
    // const readOnlyStrategy = useMemo(
    //   () => strategy?.isReadOnly,
    //   [strategy?.isReadOnly]
    // );

    const environment = useEnvironment();
    const strategyBuilderConfig = useMemo(() => {
      return environment.get("account").product?.configuration?.strategy_builder
        .form_alpha;
    }, [environment]);

    const resultHelpEnabled = useMemo(() => {
      return strategyBuilderConfig?.resultTab?.insights ?? false;
    }, [strategyBuilderConfig?.resultTab?.insights]);

    //#region

    // const helpEnabled = useMemo(() => {
    //   const isEnabled = strategyBuilderConfig?.resultTab?.insights ?? false;
    //   const _loadedStrategyId =
    //     loadedStrategyId != null && loadedStrategyId !== "new"
    //       ? parseInt(loadedStrategyId)
    //       : null;
    //   let isDashboardStrategy = false;

    //   if (dashboard != null) {
    //     for (const strategy of dashboard) {
    //       if (
    //         loadedStrategyId != null &&
    //         _loadedStrategyId === strategy.info.id
    //       ) {
    //         isDashboardStrategy = true;

    //         break;
    //       }
    //     }
    //   }

    //   return (_loadedStrategyId == null || isDashboardStrategy) && isEnabled;
    // }, [
    //   dashboard,
    //   loadedStrategyId,
    //   strategyBuilderConfig?.resultTab?.insights,
    // ]);

    //#endregion

    useEffect(() => {
      dispatch({
        type: "LOAD_STRATEGY",
        payload: universeTabConfigHelper(initialEditorState, strategyParams),
      });
    }, [dispatch, strategyParams]);
    useEffect(() => {
      if (strategyType) {
        handleTitle({ type: strategyType, name: strategyName });
      }
    }, [strategyName, strategyType]);

    /**
     * This method clean the state object from unecessary keys used to track
     * the internal state of the component. Thoose keys were added to the
     * input object and are to be deleted to set the correct output state.
     *
     * @returns state object
     */
    const prepareStateForRun = useCallback(() => {
      let stateClone: any = deepClone(state);
      const basket = 0;
      const peerGroup = 1;

      if (universeTabIndex === basket && state.universe.screening != null) {
        stateClone.universe.screening = null;
      }
      if (universeTabIndex === peerGroup && state.universe.whiteList != null) {
        stateClone.universe.whiteList = null;
      }

      //!!-----------------
      stateClone.allocation.weightInCashMin =
        stateClone.allocation.weightInCash;
      stateClone.allocation.weightInCashMax = 1;
      return stateClone;
    }, [state, universeTabIndex]);

    //#region
    /**
     * This method  syncronize the internal state with the global state
     */
    // useEffect(() => {
    //   if (stateHandler == null) {
    //     return;
    //   }
    //   stateHandler(prepareStateForRun());
    // }, [prepareStateForRun, state, stateHandler]);
    //#endregion
    useImperativeHandle(
      ref,
      () => ({
        getState: () => prepareStateForRun(),
        getDispatcher: () => dispatch,
      }),
      [dispatch, prepareStateForRun]
    );
    const tracking = useMemo(() => {
      return (
        <Tracking
          benchmark={state?.strategy?.benchmark}
          strategy={state.strategy}
          currency={state?.strategy?.currency ?? "local"}
          hedging={state?.hedging}
          dispatch={dispatch}
          backtesting={state?.backtesting}
        />
      );
    }, [dispatch, state?.backtesting, state?.hedging, state.strategy]);
    return (
      <>
        <Box display={"flex"} height={"100%"} overflow={"hidden"}>
          <Box width={"30%"} p={1} display={"flex"} flexDirection="column">
            <Box display={"flex"} gap={1} alignItems="center" pb={1}>
              {/* as when creating a strategy the name is "" */}
              {strategyName == null || strategyName === "" ? (
                <Typography variant="subtitle1">Alpha strategy</Typography>
              ) : (
                <Typography variant="subtitle1">
                  {strategyName}
                  {isReadOnlyStrategy === true && (
                    <>
                      &nbsp;&nbsp;
                      <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
                    </>
                  )}
                </Typography>
              )}
              <StrategyNavigator
                strategyType={strategyNavigatorProperties.strategyType}
                listenerChangeStrategy={
                  strategyNavigatorProperties?.listenerChangeStrategy
                }
              />
            </Box>
            <Box overflow={"auto"}>
              <fieldset className="form__fieldset form__fieldset--builder">
                <legend className="form__legend form__legend--builder">
                  <span className="builder__step">1</span> Universe
                </legend>
                <ul className="form__field-list">
                  <li className="form__field">
                    <SelectUniverse
                      params={state}
                      dispatch={dispatch}
                      selectedIndex={universeTabIndex}
                      onSelect={(index) => setUniverseTabIndex(index)}
                    />
                  </li>
                </ul>
              </fieldset>
              <SelectionRules
                showAny={false}
                strategySelection={state?.selection}
                dispatch={dispatch}
                more={state?.more?.selectionRules ?? false}
              />
              <HoldingRules
                more={state?.more?.holdingRules}
                strategy={state}
                dispatch={dispatch}
              />
              {tracking}
            </Box>
          </Box>
          <Box width={"70%"}>
            {runResults == null ? (
              <Help strategyType={strategyNavigatorProperties?.strategyType} />
            ) : (
              <Result
                helpEnabled={resultHelpEnabled}
                cleanup={cleanup}
                runManager={runManager}
                value={runResults}
                dashboard={dashboard}
              />
            )}
          </Box>
        </Box>
      </>
    );
  }
);
