import { Box, Typography } from "@mui/material";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useImmerReducer } from "use-immer";
import { handleTitle } from "../../../../../../../Utility/DocumentTitleHanlder";
import { deepClone } from "../../../../../../../deepClone";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { StrategiesStorage } from "../../../../../storage/StrategiesStorage";
import StrategyNavigator from "../../../widgets/header/StrategyNavigator";
import Help from "../../help/Help";
import Result from "../Advanced/Result/Result";
import MacroRotationHoldingRules from "./MacroRotationHoldingRules";
import MacroRotationRules from "./MacroRotationRules";
import MacroRotationSmartBetaRules from "./MacroRotationSmartBetaRules";
import { SelectUniverse } from "./SelectUniverse";
import SelectionRules from "./SelectionRules";
import Tracking from "./Tracking";
import {
  editorReducer,
  macroRotationInitialState,
  universeTabConfigHelper,
} from "./editorReducer";
import { Dashboards } from "../Advanced/Result/tabs/Insights/Insights";

type EditorMacroRotationProps = {
  readonly strategy: any;
  strategyNavigatorProperties: any;
  runResults: any;
  runManager?: StrategiesStorage;
  cleanup?: Function;
  dashboard: Dashboards;
};

const EditorMacroRotation = forwardRef(
  (
    {
      strategy,
      strategyNavigatorProperties,
      runResults,
      runManager,
      cleanup,
      dashboard,
    }: EditorMacroRotationProps = {
      strategy: null,
      // stateHandler: null,
      strategyNavigatorProperties: null,
      runResults: null,
      dashboard: { V1: undefined, V2: undefined, status: "loading" },
    },
    ref
  ) => {
    const [macroRotationState, dispatch] = useImmerReducer(
      editorReducer,
      universeTabConfigHelper(macroRotationInitialState, strategy.params)
    );

    const environment = useEnvironment();
    const strategyBuilderConfig = useMemo(() => {
      return environment.get("account").product?.configuration?.strategy_builder
        .form_macro_rotation;
    }, [environment]);

    const helpEnabled = useMemo(() => {
      return strategyBuilderConfig?.resultTab?.insights ?? false;
    }, [strategyBuilderConfig?.resultTab?.insights]);

    useEffect(
      () => console.log("macroRotationState", macroRotationState),
      [macroRotationState]
    );

    //!! **
    const [macroRotationTabIndex, setMacroRotationTabIndex] = useState(() => {
      if (strategy.params != null) {
        if (strategy.params.universe.screening != null) {
          return 1;
        } else {
          return 0;
        }
      }
      return 0;
    });

    const onTabSelectHandler = (index) => {
      setMacroRotationTabIndex(index);
    };

    useEffect(() => {
      if (strategy) {
        handleTitle(strategy);
      }
    }, [strategy]);

    useEffect(() => {
      dispatch({
        type: "LOAD_STRATEGY",
        payload: universeTabConfigHelper(
          macroRotationInitialState,
          strategy.params
        ),
      });
    }, [dispatch, strategy.params]);

    /**
     * This method clean the state object from unecessary keys used to track
     * the internal state of the component. Thoose keys were added to the
     * input object and are to be deleted to set the correct output state.
     *
     * @returns state object
     */
    const prepareStateForRun = useCallback(() => {
      let stateClone: any = deepClone(macroRotationState);
      const basket = 0;
      const peerGroup = 1;

      if (
        macroRotationTabIndex === basket &&
        macroRotationState.universe.screening != null
      ) {
        stateClone.universe.screening = null;
      }
      if (
        macroRotationTabIndex === peerGroup &&
        macroRotationState.universe.whiteList != null
      ) {
        stateClone.universe.whiteList = null;
      }

      stateClone.allocation.weightInCashMin =
        stateClone.allocation.weightInCash;
      // stateClone.allocation.weightInCashMax=1;
      return stateClone;
    }, [macroRotationState, macroRotationTabIndex]);

    //#region

    useImperativeHandle(
      ref,
      () => ({
        getState: () => prepareStateForRun(),
        getDispatcher: () => dispatch,
      }),
      [dispatch, prepareStateForRun]
    );

    //#endregion

    return (
      <Box display={"flex"} height={"100%"} overflow={"hidden"}>
        <Box width={"30%"} p={1} display={"flex"} flexDirection="column">
          <Box display={"flex"} gap={1} alignItems="center" pb={1}>
            {strategy == null ? (
              <Typography variant="subtitle1">
                Macro Rotation Strategy
              </Typography>
            ) : (
              <Typography variant="subtitle1">{strategy?.name}</Typography>
            )}
            <StrategyNavigator
              strategyType={strategyNavigatorProperties?.strategyType}
              listenerChangeStrategy={
                strategyNavigatorProperties?.listenerChangeStrategy
              }
            />
          </Box>
          <Box overflow={"auto"}>
            <fieldset className="form__fieldset form__fieldset--builder">
              <legend className="form__legend form__legend--builder">
                <span className="builder__step">1</span> Universe
              </legend>
              <ul className="form__field-list">
                <li className="form__field">
                  <SelectUniverse
                    params={macroRotationState}
                    onSelect={(index) => onTabSelectHandler(index)}
                    selectedIndex={macroRotationTabIndex}
                    dispatch={dispatch}
                  />
                </li>
              </ul>
            </fieldset>
            <MacroRotationRules
              strategy={macroRotationState}
              dispatch={dispatch}
              more={macroRotationState?.more?.rotationRules}
            />
            <SelectionRules
              showAny={true}
              strategySelection={macroRotationState?.selection}
              dispatch={dispatch}
              more={macroRotationState?.more?.selectionRules}
            />
            <MacroRotationHoldingRules
              strategy={macroRotationState}
              dispatch={dispatch}
              more={macroRotationState?.more?.holdingRules}
            />
            <MacroRotationSmartBetaRules
              strategy={macroRotationState}
              dispatch={dispatch}
            />
            <Tracking
              benchmark={macroRotationState?.strategy?.benchmark}
              strategy={macroRotationState.strategy}
              currency={macroRotationState?.strategy?.currency ?? "local"}
              hedging={macroRotationState?.hedging}
              dispatch={dispatch}
              backtesting={macroRotationState?.backtesting}
              more={macroRotationState?.more?.tracking}
            />
          </Box>
        </Box>
        <Box width={"70%"}>
          {runResults == null ? (
            <Help strategyType={strategyNavigatorProperties?.strategyType} />
          ) : (
            <Result
              helpEnabled={helpEnabled}
              cleanup={cleanup}
              runManager={runManager!}
              value={runResults}
              dashboard={dashboard}
            />
          )}
        </Box>
      </Box>
    );
  }
);

export default EditorMacroRotation;
