import { Box, CircularProgress, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Preferences } from "../../../../../api/account/Preferences";
import { Lists } from "../../../../../api/compute/Lists";
import { RankingUi2Api } from "../../../../../api/compute/RankingUi2Api";
import { TemplateTablePortfolio } from "../../../../../api/compute/TemplateTablePortfolio";
import { TableHelpers } from "../../../../../components/InstrumentsTable/Helpers/TableHelpers";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import {
  InputStubReport,
  _getDefaultColumns,
} from "../../../pages/analysisLists/widgets/utils";
import { RankUtilities } from "../../../pages/rank/RankUtilities";
import { Ranking } from "../../../pages/rank/Ranking";
import { PeerDetailStorage } from "../../../storage/PeerDetailStorage";
import { removeLoader } from "../../../utils";
import { DirectReport } from "../reportsHub/DirectReport";
import Modal from "../../../../../components/Modal/Modal";
import { deepClone } from "../../../../../deepClone";

const RANK_TEMPLATE_TAG = "reports_hub_label";

export function PdfLoader() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    removeLoader();
  }, []);

  const { value, collectionId, theme } = useParams();
  const environment = useEnvironment();

  const reports = useMemo(
    () => new DirectReport(environment.get("setup")),
    [environment]
  );
  const preferenceAPI = useMemo(
    () => new Preferences(environment.get("setup")),
    [environment]
  );
  const apiPeers = useMemo(() => {
    return new PeerDetailStorage(environment.get("setup"));
  }, [environment]);
  const apiLists = useMemo(
    () => new Lists(environment.get("setup")),
    [environment]
  );
  const usage = useMemo(() => window.App.usage, []);
  const rankUtils = useMemo(
    () => new RankUtilities(environment.get("setup")),
    [environment]
  );
  const rankingDataEncoder = useMemo(
    () => new RankingUi2Api(environment.get("setup")),
    [environment]
  );

  const appSetup = useMemo(() => environment.get("setup"), [environment]);

  const getPageConfiguration = useCallback(
    (page: string) => {
      return appSetup["configuration"].get(page);
    },
    [appSetup]
  );

  const pageConfigurationRank = useMemo(
    () => getPageConfiguration("analysisList"),
    [getPageConfiguration]
  );
  const configurationWidgetRanking = useMemo(
    () =>
      pageConfigurationRank["tabs"][
        pageConfigurationRank["tabsIndex"]["ranking"]
      ]["widgets"]["ranking"],
    [pageConfigurationRank]
  );
  const templates = useMemo(() => {
    const defaultTemplateObject = {
      configuration: {
        ranking: deepClone(
          configurationWidgetRanking["edit"]["defaultTemplate"]
        ),
      },
      name: "Default Ranking",
      id: null,
    };

    let presetTemplates = [
      ...configurationWidgetRanking["edit"]["presetTemplates"],
      { ...defaultTemplateObject },
    ];

    return presetTemplates;
  }, [configurationWidgetRanking]);

  const taggedTemplates = useMemo(() => {
    return templates.filter((template) => RANK_TEMPLATE_TAG in template);
  }, [templates]);

  const rankThemeMap = useMemo(() => {
    return taggedTemplates.reduce((prev, current) => {
      return { ...prev, [current[RANK_TEMPLATE_TAG]]: current };
    }, {});
  }, [taggedTemplates]);

  const getTemplatesByName = useCallback(
    async (names: string[]) => {
      const payload = {
        searches: [
          {
            filters: [
              {
                dimension: "ownerId",
                segments: [35],
              },
              {
                dimension: "name",
                segments: names,
              },
            ],
          },
        ],
      };

      const response = await preferenceAPI.search(payload);

      return response;
    },
    [preferenceAPI]
  );

  const getPeer = useCallback(
    async (cell) => {
      const key = cell.value;
      const [id, size, type] = key.split("__");

      const peerPayload = {
        type: "Stock",
        what: "ICB",
        where: id,
        zDimension: size,
      };

      const peer = await apiPeers.getPeer(peerPayload);

      const prototype: any = {
        page: "analysisMarketsDetail",
        target: null,
        rankingCache: null,
        widgets: null,
        storage: {
          peers: new PeerDetailStorage(environment.get("setup")),
        },
        usage: { function: "MARKET" },
        timeframe: "lastMonth",
        trimOutliers: false,
        dispersionByConstraints: {
          performanceTimeframe: "3_months",
          intervals: 4,
          trimOutliers: false,
        },
        segment: "1 Industry",
        sortState: {
          descending: false,
          property: "_s_label",
        },
        title: "",
        subject: null,
        templateId: null,
      };

      if (peer != null) {
        if (id === "R_D_A") {
          peer.name = "North America";
        }
        prototype.target = peer;
      }

      const templateNames = {
        size: "Markets",
        sector: "Markets",
        markets: "Country",
      };

      const templateName = templateNames[type];

      const ids = await getTemplatesByName([templateName]);
      prototype.templateId = ids?.[0];

      return prototype;
    },
    [apiPeers, environment, getTemplatesByName]
  );

  const prepareListReportParams = useCallback(
    async (cellInfo) => {
      const page = "analysisList";
      const target = await apiLists.get(
        parseInt(cellInfo.collectionId),
        undefined,
        true
      );
      const portfoliosTableTemplates = new TemplateTablePortfolio(
        environment.get("setup")
      );

      const listType = target["type"];
      const response = await portfoliosTableTemplates.get();

      var _columns: any[] = []; // raw columns
      var item: any = null;
      for (let i = 0, length = response.length; i < length; i++) {
        item = response[i];
        if (item["name"] === "DEFAULT_PORTFOLIO_SECURITY") {
          _columns = item["configuration"]["tableColumns"];
        }
      }
      if (_columns.length === 0) {
        _columns = _getDefaultColumns(environment, page);
      }

      var columns: any[] = [];
      for (let i = 0, length = _columns.length; i < length; i++) {
        item = _columns[i];
        columns.push({
          label: item["label"],
          property: item["field"],
        });
      }

      var holdings = new InputStubReport({
        columns: columns,
      });

      const templateIds = await getTemplatesByName(["Portfolio"]);
      const templateId = templateIds?.[0];

      if (listType.toLowerCase() === "portfolio") {
        // ActionReportWithoutWysiwygState
        const params = {
          templateId,
          strategyCache: null,
          rankingCache: null,
          page: page,
          target: target,
          // uiState: uiState,
          title: target.name,
          widgets: {
            table: holdings,
            dispersion: undefined,
          },
        };
        return params;
      } else {
        // ActionReportWysiwygState
        const params = {
          templateId,
          strategyCache: null,
          rankingCache: null,
          page: "dispersionBasketTab",
          target: target,
          title: target.name,
          // uiState: uiState,
          widgets: {
            table: holdings,
            dispersion: undefined,
          },
        };
        return params;
      }
    },
    [apiLists, environment, getTemplatesByName]
  );

  const getColumns = useCallback(
    (_columns) => {
      const helper = new TableHelpers(environment.get("setup"));

      const rankColumns = helper.get("rank");
      const common = helper.get("columns");
      let cols = common.prepareInputColumns(_columns);

      let columnsToSet: any = [];

      if (cols) {
        const tableColumns = cols;

        columnsToSet = [];

        for (const viewerCol of tableColumns) {
          if ("customColConfiguration" in viewerCol) {
            switch (viewerCol.customColConfiguration) {
              case "rankConfiguration": {
                rankColumns.configureAsRankCol(
                  viewerCol,
                  columnsToSet,
                  () => {},
                  false
                );

                break;
              }
              default:
                console.warn(
                  `${viewerCol.customColConfiguration} is not a valid configuration for columns`
                );
            }
          } else {
            const sortHandler = () => 0;

            columnsToSet.push(common.tabulatorColumn(viewerCol, sortHandler));
          }
        }
      }

      return columnsToSet;
    },
    [environment]
  );

  const getWidgetInfo = useCallback(
    (infoType, tableColumns) => {
      switch (infoType) {
        case "sortBy":
          return {
            property: "rank",
            descending: true,
          };

        case "columns": {
          let _column: any = null;
          let _columns: any = [...tableColumns];
          let column: any = null;
          let columns: any = [];

          _columns = getColumns(_columns);

          for (let i = 0, length = _columns.length; i < length; i++) {
            _column = _columns[i];

            if (_column["field"] !== undefined && _column["field"] != null) {
              column = {
                label: (_column?.["title"] ?? _column?.["label"] ?? "")
                  .replace(/<br\/>/gi, " - ")
                  .replace(/<br>/gi, " "),
                property: _column["field"],
              };
              columns.push(column);
            }
          }

          return columns;
        }

        default:
          console.warn(`Unrecognized widget type: ${infoType}`);
      }
    },
    [getColumns]
  );

  const prepareRankThemedReportParams = useCallback(
    async (cellInfo) => {
      const appEnvironment = environment.get("setup");
      const rankingEngine = new Ranking(appEnvironment);
      let listTargetId = cellInfo.collectionId;
      listTargetId = parseInt(listTargetId);

      const constraints: any = {
        constraints: [
          [
            {
              dimension: "COLLECTION",
              operator: "relation",
              segments: [listTargetId],
            },
            {
              dimension: "type",
              logicalOperator: "not",
              operator: "equals",
              segments: ["ExpiredStock"],
            },
          ],
        ],
      };

      const configuration = rankThemeMap?.[cellInfo?.theme] ?? undefined;

      if (configuration == null) {
        console.error(`Cannot find ranking template named: ${cellInfo?.theme}`);
        return;
      }

      const rules = configuration.rules;

      const reportsHubConfiguraiton =
        appSetup["account"]["product"]["configuration"]["reports_hub"];
      const defualtColumns = reportsHubConfiguraiton["rankThemedColumns"];

      rankingEngine.setConstraints(constraints);
      rankingEngine.setRules(rules);
      rankingEngine.setColumns(defualtColumns);

      const { columns } = await rankingEngine.rank();

      const templateIds = await getTemplatesByName(["Rank"]);
      const templateId = templateIds?.[0];

      let collectionName = await apiLists.portfolioFetch(
        [listTargetId],
        ["name"]
      );

      collectionName = collectionName?.[0]?.name ?? "";

      const rankSchema = {
        name: `${collectionName} - ${configuration.name}`,
        target: {
          object: {
            type: "LIST",
          },
        },
      };

      const rankParams = {
        page: "ranking",
        target: rankSchema,
        usage: window.App.usage,
        title: `Trendrating - ${collectionName}`,
        rankingCache: rankingEngine.getRankingCache(),
        widgets: {
          table: { get: (infoType) => getWidgetInfo(infoType, columns) },
        },
        templateId,
      };

      return rankParams;
    },
    [
      apiLists,
      appSetup,
      environment,
      getTemplatesByName,
      getWidgetInfo,
      rankThemeMap,
    ]
  );

  const prepareRankReportParams = useCallback(
    async (cellInfo) => {
      const appEnvironment = environment.get("setup");
      const rankingEngine = new Ranking(appEnvironment);
      const rankId = cellInfo.collectionId;

      const rankSchema = await rankUtils.getRankObject(rankId);

      const targetType = rankSchema.target.type;

      let constraints: any = undefined;

      if (targetType !== "instruments") {
        constraints = {
          constraints: [
            [
              {
                dimension: "COLLECTION",
                operator: "relation",
                segments: [rankSchema.target.object.id],
              },
              {
                dimension: "type",
                logicalOperator: "not",
                operator: "equals",
                segments: ["ExpiredStock"],
              },
            ],
          ],
        };
      } else {
        constraints = rankSchema.target.object.constraints;
      }

      if (rankSchema.against != null) {
        const againstId = rankSchema?.against?.object?.id ?? undefined;

        if (againstId != null) {
          rankingEngine.setHighlightListId(againstId);
        }
      }

      const rankingRules = rankingDataEncoder.decode(rankSchema.rules);
      rankingEngine.setConstraints(constraints);
      rankingEngine.setRules(rankingRules);

      let templateColumns: any = undefined;

      if (
        "columns" in rankSchema &&
        rankSchema.columns != null &&
        rankSchema.columns.length
      ) {
        templateColumns = [];

        for (const column of rankSchema.columns) {
          if ("field" in column && column.field) {
            templateColumns.push(column.field);
          }
        }

        rankingEngine.setColumns(templateColumns);
      }

      let fromDate = null;

      if (rankSchema.fromDate != null) {
        fromDate = rankSchema.fromDate;
        if (fromDate) {
          rankingEngine.setFromDate(fromDate);
        }
      }

      const { columns } = await rankingEngine.rank();

      const name = rankSchema.name;
      const templateIds = await getTemplatesByName(["Rank"]);
      const templateId = templateIds?.[0];

      const rankParams = {
        page: "ranking",
        target: rankSchema,
        usage: window.App.usage,
        title: name,
        rankingCache: rankingEngine.getRankingCache(),
        widgets: {
          table: { get: (infoType) => getWidgetInfo(infoType, columns) },
        },
        templateId,
      };

      return rankParams;
    },
    [
      environment,
      getTemplatesByName,
      getWidgetInfo,
      rankUtils,
      rankingDataEncoder,
    ]
  );

  const getAlertsReportParams = useCallback((cellInfo) => {
    const paramsByTimeframe = {
      alerts: {
        page: "alerts",
        target: {
          type: "ALERTS",
          name: "Alerts",
        },
        timeframe: "today",
        templateId: "ALERTS",
      },
      alerts_w: {
        page: "alerts",
        target: {
          type: "ALERTS",
          name: "Alerts",
        },
        timeframe: "lastWeek",
        templateId: "ALERTS",
      },
      alerts_m: {
        page: "alerts",
        target: {
          type: "ALERTS",
          name: "Alerts",
        },
        timeframe: "lastMonth",
        templateId: "ALERTS",
      },
    };

    return paramsByTimeframe[cellInfo.value];
  }, []);

  const getReportParams = useCallback(
    async (cellInfo) => {
      const isAlerts = cellInfo.value.startsWith("alerts");
      const isPeerGroup =
        cellInfo.value !== "portfolios" &&
        cellInfo.value !== "basket" &&
        cellInfo.value !== "rank" &&
        cellInfo.value !== "rankThemed" &&
        !isAlerts;

      if (isPeerGroup) {
        return await getPeer(cellInfo);
      } else if (isAlerts) {
        return getAlertsReportParams(cellInfo);
      } else {
        switch (cellInfo.value) {
          case "portfolios":
          case "basket":
            return await prepareListReportParams(cellInfo);

          case "rank":
            return await prepareRankReportParams(cellInfo);

          case "rankThemed": {
            return await prepareRankThemedReportParams(cellInfo);
          }
        }
      }
    },
    [
      getAlertsReportParams,
      getPeer,
      prepareListReportParams,
      prepareRankReportParams,
      prepareRankThemedReportParams,
    ]
  );

  const recordLog = useCallback(
    (value: any) => {
      let info = {
        action: "PRINT",
        actionParam: {},
        function: "REPORTS_HUB",
      };

      switch (value.value) {
        case "portfolios":
        case "basket":
        case "rank":
          info.actionParam = {
            type: value.value,
            id: value.collectionId,
          };

          usage.record(info);

          break;

        case "rankThemed": {
          info.actionParam = {
            type: value.value,
            id: value.collectionId,
            theme: value.theme,
          };

          usage.record(info);

          break;
        }

        case "alerts":
          info.actionParam = {
            type: "alerts",
          };

          usage.record(info);

          break;

        default:
          const [id, size] = value.value.split("__");

          info.actionParam = {
            type: "peer",
            market: id,
            size,
          };

          usage.record(info);
      }
    },
    [usage]
  );

  const printReport = useCallback(
    async (value) => {
      try {
        setIsLoading(true);
        /**
         * Get Report Params
         */
        const params = await getReportParams(value);
        /**
         * Load report params
         */
        reports.setReportParams(params);

        recordLog(value);

        await reports.doAction();
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    },
    [getReportParams, reports, recordLog]
  );

  const onLanding = useCallback(async () => {
    if (value) {
      const cellInfo = {
        value,
        collectionId: collectionId ?? undefined,
        theme: theme ?? undefined,
      };

      printReport(cellInfo);
    }
  }, [collectionId, printReport, theme, value]);

  useEffect(() => {
    onLanding();
  }, [onLanding]);

  return (
    <div>
      {isLoading && (
        <Modal closeIcon={false}>
          <Box
            p={2}
            display={"flex"}
            gap={1}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress sx={{ color: "#2a7090" }} />
            <Typography>Preparing report...</Typography>
          </Box>
        </Modal>
      )}
    </div>
  );
}
