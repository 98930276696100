/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/formatter/StrategyOptions
 * @summary Editor for advanced strategy: Select options for advanced editor
 *
 */

import { deepClone } from "../../../deepClone";

export class StrategyOptions {
  getEligibility(type, refValue = null) {
    const options = this._optionsEligibility();
    switch (type) {
      case "LABEL_LOOKUP": {
        const key = this.getEligibility("OPTION_LOOKUP", refValue);

        return options[key].label;
      }
      case "OPTION_LOOKUP": {
        if (refValue != null && refValue in options) {
          return refValue;
        }

        return null;
      }
      case "OPTIONS": {
        const prototype = [options["desc"], options["asc"]];

        return deepClone(prototype);
      } // no default
    }
  }

  getRotation(type, refValue = null) {
    const options = this._optionsRotation();
    switch (type) {
      case "LABEL_LOOKUP": {
        let key = this.getRotation("OPTION_LOOKUP", refValue);

        return options[key].label;
      }
      case "OPTION_LOOKUP": {
        if (refValue != null && refValue in options) {
          return refValue;
        }

        return null;
      }
      case "OPTIONS": {
        const prototype = [
          options["Country"],
          options["Region"],
          options["Area"],
          options["Country__3 Sector"],
          options["Country__1 Industry"],
        ];

        return deepClone(prototype);
      }
      case "OPTIONS_EXTENDED": {
        const prototype = [
          options["Country"],
          options["Region"],
          options["Country__3 Sector"],
          options["Country__1 Industry"],
          options["Country__4 Subsector"],

          {
            label: "<em>More ...</em>",
            value: null,
            children: [
              options["Area"],
              options["3 Sector"],
              options["1 Industry"],
              options["Region__1 Industry"],
              options["Area__1 Industry"],
              options["Region__3 Sector"],
              options["Area__3 Sector"],
            ],
          },
        ];

        return deepClone(prototype);
      } // no default
    }

    return null;
  }

  getWeightCappingPeerLevel(type, refValue = null, isEtf) {
    const options = isEtf
      ? this._optionsWeightCappingPeerLevelETF()
      : this._optionsWeightCappingPeerLevel();

    switch (type) {
      case "LABEL_LOOKUP": {
        let key = this.getWeightCappingPeerLevel(
          "OPTION_LOOKUP",
          refValue,
          isEtf
        );

        return options[key].label;
      }
      case "OPTION_LOOKUP": {
        if (refValue != null && refValue in options) {
          return refValue;
        }

        return null;
      }
      case "OPTIONS": {
        const prototype = [
          options["Country"],
          options["Region"],
          options["Area"],
          options["3 Sector"],
          options["1 Industry"],
        ];

        return deepClone(prototype);
      } // no default
    }

    return null;
  }
  // ----------------------------------------------------- private methods
  _optionsEligibility() {
    const options = {
      asc: {
        label: "Lowest Mkt. Cap.",
        value: "asc",
      },
      desc: {
        label: "Highest Mkt. Cap.",
        value: "desc",
      },
    };

    return options;
  }

  _optionsRotation() {
    const options = {
      Country: { label: "Markets", value: "Country" },

      Region: { label: "Regions", value: "Region" },

      Area: { label: "Areas", value: "Area" },

      "3 Sector": {
        label: "Industries",
        value: "3 Sector",
      },

      "1 Industry": {
        label: "Sectors",
        value: "1 Industry",
      },

      "Country__3 Sector": {
        label: "Industries in Markets",
        value: "Country__3 Sector",
      },

      "Country__1 Industry": {
        label: "Sectors in Markets",
        value: "Country__1 Industry",
      },

      "Region__1 Industry": {
        label: "Regions in Sectors",
        value: "Region__1 Industry",
      },

      "Area__1 Industry": {
        label: "Area in Sectors",
        value: "Area__1 Industry",
      },

      "Region__3 Sector": {
        label: "Regions in Industries",
        value: "Region__3 Sector",
      },

      "Area__3 Sector": {
        label: "Area in Industries",
        value: "Area__3 Sector",
      },
      "Country__4 Subsector": {
        label: "Themes in Markets (ETF)",
        value: "Country__4 Subsector",
      },
    };

    return options;
  }

  _optionsWeightCappingPeerLevel() {
    const options = {
      Country: { label: "Markets", value: "Country" },

      Region: { label: "Regions", value: "Region" },

      Area: { label: "Areas", value: "Area" },

      "3 Sector": {
        label: "Industries",
        value: "3 Sector",
      },

      "1 Industry": {
        label: "Sectors",
        value: "1 Industry",
      },
    };

    return options;
  }

  _optionsWeightCappingPeerLevelETF() {
    const options = {
      Country: { label: "Inv.Region", value: "Country" },

      Region: { label: "Regions", value: "Region" },

      Area: { label: "Areas", value: "Area" },

      "3 Sector": {
        label: "Specialty",
        value: "3 Sector",
      },

      "1 Industry": {
        label: "Asset Class",
        value: "1 Industry",
      },
      "4 Subsector": {
        label: "Theme",
        value: "4 Subsector",
      },
    };

    return options;
  }
}
