import { Box, Card, CardContent } from "@mui/material";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { ScreeningParams } from "../../../../../api/compute/Instruments";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { FormOptions } from "../../../../trendrating-widgets/form/FormOptions";
import SavedTemplatesBar from "../SavedTemplatesBar/SavedTemplatesBar";
import { MarketFilter } from "./../FilterBar/FilterWidgets/MarketFilter/MarketFilter";
import { FilterBarHelper, WidgetInfo, WidgetTag } from "./FilterBarHelper";
import { AnalyticsFilters } from "./FilterWidgets/AnalyticsFilters/AnalyticsFilters";
import { AssetClassFilter } from "./FilterWidgets/AssetClassFilter/AssetClassFilter";
import { DomicileFilter } from "./FilterWidgets/DomicileFilter/DomicileFilter";
import { FamilyFilter } from "./FilterWidgets/FamilyFilter/FamilyFilter";
import { MarketCapFilter } from "./FilterWidgets/MarketCapFilter/MarketCapFilter";
import { OptionsFilter } from "./FilterWidgets/OptionFilter/OptionFilter";
import { RangeRateFilter } from "./FilterWidgets/RangeRateFilter/RangeRateFilter";
import { RegionFilter } from "./FilterWidgets/RegionFilter/RegionFilter";
import { SectorFilter } from "./FilterWidgets/SectorFilters/SectorFilter";
import { UniverseDefinitionFilter } from "./FilterWidgets/UniverseDefinitionFilter/UniverseDefinitionFilter";

type FilterRowContraintsProps = {
  rowToRender: {
    label: string;
    field: string | string[];
    display: { widget: string };
    hidden?: boolean;
  }[];
  onRowChange: (value) => void;
  uriParams?: {
    alert: string;
    size: string;
    type: string;
    timeframe: string;
    what: string;
    where: string;
    preferences?: string[];
  };
  tabType: string;
  setActiveTemplate: (value) => void;
  getActiveTemplate: () => { id: number; name: string } | undefined;
  updateTableColumns: (fields: any) => any;
};

type FilterCardProps = {
  children: JSX.Element | JSX.Element[];
  label?: string;
};

type WidgetInFilterRowProps = {
  widget: WidgetInfo;
  helper: FilterBarHelper;
  tabType: string;
  updateTableColumns: (fields: any) => any;
};

type FilterWizardProps = {
  widgetParams: any[];
  tabType: string;
  updateTableColumns: (fields) => any;
};

type MarketsFiltersType = {
  type: string;
  value: { dimension: string; segments: any[] };
}[];

type FilterContextType =
  | {
      filters: { dimension: string; segments: any[] }[];
      ranges: { dimension: string; segments: any[] }[];
      updateRanges: (value: { dimension: string; segments: any[] }[]) => void;
      updateFilters: (value: { dimension: string; segments: any[] }[]) => void;
      ratingFilterState: { A: boolean; B: boolean; C: boolean; D: boolean };
      updateRatingFilter: (updatedState: {
        A: boolean;
        B: boolean;
        C: boolean;
        D: boolean;
      }) => void;
      alertsState: string;
      updateAlertsState: (value: string) => void;
      newHL: string;
      updateNewHL: (value: string) => void;
      fundamentalsConstraints: {
        field: string;
        value: any;
        label: string;
        editorType: string;
        widget: string;
      }[];
      updateFundamentalsConstraints: (
        constraints: {
          field: string;
          value: any;
          label: string;
          editorType: string;
          widget: string;
        }[]
      ) => void;
      whiteList:
        | {
            id: number;
            name: string | undefined;
            type: string;
          }[]
        | null;
      updateWhiteList: (
        value:
          | {
              id: number;
              name: string | undefined;
              type: string;
            }[]
          | null
      ) => void;
      markets: MarketsFiltersType | null;
      updateMarkets: (value: MarketsFiltersType) => void;
      domicile: MarketsFiltersType | null;
      updateDomicile: (value: MarketsFiltersType) => void;
      issuer: MarketsFiltersType | null;
      updateIssuer: (value: MarketsFiltersType) => void;
      sector: MarketsFiltersType | null;
      updateSector: (value: MarketsFiltersType) => void;
      assetClass: MarketsFiltersType | null;
      updateAssetClass: (value: MarketsFiltersType) => void;
      size: { ge: number | null; le: number | null } | null;
      updateSize: (
        value: { ge: number | null; le: number | null } | null
      ) => void;
      invRegion: MarketsFiltersType | null;
      updateInvRegion: (value: MarketsFiltersType) => void;
      indexFamily: string[] | null;
      updateIndexFamily: (value: string[] | null) => void;
      loadTemplates: Function;
    }
  | undefined;

export const FiltersContext = createContext<FilterContextType>(undefined);

export function FilterRowContraints({
  rowToRender,
  onRowChange,
  uriParams,
  tabType,
  setActiveTemplate,
  updateTableColumns,
  getActiveTemplate,
}: FilterRowContraintsProps) {
  const environment = useEnvironment();

  //Initialize state with params that might be in url
  const getFiltersFromUri = useCallback(() => {
    let state: any = undefined;

    if (uriParams) {
      state = {};

      if ("alert" in uriParams && uriParams.alert !== "-") {
        const typeOfAlet = uriParams.alert === "upgrades" ? "U_" : "D_";
        let days = 0;

        if ("timeframe" in uriParams && uriParams.timeframe !== "-") {
          switch (uriParams.timeframe) {
            default:
            case "today":
              days = 0;
              break;

            case "lastWeek":
              days = 4;
              break;

            case "lastMonth":
              days = 19;
              break;
          }
        }

        state["alerts"] = typeOfAlet + days;
      }

      if (
        "what" in uriParams &&
        uriParams.what !== "-" &&
        "type" in uriParams &&
        uriParams.type !== "-"
      ) {
        const type = uriParams.type;
        const [securityAssetClass] = type.split("_");

        if (securityAssetClass) {
          const filter: {
            type: string;
            value: { dimension: string; segments: any[] };
          }[] = [];
          const value = {
            type: "filters",
            value: {
              dimension: securityAssetClass !== "ETF" ? "icb" : "etfclass",
              segments: [uriParams.what],
            },
          };
          filter.push(value);

          if (securityAssetClass === "ETF") {
            state["assetClass"] = filter;
          } else {
            state["sectors"] = filter;
          }
        }
      }

      if (
        "where" in uriParams &&
        uriParams.where !== "-" &&
        "type" in uriParams &&
        uriParams.type !== "-"
      ) {
        const type = uriParams.type;
        const [securityAssetClass, stockClass] = type.split("_");

        if (securityAssetClass) {
          const filter: {
            type: string;
            value: { dimension: string; segments: any[] };
          }[] = [];
          const value = {
            type: "filters",
            value: {
              dimension: securityAssetClass !== "ETF" ? "country" : "etfgeo",
              segments: [uriParams.where],
            },
          };
          filter.push(value);

          if (stockClass && stockClass === "commons-stocks") {
            filter.push({
              type: "filters",
              value: {
                dimension: "stockclass",
                segments: ["STOCK"],
              },
            });
          }

          if (securityAssetClass === "ETF") {
            state["investmentRegion"] = filter;
          } else {
            state["markets"] = filter;
          }
        }
      }

      if ("size" in uriParams && uriParams.size !== "-") {
        const size = uriParams.size;
        let sizeValue: any = null;

        if (size != null) {
          const _options = FormOptions.get("MARKET_CAP");
          switch (size) {
            case "micro": {
              sizeValue = {
                ge: _options[0]["ge"],
                le: _options[0]["le"],
              };

              break;
            }
            case "microSmall": {
              sizeValue = {
                ge: _options[0]["ge"],
                le: _options[1]["le"],
              };

              break;
            }
            case "microMid": {
              sizeValue = {
                ge: _options[0]["ge"],
                le: _options[2]["le"],
              };

              break;
            }
            case "small": {
              sizeValue = {
                ge: _options[1]["ge"],
                le: _options[1]["le"],
              };

              break;
            }
            case "smallMid": {
              sizeValue = {
                ge: _options[1]["ge"],
                le: _options[2]["le"],
              };

              break;
            }
            case "smallLarge": {
              sizeValue = {
                ge: _options[1]["ge"],
                le: null,
              };

              break;
            }
            case "mid": {
              sizeValue = {
                ge: _options[2]["ge"],
                le: _options[2]["le"],
              };

              break;
            }
            case "midLarge": {
              sizeValue = {
                ge: _options[2]["ge"],
                le: null,
              };

              break;
            }
            case "large": {
              sizeValue = {
                ge: _options[3]["ge"],
                le: null,
              };

              break;
            }
            case "microLarge":
            default: {
              // all size
            }
          }

          if (sizeValue) {
            state["size"] = sizeValue;
          }
        }
      }
    }

    return state;
  }, [uriParams]);

  const lookIntoUriParams = useCallback(
    (
      paramKey:
        | "alerts"
        | "assetClass"
        | "sectors"
        | "investmentRegion"
        | "markets"
        | "size"
    ) => {
      const stateFromUri = getFiltersFromUri();

      if (!stateFromUri) {
        return;
      }

      return stateFromUri[paramKey];
    },
    [getFiltersFromUri]
  );

  const preferences = useMemo(() => {
    const globalPreferences =
      environment.get("setup")?.account?.user?.preferences?.preferences ??
      undefined;

    if ("screening" in globalPreferences) {
      const screeningPreferences = globalPreferences.screening;

      if (screeningPreferences) {
        const markets = screeningPreferences?.markets?.ids ?? [];

        if (markets.length) {
          const marketsWidgetInitState = [
            {
              type: "filters",
              value: {
                dimension: "country",
                segments: markets,
              },
            },
          ];

          return marketsWidgetInitState;
        }
      }
    }

    return null;
  }, [environment]);

  const alertInitState = useMemo(() => {
    return lookIntoUriParams("alerts");
  }, [lookIntoUriParams]);
  const assetClassInit = useMemo(() => {
    return lookIntoUriParams("assetClass");
  }, [lookIntoUriParams]);
  const sectorInit = useMemo(
    () => lookIntoUriParams("sectors"),
    [lookIntoUriParams]
  );
  const investmentRegionInit = useMemo(
    () => lookIntoUriParams("investmentRegion"),
    [lookIntoUriParams]
  );
  const marketsInit = useMemo(() => {
    const marketsFilters = lookIntoUriParams("markets");

    if (marketsFilters) {
      return marketsFilters;
    } else if (preferences) {
      return preferences;
    }

    return;
  }, [lookIntoUriParams, preferences]);
  const sizeInitState = useMemo(
    () => lookIntoUriParams("size"),
    [lookIntoUriParams]
  );

  const [filters, setFilters] = useState<
    { dimension: string; segments: any[] }[]
  >([]);
  const [ranges, setRanges] = useState<
    { dimension: string; segments: any[] }[]
  >([]);
  const [ratingState, setRatingState] = useState({
    A: false,
    B: false,
    C: false,
    D: false,
  });
  const [alerts, setAlerts] = useState(alertInitState ?? "None");
  const [hl, setHL] = useState("None");
  const [activeConstraints, updateActiveConstraints] = useState<
    {
      field: string;
      value: any;
      label: string;
      editorType: string;
      widget: string;
    }[]
  >([]);
  const [whiteList, setWhiteList] = useState<
    | {
        id: number;
        name: string | undefined;
        type: string;
      }[]
    | null
  >(null);
  const [markets, setMarkets] = useState<MarketsFiltersType | null>(
    marketsInit ?? null
  );
  const [issuer, setIssuer] = useState<MarketsFiltersType | null>(null);
  const [domicile, setDomicile] = useState<MarketsFiltersType | null>(null);
  const [sectors, setSectors] = useState<MarketsFiltersType | null>(
    sectorInit ?? null
  );
  const [assetClass, setAssetClass] = useState<MarketsFiltersType | null>(
    assetClassInit ?? null
  );
  const [invRegion, setInvRegion] = useState<MarketsFiltersType | null>(
    investmentRegionInit ?? null
  );
  const [size, setSize] = useState<{
    ge: number | null;
    le: number | null;
  } | null>(sizeInitState ?? null);
  const [indexFamily, setIndexFamily] = useState<string[] | null>(null);
  const properties = useMemo(
    () => environment.get("properties"),
    [environment]
  );
  const rawProperties = useMemo(() => {
    const p = properties.properties;
    const rawProperties = {
      ...p.stock,
      ...p.security,
    };

    return rawProperties;
  }, [properties.properties]);

  const filterBarHelper = useMemo(() => new FilterBarHelper(), []);

  const widgetStateWrapper = useCallback(
    (widgetType: WidgetTag) => {
      return filterBarHelper.formatWidgetState(widgetType);
    },
    [filterBarHelper]
  );

  const escapeFirstRender = useRef(true);

  const payloadBuilder = useCallback(
    (state: {
      issuer?: typeof issuer;
      sectors?: typeof sectors;
      assetClass?: typeof assetClass;
      investmentRegion?: typeof invRegion;
      indexFamily?: typeof indexFamily;
      markets?: typeof markets;
      domicile?: typeof domicile;
      rating?: typeof ratingState;
      whiteList?: typeof whiteList;
      alerts?: typeof alerts;
      newHl?: typeof hl;
      size?: typeof size;
      additionalConstraints?: typeof activeConstraints;
    }) => {
      const filters: any = [];

      // GET value of whitelist widget
      if ("whiteList" in state) {
        // Might be an array but at now we want make selectable only one white list
        if (state.whiteList != null && state.whiteList.length) {
          filters.push({
            filter: [
              {
                domain: state.whiteList.map((item) => item.id),
                range: "COLLECTION",
              },
            ],
            type: "relations",
          });
        }
      }

      // GET value of asset class widget (ETF)
      if ("assetClass" in state) {
        if (state.assetClass && state.assetClass.length) {
          const assetClassFilters = state.assetClass.map((item) => item.value);
          filters.push({
            type: "filters",
            filter: assetClassFilters,
          });
        }
      }

      // GET value of investment region widget (ETF)
      if ("investmentRegion" in state) {
        if (state.investmentRegion && state.investmentRegion.length) {
          const invRegionFilters = state.investmentRegion.map(
            (item) => item.value
          );
          filters.push({ type: "filters", filter: invRegionFilters });
        }
      }

      // GET value of issuer widget (ETF)
      if ("issuer" in state) {
        if (state.issuer && state.issuer.length) {
          const issuerFilters = state.issuer.map((item) => item.value);
          filters.push({ type: "filters", filter: issuerFilters });
        }
      }

      // GET value of markets widget
      if ("markets" in state) {
        if (state.markets && state.markets.length) {
          const marketsFilters = state.markets.map((item) => item.value);
          filters.push({ type: "filters", filter: marketsFilters });
        }
      }

      // GET value of sectors widget
      if ("sectors" in state) {
        if (state.sectors && state.sectors.length) {
          const sectorsFilters = state.sectors.map((item) => item.value);
          filters.push({ type: "filters", filter: sectorsFilters });
        }
      }

      // GET value of domicile widget
      if ("domicile" in state) {
        if (state.domicile && state.domicile.length) {
          const domicileFilters = state.domicile.map((item) => item.value);
          filters.push({ type: "filters", filter: domicileFilters });
        }
      }

      // GET value of market cap widget
      if ("size" in state) {
        if (state.size && (state.size.ge || state.size.le)) {
          const sizeFilter: any = {
            filter: [{ dimension: "marketcap", segments: [] }],
            type: "ranges",
          };

          const sizeObject = {};

          if (state.size.le) {
            sizeObject["le"] = state.size.le * 1e6;
          }

          if (state.size.ge) {
            sizeObject["ge"] = state.size.ge * 1e6;
          }

          sizeFilter.filter[0].segments.push(sizeObject);

          filters.push(sizeFilter);
        }
      }

      // GET value of rating widget
      if ("rating" in state) {
        const rc = widgetStateWrapper("rating")(state.rating as any);
        if (rc && rc.filter && rc.filter.length) {
          filters.push(rc);
        }
      }

      // GET value of alert widget
      if ("alerts" in state) {
        const alertsFilter = widgetStateWrapper("alert")(state.alerts as any);
        if (alertsFilter && alertsFilter.filter && alertsFilter.filter.length) {
          filters.push(alertsFilter);
        }
      }

      // GET value of new high new low widget
      if ("newHl" in state) {
        const hlValue = widgetStateWrapper("newHL")(state.newHl as any);
        if (hlValue && hlValue.filter && hlValue.filter.length) {
          filters.push(hlValue);
        }
      }

      // GET value of additional filters
      if ("additionalConstraints" in state) {
        const customAnalytics: any = widgetStateWrapper("customAnalytics")(
          state.additionalConstraints as any
        );
        if (customAnalytics && customAnalytics.length) {
          filters.push(...customAnalytics);
        }
      }

      // GET value of index family widget (Index)
      if ("indexFamily" in state) {
        if (state.indexFamily && state.indexFamily.length) {
          const indexSubtypeFilter = {
            type: "filters",
            filter: [
              {
                dimension: "subtype",
                segments: state.indexFamily,
              },
            ],
          };
          filters.push(indexSubtypeFilter);
        }
      }

      return filters;
    },
    [widgetStateWrapper]
  );

  const getData = useCallback(() => {
    const state = {
      issuer: issuer,
      sectors: sectors,
      assetClass: assetClass,
      investmentRegion: invRegion,
      indexFamily: indexFamily,
      markets: markets,
      domicile: domicile,
      rating: ratingState,
      whiteList: whiteList,
      alerts: alerts,
      newHl: hl,
      size: size,
      additionalConstraints: activeConstraints,
    };

    const filters = payloadBuilder(state);
    onRowChange(filters);
  }, [
    activeConstraints,
    alerts,
    assetClass,
    domicile,
    hl,
    indexFamily,
    invRegion,
    issuer,
    markets,
    onRowChange,
    payloadBuilder,
    ratingState,
    sectors,
    size,
    whiteList,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);

  const clearFilters = useCallback(() => {
    setFilters([]);
    setRanges([]);
    setRatingState({ A: false, B: false, C: false, D: false });
    setAlerts("None");
    setHL("None");
    updateActiveConstraints([]);
    setWhiteList(null);
    setMarkets(preferences);
    setDomicile(null);
    setIssuer(null);
    setSectors(null);
    setSize(null);
    setAssetClass(null);
    setInvRegion(null);
    setIndexFamily(null);
  }, [preferences]);

  useEffect(() => {
    // Skip first render cause filters have not to be cleared on first render
    if (escapeFirstRender.current) {
      escapeFirstRender.current = false;
    } else {
      clearFilters();
    }
  }, [clearFilters, tabType]);

  // Handle Syntax conversion and filters initial population
  const fromServerSyntaxToUI = useCallback(
    async (data: ScreeningParams) => {
      if (!data) {
        return data;
      }

      clearFilters();
      const filterWidgetConstraints: {
        field: string;
        value: any;
        label: string;
        editorType: string;
        widget: string;
      }[] = [];

      //handle lr && direction (Alert)
      const alertArray: any = [];

      data.constraints.forEach((filterSet) => {
        filterSet.forEach((filter) => {
          switch (filter.dimension) {
            case "direction":
            case "lr": {
              alertArray.push(filter);
              break;
            }
            case "rc": {
              const segments = filter.segments;

              const rcObject = {
                A: false,
                B: false,
                C: false,
                D: false,
              };

              segments?.forEach((item) => {
                if ("<=" in item && ">=" in item && item["<="] && item[">="]) {
                  if (item["<="] === item[">="]) {
                    switch (item["<="]) {
                      case 2: {
                        rcObject["A"] = true;
                        break;
                      }

                      case 1: {
                        rcObject["B"] = true;
                        break;
                      }

                      case -1: {
                        rcObject["C"] = true;
                        break;
                      }

                      case -2: {
                        rcObject["D"] = true;
                        break;
                      }
                    }
                  }
                }
              });

              setRatingState(rcObject);

              break;
            }

            case "mv": {
              const segments = filter.segments;

              if (segments?.length) {
                const moverObj = segments?.[0] ?? null;
                if (moverObj) {
                  const valuePositive = moverObj?.[">="] ?? null;
                  const valueNegative = moverObj?.["<="] ?? null;

                  if (valuePositive) {
                    setAlerts("MOVERS_POSITIVE");
                  } else if (valueNegative) {
                    setAlerts("MOVERS_NEGATIVE");
                  }
                }
              }

              break;
            }

            case "etfgeo":
            case "etfclass":
            case "subtype":
            case "icb":
            case "country": {
              const value = {
                type: "filters",
                value: {
                  dimension: filter.dimension,
                  segments: filter.segments as string[],
                },
              };

              if (filter.dimension === "country") {
                const markets: any = [value];
                const subtype = filterSet.find(
                  (item) => item.dimension === "subtype"
                );
                const stockclass = filterSet.find(
                  (item) => item.dimension === "stockclass"
                );

                if (subtype && tabType !== "ETF") {
                  markets.push({
                    type: "filters",
                    value: {
                      dimension: "subtype",
                      segments: subtype.segments,
                    },
                  });
                }

                if (stockclass) {
                  markets.push({
                    type: "filters",
                    value: {
                      dimension: "stockclass",
                      segments: stockclass.segments,
                    },
                  });
                }

                setMarkets(markets as any);
              }

              if (filter.dimension === "icb") {
                setSectors([value] as any);
              }

              if (filter.dimension === "subtype") {
                const type = filterSet.find((el) => el.dimension === "type");

                if (type) {
                  const stockType = type.segments?.[0] ?? null;
                  if (stockType) {
                    if (stockType === "Index") {
                      setIndexFamily(filter.segments as any);
                    } else {
                      setIssuer([value] as any);
                    }
                  }
                }
              }

              if (filter.dimension === "etfclass") {
                setAssetClass([value] as any);
              }

              if (filter.dimension === "etfgeo") {
                setInvRegion([value] as any);
              }

              break;
            }

            case "marketcap": {
              const segments: any = filter?.segments ?? [];
              let size: any = {
                le: null,
                ge: null,
              };

              if (segments.length && segments[0]) {
                if (("<=" in segments[0]) as any) {
                  size["le"] = segments[0]["<="] / 1e6;
                }

                if (">=" in segments[0]) {
                  size["ge"] = segments[0][">="] / 1e6;
                }
              }

              if (size.le != null || size.ge != null) {
                setSize(size as any);
              }

              break;
            }

            case "COLLECTION": {
              if (filter.segments?.length) {
                const whiteLists: typeof whiteList = [];

                filter.segments.forEach((segment) => {
                  whiteLists?.push({
                    id: parseInt(segment as any),
                    name: undefined,
                    type: filter.dimension,
                  });
                }, []);

                setWhiteList(whiteLists);
              }

              break;
            }

            default: {
              if (filter.dimension !== "type") {
                if (rawProperties && rawProperties[filter.dimension]) {
                  const property = rawProperties[filter.dimension];
                  let value: any = {};

                  switch (property?.input?.widget ?? false) {
                    case "minMax": {
                      if (filter.segments?.[0]) {
                        const segment = filter.segments[0] as any;
                        if ("<=" in segment) {
                          value["max"] =
                            filter.dimension === "tradedvalue"
                              ? segment["<="] / 1e6
                              : segment["<="];
                        }

                        if (">=" in segment) {
                          value["min"] =
                            filter.dimension === "tradedvalue"
                              ? segment[">="] / 1e6
                              : segment[">="];
                        }
                      }
                      break;
                    }

                    case "taxonomyFilter": {
                      value = filter.segments;

                      break;
                    }

                    case "newHighNewLow": {
                      value =
                        JSON.stringify(filter.segments?.[0]?.[">="]) ?? null;

                      break;
                    }
                  }

                  filterWidgetConstraints.push({
                    field: filter.dimension,
                    value,
                    label: property?.name?.[0] ?? "",
                    widget: property?.input?.widget ?? "",
                    editorType: property?.input?.type ?? "",
                  });
                }
              }

              break;
            }
          }
        });
      });

      // 2 Elements are needed lr and direction
      if (alertArray.length) {
        const alertFieldsObj = alertArray.reduce((prev, current) => {
          prev[current.dimension] = current;

          return prev;
        }, {});

        if ("lr" in alertFieldsObj && "direction" in alertFieldsObj) {
          const le = alertFieldsObj["lr"].segments?.[0]?.["<="] ?? null;
          const directionValue =
            alertFieldsObj["direction"].segments?.[0]?.["<="] ?? null;

          if (le != null && directionValue != null) {
            const prefix = directionValue > 0 ? "U_" : "D_";

            setAlerts(prefix + le);
          }
        }
      }

      if (filterWidgetConstraints.length) {
        updateActiveConstraints(filterWidgetConstraints);
      }
    },
    [clearFilters, rawProperties, tabType]
  );

  const contextObject: FilterContextType = {
    filters,
    ranges,
    updateFilters: (filtersValue: { dimension: string; segments: any[] }[]) => {
      setFilters(filtersValue);
    },
    updateRanges: (rangesValue: { dimension: string; segments: any[] }[]) => {
      setRanges(rangesValue);
    },
    ratingFilterState: ratingState,
    updateRatingFilter: (ratingFilter: {
      A: boolean;
      B: boolean;
      C: boolean;
      D: boolean;
    }) => {
      setRatingState(ratingFilter);
    },
    alertsState: alerts,
    updateAlertsState: (alerts: string) => {
      setAlerts(alerts);
    },
    newHL: hl,
    updateNewHL: (hl: string) => {
      setHL(hl);
    },
    fundamentalsConstraints: activeConstraints,
    updateFundamentalsConstraints: (value) => {
      updateActiveConstraints(value);
    },
    whiteList,
    updateWhiteList: (value) => {
      setWhiteList(value);
    },
    markets,
    updateMarkets: (value: MarketsFiltersType) => {
      setMarkets(value);
    },
    domicile,
    updateDomicile: (value) => {
      setDomicile(value);
    },
    issuer,
    updateIssuer: (value) => {
      setIssuer(value);
    },
    sector: sectors,
    updateSector: (value) => {
      setSectors(value);
    },
    size,
    updateSize: (value) => {
      setSize(value);
    },
    assetClass,
    updateAssetClass: (value) => {
      setAssetClass(value);
    },
    invRegion,
    updateInvRegion: (value) => {
      setInvRegion(value);
    },
    indexFamily,
    updateIndexFamily: (value) => {
      setIndexFamily(value);
    },
    loadTemplates: fromServerSyntaxToUI,
  };

  return (
    <FiltersContext.Provider value={contextObject}>
      <SavedTemplatesBar
        key={tabType}
        clearFilters={clearFilters}
        onSetActiveTemplate={setActiveTemplate}
        getActiveTemplate={getActiveTemplate}
        currentTab={tabType}
      />
      {rowToRender && rowToRender.length ? (
        <Box display={"flex"}>
          <Box component={"ul"} display={"flex"} gap={1}>
            {rowToRender.map((widget) => (
              <WidgetInFilterRow
                tabType={tabType}
                updateTableColumns={updateTableColumns}
                helper={filterBarHelper}
                key={uuidv4()}
                widget={widget}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </FiltersContext.Provider>
  );
}

const WidgetInFilterRow = ({
  widget,
  helper,
  tabType,
  updateTableColumns,
}: WidgetInFilterRowProps) => {
  const formatter = useFormatter();
  const usage = useMemo(
    () => ({
      function: "SCREENING",
    }),
    []
  );

  const widgetParams = useMemo(
    () =>
      helper.getWidgetContextualParams(widget, formatter, usage, FormOptions),
    [formatter, helper, usage, widget]
  );

  return widget["hidden"] !== undefined &&
    widget["hidden"] != null &&
    widget["hidden"] === true ? (
    <></>
  ) : (
    <Box component={"li"} display={"flex"}>
      <FilterWizard
        updateTableColumns={updateTableColumns}
        tabType={tabType}
        widgetParams={widgetParams}
      />
    </Box>
  );
};

const FilterWizard = ({
  widgetParams,
  tabType,
  updateTableColumns,
}: FilterWizardProps) => {
  const [tag, widgetProps] = widgetParams;
  const context = useContext(FiltersContext);

  const updateSize = useCallback(
    (size) => {
      if (context?.size?.le !== size.le || context?.size?.ge !== size.ge) {
        context?.updateSize(size);
      }
    },
    [context]
  );

  switch (tag) {
    case "RelationUniverse": {
      return <UniverseDefinitionFilter label={widgetProps?.label ?? ""} />;
    }

    case "FilterMarket":
      return (
        <MarketFilter
          widgetParams={widgetProps}
          type={tabType}
          field={"country"}
        />
      );

    case "RangeRate": {
      return (
        <FilterCard label={widgetProps.label}>
          <RangeRateFilter />
        </FilterCard>
      );
    }

    case "RangeAlert": {
      let label = widgetProps.label;

      if (context?.alertsState && context.alertsState !== "None") {
        let isUpgrade = /^U_\d{1,2}$/gi.test(context.alertsState);

        if (isUpgrade) {
          label = "Upgrades";
        } else if (context.alertsState.includes("MOVERS")) {
          label = "Movers";
        } else {
          label = "Downgrades";
        }
      }

      const handleClickOnAlertsGroupLabel = (opt, setValue) => {
        if (opt?.label) {
          let value: string | null = null;

          if (opt.label === "Upgrades") {
            value = "U_0";
          } else if (opt.label === "Downgrades") {
            value = "D_0";
          }

          if (value != null) {
            setValue(value);
          }
        }
      };

      return (
        <FilterCard label={label}>
          <OptionsFilter
            value={context?.alertsState ?? "None"}
            setValue={context?.updateAlertsState as Function}
            widgetParams={widgetProps}
            handleClickOnGroupLabel={handleClickOnAlertsGroupLabel}
          />
        </FilterCard>
      );
    }

    // Actually not used
    case "RangeNewHL": {
      return (
        <FilterCard label={widgetProps.label}>
          <OptionsFilter
            value={context?.newHL ?? "None"}
            setValue={context?.updateNewHL as Function}
            widgetParams={widgetProps}
          />
        </FilterCard>
      );
    }

    case "filter": {
      return (
        <AnalyticsFilters
          tabType={tabType}
          widgetParams={widgetProps}
          updateTableColumns={updateTableColumns}
        />
      );
    }

    // Actually not used
    case "FilterDomicile": {
      return <DomicileFilter label={widgetProps.label} />;
    }

    case "FilterSector": {
      return <SectorFilter label={widgetProps.label} />;
    }

    case "RangeMktCap": {
      return (
        <FilterCard label={widgetProps.label}>
          <MarketCapFilter updateValue={updateSize} initValue={context?.size} />
        </FilterCard>
      );
    }

    case "FilterETFType": {
      return <AssetClassFilter label={widgetProps.label} />;
    }

    case "FilterRegion": {
      return <RegionFilter label={widgetProps.label} />;
    }

    case "FilterFamily": {
      return (
        <FilterCard label={widgetProps.label}>
          <FamilyFilter />
        </FilterCard>
      );
    }

    default:
      return <></>;
  }
};

export const FilterCard = ({ children, label }: FilterCardProps) => {
  return (
    <Card sx={{ display: "flex" }}>
      <CardContent
        sx={{
          padding: "2px 6px!important",
          display: "flex",
          flex: 1,
          textAlign: "center",
          flexDirection: "column",
        }}
      >
        {label && (
          <strong>
            <label className="filter-bar__field-label">{label}</label>
          </strong>
        )}
        <Box flex={1} display={"flex"} alignItems={"center"}>
          {children}
        </Box>
      </CardContent>
    </Card>
  );
};
