import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { LoaderContext } from "./StrategiesBuilder";

export function Loader() {
  const [preLoader, setPreLoader] = useState(false);

  const { on, remove, availableEvents } = useContext(LoaderContext);

  const {
    events,
    registerEvent,
    updateEvent,
    finish,
    loadFinish,
    clearEvents,
  } = useProgressStream();

  const preloadStart = useCallback(() => {
    setPreLoader(true);
  }, []);

  const preloadStop = useCallback(() => {
    setPreLoader(false);
  }, []);

  const cleanProgresses = useCallback(() => {}, []);

  const newProg = useCallback(
    (e) => {
      registerEvent(e);

      if (preLoader) {
        setPreLoader(false);
      }
    },

    [preLoader, registerEvent]
  );

  const updateProg = useCallback(
    (e) => {
      updateEvent(e);
    },
    [updateEvent]
  );

  const completeProg = useCallback(
    (e: any) => {
      const id = e.detail;
      finish(id);
    },
    [finish]
  );

  useEffect(() => {
    if (loadFinish) {
      clearEvents();
    }
  }, [clearEvents, loadFinish]);

  // Handle Events that control the state of Loader from outside
  useEffect(() => {
    const {
      startPreload,
      stopPreload,
      newProgram,
      updateProgram,
      completeProgram,
      clearProgresses,
    } = availableEvents;

    // Subscribe to events
    on(startPreload, preloadStart);
    on(stopPreload, preloadStop);
    on(newProgram, newProg);
    on(updateProgram, updateProg);
    on(completeProgram, completeProg);
    on(clearProgresses, cleanProgresses);

    // Unsibscribe from events
    return () => {
      remove(startPreload, preloadStart);
      remove(stopPreload, preloadStop);
      remove(newProgram, newProg);
      remove(updateProgram, updateProg);
      remove(completeProgram, completeProg);
      remove(clearProgresses, cleanProgresses);
    };
  }, [
    availableEvents,
    cleanProgresses,
    completeProg,
    newProg,
    on,
    preloadStart,
    preloadStop,
    remove,
    updateProg,
  ]);

  return !loadFinish || preLoader === true ? (
    <Box
      position={"absolute"}
      height={"100%"}
      width={"100%"}
      display={"flex"}
      bgcolor={"#fff"}
      alignItems={"center"}
      justifyContent={"center"}
      zIndex={999}
    >
      <Box display={"flex"} flexDirection={"column"} gap={2} width={"60%"}>
        {preLoader === false &&
          Object.entries(events.events).map((item: any, index) => {
            const [streamId, event] = item;
            return (
              <Card key={index} sx={{ boxShadow: 3 }}>
                <CardContent sx={{ pb: "16px !important" }}>
                  <Box display={"flex"} flexDirection={"column"}>
                    <Typography>Computing: {event.stream[0].name}</Typography>
                    <ProgressBar
                      stream={event.stream}
                      streamId={streamId}
                      finish={finish}
                    />
                  </Box>
                </CardContent>
              </Card>
            );
          })}
        {preLoader === true && (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Typography align="center">
              Data is loading, please wait.
            </Typography>
            <LinearProgress />
          </Box>
        )}
      </Box>
    </Box>
  ) : null;
}

const date2milli = (T) => {
  var quot = Math.floor(T / 5);
  var rem = Math.floor(T % 5);
  var u = Math.floor((quot * 7 + rem + 4) * 86400 * 1000);

  const theMonth = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let date = new Date(u);
  let Y: any = date.getFullYear();
  var m = date.getMonth();
  return `${theMonth[m]} ${Y}`;
};

const useProgressStream = () => {
  const [events, setEvents] = useState<{
    events: {
      [key: string]: { stream: any[]; status: "fresh" | "consumed" };
    };
  }>({ events: {} });

  const registerEvent = useCallback((event) => {
    const obj = event.detail;
    const id = obj.id;

    setEvents((current) => {
      return {
        events: { ...current.events, [id]: { stream: [obj], status: "fresh" } },
      };
    });
  }, []);

  const updateEvent = useCallback((event) => {
    const obj = event.detail;

    setEvents((current) => ({
      events: {
        ...current.events,
        [obj.id]: {
          stream: [...current.events[obj.id]["stream"], obj],
          status: "fresh",
        },
      },
    }));
  }, []);

  const finish = useCallback((id: string) => {
    setEvents((current) => {
      return {
        events: {
          ...current.events,
          [id]: {
            stream: [...current["events"][id]["stream"]],
            status: "consumed",
          },
        },
      };
    });
  }, []);

  const loadFinish = useMemo(() => {
    let finish = true;

    for (const key in events.events) {
      if (events.events[key].status === "fresh") {
        finish = false;

        break;
      }
    }

    return finish;
  }, [events.events]);

  const clearEvents = useCallback(() => {
    setEvents({ events: {} });
  }, []);

  return {
    events,
    updateEvent,
    registerEvent,
    finish,
    loadFinish,
    clearEvents,
  };
};

const ProgressBar = ({ stream, streamId, finish }) => {
  const [value, setValue] = useState(0);
  const [label, setLabel] = useState("");
  const [point, setPoint] = useState("0");

  useEffect(() => {
    let p: any = null;

    for (let i = 0; i < stream.length; i++) {
      p = stream[i];

      setValue(
        ((p.point - stream[0].min) / (stream[0].max - stream[0].min)) * 100
      );

      setLabel(
        (
          ((p.point - stream[0].min) / (stream[0].max - stream[0].min)) *
          100
        ).toFixed(2) + "%"
      );

      setPoint(p.point);
    }
  }, [finish, stream, streamId]);

  return (
    <Box
      display={"flex"}
      width={"100%"}
      height={"100%"}
      position={"relative"}
      alignItems={"center"}
      gap={1}
    >
      <Box display={"block"} height={"100%"} width={"100%"}>
        <LinearProgress
          sx={{ height: 15 }}
          variant="determinate"
          value={value}
        />
      </Box>
      <Box
        minWidth={150}
        display={"flex"}
        gap={1}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <Typography>{label}</Typography>

        <Typography>
          <strong>{date2milli(parseInt(point))}</strong>
        </Typography>
      </Box>
    </Box>
  );
};
