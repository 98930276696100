import { useCallback, useEffect, useMemo, useState } from "react";
import { RankingUi2Api } from "../../../../../../../api/compute/RankingUi2Api";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { Checkbox as ReactCheckbox } from "@mui/material";
import { useImmerReducer } from "use-immer";
import CustomFieldCollapser from "../../../../../../../js/app/components/ui/form/CustomFieldCollapser";
import SelectNested from "../../../../../../../js/app/components/ui/form/SelectNested";
import { Strategy } from "./editorReducer";
import { PredefinedRankingTemplate } from "./PredefinedRankingTemplate";
import { rankingReducer, rankingState, State } from "./rankingReducer";

import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import MinMaxInputNumer from "./MinMaxInputNumer";

type HoldingRulesProps = {
  strategy: Strategy;
  dispatch: Function;
  more?: boolean;
};

export default function HoldingRules({
  strategy,
  dispatch,
  more = false,
}: HoldingRulesProps) {
  /**
   * This method generate the correct array to pass as a key to the PredefinedRankingTemplateCLass
   */
  const generateKeyForSelectedValue = useCallback((ranking) => {
    let rankingFactor: any = {};
    let keyForSelectedValue: any = [];
    ranking.forEach((element) => {
      switch (element.property) {
        case "pr":
          if (element.function === "quantile") {
            rankingFactor = {
              field: element.property,
              sort: element.operatorParams.value,
              rankType: element.function,
              rankTypeParams: {
                n: element.functionParams.value,
                trimOutlier: false,
              },
            };
          } else {
            rankingFactor = {
              field: element.property,
              sort: element.operatorParams.value,
            };
          }
          break;
        default:
          rankingFactor = {
            field: element.property,
            sort: element.operatorParams.value,
          };
      }
      keyForSelectedValue.push(rankingFactor);
    });
    return keyForSelectedValue;
  }, []);

  /**
   *
   * This method use the data structure of the global state that comes throw the props
   * and transpile it to the data structure of the state of this component. This is
   * necessary to syncronize the current component state with the global state
   *
   * @returns state
   */
  const mapRankingToState = useCallback(() => {
    let state: State = { keepValidPositions: true, prioritySelect: "" };

    const checkValidPosition = strategy?.ranking!.find(
      (item) => item.property === "exists"
    );

    if (checkValidPosition) {
      state.keepValidPositions = true;
    } else {
      state.keepValidPositions = false;
    }

    const prioritySelectState = strategy?.ranking!.filter(
      (item) => item.property !== "exists"
    );

    const unparsedPriorityKey =
      generateKeyForSelectedValue(prioritySelectState);

    const priorityKey = PredefinedRankingTemplate.get(
      "OPTION_LOOKUP",
      JSON.stringify(unparsedPriorityKey)
    );

    switch (priorityKey) {
      case "CLOSEST_TO_THE_MAXIMUM_NO_KEEP":
        state.prioritySelect = "CLOSEST_TO_THE_MAXIMUM";
        break;
      default:
        state.prioritySelect = priorityKey;
    }

    return state;
  }, [generateKeyForSelectedValue, strategy?.ranking]);

  const [rankingRules, rankingDispatch] = useImmerReducer(
    rankingReducer,
    strategy ? mapRankingToState() : rankingState
  );
  const [maxCashCheckbox, setMaxCashCheckbox] = useState(false);

  const { keepValidPositions, prioritySelect } = rankingRules;
  const environment = useEnvironment();
  const envTransformation = environment.get("setup");

  /**
   * Syncronize the component state with the global state
   */
  useEffect(() => {
    rankingDispatch({
      type: "SYNC_RANKING_WITH_STATE",
      payload: mapRankingToState(),
    });
  }, [mapRankingToState, rankingDispatch]);

  useEffect(() => {
    if (strategy?.allocation?.weightInCash !== 0) {
      setMaxCashCheckbox(true);
    } else {
      setMaxCashCheckbox(false);
    }
  }, [strategy?.allocation?.weightInCash]);

  /**
   * Use the current state to track widgets activity state and translate the state map to the
   * Server data format. After that this format is translated to the new input object format and
   * is set in the global state by dispatching an action.
   */
  useEffect(() => {
    const serverFormatEncoder = new RankingUi2Api(envTransformation);
    let ranking: any = [];
    if (prioritySelect != null) {
      const priorities = PredefinedRankingTemplate.getLookUpForServer(
        prioritySelect,
        keepValidPositions
      );

      if (Array.isArray(priorities)) {
        ranking = ranking.concat(priorities);
      } else {
        ranking.push(priorities);
      }

      dispatch({
        type: "SET_RANKING",
        payload: serverFormatEncoder.decode(ranking),
      });
    }
  }, [dispatch, envTransformation, keepValidPositions, prioritySelect]);

  const holdingsValue = useMemo(
    () => strategy?.strategy?.holdings ?? "30",
    [strategy?.strategy?.holdings]
  );

  const weightInCash = useMemo(
    () => strategy?.allocation?.weightInCash ?? 1,
    [strategy?.allocation?.weightInCash]
  );

  const enableWeightCapping = useMemo(
    () => strategy?.allocation?.weightCappingSecurity?.weightCappedMax != null,
    [strategy?.allocation?.weightCappingSecurity?.weightCappedMax]
  );
  const weightCapping = useMemo(
    () =>
      strategy?.allocation?.weightCappingSecurity?.weightCappedMax != null
        ? strategy?.allocation?.weightCappingSecurity?.weightCappedMax
        : 0.01,
    [strategy?.allocation?.weightCappingSecurity?.weightCappedMax]
  );

  const rebalancefrequency = useMemo(
    () => strategy?.strategy?.rebalance ?? "20_DAYS",
    [strategy?.strategy?.rebalance]
  );

  const weightingSchemaExistingPositions = useMemo(
    () =>
      strategy?.weighting.weightingSchemaExistingPositions ??
      "WEIGHT_EXISTING_POSITIONS_KEEP",
    [strategy?.weighting.weightingSchemaExistingPositions]
  );

  return (
    <fieldset className="form__fieldset form__fieldset--builder">
      <legend className="form__legend form__legend--builder">
        <span className="builder__step">3</span> Holding rules
      </legend>
      <ul className="form__field-list">
        <li className="form__field layout-grid">
          <Box display={"flex"} gap={2}>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Typography>Securities to hold</Typography>
              <MinMaxInputNumer
                value={holdingsValue != null ? holdingsValue : 30}
                step={1}
                min={1}
                max={500}
                stateSetter={(value) => {
                  dispatch({
                    type: "SET_SECURITY_TO_HOLD",
                    payload: value,
                  });
                }}
              />
            </Box>
            <FormControl>
              <Typography textAlign={"start"}>Investment</Typography>
              <RadioGroup
                row
                value={
                  weightInCash !== 1
                    ? "SET_INVESTMENT_FLEX"
                    : "SET_INVESTMENT_FULL"
                }
                onChange={(e) => {
                  dispatch({
                    type: e.target.value,
                  });
                }}
              >
                <FormControlLabel
                  value="SET_INVESTMENT_FLEX"
                  control={<Radio />}
                  label={<Typography>Flex</Typography>}
                />
                <FormControlLabel
                  value="SET_INVESTMENT_FULL"
                  control={<Radio />}
                  label={<Typography>Full</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </li>
        <li className="form__field">
          <Box display={"flex"}>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <ReactCheckbox
                      onChange={(e) => {
                        const checked = e.target.checked;
                        dispatch({
                          type: "SET_ENABLE_WEIGHT_CAPPING",
                          payload: checked,
                          weightCappedMax: weightCapping,
                        });
                      }}
                      checked={enableWeightCapping}
                    />
                  }
                  label={<Typography>% weight capped at</Typography>}
                />
              </FormGroup>

              <MinMaxInputNumer
                value={
                  weightCapping != null
                    ? parseFloat((weightCapping * 100).toFixed(2))
                    : 1
                }
                min={1}
                max={100}
                step={0.01}
                disabled={!enableWeightCapping}
                stateSetter={(value) => {
                  dispatch({
                    type: "SET_WEIGHT_CAPPING",
                    payload: parseFloat((value / 100).toString()).toFixed(5),
                  });
                }}
              />
            </Box>
          </Box>
        </li>
        <li className="form__field">
          <FormControl>
            <Typography textAlign={"start"}>Rebalance frequency</Typography>
            <RadioGroup
              row
              value={rebalancefrequency}
              onChange={(e, value) => {
                dispatch({
                  type: "SET_REBALANCE_FREQUENCY",
                  event: true,
                  payload: value,
                });
              }}
            >
              <FormControlLabel
                value="05_DAYS"
                control={<Radio />}
                label={<Typography>Weekly</Typography>}
              />
              <FormControlLabel
                value="20_DAYS"
                control={<Radio />}
                label={<Typography>Monthly</Typography>}
              />
              <FormControlLabel
                value="60_DAYS"
                control={<Radio />}
                label={<Typography>Quarterly</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </li>
        <li className="form__field">
          <Typography>Give priority to</Typography>
          <SelectNested dispatch={rankingDispatch} globalState={strategy} />
        </li>
        {prioritySelect !== "CLOSEST_TO_THE_MAXIMUM" ? (
          <li className="form__field">
            <FormGroup>
              <FormControlLabel
                control={
                  <ReactCheckbox
                    onChange={(e) => {
                      const checked = e.target.checked;
                      rankingDispatch({
                        type: "SET_KEEP_VALID_POSITIONS",
                        payload: checked,
                      });
                    }}
                    checked={keepValidPositions}
                  />
                }
                label={<Typography>Keep valid positions</Typography>}
              />
            </FormGroup>
          </li>
        ) : null}
        <CustomFieldCollapser
          isOpen={more ?? false}
          setIsOpen={(e) => {
            dispatch({ type: "MORE_HOLDING_RULES", payload: e });
          }}
        >
          <li className="form__field">
            <div
              className="form__more"
              data-dojo-attach-event="collapser-resize:_collapserResize"
              data-dojo-attach-point="_widgetRebalanceOptions"
              data-dojo-type="app/ui/form/FieldCollapser"
            >
              <div className="form__field">
                <FormControl>
                  <Typography textAlign={"start"}>
                    Existing positions
                  </Typography>
                  <RadioGroup
                    row
                    value={weightingSchemaExistingPositions}
                    onChange={(e) => {
                      const value = e.target.value;
                      dispatch({
                        type: "SET_EXISTING_POSITIONS",
                        payload: true,
                        value: value,
                      });
                    }}
                  >
                    <FormControlLabel
                      value="WEIGHT_EXISTING_POSITIONS_KEEP"
                      control={<Radio />}
                      label={<Typography>Keep weights</Typography>}
                    />
                    <FormControlLabel
                      value="WEIGHT_EXISTING_POSITIONS_REBALANCE"
                      control={<Radio />}
                      label={<Typography>Rebalance weights</Typography>}
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="form__field">
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography>Maximum cash to hold</Typography>
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <FormGroup>
                      <FormControlLabel
                        sx={{ m: 0 }}
                        control={
                          <ReactCheckbox
                            onChange={(e) => {
                              const checked = e.target.checked;
                              setMaxCashCheckbox(checked);
                              if (!checked && weightInCash !== 1) {
                                dispatch({
                                  type: "SET_INVESTMENT_FLEX",
                                });
                              }
                            }}
                            disabled={weightInCash === 1}
                            checked={maxCashCheckbox}
                          />
                        }
                        label={<Typography>% weight</Typography>}
                      />
                    </FormGroup>
                    <Box display={"flex"} gap={1} alignItems={"center"}>
                      <MinMaxInputNumer
                        value={parseFloat(
                          ((1 - weightInCash) * 100).toFixed(2)
                        )}
                        min={1}
                        max={100}
                        stateSetter={(value) => {
                          dispatch({
                            type: "SET_WEIGHT_IN_CASH",
                            payload: parseFloat(
                              (1 - value / 100).toString()
                            ).toFixed(5),
                          });
                        }}
                        disabled={!maxCashCheckbox || weightInCash === 1}
                      />
                    </Box>
                  </Box>
                </Box>
              </div>
            </div>
          </li>
        </CustomFieldCollapser>
      </ul>
    </fieldset>
  );
}
