import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useImmerReducer } from "use-immer";
import { Peers } from "../../../../../../api/compute/Peers";
import {
  formatTaxonPrefixingParent,
  getTaxonById,
} from "../../../../../../api/compute/Taxon";
import { encodePeerId } from "../../../../../../api/utils";
import Modal from "../../../../../../components/Modal/Modal";
import { deepClone } from "../../../../../../deepClone";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../hooks/useFormatter";
import { Collector } from "../../../../../trendrating-report/data/Collector";
import { defaultTemplateReportPeerWizard } from "../../../../../trendrating-report/defaultTemplateReportPeerWizard-ts";
import { Downloader } from "../../../../../trendrating-report/downloader/DownloaderReact";
import { Generator } from "../../../../../trendrating-report/generator/Generator";
import { messageError } from "../../../../utils";
import { _getUiState } from "../../../../widgets/app-infrastructure/workflowBar/actions/report/ReactDojoReport";
import { _getUserPreference } from "../../../../widgets/app-infrastructure/workflowBar/actions/report/ReportWizzard";
import Focus from "./Focus";
import Markets from "./Markets";
import PerformanceDispersion from "./PerformanceDispersion";
import { initValue, reportReducer } from "./Report.reducer";
import ReportType from "./ReportType";
import { useBroadcast } from "../../../../../../hooks/useBroadcast";

type Props = { closeReportModal: Function; storage: any };

export default function Report({ closeReportModal, storage }: Props) {
  const [state, dispatch] = useImmerReducer(reportReducer, initValue);
  const environment = useEnvironment();
  const envSetup = environment.get("setup");
  const peersAPI = useMemo(() => new Peers(envSetup), [envSetup]);
  const { t } = useTranslation();
  const formatter = useFormatter();
  const { broadcast } = useBroadcast();

  //#region - makets handlers
  const onChangeTypeHandler_markets = useCallback(
    (whereType) => {
      dispatch({ type: "SET_WHERE_TYPE", payload: whereType });
      switch (whereType) {
        case "World":
          dispatch({ type: "SET_FOCUSON", payload: "Area" });
          break;
        case "Area":
          dispatch({ type: "SET_FOCUSON", payload: "Region" });
          break;
        case "Region":
          dispatch({ type: "SET_FOCUSON", payload: "Country" });
          break;
        case "Country":
          dispatch({ type: "SET_FOCUSON", payload: "1 Industry" });
          break;
      }
    },
    [dispatch]
  );

  const onSelectHandler_markets = useCallback(
    (whereValue) => dispatch({ type: "SET_WHERE_VALUE", payload: whereValue }),
    [dispatch]
  );

  const setSizeHandler_markets = useCallback(
    (size) => {
      dispatch({ type: "SET_SIZE", payload: size });
    },
    [dispatch]
  );
  //#endregion

  //#region - focus handlers
  const onSetFocusOnHandler_focus = useCallback(
    (value) => {
      dispatch({ type: "SET_FOCUSON", payload: value });
      dispatch({
        type: "SET_FOCUS_WHAT",
        payload: {
          label: "Any",
          type: "0 root",
          value: "ICB",
        },
      });
    },
    [dispatch]
  );
  const onSetHasIndustriesHandler_focus = useCallback(
    (value) => dispatch({ type: "SET_HASINDUSTRIES", payload: value }),
    [dispatch]
  );
  const onSetFocusWhatHandler_focus = useCallback(
    (value) => dispatch({ type: "SET_FOCUS_WHAT", payload: value }),
    [dispatch]
  );
  //#endregion
  const TRABButtons = useMemo(() => {
    const obj = {
      today: "Today",
      lastWeek: "1 Week",
      lastMonth: "1 Month",
    };
    const objKeys = Object.keys(obj);
    let btns: any[] = [];
    objKeys.forEach((key, index) => {
      btns.push(
        <Button
          key={index}
          variant={state.timeframe === key ? "contained" : undefined}
          onClick={() => dispatch({ type: "SET_TIMEFRAME", payload: key })}
        >
          {obj[key]}
        </Button>
      );
    });
    return btns;
  }, [dispatch, state.timeframe]);

  const _setName = useCallback(
    (params, peers: any) => {
      let instrumentType: any = params["instrumentType"].toLowerCase();
      let taxon: any = null;
      let taxonomies: any = envSetup["taxonomies"];
      let fieldMap: any = envSetup["taxonomyFields"];
      let whatRootNode: any = Object.values<any>(
        taxonomies[
          fieldMap[instrumentType === "etf" ? "ETF" : "security"][
            instrumentType === "etf" ? "etfclass" : "sector"
          ]
        ]
      ).find((node: any) => node.parent === null)["id"];
      const whereRootNode: any = Object.values<any>(
        taxonomies[
          fieldMap[instrumentType === "etf" ? "ETF" : "security"][
            instrumentType === "etf" ? "etfgeo" : "country"
          ]
        ]
      ).find((node) => node.parent === null)["id"];
      let field: any = null;
      for (const peer of peers) {
        if (peer["what"] === whatRootNode && peer["where"] === whereRootNode) {
          peer["name"] = "__ROOT__"; // special name: managed by render
        } else if (
          peer["what"] === whatRootNode &&
          peer["where"] !== whereRootNode
        ) {
          // where only
          field =
            fieldMap[instrumentType === "etf" ? "ETF" : "security"][
              instrumentType === "etf" ? "etfgeo" : "country"
            ];
          taxon = Object.values(taxonomies[field]).find(
            (item: any) => item.id === peer["where"]
          );
          peer["name"] = taxon["name"];
        } else if (
          peer["what"] !== whatRootNode &&
          peer["where"] === whereRootNode
        ) {
          // what only
          field =
            fieldMap[instrumentType === "etf" ? "ETF" : "security"][
              instrumentType === "etf" ? "etfclass" : "sector"
            ];
          taxon = Object.values(taxonomies[field]).find(
            (item: any) => item.id === peer["what"]
          );
          peer["name"] = taxon["name"];
        } else {
          // cross: using , as separator. The UI can split the name
          // in substring for formatting purposes
          const whereField =
            fieldMap[instrumentType === "etf" ? "ETF" : "security"][
              instrumentType === "etf" ? "etfgeo" : "country"
            ];
          const whatField =
            fieldMap[instrumentType === "etf" ? "ETF" : "security"][
              instrumentType === "etf" ? "etfclass" : "sector"
            ];
          peer["name"] = [
            Object.values<any>(taxonomies[whereField]).find(
              (item) => item.id === peer["where"]
            )["name"],
            Object.values<any>(taxonomies[whatField]).find(
              (item) => item.id === peer["what"]
            )["name"],
          ].join(",");
        }
      }

      return peers;
    },
    [envSetup]
  );

  const _preparePrintParams = useCallback(
    (data, peer) => {
      // template
      var template = _getTemplate(data);
      template.configuration["headerConfig"] = data.headerConfig;
      // executable sections
      var baseId = new Date().getTime();
      var executableSections = deepClone(template.configuration.sections);
      var section: any = null;
      for (var i = 0, length = executableSections.length; i < length; i++) {
        section = executableSections[i];

        section.id = baseId + "-" + i;
        switch (section.type) {
          case "REPORT_COMMON_COVER":
          case "REPORT_COMMON_DISCLAIMER":
          case "REPORT_COMMON_HEADER_1":
          case "REPORT_COMMON_PAGE_BREAK":
          case "REPORT_COMMON_PARAGRAPH":
          case "REPORT_COMMON_SPACING":
          case "REPORT_COMMON_TITLE": {
            // nothing to do
            break;
          }
          case "REPORT_PEER_DISPERSION": {
            section.content.intervals = Number(data.dispersion.intervals);
            section.content.timeframe = data.dispersion.timeframe;

            break;
          }
          case "REPORT_PEER_DISPERSION_CHILDREN": {
            section.content.focusOn = _getFocusOn(data);
            section.content.intervals = Number(data.dispersion.intervals);
            section.content.timeframe = data.dispersion.timeframe;

            break;
          }
          case "REPORT_PEER_WHAT_OVERVIEW": {
            section.content.hasChildren = data.focus.hasIndustries;

            break;
          }
          case "REPORT_PEER_WHERE_OVERVIEW": {
            break;
          }
          case "REPORT_PEER_TCR":
          case "REPORT_PEER_WHAT_TCR_CHANGES":
          case "REPORT_PEER_WHAT_AB_CHANGES":
          case "REPORT_PEER_WHAT_UPGRADES_DOWNGRADES":
          case "REPORT_PEER_WHERE_TCR_CHANGES":
          case "REPORT_PEER_WHERE_AB_CHANGES":
          case "REPORT_PEER_WHERE_UPGRADES_DOWNGRADES": {
            section.content.timeframe = data.timeframe;

            break;
          } // no default
        }
      }
      // user preferences
      var user = envSetup["account"]["user"];
      var userPreference = _getUserPreference(user, template.configuration);
      // wysiwygState
      var wysiwygState = _getUiState(
        "analysisMarkets",
        { target: peer },
        environment,
        t
      )?.wysiwygState;

      wysiwygState.storage = {
        peers: storage,
      };

      return {
        sections: executableSections,
        template: template,
        userPreference: userPreference,
        wysiwygState: wysiwygState,
      };
    },
    [envSetup, environment, storage, t]
  );

  const printHandler = useCallback(async () => {
    setIsGeneratingPdf(true);
    const data = deepClone(state);
    let peerBasicInfo = {
      zDimension: fixPeerSize(data.size),
      type: "Stock",
      what: data.focus.what.value,
      where: data.where.value,
    };
    const paramsPeer = {
      instrumentType: peerBasicInfo["type"].toLowerCase(),
      peerId: encodePeerId(peerBasicInfo),
    };
    let response = await peersAPI.get([[peerBasicInfo]] as any);
    response = _setName(paramsPeer, response[0])[0];
    //-----------

    let target = response; // used by _getUiState()

    // Region are formatted in a special way, with the
    // parent Area combined in the name.
    // Needs to have what value as ICB
    if (data.focus.what.value === "ICB" && data.where.type === "Region") {
      let taxonomies: any = envSetup["taxonomies"];
      let fieldMap: any = envSetup["taxonomyFields"];

      const taxonomy = taxonomies[fieldMap["security"]["country"]];

      target["name"] = formatTaxonPrefixingParent(
        getTaxonById(data.where.value, [taxonomy], 0),
        [taxonomy],
        "Region"
      );
    }

    var printParams = _preparePrintParams(data, target);

    var environmentSetup = environment.get("setup");
    var _data = new Collector(environmentSetup, {
      rankingCache: null,
      // rankingType: null,
      sections: deepClone(printParams.sections),
      template: deepClone(printParams.template),
      userPreference: printParams.userPreference,
      wysiwygState: printParams.wysiwygState,
    });

    _data.retrieve().then(
      ({ response, _wysiwygState }) => {
        var report = new Generator(environmentSetup, {
          data: response,
          formatter: formatter,
          sections: deepClone(printParams.sections),
          template: deepClone(printParams.template),
          userPreference: printParams.userPreference,
          wysiwygState: _wysiwygState,
        });

        return report
          .create()
          .print()
          .then(
            (response) => {
              var downloader = new Downloader(report);
              downloader.download(response);
              setIsGeneratingPdf(false);
            },
            (error) => {
              setIsGeneratingPdf(false);
              const [channel, msg] = messageError("Something went wrong.");
              broadcast(channel as string, msg);
              throw new Error(error);
            }
          );
      },
      (error) => {
        const [channel, msg] = messageError("Something went wrong.");
        broadcast(channel as string, msg);
        setIsGeneratingPdf(false);
        throw new Error(error);
      }
    );
  }, [
    _preparePrintParams,
    _setName,
    broadcast,
    envSetup,
    environment,
    formatter,
    peersAPI,
    state,
  ]);

  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  return (
    <Modal
      closeIcon={false}
      closeOnClickAway={false}
      headerConfig={{
        headerContent: (
          <Typography variant="subtitle1"> Create a PDF report</Typography>
        ),
      }}
      buttonsEnalbed
      buttons={[
        {
          name: "Print",
          callback: printHandler,
          class: ["tFormButton--primary", "tFormButton"],
        },
        { name: "Cancel", callback: closeReportModal, variant: "cancel" },
      ]}
      onClose={closeReportModal}
    >
      {isGeneratingPdf && (
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={1}
          justifyContent={"center"}
        >
          <CircularProgress size={"0.9vw"} />
          <Typography>Generating PDF...</Typography>
        </Box>
      )}
      {!isGeneratingPdf && (
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Markets
            where={state.where as any}
            onChangeType={onChangeTypeHandler_markets}
            onSelect={onSelectHandler_markets}
            size={state.size}
            onSetSize={setSizeHandler_markets}
          />
          <Focus
            marketType={state.where.type as any}
            focusOn={state.focus.focusOn as any}
            focusWhat={state.focus.what as any}
            hasIndustries={state.focus.hasIndustries}
            onSetFocusOn={onSetFocusOnHandler_focus}
            onSetHasIndustries={onSetHasIndustriesHandler_focus}
            onSetFocusWhat={onSetFocusWhatHandler_focus}
          />
          <Card>
            <CardContent sx={{ p: 1, pb: "8px !important" }}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={1}
              >
                <Typography variant="tr_subtitle_blue">
                  TCR and AB% changes, Up/Downgrades
                </Typography>
                <ButtonGroup>{TRABButtons}</ButtonGroup>
              </Box>
            </CardContent>
          </Card>
          <PerformanceDispersion
            intervals={state.dispersion.intervals as any}
            timeframe={state.dispersion.timeframe as any}
            onsSetIntervals={(interval) =>
              dispatch({ type: "SET_DISPERSION_INTERVALS", payload: interval })
            }
            onSetTimeframe={(timeframe) =>
              dispatch({ type: "SET_DISPERSION_TIMEFRAME", payload: timeframe })
            }
          />
          <ReportType
            type={state.reportType as any}
            onSetType={(type) =>
              dispatch({ payload: type, type: "SET_REPORT_TYPE" })
            }
          />
        </Box>
      )}
    </Modal>
  );
}

const fixPeerSize = (value) => {
  if (value == null) {
    return "microLarge";
  }

  if (value === "microLarge") {
    return null;
  }

  return value;
};

const _getFocusOn = (wizardParams) => {
  var focusOn: any = null;
  switch (wizardParams.focus.focusOn) {
    case "Area":
    case "Country":
    case "Region": {
      focusOn = "where";

      break;
    }
    case "1 Industry":
    case "3 Sector": {
      focusOn = "what";
      break;
    }
    default: {
      break;
    }
  }

  return focusOn;
};

const _getTemplate = (wizardParams) => {
  var reportFocus = _getFocusOn(wizardParams);
  var reportType: any = null;
  switch (wizardParams.reportType) {
    case "detailed": {
      reportType = "detailed";
      break;
    }
    case "overview": {
      reportType = "overview";
      break;
    }
    default: {
    }
  }
  if (reportFocus == null || reportType == null) {
    throw new Error("Error selecting template");
  }
  var reportKey = reportType + "_" + reportFocus;
  var templates = defaultTemplateReportPeerWizard;
  return templates[reportKey];
};
