import { Box, Typography } from "@mui/material";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useImmerReducer } from "use-immer";
import { handleTitle } from "../../../../../../../Utility/DocumentTitleHanlder";
import { deepClone } from "../../../../../../../deepClone";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { StrategiesStorage } from "../../../../../storage/StrategiesStorage";
import StrategyNavigator from "../../../widgets/header/StrategyNavigator";
import Help from "../../help/Help";
import Result from "../Advanced/Result/Result";
import { SelectUniverse } from "./SelectUniverse";
import SmartBetaHoldingsRules from "./SmartBetaHoldingsRules";
import SmartBetaRules from "./SmartBetaRules";
import Tracking from "./Tracking";
import {
  editorReducer,
  smartBetaDefaultState,
  universeTabConfigHelper,
} from "./editorReducer";
import { Dashboards } from "../Advanced/Result/tabs/Insights/Insights";

type SmartBetaProps = {
  readonly strategy: any;
  strategyNavigatorProperties: any;
  runResults: any;
  runManager?: StrategiesStorage;
  cleanup?: Function;
  dashboard: Dashboards;
};

const SmartBeta = forwardRef(
  (
    {
      strategy,
      strategyNavigatorProperties,
      runResults,
      runManager,
      cleanup,
      dashboard,
    }: SmartBetaProps = {
      strategy: null,
      strategyNavigatorProperties: null,
      runResults: null,
      dashboard: { V1: undefined, V2: undefined, status: "loading" },
    },
    ref
  ) => {
    const [smartBetaTabIndex, setSmartBetaTabIndex] = useState(() => {
      if (strategy.params != null) {
        if (strategy.params.universe.screening != null) {
          return 1;
        } else {
          return 0;
        }
      }
      return 0;
    });
    const [smartBetaState, smartBetaDispatch] = useImmerReducer(
      editorReducer,
      universeTabConfigHelper(smartBetaDefaultState, strategy.params)
    );

    const environment = useEnvironment();
    const strategyBuilderConfig = useMemo(() => {
      return environment.get("account").product?.configuration?.strategy_builder
        .form_smart_beta;
    }, [environment]);

    const helpEnabled = useMemo(() => {
      return strategyBuilderConfig?.resultTab?.insights ?? false;
    }, [strategyBuilderConfig?.resultTab?.insights]);

    useEffect(() => {
      if (strategy) {
        handleTitle(strategy);
      }
    }, [strategy]);

    const onTabSelectHandler = (index) => {
      setSmartBetaTabIndex(index);
    };

    useEffect(() => {
      smartBetaDispatch({
        type: "LOAD_STRATEGY",
        payload: universeTabConfigHelper(
          smartBetaDefaultState,
          strategy.params
        ),
      });
    }, [smartBetaDispatch, strategy.params]);

    /**
     * This method clean the state object from unecessary keys used to track
     * the internal state of the component. Thoose keys were added to the
     * input object and are to be deleted to set the correct output state.
     *
     * @returns state object
     */
    const prepareStateForRun = useCallback(() => {
      let stateClone: any = deepClone(smartBetaState);
      const basket = 0;
      const peerGroup = 1;

      if (
        smartBetaTabIndex === basket &&
        smartBetaState.universe.screening != null
      ) {
        stateClone.universe.screening = null;
      }
      if (
        smartBetaTabIndex === peerGroup &&
        smartBetaState.universe.whiteList != null
      ) {
        stateClone.universe.whiteList = null;
      }

      return stateClone;
    }, [smartBetaState, smartBetaTabIndex]);

    /**
     * This method  syncronize the internal state with the global state
     */
    // useEffect(() => {
    //   if (stateHandler == null) {
    //     return;
    //   }
    //   stateHandler(prepareStateForRun());
    // }, [prepareStateForRun, smartBetaState, smartBetaTabIndex, stateHandler]);

    useImperativeHandle(
      ref,
      () => ({
        getState: () => prepareStateForRun(),
        getDispatcher: () => smartBetaDispatch,
      }),
      [prepareStateForRun, smartBetaDispatch]
    );

    return (
      <Box display={"flex"} height={"100%"} overflow={"hidden"}>
        <Box width={"30%"} p={1} display={"flex"} flexDirection="column">
          <Box display={"flex"} gap={1} alignItems="center" pb={1}>
            {strategy == null ? (
              <Typography variant="subtitle1">Smart Beta Strategy</Typography>
            ) : (
              <Typography variant="subtitle1">{strategy?.name}</Typography>
            )}
            <StrategyNavigator
              strategyType={strategyNavigatorProperties?.strategyType}
              listenerChangeStrategy={
                strategyNavigatorProperties?.listenerChangeStrategy
              }
            />
          </Box>
          <Box overflow={"auto"}>
            <fieldset className="form__fieldset form__fieldset--builder">
              <legend className="form__legend form__legend--builder">
                <span className="builder__step">1</span> Universe
              </legend>
              <ul className="form__field-list">
                <li className="form__field">
                  <SelectUniverse
                    params={smartBetaState}
                    dispatch={smartBetaDispatch}
                    onSelect={onTabSelectHandler}
                    selectedIndex={smartBetaTabIndex}
                  />
                </li>
              </ul>
            </fieldset>
            <SmartBetaHoldingsRules
              state={smartBetaState}
              dispatch={smartBetaDispatch}
              more={smartBetaState?.more?.holdingRules}
            />
            <SmartBetaRules
              state={smartBetaState}
              dispatch={smartBetaDispatch}
              more={smartBetaState?.more?.smartBetaRules}
            />
            <Tracking
              more={smartBetaState?.more?.tracking}
              benchmark={smartBetaState?.strategy?.benchmark}
              strategy={smartBetaState.strategy}
              currency={smartBetaState?.strategy?.currency ?? "local"}
              hedging={smartBetaState?.hedging}
              backtesting={smartBetaState?.backtesting}
              dispatch={smartBetaDispatch}
            />
          </Box>
        </Box>
        <Box width={"70%"}>
          {runResults == null ? (
            <Help strategyType={strategyNavigatorProperties?.strategyType} />
          ) : (
            <Result
              dashboard={dashboard}
              helpEnabled={helpEnabled}
              cleanup={cleanup}
              runManager={runManager!}
              value={runResults}
            />
          )}
        </Box>
      </Box>
    );
  }
);

export default SmartBeta;
