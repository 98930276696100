import Highcharts from "highcharts/highstock";
import HighchartsExporting from "highcharts/modules/exporting";
import { deepClone } from "../../../deepClone";
import { TDate } from "../../../trendrating/date/TDate";
import { _rate } from "../../../trendrating/formatter/_rate";
import { Property } from "../../../types/Api";

HighchartsExporting(Highcharts);

/**
 * Cut data after the final date
 *
 * @param {Number} finalDate - the custom date since the analitycs must
 *      finish. Date is in Trendrating format
 * @param {array} serie - the serie to be cutted
 */
export function getDataSinceFinalDate(finalDate, serie) {
  const serieLength = serie.length;

  if (finalDate == null || finalDate > serie[serieLength - 1]["d"]) {
    return serie;
  }

  const isTooBack = finalDate < serie[0]["d"];
  const isTooForward = finalDate > serie[serieLength - 1]["d"];
  if (isTooBack || isTooForward) {
    return serie;
  }

  const cuttedData: any = [];
  for (let i = 0; i < serieLength; i++) {
    const datum = serie[i];

    if (datum["d"] > finalDate) {
      continue;
    }

    cuttedData.push(datum);
  }

  return cuttedData;
}

/**
 * Cut data previous the starting / launch date
 *
 * @param {Number} launchDate - the custom date since the analitycs must
 *      begin. Date is in Trendrating format
 * @param {array} serie - the serie to be cutted
 */
export function getDataSinceLaunchDate(launchDate, serie) {
  if (launchDate == null || launchDate < serie[0]["d"]) {
    return serie;
  }

  const serieLength = serie.length;

  const isTooBack = launchDate < serie[0]["d"];
  const isTooForward = launchDate > serie[serieLength - 1]["d"];
  if (isTooBack || isTooForward) {
    return serie;
  }

  const cuttedData: any = [];
  for (let i = 0; i < serieLength; i++) {
    const datum = serie[i];

    if (datum["d"] < launchDate) {
      continue;
    }

    cuttedData.push(datum);
  }

  return cuttedData;
}

export function getStrategyFinalDate(template) {
  let lastDate =
    template?.configuration?.integration?.strategy?.lastDate ?? null;

  return lastDate;
}

export function getStrategyLaunchDate(template) {
  const launchDate =
    template?.configuration?.integration?.strategy?.date ?? null;
  return launchDate;
}

export function getSymbolWeight(positions) {
  const data: any = [];

  for (let i = 0, length = positions.length; i < length; i++) {
    const security = positions[i];
    data.push({
      symbol: security.symbol,
      weight: security.weight,
    });
  }

  return data;
}

// merge snapshot (allocationAt.positions) with allocation (contributions) data
export function prepareAllocationAtMap(responseCompute) {
  return responseCompute.systematic.allocationAt.positions.reduce(
    (acc, item) => {
      acc[item.symbol] = deepClone(item);
      if ("weight" in acc[item.symbol]) {
        delete acc[item.symbol]["weight"];
      }
      return acc;
    },
    {}
  );
}

export function prepareSnapshotAtMap(responseCompute) {
  return responseCompute.systematic.snapshot.positions.reduce((acc, item) => {
    acc[item.symbol] = deepClone(item);
    return acc;
  }, {});
}

/**
 *
 * @param {Number} launchDate - the custom date since the analitycs must
 *      begin. Date is in Trendrating format
 * @param {Number} finalDate - the custom date since the analitycs must
 *      finish. Date is in Trendrating format
 * @param {array} H - the price level curve
 * @param {POS} POS - the historical allocations
 * @param {array} H - the price level curve of the Benchmark
 *
 * @returns {object} - customized strategy run
 */
export function prepareCustomStrategyAnalitycs(
  launchDate,
  finalDate,
  H,
  POS,
  B?
) {
  const result: any = {
    launchDate: null,
    finalDate: null,
    strategyRun: null,
  };

  // default launchDate
  result["launchDate"] = H[0]["d"];
  // default finalDate
  result["finalDate"] = H[H.length - 1]["d"];

  // Long short does not return any POS
  if (POS == null || POS.length < 2) {
    result["strategyRun"] = { data: { CURVES: { H }, POS } };

    if (B) {
      result["strategyRun"]["data"]["CURVES"]["B"] = B;
    }

    return result;
  }

  // Prepare initial data
  result["strategyRun"] = { data: { CURVES: { H }, POS } };

  // If the benchmark price level exists add it to the initial data
  if (B) {
    result["strategyRun"]["data"]["CURVES"]["B"] = B;
  }

  /**
   * Custom starting / launch date
   *
   * It will be adjusted if there is no POS elements: we need to keep
   * at least two item.
   *
   * The launchDate will be adjusted to the
   * one-to-last element.
   *
   * Also strategyRun.data.POS.length needs to be at least 2
   */
  if (launchDate != null && result["strategyRun"].data.POS.length > 1) {
    // ----------------------------------- Cut POS, used in turnover
    let newPOS = getDataSinceLaunchDate(
      launchDate,
      result["strategyRun"].data.POS
    );
    result["strategyRun"].data.POS = newPOS;

    // -------------------- Adapt strategyRun: history and benchmark
    let newH = getDataSinceLaunchDate(
      launchDate,
      result["strategyRun"].data["CURVES"]["H"]
    );
    result["strategyRun"].data["CURVES"]["H"] = newH;

    if (newH.length < 2) {
      console.warn("Not enough data");
    }
    // Update launchDate if there is at least one
    if (newH.length > 0) {
      result["launchDate"] = newH[0]["d"];
    }

    if (
      "B" in result["strategyRun"].data["CURVES"] &&
      result["strategyRun"].data["CURVES"]["B"].length
    ) {
      let newB = getDataSinceLaunchDate(
        launchDate,
        result["strategyRun"].data["CURVES"]["B"]
      );
      result["strategyRun"].data["CURVES"]["B"] = newB;
    }
  }

  // Same as before, but for last date
  if (finalDate != null && result["strategyRun"].data.POS.length > 1) {
    // ----------------------------------- Cut POS, used in turnover
    let newPOS = getDataSinceFinalDate(
      finalDate,
      result["strategyRun"].data.POS
    );
    result["strategyRun"].data.POS = newPOS;

    // -------------------- Adapt strategyRun: history and benchmark
    let newH = getDataSinceFinalDate(
      finalDate,
      result["strategyRun"].data["CURVES"]["H"]
    );
    result["strategyRun"].data["CURVES"]["H"] = newH;

    if (
      "B" in result["strategyRun"].data["CURVES"] &&
      result["strategyRun"].data["CURVES"]["B"].length
    ) {
      let newB = getDataSinceFinalDate(
        finalDate,
        result["strategyRun"].data["CURVES"]["B"]
      );
      result["strategyRun"].data["CURVES"]["B"] = newB;
    }
  }

  return result;
}

export function prepareParams(
  wysiwygState,
  section,
  responseCompute,
  computing,
  hasStrategyFinalDate,
  strategyFinalDate
) {
  let list: any /*[] | null*/ = null;
  if (wysiwygState.targetType === "LIST") {
    list = wysiwygState.target;
  } else if (wysiwygState.targetType === "SYSTEMATIC") {
    list = deepClone(responseCompute["systematic"]["allocationAt"]);

    //
    // 2020-09-01
    //
    // need to merge contribution/performance data in order
    // to be used for injestion in case of sort
    //
    const contributions = computing["allocationAt"];
    const holdings = list!["positions"];
    for (let i = 0; i < holdings.length; i++) {
      holdings[i] = {
        ...holdings[i],
        ...contributions[holdings[i]["symbol"]],
      };
    }

    //
    // 2020-12-30
    //
    // merge correct weight
    //
    let snapshot = computing["snapshotAt"];
    for (let i = 0; i < holdings.length; i++) {
      if (snapshot[holdings[i]["symbol"]] != null) {
        holdings[i] = {
          ...holdings[i],
          ...snapshot[holdings[i]["symbol"]],
        };
      }
    }
  } else if (wysiwygState.targetType === "STRATEGY") {
    list = {};
    list!["positions"] = responseCompute["strategy"]["holdings"];
    list!["positionsIndex"] = responseCompute["strategy"]["holdings"].reduce(
      (prev, current, index) => {
        prev[current["symbol"]] = index;
        return prev;
      },
      {}
    );
  }
  const prepared: any = {
    columns: [],
    params: {
      constraints: null,
      list: list,
      pagination: {
        page: 1,

        // TODO - (to be refactored) is overwritten by
        // _RankingBase
        rows: null,
      },
      sortBy: null,
    },
    properties: [],
  };

  if (hasStrategyFinalDate && strategyFinalDate != null) {
    prepared.params["date"] = TDate.daysToIso8601(strategyFinalDate);
  }

  // It can be null, like holdings chart
  if (section.content != null) {
    // constraints
    if (section["presentation"]["useWysiwyg"]) {
      prepared.params["constraints"] = deepClone(
        wysiwygState["actions"]["constraints"]
      );
    } else {
      if (section.content["constraints"] != null) {
        prepared.params["constraints"] = section.content["constraints"];
      }
      // rating
      if (section.content["rate"] != null) {
        const rateA = section.content["rate"]["A"];
        const rateB = section.content["rate"]["B"];
        const rateC = section.content["rate"]["C"];
        const rateD = section.content["rate"]["D"];
        // If there is at least one active
        if (rateA || rateB || rateC || rateD) {
          if (prepared.params["constraints"] == null) {
            prepared.params["constraints"] = {};
          }
          if (!("ranges" in prepared.params["constraints"])) {
            prepared.params["constraints"]["ranges"] = [];
          }

          const rating: any = [];
          if (rateA) {
            rating.push({
              max: 2,
              min: 2,
            });
          }
          if (rateB) {
            rating.push({
              max: 1,
              min: 1,
            });
          }
          if (rateC) {
            rating.push({
              max: -1,
              min: -1,
            });
          }
          if (rateD) {
            rating.push({
              max: -2,
              min: -2,
            });
          }

          // Filter ranges and exlude old one

          const newRanges: any = [];
          for (
            let i = 0;
            i < prepared.params["constraints"]["ranges"].length;
            i++
          ) {
            const range = prepared.params["constraints"]["ranges"][i];
            if (range.dimension === "rc") {
              continue;
            }
            newRanges.push(range);
          }

          newRanges.push({
            dimension: "rc",
            segments: rating,
          });

          prepared.params["constraints"]["ranges"] = newRanges;
        }
      }
    }

    // top
    if (section["presentation"]["useWysiwyg"]) {
      // Do not set any "top" value
    } else {
      if (
        section.content["top"] != null &&
        section.content["top"]["isEnabled"]
      ) {
        prepared.params["pagination"].rows = parseInt(
          section.content["top"]["content"],
          10
        );
      }
    }
    // sort by
    if (section["presentation"]["useWysiwyg"]) {
      prepared.params["sortBy"] = deepClone(wysiwygState["actions"]["sortBy"]);
    } else {
      if (section.content["sortBy"] != null) {
        prepared.params["sortBy"] = section.content["sortBy"];
      }

      // Overrides sorting
      if (
        section["presentation"]["rank"] != null &&
        section["presentation"]["sortByRank"] != null
      ) {
        if (
          section["presentation"]["rank"] &&
          section["presentation"]["sortByRank"]
        ) {
          // Check if there is a rank active
          // If not, just disable it
          let hasRank = false;
          for (let i = 0; i < wysiwygState["columns"].length; i++) {
            if (/^rank/.test(wysiwygState["columns"][i]["property"])) {
              hasRank = true;
              break;
            }
          }

          if (hasRank) {
            // Only set forced rank if there is at least one
            // rank column
            prepared.params["sortBy"] = {
              property: "rank",
              descending: false,
            };
          } else {
            // TODO does the data needs to maintain a status
            // for having the ranking mode disabled?
          }
        }
      }
    }
  }

  // Filtered columns to use in later calls and generator instead of
  // section["presentation"]["columns"]
  let columns: any = [];
  let properties: Property[] = [];

  if (section["presentation"]["useWysiwyg"]) {
    columns = deepClone(wysiwygState["columns"]) ?? [];
    for (let i = 0; i < columns.length; i++) {
      // Adjust date of all fields is there is a preselected date
      let date = null;
      if (prepared.params["date"] != null) {
        date = prepared.params["date"];
      }

      properties.push({
        date: date,
        property: columns[i]["property"],
      });
    }
  } else {
    const hasRank = section["presentation"]["rank"];
    const sectionColumns = section["presentation"]["columns"];

    for (let i = 0; i < sectionColumns.length; i++) {
      if (!hasRank && /^rank/.test(sectionColumns[i]["property"])) {
        // Ignore rank fields if rank is not enabled
        continue;
      }

      // Adjust date of all fields is there is a preselected date
      let date = null;
      if (prepared.params["date"] != null) {
        date = prepared.params["date"];
      }

      properties.push({
        date: date,
        property: sectionColumns[i]["property"],
      });

      columns.push(sectionColumns[i]);
    }
  }

  switch (section.type) {
    case "REPORT_COMMON_SECURITY_CHART": {
      // Limit number of chart so the server does not crash
      if (prepared.params["pagination"].rows != null) {
        prepared.params["pagination"].rows = Math.min(
          prepared.params["pagination"].rows,
          125
        );
      } else {
        prepared.params["pagination"].rows = 125;
      }
      prepared["properties"] = properties;

      break;
    }
    case "REPORT_COMMON_SECURITY_TABLE": {
      prepared["columns"] = columns;
      prepared["properties"] = properties;

      break;
    }
    default:
      console.log("Missing type " + section.type);
      break;
  }

  return prepared;
}

/**
 * Prepare data for Systematic portfolio
 *
 * @param {array<object>} curveH - price list curve
 * @param {array<object>} curveB - price benchmark curve
 * @param {number} launchDate - the date where data starts
 * @param {number} finalDate - the date where data finish
 */
export function prepareSystematicPortfoliosCurves(
  curveH,
  curveB,
  launchDate,
  finalDate
) {
  launchDate = launchDate == null ? curveH[0]["d"] : launchDate;
  finalDate = finalDate == null ? curveH[curveH.length - 1]["d"] : finalDate;

  const prepared = {
    B: null,
    H: [],
    launchDate: launchDate,
    finalDate: finalDate,
  };

  // Adapt strategyRun
  prepared["H"] = getDataSinceLaunchDate(launchDate, curveH);
  if (prepared["H"].length < 2) {
    console.warn("Not enough data");
  }

  prepared["H"] = getDataSinceFinalDate(finalDate, prepared["H"]);
  if (prepared["H"].length < 2) {
    console.warn("Not enough data");
  }

  // Update launchDate if there is at least one
  if (prepared["H"].length > 0) {
    prepared["launchDate"] = prepared["H"][0]["d"];
    prepared["finalDate"] = prepared["H"][prepared["H"].length - 1]["d"];
  }

  if (curveB != null && curveB.length) {
    prepared["B"] = getDataSinceLaunchDate(launchDate, curveB);
    prepared["B"] = getDataSinceFinalDate(finalDate, prepared["B"]);
  }

  return prepared;
}

/**
 *
 * @param {array} data
 * @param {object} boundaries
 * @param {number} translationFactor
 */
export function fromJsonToSerieAndMinMax(
  data,
  boundaries,
  translationFactor?: number
) {
  const serie: any = [];
  for (let i = 0, length = data.length; i < length; i++) {
    const vy = parseFloat(data[i]["v"]);
    if (vy > boundaries.max) {
      boundaries.max = vy;
    }
    if (vy < boundaries.min) {
      boundaries.min = vy;
    }
    serie.push([
      // the date
      TDate.daysToMilliseconds(data[i]["d"]),
      // close
      translationFactor != null ? vy / translationFactor : vy,
    ]);
  }
  return serie;
}

/**
 *
 * @param {object}  params
 * @param {boolean} params.legend
 * @param {boolean} params.logAxis
 * @param {string}  params.title
 * @param {object}  strategy
 * @param {object}  data
 */
export function strategyChartSvg(params, strategy, data) {
  const chartNode = document.createElement("div");
  chartNode.style.width = "632px";
  document.body.appendChild(chartNode);
  const pboundaries = { max: 0, min: 999999 };
  let price;
  let benchmark;
  const colors = {
    border: "#d3d3d3",
    lineGrid: "#d3d3d3",
    line: "#d3d3d3",
    securityDefault: "#2a7092", // #2F7ED8
    securityBenchmark: "#ffcc00", // #133458
    securityRateA: _rate.colors.A,
    securityRateB: _rate.colors.B,
    securityRateC: _rate.colors.C,
    securityRateD: _rate.colors.D,
    securityRateU: _rate.colors.U,
    trendrating: "#2a7092", // #ffc001 #0da760,
    trendratingGold: "#ffc001",
    trendratingGrey: "#a3a3a3",
  };

  if (
    data.CURVES.hasOwnProperty("B") &&
    data.CURVES.B !== null &&
    data.CURVES.B.length > 0
  ) {
    price = fromJsonToSerieAndMinMax(data.CURVES.H, pboundaries);

    let indexOfFirstBenchmarkDay = 0;
    for (let i = 0, length = data.CURVES.H.length; i < length; i++) {
      if (data.CURVES.H[i].d >= data.CURVES.B[0].d) {
        indexOfFirstBenchmarkDay = i;
        break;
      }
    }

    const _translationFactor =
      data.CURVES.B[0].v / price[indexOfFirstBenchmarkDay]["1"];
    benchmark = fromJsonToSerieAndMinMax(
      data.CURVES.B,
      pboundaries,
      _translationFactor
    );
  } else {
    price = fromJsonToSerieAndMinMax(data.CURVES.H, pboundaries);
  }

  const axys: Highcharts.YAxisOptions[] = [
    {
      lineColor: colors["lineGrid"],
      lineWidth: 1,
      type: params.logAxis ? "logarithmic" : "linear",
      minPadding: 0,
      maxPadding: 0,
      startOnTick: false,
      endOnTick: false,
      opposite: false,
      showEmpty: false,
      title: {
        text: null,
      },
    },
    {
      lineColor: colors["lineGrid"],
      lineWidth: 1,
      type: params.logAxis ? "logarithmic" : "linear",
      minPadding: 0,
      maxPadding: 0,
      startOnTick: false,
      endOnTick: false,
      opposite: false,
      showEmpty: false,
      title: {
        text: null,
      },
    },
  ];

  const series: any = [];
  const sPrice = {
    id: "price",
    name: strategy.name,
    data: price,
    yAxis: 0,
    color: colors["trendrating"],
    index: 0,
    lineWidth: 2,
    marker: { enabled: false },
    showInLegend: true,
    tooltip: {
      valueDecimals: 2,
    },
  };
  series.push(sPrice);

  if (params.benchmark) {
    const sBenchmark = {
      id: "benchmark",
      name: params.benchmark["name"],
      data: benchmark,
      yAxis: 0,
      color: colors["securityBenchmark"],
      index: 1,
      lineColor: colors["securityBenchmark"],
      lineWidth: 2,
      marker: { enabled: false },
      showInLegend: true,
      tooltip: {
        valueDecimals: 2,
      },
    };
    series.push(sBenchmark);
  }

  const chart = new Highcharts.StockChart({
    chart: {
      alignTicks: false,
      animation: false,
      height: 474,
      borderRadius: 0,
      borderWidth: 0,
      renderTo: chartNode,
      type: "line",
      width: 750,
      zoomType: "x",
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: params["legend"],
      itemHiddenStyle: {
        color: "#000",
        textDecoration: "none",
      },
      itemStyle: {
        fontSize: "9px",
        color: "#000",
        textDecoration: "underline",
      },
      symbolWidth: 10,
    },
    rangeSelector: { enabled: false },
    navigator: { enabled: false },
    credits: { enabled: false },
    title: {
      text: params["title"],
    },
    tooltip: { enabled: false },
    scrollbar: { enabled: false },
    plotOptions: {
      arearange: {
        fillOpacity: 0.2,
      },
    },
    yAxis: axys,
    series: series,
  });

  const svg = chart.getSVG();
  chart.destroy();

  // chartNode will go out of scope so no need to also set it to null
  document.body.removeChild(chartNode);

  return svg;
}
