/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/Utils
 * @summary Utilities requests
 *
 */

import { ServerToday, StoredObjectPubSubType } from "../../types/Api";
import { endpoints } from "../endpoints";
import { _Base } from "../_Base";
import { modelObjectTypes } from "./modelObjects/modelObjectTypes";
import { SHARED_OBJECT_TYPE_UI_TO_SERVER } from "./_CommonPublicationsSubscriptions";

export class Utils extends _Base {
    static readonly ACTIONS_UI_TO_SERVER = {
        remove: "delete",
        unsubscribe: "unsubscribe",
    } as const;

    relations({
        action,
        item,
    }: {
        action: keyof typeof Utils.ACTIONS_UI_TO_SERVER;
        item: StoredObjectPubSubType;
    }) {
        var endPointRoot = this.getEndpointRoot(this.environment.api.compute);
        var url = endPointRoot + endpoints.utils.relations;

        var serverAction = Utils.ACTIONS_UI_TO_SERVER[action];
        var user = this.environment.account.user;
        var _params = {
            action: serverAction,
            customer: user?.id,
            objectId: item.id,
            objectType:
                item.isSubscribed === true
                    ? SHARED_OBJECT_TYPE_UI_TO_SERVER[item.pubSubType]
                    : modelObjectTypes.sharedObjects[item.type],
        };

        return this.preparePost(url, _params, null);
    }

    /**
     * @typedef  {object} Today
     * @property {number} today - Today as Trendrating days
     */

    /**
     * Get the "today" of the server (regarding data freshness)
     *
     * @returns {Today}
     */
    today(): Promise<ServerToday> {
        var endPointRoot = this.getEndpointRoot(this.environment.api.compute);
        var url = endPointRoot + endpoints["utils"]["today"];

        return this.prepareGet(url, null, null);
    }

    version() {
        var endPointRoot = this.getEndpointRoot(
            this.environment["api"]["rest"]
        );
        var url = endPointRoot + endpoints["utils"]["versions"];

        return this.prepareGet(url, null, null);
    }
}
