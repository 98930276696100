/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/widgets/app-infrastructure/workflowBar/actions/remove/Remove
 * @summary Workflow action: Remove an item from the application
 */

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../../../../../components/Modal/Modal";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { RemoveResource } from "./RemoveResource";
import { v4 as uuidv4 } from "uuid";

type RemoveProps = {
  item: any;
  label?: string;
  onRemoveDone: (event) => void;
  onCancel?: () => void;
  isUnsubscribe?: boolean;
};

type DialogRelationsProps = {
  close: () => void;
  itemToDelete: any;
  onRemoveDone: (value: any) => void;
  isUnsubscribe: boolean;
  onCancel?: () => void;
};

export function Remove({
  item,
  onRemoveDone,
  label,
  onCancel,
  isUnsubscribe = false,
}: RemoveProps) {
  const [showRelationsDialog, setShowRelationsDialog] = useState(false);
  const { t } = useTranslation();

  const doAction = useCallback(() => {
    setShowRelationsDialog(true);
  }, []);

  const closeRelationsDialog = useCallback(() => {
    setShowRelationsDialog(false);
  }, []);

  const actionTitle = useMemo(() => {
    let title = t("Remove");

    if (label) {
      title = label;
    } else if (isUnsubscribe) {
      title = t("Unsubscribe");
    }

    return title;
  }, [isUnsubscribe, label, t]);

  return (
    <>
      {showRelationsDialog && (
        <DialogRelations
          onRemoveDone={onRemoveDone}
          itemToDelete={item}
          close={closeRelationsDialog}
          isUnsubscribe={isUnsubscribe}
          onCancel={onCancel}
        />
      )}
      <li className="menu__item menu__item--delete" onClick={doAction}>
        {actionTitle}
      </li>
    </>
  );
}

export const DialogRelations = ({
  close,
  itemToDelete,
  onRemoveDone,
  isUnsubscribe,
  onCancel,
}: DialogRelationsProps) => {
  const [relations, setRelations] = useState<any>();
  const [readyToRemove, setReadyToRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const environment = useEnvironment();
  const setup = useMemo(() => environment.get("setup"), [environment]);

  const renderRelations = useMemo(
    () => relations != null && relations.length > 0,
    [relations]
  );

  const wrapRemoveDone = useCallback(() => {
    close();
    onRemoveDone({ value: itemToDelete });
  }, [close, itemToDelete, onRemoveDone]);

  const remove = useCallback(async () => {
    const isDryRun = false;
    const removeAPI = new RemoveResource(setup, itemToDelete, isDryRun);
    await removeAPI.remove();
    wrapRemoveDone();
  }, [itemToDelete, setup, wrapRemoveDone]);

  const startRemoveWorflow = useCallback(async () => {
    setIsLoading(true);
    try {
      const isDryRun = true;
      const removeAPI = new RemoveResource(setup, itemToDelete, isDryRun);
      const result = await removeAPI.remove();

      if ("relations" in result) {
        var relations = result["relations"];

        if (relations.length > 0) {
          // this.dialogRelations.set("isLoading", false);
          setRelations(relations);
        } else {
          setReadyToRemove(true);
        }
      } else {
        // the result of remove
        wrapRemoveDone();
      }
    } catch (error) {
      console.log(error);
      close();
    } finally {
      setIsLoading(false);
    }
  }, [close, itemToDelete, setup, wrapRemoveDone]);

  useEffect(() => {
    startRemoveWorflow();
  }, [startRemoveWorflow]);

  const baseClass = "tDialog";
  const cssClassRelation = baseClass + "-relation";
  const cssClassRelationName = baseClass + "-relationName";
  const cssClassRelationType = baseClass + "-relationType";

  const cancel = useCallback(() => {
    close();

    if (onCancel) {
      onCancel();
    }
  }, [close, onCancel]);

  return (
    <Modal
      headerConfig={{ hasBackground: true, headerContent: "Delete" }}
      onClose={close}
      closeIcon={false}
    >
      <div className="tDialog">
        <div
          className="tDialog-loading"
          data-dojo-attach-point="nodeLoading"
        ></div>
        {isLoading ? (
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <CircularProgress sx={{ color: "#2a7090" }} />
              <Typography>{t("Checking_relations")}</Typography>
            </Box>
          </Box>
        ) : (
          <>
            {renderRelations && (
              <div
                className="tDialog-content"
                data-dojo-attach-point="nodeContent"
              >
                <p
                  className="tDialog-message"
                  data-dojo-attach-point="nodeMessageWarning"
                  dangerouslySetInnerHTML={{
                    __html: isUnsubscribe
                      ? `Could not unsubscribe <strong>${itemToDelete.name}</strong>.`
                      : `Could not delete <strong>${itemToDelete.name}</strong>.`,
                  }}
                ></p>
                <p
                  className="tDialog-message"
                  data-dojo-attach-point="nodeMessageRelations"
                >
                  <strong>{itemToDelete.name}</strong> is used by:
                </p>
                <ul
                  className="tDialog-relations"
                  data-dojo-attach-point="nodeRelations"
                >
                  {relations.map((relation) => {
                    let type = [];

                    if (relation["isSubscription"] === true) {
                      type.push(t("Subscription"));
                    }

                    switch (relation["type"]) {
                      case "combinedProduct": {
                        type.push(t("Combined_product"));

                        break;
                      }
                      case "combinedStrategy": {
                        type.push(t("Combined_strategy"));

                        break;
                      }
                      case "rankingAboutTarget": {
                        type.push(t("Rank"));

                        break;
                      }
                      case "strategyAdvanced": {
                        type.push(t("Strategy"));
                        type.push(t("Advanced"));

                        break;
                      }
                      case "strategyAlpha": {
                        type.push(t("Strategy"));
                        type.push(t("Momentum_Alpha"));

                        break;
                      }
                      case "strategyMacroRotation": {
                        type.push(t("Strategy"));
                        type.push(t("Momentum_Macro_Rotation"));

                        break;
                      }
                      case "strategySmartBeta": {
                        type.push(t("Strategy"));
                        type.push(t("Momentum_Smart_Beta"));

                        break;
                      }
                      case "systematicPortfolio": {
                        type.push(t("Systematic_portfolio"));

                        break;
                      }
                      default: {
                        type.push(t("Unknown object"));

                        break;
                      }
                    }

                    return (
                      <li key={uuidv4()} className={cssClassRelation}>
                        <span
                          className={cssClassRelationName}
                          dangerouslySetInnerHTML={{ __html: relation["name"] }}
                        ></span>
                        <span
                          className={cssClassRelationType}
                          dangerouslySetInnerHTML={{
                            __html: "(" + type.join(" - ") + ")",
                          }}
                        ></span>
                      </li>
                    );
                  })}
                </ul>
                <p
                  className="tDialog-message"
                  data-dojo-attach-point="nodeMessageSuggestion"
                  dangerouslySetInnerHTML={{
                    __html:
                      isUnsubscribe === true
                        ? `To unsubscribe <strong>${itemToDelete.name}</strong> please edit the items above.`
                        : `To delete <strong>${itemToDelete.name}</strong> please edit the items above.`,
                  }}
                ></p>
                <div className="tDialog-buttons">
                  <Button variant="tr_button_cancel" onClick={cancel}>
                    {t("Cancel")}
                  </Button>
                </div>
              </div>
            )}
            {readyToRemove && (
              <div className={baseClass}>
                <div className="tDialog-content">
                  <p
                    data-dojo-attach-point="nodeMessage"
                    dangerouslySetInnerHTML={{
                      __html: isUnsubscribe
                        ? `Are you sure to unsubscribe <strong>${itemToDelete.name}</strong>?`
                        : `Are you sure to delete <strong>${itemToDelete.name}</strong>?`,
                    }}
                  ></p>
                </div>
                <div className="tDialog-buttons">
                  <Button
                    variant="contained"
                    onClick={remove}
                    sx={{ marginRight: "8px" }}
                  >
                    {t("Delete")}
                  </Button>
                  <Button variant="tr_button_cancel" onClick={cancel}>
                    {t("Cancel")}
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};
