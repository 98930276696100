/* eslint-disable import/no-amd */

/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/api/compute/Strategies
 * @summary Requests for strategies
 *
 */

import { deepClone } from "../../deepClone";
import { httpAll } from "../../httpAll";
import { cloneObjectLiteral } from "../../trendrating/core/UtilsObject";
import { TDate } from "../../trendrating/date/TDate";
import { Formatter } from "../../trendrating/formatter/Formatter";
import {
  ServerStrategy,
  ServerStrategyParams,
  StoredObjectType,
  Strategy,
  StrategyParams,
} from "../../types/Api";
import { AppEnvironment } from "../../types/Defaults";
import { Preferences } from "../account/Preferences";
import { endpoints } from "../endpoints";
import { addQueryParam } from "../utils";
import { _StoredObjects } from "../_StoredObjects";
import { Common } from "./Common";
import { Instruments } from "./Instruments";
import { Publications } from "./Publications";
import { RankingUi2Api } from "./RankingUi2Api";
import { SelectionUi2Api } from "./SelectionUi2Api";
import { SmartBetaUi2Api } from "./SmartBetaUi2Api";
import { StrategyTranspiler } from "./StrategyTranspiler";
import { Subscriptions } from "./Subscriptions";
import { _RationaleBase } from "./_RationaleBase";

export class Strategies extends _RationaleBase {
  configurationStrategyBuilder: any;
  http: any;
  storedObject: any;
  universeCardinalityMax = 20000;

  // TODO (hack) Static + instance class can read same variable
  static readonly SYMBOL_NEUTRAL_STRATEGY = "TRENDRATING_NEUTRAL_STRATEGY";
  readonly SYMBOL_NEUTRAL_STRATEGY = Strategies.SYMBOL_NEUTRAL_STRATEGY;

  static readonly SYMBOL_NEUTRAL_STRATEGY_EQUAL_WEIGHTED =
    "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED";
  readonly SYMBOL_NEUTRAL_STRATEGY_EQUAL_WEIGHTED =
    Strategies.SYMBOL_NEUTRAL_STRATEGY_EQUAL_WEIGHTED;

  TYPE: StoredObjectType = "PREFERENCE_INDEX";

  _peerLevelSeparator: string = "__";
  _statusPollingTimeout: any = null;

  // It overwrites _Base constructor because of extra initialization
  // operations are needed
  constructor(environment: AppEnvironment) {
    super(environment);

    this.http = {
      accountPreferences: new Preferences(environment),
      common: new Common(environment),
      instruments: new Instruments(environment),
      publications: new Publications(environment),
      subscriptions: new Subscriptions(environment),
    };

    const storedObject = new _StoredObjects(environment);
    storedObject.storedObjectType = this.TYPE;
    this.storedObject = storedObject;

    this.configurationStrategyBuilder =
      environment.configuration.get("strategyBuilder");
  }

  /**
   * Retrieves strategy analytics
   *
   * @param {object} params - request parameters
   * @param {object} params.strategy - a decoded strategy
   * @param {object} params.strategyResult - raw run result
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async analytics(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const urlAnalytics = endPointRoot + endpoints.strategies.analytics;
    const urlKeyFacts = endPointRoot + endpoints.strategies.keyFacts;

    const paramsKeyFacts = this._cleanupAllocationData(
      this._prepareParamsKeyFacts(params)
    );

    // const _paramsCompressed = {
    //     "content-encoding": "LZW",
    //     content: LZW.compress((_params))
    // };
    const _paramsCompressed = paramsKeyFacts;

    const requestAnalytics = this.preparePost(urlAnalytics, _paramsCompressed);

    const requestKeyFacts = this.preparePost(urlKeyFacts, _paramsCompressed);

    const response = await httpAll({
      details: requestAnalytics,
      aggregated: requestKeyFacts,
    });
    return this._decodeAnalytics(response);
  }

  /**
   * Runs a strategy, returns the POS / SPOS
   *
   * @param {object} params - encoded strategy
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async backtest(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.strategies.backtest;

    const response = await this.preparePost(url, params, null);

    let error: any = null;
    if (response["status"] === "KO") {
      error = this.simulateHttpError(response);
    } else if (response.data != null && response.data.WARNING != null) {
      error = this.simulateHttpError(response);
    }

    if (error != null) {
      return error;
    }

    return response;
  }

  /**
   * Combine two strategies (positions weights)
   *
   * @param {object} params
   * @param {object} params.percentage1 - weight of first strategy
   * @param {object} params.percentage2 - weight of second strategy
   * @param {object} params.positions1 - history/positions of first strategy
   * @param {object} params.positions2 - history/positions of second strategy
   * @param {object} params.percentage3 - weight of third strategy
   * @param {object} params.positions3 - history/positions of third strategy
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  combine(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.strategies.combine;

    const apiParams = {
      strategy1: {
        A: params.percentage1,
        POS: {
          v: params.positions1.map((position: any) => ({
            A: position.weight,
            S: position.symbol,
          })),
        },
      },
      strategy2: {
        A: params.percentage2,
        POS: {
          v: params.positions2.map((position: any) => ({
            A: position.weight,
            S: position.symbol,
          })),
        },
      },
      strategy3:
        params.percentage3 != null && params.positions3 != null
          ? {
              A: params.percentage3,
              POS: {
                v: params.positions3.map((position: any) => ({
                  A: position.weight,
                  S: position.symbol,
                })),
              },
            }
          : null,
    };

    return this.preparePost(url, apiParams);
  }

  /**
   * Get strategy contribution for the given allocation
   *
   * @param {object} params - contributions request parameters
   * @param {string} currency - the currency
   * @param {number} d - day
   * @param {number} de - day end
   * @param {number} i - strategy value
   * @param {number} i_g - strategy gain
   * @param {object[]} v - holdings
   * @param {number}   v[].A - weight (Allocation)
   * @param {string}   v[].D - day
   * @param {string}   v[].S - symbol
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async contributions(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.strategies.contributions;

    const response = await this.preparePost(url, params);
    return response.contribution;
  }

  async create(params: any) {
    if (params.LEGACY) {
      console.warn("Unexpected field LEGACY in data structure");
      delete params.LEGACY;
    }

    const encodedObject = deepClone(params);
    encodedObject.params = this.encode(encodedObject.params);

    const response = await this.storedObject.create(encodedObject);
    response.params = this.decode(response.params);
    return response;
  }

  /**
   * Decode server params in UI params
   */
  decode(params: ServerStrategyParams): StrategyParams {
    const decoded: any = {
      allocation: {
        weightInCashMin: 0,
        weightInCashMax: 1,
        weightCappingPeer: null,
        weightCappingSecurity: null,
      },
      backtesting: {
        inceptionDate: null,
        inceptionValue: null,
        period: null,
      },
      strategy: {
        blacklist: null,
        benchmark: null,
        currency: null,
        holdings: null,
        performance: null,
        rebalance: null,
      },
      hedging: null,
      ranking: null,
      selection: null,
      tracking: null,
      holding: null,
      universe: {
        screening: null,
        selection: null,
        trimOutliers: null,
        whiteList: null,
      },
      weighting: {
        rotation: null,
        smartBeta: null,
        weightingSchema: null,
        weightingSchemaExistingPositions: null,
      },
    };
    let _params: any = null;
    // ------------------------------------------ allocation constraints
    _params = params["strategy"]["cappingRules"];
    decoded["allocation"]["weightInCashMin"] = _params["minAllocation"];
    decoded["allocation"]["weightInCash"] = _params["minAllocation"];
    decoded["allocation"]["weightInCashMax"] = _params["maxAllocation"];

    decoded["universe"]["blacklist"] =
      params["strategy"]?.["blackList"] ?? null;

    if ("capValue" in _params || "minValue" in _params) {
      decoded["allocation"]["weightCappingSecurity"] = {
        weightCappedMax: null,
        weightCappedMin: null,
      };

      if ("capValue" in _params) {
        decoded["allocation"]["weightCappingSecurity"]["weightCappedMax"] =
          _params["capValue"];
      }
      if ("minValue" in _params) {
        decoded["allocation"]["weightCappingSecurity"]["weightCappedMin"] =
          _params["minValue"];
      }
    }

    if ("rotation" in _params) {
      decoded["allocation"]["weightCappingPeer"] = {
        peerLevel: null,
        weightCappedMin: null,
        weightCappedMax: null,
        weightCappedMethod: null,
      };

      decoded["allocation"]["weightCappingPeer"]["peerLevel"] =
        this._decodePeerLevel(
          _params["rotation"]["countryLevel"],
          _params["rotation"]["sectorLevel"]
        );
      decoded["allocation"]["weightCappingPeer"]["weightCappedMax"] =
        _params["rotation"]["capValue"];
      decoded["allocation"]["weightCappingPeer"]["weightCappedMethod"] =
        _params?.cappingMethod ?? null;
    }
    // ----------------------------------------------------- backtesting
    _params = params["pricing"];
    decoded["backtesting"]["inceptionDate"] = _params["inceptionDay"];
    decoded["backtesting"]["inceptionValue"] = _params["inceptionValue"];
    _params = params["backtesting"];
    if (_params["includeFromDay"] != null) {
      decoded["backtesting"]["period"] = {
        type: "DAY",
        value: _params["includeFromDay"],
      };
    } else if (_params["yearsBack"] != null) {
      decoded["backtesting"]["period"] = {
        type: "YEAR",
        value: _params["yearsBack"],
      };
    }
    // -------------------------------------------------------- strategy

    decoded["strategy"]["benchmark"] =
      "benchmark" in params["pricing"] ? params["pricing"]["benchmark"] : null;
    decoded["strategy"]["currency"] = params["strategy"]["currency"];
    decoded["strategy"]["performance"] = this._decodePricingPerformance(
      params["pricing"]["method"]
    );
    decoded["strategy"]["rebalance"] = this._decodeRebalance(
      params["backtesting"]["reviewGranularity"]
    );
    // --------------------------------------------------------- hedging
    if (params["hedging"] != null) {
      decoded["hedging"] = {
        constraints: this._decodeHedgingConstraints(
          params["hedging"]["constraints"]
        ),
        instrument: params["hedging"]["symbol"],
        leverage: params["hedging"]["leverage"],
      };
    }
    // -------------------------------------------- holdings constraints
    decoded["strategy"]["holdings"] =
      params["strategy"]["selectionRules"]["maxPositions"];
    // --------------------------------------------------------- ranking
    if (params["strategy"]["rank"] != null) {
      const ranking = new RankingUi2Api(this.environment);
      decoded["ranking"] = ranking.decode(params["strategy"]["rank"]);
    }
    // ------------------------------------------------------- selection
    if (params["strategy"]["selectionRules"]["constraints"] != null) {
      decoded["selection"] = this._decodeSelectionConstraints(
        params["strategy"]["selectionRules"]["constraints"]
      );
    }
    // -------------------------------------------------------- holding

    if (params["strategy"]?.["holdingRules"]?.["constraints"] != null) {
      decoded["holding"] = this._decodeSelectionConstraints(
        params["strategy"]["holdingRules"]["constraints"]
      );
    }
    // -------------------------------------------------------- tracking
    _params = params["tracking"];
    if (_params != null) {
      decoded["tracking"] = {
        trackingDate: _params["trackingDay"],
      };
    }
    // -------------------------------------------------------- universe
    _params = params["universe"]["search"];

    // additional rules (selection)
    if ("constraints" in _params && _params["constraints"] != null) {
      decoded["universe"]["selection"] = this._decodeSelectionConstraints(
        _params["constraints"]
      );
    }

    if ("relations" in _params) {
      // white list
      decoded["universe"]["whiteList"] = {
        id: Number(_params["relations"][0]["domain"][0]),
        type: _params["relations"][0]["range"],
      };
    } else {
      // screening

      if (_params["justInTimeTops"]) {
        decoded["universe"]["screening"] = {
          eligibility: {
            cardinality:
              "justInTimeTops" in _params
                ? _params["justInTimeTops"][0]["n"]
                : _params["page"]["rows"],
            isEnabled: "justInTimeTops" in _params ? true : false,
            sortBy: null,
            //! moved this part under the variable assignment (***)
            // "justInTimeTops" in _params
            //   ? _params["justInTimeTops"][0]["rev"] === true
            //     ? "desc"
            //     : "asc"
            //   : _params["sort"]["rev"] === true
            //   ? "desc"
            //   : "asc",
            //!--------------------------------

            // cardinality: _params["page"]["rows"],
            // sortBy: _params["sort"]["rev"] === true ? "desc" : "asc"
          },
          instrumentType: null,
          what: [],
          whereSource: {
            domestic: false,
            foreign: false,
            market: [],
            stockClassification: [],
          },
          whereTarget: {
            // Only ETFs: etfgeo
            domestic: false,
            foreign: false,
            market: [],
            stockClassification: [],
          },
        };
        //!HERE***
        if ("justInTimeTops" in _params) {
          if (_params["justInTimeTops"][0]["rev"] === true) {
            decoded["universe"]["screening"]["eligibility"]["sortBy"] = "desc";
          } else {
            decoded["universe"]["screening"]["eligibility"]["sortBy"] = "asc";
          }
        } else {
          if (_params["sort"]["rev"] === true) {
            decoded["universe"]["screening"]["eligibility"]["sortBy"] = "desc";
          } else {
            decoded["universe"]["screening"]["eligibility"]["sortBy"] = "asc";
          }
        }
      } else {
        decoded["universe"]["screening"] = {
          eligibility: {
            cardinality: _params["page"]["rows"],
            isEnabled: false,
            sortBy: _params["sort"]["rev"] === true ? "desc" : "asc",
            // cardinality: _params["page"]["rows"],
            // sortBy: _params["sort"]["rev"] === true ? "desc" : "asc"
          },
          instrumentType: null,
          what: [],
          whereSource: {
            domestic: false,
            foreign: false,
            market: [],
            stockClassification: [],
          },
          whereTarget: {
            // Only ETFs: etfgeo
            domestic: false,
            foreign: false,
            market: [],
            stockClassification: [],
          },
        };
      }

      _params = params["universe"]["search"]["filters"];
      for (let i = 0, length = _params.length; i < length; i++) {
        const filter = _params[i];

        switch (filter["dimension"]) {
          case "country": {
            decoded["universe"]["screening"]["whereSource"]["market"] =
              filter["segments"];

            break;
          }
          case "etfgeo": {
            decoded["universe"]["screening"]["whereTarget"]["market"] =
              filter["segments"];

            break;
          }
          case "etfclass":
          case "icb": {
            decoded["universe"]["screening"]["what"] = filter["segments"];

            break;
          }
          case "stockclass": {
            decoded["universe"]["screening"]["whereSource"][
              "stockClassification"
            ] = filter["segments"];

            break;
          }
          case "subtype": {
            if (filter["segments"][0] === "Domestic Stock") {
              decoded["universe"]["screening"]["whereSource"]["domestic"] =
                true;
            }

            if (filter["segments"][0] === "Foreign Stock") {
              decoded["universe"]["screening"]["whereSource"]["foreign"] = true;
            }
            if (filter["segments"].length > 0) {
              decoded["universe"]["screening"]["subtype"] = [
                ...filter["segments"],
              ];
            } else {
              decoded["universe"]["screening"]["subtype"] = [];
            }

            break;
          }
          case "type": {
            decoded["universe"]["screening"]["instrumentType"] =
              filter["segments"][0].toLowerCase();
            break;
          }
          default:
            break;
        }
      }
    }
    // --------------------------------------------- weighting rules
    decoded["weighting"]["weightingSchema"] = this._decodeWeightSchema(
      params["strategy"]["weightingRules"]["weightCriteria"]
    );
    decoded["weighting"]["weightingSchemaExistingPositions"] =
      this._decodeWeightSchemaExistingPositions(
        params["strategy"]["weightingRules"]["weightRule"]
      );

    if ("factors" in params["strategy"]["weightingRules"]) {
      const smartBeta = new SmartBetaUi2Api();
      decoded["weighting"]["smartBeta"] = smartBeta.decode(
        params["strategy"]["weightingRules"]["factors"]
      );

      const factors: any =
        params["strategy"]["weightingRules"]["factors"] ?? [];
      for (let i = 0; i < factors.length; i++) {
        let factor = factors[i];

        switch (factor["name"]) {
          // case "rating": {
          //     // smart beta
          //     decoded["weighting"]["smartBeta"] = {
          //         A: factor["bySegments"]["A"],
          //         B: factor["bySegments"]["B"],
          //         C: factor["bySegments"]["C"],
          //         D: factor["bySegments"]["D"]
          //     };

          //     break;
          // }
          case "rotation": {
            // peer rotation
            decoded["weighting"]["rotation"] = {
              factor: this._decodeRotationFactor(factor["metricType"]),
              rotate: this._decodePeerLevel(
                factor["countryLevel"],
                factor["sectorLevel"]
              ),
            };

            break;
          }
          default:
            break;
        }
      }
    }

    decoded["universe"]["trimOutliers"] =
      params["strategy"]["trimOutliers"] ?? false;

    return decoded;
  }

  decodeKeyFacts(strategy: any, response: any) {
    if (response.status === "KO") {
      return this.simulateHttpError(response);
    } else if (response.data != null && response.data.WARNING != null) {
      return this.simulateHttpError(response);
    }

    const average = response["STATS"]["aggregated"]["average"];
    const total = response["STATS"]["aggregated"]["total"];

    const index = total.equity;
    const indexAvg = average.equity;
    let benchmark: any = null;
    let benchmarkAvg: any = null;
    let delta: any = null;
    let deltaAvg: any = null;

    if ("benchmark" in total) {
      benchmark = total.benchmark;
      benchmarkAvg = average.benchmark;
      delta = total.diff;
      deltaAvg = average.diff;
    }

    const performance = {
      cumulative: {
        strategy: index.TotalPerf,
        benchmark: benchmark != null ? benchmark.TotalPerf : null,
        delta: delta != null ? delta.TotalPerf : null,
      },
      annualized: {
        strategy: index.AnnualizedRateOfReturn,
        benchmark: benchmark != null ? benchmark.AnnualizedRateOfReturn : null,
        delta: delta != null ? delta.AnnualizedRateOfReturn : null,
      },
      yearlyAverage: {
        strategy: indexAvg.TotalPerf,
        benchmark: benchmarkAvg != null ? benchmarkAvg.TotalPerf : null,
        delta: deltaAvg != null ? deltaAvg.TotalPerf : null,
      },
      winningPeriods: {
        strategy: index.MaxConsWinningFrequency,
        benchmark: benchmark != null ? benchmark.MaxConsWinningFrequency : null,
        delta: delta != null ? delta.MaxConsWinningFrequency : null,
      },
    };

    const risk = {
      maxDrawdown: {
        strategy: index.MaxDrowDown,
        benchmark: benchmark != null ? benchmark.MaxDrowDown : null,
        delta: delta != null ? delta.MaxDrowDown : null,
      },
      avgYearlyDrawdown: {
        strategy: indexAvg.MaxDrowDown,
        benchmark: benchmarkAvg != null ? benchmarkAvg.MaxDrowDown : null,
        delta: deltaAvg != null ? deltaAvg.MaxDrowDown : null,
      },
      monthlyStdDev: {
        strategy: index.StdDev,
        benchmark: benchmark != null ? benchmark.StdDev : null,
        delta: delta != null ? delta.StdDev : null,
      },
      maxConsecutiveLoosingPeriod: {
        strategy: index.MaxConsLoosingFrequency,
        benchmark: benchmark != null ? benchmark.MaxConsLoosingFrequency : null,
        delta: delta != null ? delta.MaxConsLoosingFrequency : null,
      },
    };

    const keyRatios = {
      avgTurnover: {
        strategy: index.Turnover != null ? index.Turnover : null,
        benchmark: null,
        delta: null,
      },
      sharpeRatio: {
        strategy: index.SharpRatio != null ? index.SharpRatio : null,
        benchmark:
          benchmark != null && benchmark.SharpRatio
            ? benchmark.SharpRatio
            : null,
        delta: delta != null ? delta.SharpRatio : null,
      },
      sterlingRatio: {
        strategy: index.SterlingRatio != null ? index.SterlingRatio : null,
        benchmark:
          benchmark != null && benchmark.SterlingRatio
            ? benchmark.SterlingRatio
            : null,
        delta: delta != null ? delta.SterlingRatio : null,
      },
      sortinoRatio: {
        strategy: index.SortinoRatio != null ? index.SortinoRatio : null,
        benchmark:
          benchmark != null && benchmark.SortinoRatio
            ? benchmark.SortinoRatio
            : null,
        delta: delta != null ? delta.SortinoRatio : null,
      },
      beta: {
        strategy: index.Beta != null ? index.Beta : null,
        benchmark: benchmark != null && benchmark.Beta ? benchmark.Beta : null,
        delta: delta != null && delta.Beta ? delta.Beta : null,
      },
      trackingError: {
        strategy: index.TrackingError != null ? index.TrackingError : null,
        benchmark: null,
        delta: null,
      },
      infoRatio: {
        strategy:
          index.InformationRatio != null ? index.InformationRatio : null,
        benchmark:
          benchmark != null && benchmark.InformationRatio
            ? benchmark.InformationRatio
            : null,
        delta:
          delta != null && delta.InformationRatio
            ? delta.InformationRatio
            : null,
      },
      treynorRatio: {
        strategy: index.TreynorRatio != null ? index.TreynorRatio : null,
        benchmark:
          benchmark != null && benchmark.TreynorRatio != null
            ? benchmark.TreynorRatio
            : null,
        delta:
          delta != null && delta.TreynorRatio != null
            ? delta.TreynorRatio
            : null,
      },
      percentagePositivePeriod: {
        strategy:
          index.WinningFrequency != null ? index.WinningFrequency : null,
        benchmark:
          benchmark != null && benchmark.WinningFrequency
            ? benchmark.WinningFrequency
            : null,
        delta: delta != null ? delta.WinningFrequency : null,
      },
      winningPeriod: {
        strategy: delta != null && delta.winning ? delta.winning : null,
        benchmark: null,
        delta: null,
      },
      winningAvgPeriod: {
        strategy:
          delta != null && delta.winningAverage ? delta.winningAverage : null,
        benchmark: null,
        delta: null,
      },
      losingPeriod: {
        strategy: delta != null && delta.loosing ? delta.loosing : null,
        benchmark: null,
        delta: null,
      },
      losingAvgPeriod: {
        strategy:
          delta != null && delta.loosingAverage ? delta.loosingAverage : null,
        benchmark: null,
        delta: null,
      },
    };

    const _period = strategy["params"]["strategy"]["rebalance"];
    let period: any = null;
    switch (_period) {
      case "20_DAYS": {
        period = "months";

        break;
      }
      case "60_DAYS": {
        period = "quarters";

        break;
      }
      default: {
        period = "weeks";
      }
    }

    const keyFacts = {
      performance: performance,
      risk: risk,
      keyRatios: keyRatios,
      period: period,
    };

    return keyFacts;
  }

  /**
   * Encode UI params in server params
   */
  encode(params: StrategyParams): ServerStrategyParams {
    let _params: any = null;
    const encoded: any = {
      backtesting: {
        includeFromDay: null,
        reviewClosureType: "CLOSE_OPEN", // not in UI
        reviewPeriodType: "CALENDAR", // not in UI
        reviewGranularity: null,
        yearsBack: null,
      },
      hedging: null,
      pricing: {
        benchmark: null,
        inceptionDay: null,
        inceptionValue: null,
        initialCapital: 1000000, // not in UI
        method: null,
      },
      strategy: {
        blackList: null,
        cappingRules: {
          maxAllocation: 1, // not in UI
          minAllocation: 0,
          /* cappingMethod added only if available */
        },
        currency: null,
        rank: null,
        selectionRules: {
          maxPositions: 0,
          constraints: [],
        },
        holdingRules: {},
        trimOutliers: null,
        weightingRules: {
          weightCriteria: null,
          weightRule: null,
        },
      },
      tracking: null,
      universe: {
        search: null,
      },
    };
    let peerGeometry: any = null;
    // used for selection and additional rules (universe)
    const selectionTransformations =
      this.configurationStrategyBuilder["selection"]["edit"]["transformations"];
    const selection = new SelectionUi2Api({
      transformations: selectionTransformations,
    });
    // ----------------------------------------------------- backtesting
    _params = params["backtesting"]["period"];
    switch (_params["type"]) {
      case "DAY": {
        encoded["backtesting"]["includeFromDay"] = _params["value"];

        break;
      }
      case "YEAR": {
        encoded["backtesting"]["yearsBack"] = _params["value"];

        break;
      }
      default:
        break;
    }
    // review granularity
    encoded["backtesting"]["reviewGranularity"] = this._encodeRebalance(
      params["strategy"]["rebalance"]
    );
    // --------------------------------------------------------- hedging
    if (params["hedging"] != null) {
      const hedgingTransformations =
        this.configurationStrategyBuilder["hedging"]["edit"]["transformations"];
      const hedging = new SelectionUi2Api({
        transformations: hedgingTransformations,
      });

      encoded["hedging"] = {
        constraints: hedging.encode(
          params["hedging"]["constraints"]["value"] == null
            ? []
            : params["hedging"]["constraints"]["value"]
        ),
        enabled: true,
        leverage: params["hedging"]["leverage"],
        symbol: params["hedging"]["instrument"],
      };
    }
    // --------------------------------------------------------- pricing
    if (params["strategy"]["benchmark"] != null) {
      const benchmarkValue =
        params?.["strategy"]?.["benchmark"] != null
          ? params?.["strategy"]?.["benchmark"]
          : null;
      encoded["pricing"]["benchmark"] = benchmarkValue;
    }
    _params = params["backtesting"];
    encoded["pricing"]["inceptionDay"] = _params["inceptionDate"];
    encoded["pricing"]["inceptionValue"] = _params["inceptionValue"];
    encoded["pricing"]["method"] = this._encodePricingPerformance(
      params["strategy"]["performance"]
    );
    // -------------------------------------------------------- strategy
    encoded["strategy"]["currency"] = params["strategy"]["currency"];
    // capping rules
    _params = params["allocation"];
    // weight in cash -> min allocation
    encoded["strategy"]["cappingRules"]["minAllocation"] =
      _params["weightInCashMin"];
    encoded["strategy"]["cappingRules"]["maxAllocation"] =
      _params["weightInCashMax"];

    if (_params["weightCappingSecurity"] != null) {
      encoded["strategy"]["cappingRules"]["capValue"] =
        _params["weightCappingSecurity"]["weightCappedMax"];
      encoded["strategy"]["cappingRules"]["minValue"] =
        _params["weightCappingSecurity"]["weightCappedMin"];
    }
    if (_params["weightCappingPeer"] != null) {
      if (encoded["strategy"]["cappingRules"] == null) {
        encoded["strategy"]["cappingRules"] = {};
      }

      encoded["strategy"]["cappingRules"]["rotation"] = {
        capValue: _params["weightCappingPeer"]["weightCappedMax"],
        countryLevel: null,
        name: "rotation", // not in UI
        sectorLevel: null,
      };

      peerGeometry = this._encodePeerLevel(
        _params["weightCappingPeer"]["peerLevel"]
      );
      encoded["strategy"]["cappingRules"]["rotation"]["countryLevel"] =
        peerGeometry["where"];
      encoded["strategy"]["cappingRules"]["rotation"]["sectorLevel"] =
        peerGeometry["what"];

      const weightCappedMethod =
        params?.allocation?.weightCappingPeer?.weightCappedMethod;
      if (weightCappedMethod != null) {
        encoded["strategy"]["cappingRules"]["cappingMethod"] =
          weightCappedMethod;
      }
    }
    // ranking rules
    if (params["ranking"] != null) {
      const ranking = new RankingUi2Api(this.environment);
      encoded["strategy"]["rank"] = ranking.encode(params["ranking"]);
    }
    // selection rules
    if (params["selection"] != null) {
      encoded["strategy"]["selectionRules"]["constraints"] = selection.encode(
        params["selection"]
      );
    }
    //!!----holding rules
    if (params["holding"] != null && params["holding"]?.length > 0) {
      encoded["strategy"]["holdingRules"]["constraints"] = selection.encode(
        params["holding"]
      );
    }

    encoded["strategy"]["selectionRules"]["maxPositions"] =
      params["strategy"]["holdings"];

    // weighting rules
    // weight schema
    encoded["strategy"]["weightingRules"]["weightCriteria"] =
      this._encodeWeightSchema(params["weighting"]["weightingSchema"]);
    // weight schema for existing positions
    encoded["strategy"]["weightingRules"]["weightRule"] =
      this._encodeWeightSchemaExistingPositions(
        params["weighting"]["weightingSchemaExistingPositions"]
      );

    if (
      params["weighting"]["rotation"] != null ||
      params["weighting"]["smartBeta"] != null
    ) {
      encoded["strategy"]["weightingRules"]["factors"] = [];
      // smart beta
      if (params["weighting"]["smartBeta"] != null) {
        const smartBeta = new SmartBetaUi2Api();
        encoded["strategy"]["weightingRules"]["factors"] = smartBeta.encode(
          params["weighting"]["smartBeta"]
        );

        /* encoded["strategy"]["weightingRules"]["factors"].push({
                         bySegments: {
                             A: params["weighting"]["smartBeta"]["A"],
                             B: params["weighting"]["smartBeta"]["B"],
                             C: params["weighting"]["smartBeta"]["C"],
                             D: params["weighting"]["smartBeta"]["D"],
                             NA: 1.0 // not in UI
                         },
                         dimension: "rc",
                         name: "rating"
                     }); */
      }
      // rotation
      if (params["weighting"]["rotation"] != null) {
        const rule = {
          countryLevel: null,
          name: "rotation",
          metricType: this._encodeRotationFactor(
            params["weighting"]["rotation"]["factor"]
          ),
          sectorLevel: null,
        };

        peerGeometry = this._encodePeerLevel(
          params["weighting"]["rotation"]["rotate"]
        );
        rule["countryLevel"] = peerGeometry["where"];
        rule["sectorLevel"] = peerGeometry["what"];

        encoded["strategy"]["weightingRules"]["factors"].push(rule);
      }
    }
    // -------------------------------------------------------- universe
    if (params["tracking"] != null) {
      encoded["tracking"] = {
        trackingDay: params["tracking"]["trackingDate"],
      };
    }

    const universeCardinality = this.universeCardinalityMax;
    if (params?.["universe"]?.["blacklist"]?.length) {
      encoded["strategy"]["blackList"] =
        params["universe"]?.["blacklist"] ?? null;
    }
    if (params["universe"]["whiteList"] != null) {
      _params = params["universe"]["whiteList"];

      encoded["universe"]["search"] = {
        page: {
          page: 1,
          rows: universeCardinality,
        },
        relations: [
          {
            range: _params["type"],
            domain: [String(_params["id"])],
          },
        ],
        sort: {
          dimension: "marketcap",
          rev: true,
        },
      };
    } else if (params["universe"]["screening"] != null) {
      _params = params["universe"]["screening"];

      encoded["universe"]["search"] = {
        page: {
          page: 1,
          rows: universeCardinality,
        },
        sort: {
          dimension: "marketcap",
          rev: false,
        },
      };

      //
      // 2021-04-21 compatibility with old editors
      // alpha, smart beta and macro rotation
      //

      encoded["universe"]["search"]["sort"]["rev"] =
        _params["eligibility"]["sortBy"] === "desc" ? true : false;

      if (_params["instrumentType"] !== "etf") {
        if (_params["eligibility"]["isEnabled"] === true) {
          encoded["universe"]["search"]["justInTimeTops"] = [
            {
              dimension: "marketcap",
              n: _params["eligibility"]["cardinality"],
              rev: _params["eligibility"]["sortBy"] === "desc" ? true : false,
            },
          ];
        } else {
          encoded["universe"]["search"]["page"]["rows"] =
            _params["eligibility"]["cardinality"];
        }
      }

      const _instrumentType = _params["instrumentType"];
      let instrumentType: any = null;
      switch (_instrumentType) {
        case "etf": {
          instrumentType = ["ETF"];
          // etfclass
          if (_params["what"].length > 0) {
            addQueryParam(encoded["universe"]["search"], "filters", {
              dimension: "etfclass",
              segments: _params["what"],
            });
          }
          // etfgeo
          if (_params["whereTarget"]["market"].length > 0) {
            addQueryParam(encoded["universe"]["search"], "filters", {
              dimension: "etfgeo",
              segments: _params["whereTarget"]["market"],
            });
          }

          if (_params["subtype"] && _params["subtype"].length > 0) {
            addQueryParam(encoded["universe"]["search"], "filters", {
              dimension: "subtype",
              segments: _params["subtype"],
            });
          }
          // else {
          //   addQueryParam(encoded["universe"]["search"], "filters", {
          //     dimension: "subtype",
          //     segments: ["ALL"],
          //   });
          // }

          break;
        }
        case "stock": {
          instrumentType = ["Stock"];
          // icb
          if (_params["what"].length > 0) {
            addQueryParam(encoded["universe"]["search"], "filters", {
              dimension: "icb",
              segments: _params["what"],
            });
          }
          // domestic and/or foreign
          if (
            _params["whereSource"]["domestic"] === true &&
            _params["whereSource"]["foreign"] === false
          ) {
            addQueryParam(encoded["universe"]["search"], "filters", {
              dimension: "subtype",
              segments: ["Domestic Stock"],
            });
          }
          if (
            _params["whereSource"]["domestic"] === false &&
            _params["whereSource"]["foreign"] === true
          ) {
            addQueryParam(encoded["universe"]["search"], "filters", {
              dimension: "subtype",
              segments: ["Foreign Stock"],
            });
          }
          // stock classification
          if (_params["whereSource"]["stockClassification"].length > 0) {
            addQueryParam(encoded["universe"]["search"], "filters", {
              dimension: "stockclass",
              segments: _params["whereSource"]["stockClassification"],
            });
          }

          break;
        }
        default:
          break;
      }
      // country
      if (_params["whereSource"]["market"].length > 0) {
        addQueryParam(encoded["universe"]["search"], "filters", {
          dimension: "country",
          segments: _params["whereSource"]["market"],
        });
      }
      // instrument type
      if (instrumentType != null) {
        addQueryParam(encoded["universe"]["search"], "filters", {
          dimension: "type",
          segments: instrumentType,
        });
      }
    }

    // selection (additional rules)
    if (encoded["universe"]["search"] == null) {
      encoded["universe"]["search"] = {
        constraints: null,
      };
    }
    encoded["universe"]["search"]["constraints"] = null;
    if (params["universe"]["selection"] != null) {
      encoded["universe"]["search"]["constraints"] = selection.encode(
        params["universe"]["selection"]
      );
    }

    // 2022-03-02 trim outliers
    encoded["strategy"]["trimOutliers"] =
      params?.["universe"]?.["trimOutliers"] ?? false;

    return encoded;
  }

  generateBusId() {
    const timestamp = new Date().getTime();
    const user = this.environment.account.user;
    const userId = user?.id;
    if (userId == null) {
      return null;
    }
    const id = userId + timestamp;
    return String(id);
  }

  async select(id: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    let url = endPointRoot + endpoints.strategies.get_id;

    try {
      const response = await this.prepareGet(url, { id: id }, null);
      const strategy = this._decodeGetHelper(
        response?.["data"]?.["preference"]
      );

      const userId = this.environment.account.user?.id;
      strategy["isReadOnly"] = userId !== strategy["ownerId"];

      return strategy;
    } catch (error: any) {
      // Server returns 500 instead 404 when a strategy is not
      // available (not exist or has been deleted)
      error["initiator"] = "trendrating/api/compute/Strategies.get(id)";

      return this.simulateHttpError(error);
    }
  }

  async getUserStrategiesIDs() {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    let url = endPointRoot + endpoints.strategies.strategySelect;
    const userID = this.environment.account.user?.id;
    let payload: any = {
      searches: [
        {
          filters: [
            { dimension: "type", segments: ["PREFERENCE_INDEX"] },
            { dimension: "ownerId", segments: [userID] },
          ],
        },
      ],
    };
    let strategiesIDS = await this.preparePost(url, payload);
    strategiesIDS = strategiesIDS.data.ids;
    let subscriptionStrategies = await this.http["subscriptions"].get({
      type: "strategy",
    });
    subscriptionStrategies = subscriptionStrategies.map((item) => item.id);
    return [...strategiesIDS, ...subscriptionStrategies];
  }

  async fetch(ids: number[], arrProps: string[]) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.common.fetch;
    const payload = [
      {
        classType: "PREFERENCE_INDEX",
        id: ids,
        extendedRequest: {
          onDemandResult: arrProps.map((prop) => ({ dimension: prop })),
        },
      },
    ];

    const response = await this.preparePost(url, payload);
    let remappedDataObj: any = [];
    if (response) {
      for (const dataObj of response.data) {
        for (const row of dataObj.rows) {
          const remappedObj = {};

          for (const key in row) {
            if (key.startsWith("object.")) {
              const exploded = key.split(".");
              remappedObj[exploded[exploded.length - 1]] = row[key];
            } else {
              remappedObj[key] = row[key];
            }
          }

          remappedDataObj.push(remappedObj);
        }

        dataObj.rows = remappedDataObj;
      }
    }

    return response;
  }

  /**
   * Retrieves all strategies, included shared objects
   *
   * @param {array} fields - The fields of the strategy to be retrieved
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async getList(fields?: string[]) {
    const defaultFields = ["name", "ownerId"];
    const ids = await this.getUserStrategiesIDs();
    fields =
      fields != null
        ? [...new Set([...defaultFields, ...fields])]
        : defaultFields;

    const userID = this.environment.account.user?.id;

    try {
      const userStrategies = await this.fetch(ids, fields);
      const strategies: any[] = [];

      for (const strategy of userStrategies?.data?.[0]?.rows) {
        strategy.isReadOnly = strategy.ownerId !== userID;

        strategies.push(strategy);
      }

      return strategies;
    } catch (error) {
      return this.simulateHttpError(error);
    }
  }

  /**
   * Retrieves a strategy
   *
   * @param {number} id - The ID of the strategy to be retrieved
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async getById(id: any) {
    return await this.select(id);
  }

  /**
   * Get tags from a list of strategies id
   *
   * @param {array} ids - array of ids of strategies
   * @returns
   */
  getTags(ids: any) {
    return this.http["common"].fetch({
      classType: this.TYPE,
      ids: ids,
      properties: ["name", "tags"],
    });
  }

  /**
   * Retrieves data about a tracked strategy
   *
   * @param {number} id - the ID of tracked strategy to retrieve
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async getTracked(id: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.strategies.tracked;

    const response = await this.prepareGet(url, { "prefIds[]": id }, null);
    return response.data[0];
  }

  async priceAnalytics(
    H: { d: number; v: number }[],
    stats: string[],
    B?: { d: number; v: number }[]
  ) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.common.analytics;

    const payload = {
      H,
      stats,
    };

    if (B != null) {
      payload["B"] = B;
    }

    let result: any = undefined;

    try {
      result = await this.preparePost(url, payload);
      result = result?.stats;
    } catch (error: any) {
      const errorObj = {
        data: undefined,
        status: 500,
        errorDetails: error?.response ?? "Unknown error",
      };
      if ("status" in error) {
        errorObj["status"] = error.status;
      }

      result = errorObj;
    } finally {
      return result;
    }
  }

  /**
   * Return the prototype of a strategy
   *
   * @returns {object}
   */
  getPrototype(): Strategy {
    return {
      entity_type: "BUILDER",
      id: null,
      isReadOnly: false,
      name: "",
      params: {
        allocation: {
          weightInCashMin: 0,
          weightInCashMax: 1,

          weightCappingPeer: null,
          weightCappingSecurity: null,
        },
        backtesting: {
          inceptionDate: null,
          inceptionValue: null,
          period: null,
        },
        strategy: {
          benchmark: null,
          currency: "local",
          holdings: 0,
          performance: "",
          rebalance: "",
        },
        hedging: null,
        ranking: null,
        selection: null,
        tracking: null,
        universe: {
          screening: null,
          selection: null,
          trimOutliers: false,
          whiteList: null,
        },
        weighting: {
          weightingSchema: null,
          weightingSchemaExistingPositions: null,
        },
      },
      type: "PREFERENCE_INDEX",
      version: "2.0",
    };
  }

  /**
   * Combine two strategies (low level call)
   *
   * @param {object} params
   * @param {object} params.priceLevel - calculated by POS
   * @param {object} params.hedgingPriceLevel - calculated by SPOS
   * @param {object} params.strategyParams - strategy params, encoded
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async hedging(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.strategies.longShort;

    const startingDate = TDate.daysToIso8601(params.priceLevel[0]["d"]);

    // UI does have the value positive, needs to invert sign
    const leverage = parseFloat(params.strategyParams.hedging.leverage) * -1;

    const _params = {
      granularity: params.strategyParams.backtesting.reviewGranularity,
      inceptionDay: startingDate,
      inceptionValue: params.strategyParams.pricing.inceptionValue,
      components: [
        {
          A: 1,
          H: params.priceLevel,
        },
        {
          A: leverage,
          H: params.hedgingPriceLevel,
        },
      ],
    };

    const response = await this.preparePost(url, _params);
    if ("WARNING" in response.data) {
      let error = this.simulateHttpError(response.data);

      if (error != null) {
        return error;
      }
    }
    return response.data.CURVES.H;
  }

  /**
   * Retrives holdings and contributions of a strategy
   *
   * params is one item POS array
   *
   * @param {object}   params - request parameters
   * @param {object}   params.allocation - allocation
   * @param {number}   params.allocation.d - day start
   * @param {number}   params.allocation.de - day end
   * @param {number}   params.allocation.i -
   * @param {number}   params.allocation.i_g -
   * @param {object[]} params.allocation.v - allocations
   * @param {number}   params.allocation.v[].A - weight (Allocation)
   * @param {string}   params.allocation.v[].D - day
   * @param {string}   params.allocation.v[].S - symbol
   * @param {object[]} params.instrumentProperties - properties to be
   *      retrieved for each instrument
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async holdings(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.strategies.holdings;

    // symbols and remove CASH
    const allocations = params["allocation"]["v"];
    const symbols: any = [];
    for (let i = 0, length = allocations.length; i < length; i++) {
      const allocation = allocations[i];
      if (allocation["S"] !== "CASH") {
        symbols.push(allocation["S"]);
      }
    }

    const paramsInstruments = {
      properties: params["instrumentsProperties"],
      symbols: symbols,
      type: "security",
    };

    const response = await httpAll({
      contributions: this.preparePost(url, params["allocation"]),
      instruments: this.http["instruments"].fetch(paramsInstruments),
    });
    return this._decodeHoldings(response);
  }

  /**
   * Retrieves holdings history
   *
   * @param {object[]} params.strategyResult.POS - positions
   * @param {number}   params.strategyResult.POS[].d - day start
   * @param {number}   params.strategyResult.POS[].de - day end
   * @param {number}   params.strategyResult.POS[].i - index value
   * @param {number}   params.strategyResult.POS[].i_g - index gain
   * @param {object[]} params.strategyResult.POS[].v - allocations
   * @param {number}   params.strategyResult.POS[].v[].A - weight (Allocation)
   * @param {string}   params.strategyResult.POS[].v[].D - day
   * @param {string}   params.strategyResult.POS[].v[].S - symbol
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async holdingsHistory(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.strategies.holdingsHistory;

    const paramsHoldingsHistory = this._cleanupAllocationData({
      allocations: params.strategyResult.POS,
    });

    // const paramsCompressed = {
    //     "content-encoding": "LZW",
    //     content: LZW.compress((paramsHoldingsHistory))
    // };

    const response = await this.preparePost(url, paramsHoldingsHistory);
    return response.metric;
  }

  /**
   * Retrives the key facts about a strategy
   *
   * @param {object}   params - request parameters
   * @param {object}   params.strategy - a strategy (output of get)
   *
   * @param {object}   params.strategyResult - strategy results (compute)
   * @param {object}   params.strategyResult.CURVES - history (H) and
   *      benchmark (B) if available
   *
   * @param {object[]} params.strategyResult.POS - positions
   * @param {number}   params.strategyResult.POS[].d - day start
   * @param {number}   params.strategyResult.POS[].de - day end
   * @param {number}   params.strategyResult.POS[].i - index value
   * @param {number}   params.strategyResult.POS[].i_g - index gain
   * @param {object[]} params.strategyResult.POS[].v - allocations
   * @param {number}   params.strategyResult.POS[].v[].A - weight (Allocation)
   * @param {string}   params.strategyResult.POS[].v[].D - day
   * @param {string}   params.strategyResult.POS[].v[].S - symbol
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async keyFacts(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.strategies.keyFacts;

    const _params = this._cleanupAllocationData(
      this._prepareParamsKeyFacts(params)
    );

    // const _paramsCompressed = {
    //     "content-encoding": "LZW",
    //     content: LZW.compress((_params))
    // };
    const _paramsCompressed = _params;

    const response = await this.preparePost(url, _paramsCompressed);
    return this.decodeKeyFacts(params.strategy, response);
  }

  // old strategies can use "At rebalance" (checked) to inject
  // justInTimeTops constraints
  legacyFixAtRebalance(encoded: ServerStrategyParams, oldParams: any) {
    if (oldParams != null) {
      if (!("justInTimeTops" in oldParams["universe"]["search"])) {
        delete encoded["universe"]["search"]["justInTimeTops"];
        encoded["universe"]["search"]["page"] =
          oldParams["universe"]["search"]["page"];
      }
    }

    return encoded;
  }

  async combineHistoricalPositions(payload: {
    currency: string;
    portfolios: {
      A: number;
      POS: { d: number; de: number; v: { A: number; S: string }[] }[];
    }[];
  }) {
    if (!payload) {
      return undefined;
    }

    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.strategies.combineHistoricalPOS;

    try {
      const response = await this.preparePost(url, payload);

      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  /**
   * Combine strategies
   *
   * @param {object} params
   * @param {object} params.percentage1
   * @param {object} params.percentage2
   * @param {object} params.strategy1
   * @param {object} params.strategy2
   * @param {object} params.backtest1 (optional) already computed strategy 1 run
   * @param {object} params.backtest2 (optional) already computed strategy 2 run
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async longShort(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.strategies.longShort;

    let run1Params = deepClone(params["strategy1"]["params"]);
    if (params.backtest1) {
      run1Params["backtest"] = params.backtest1;
    }

    let run2Params = deepClone(params["strategy2"]["params"]);
    if (params.backtest2) {
      run2Params["backtest"] = params.backtest2;
    }

    let run3Params: any = null;
    if (params["strategy3"] != null) {
      run3Params = deepClone(params["strategy3"]["params"]);
      if (run3Params != null && params.backtest3) {
        run3Params["backtest"] = params.backtest3;
      }
    }

    const response = await httpAll({
      request1: this.run(run1Params, null),
      request2: this.run(run2Params, null),
      request3: run3Params != null && this.run(run3Params, null),
    });

    const run1 = response["request1"]["data"];
    const run2 = response["request2"]["data"];
    const run3 = response["request3"]?.["data"];

    const startingDate = TDate.daysToIso8601(run1["CURVES"]["H"][0]["d"]);

    let granularity: any = null; // No fallback in case the granularity is missing
    // Adjust granularity
    switch (params["strategy1"]["params"]["strategy"]["rebalance"]) {
      case "05_DAYS": {
        granularity = "WEEKLY";

        break;
      }
      case "20_DAYS": {
        granularity = "MONTHLY";

        break;
      }
      case "60_DAYS": {
        granularity = "QUARTERLY";

        break;
      }
      default:
        break;
    }

    // inceptionDay start at the start of the data, is not the inceptionDate of the strategy
    // it is wrongly named, actually is the same as the period
    // inceptionValue is always 100
    const _paramsCombined = {
      granularity: granularity,
      inceptionDay: startingDate,
      inceptionValue: 100,
      components: [
        {
          A: params["percentage1"],
          H: run1["CURVES"]["H"],
        },
        {
          A: params["percentage2"],
          H: run2["CURVES"]["H"],
        },
      ],
    };

    //In case run3 is not null include it in _paramsCombined
    if (run3 != null) {
      if (run3["CURVES"]?.["H"] != null) {
        _paramsCombined.components.push({
          A: params["percentage3"],
          H: run3["CURVES"]["H"],
        });
      }
    }

    const _params1 = {
      granularity: granularity,
      inceptionDay: startingDate,
      inceptionValue: 100,
      components: [
        {
          A: params["percentage1"],
          H: run1["CURVES"]["H"],
        },
      ],
    };

    const _params2 = {
      granularity: granularity,
      inceptionDay: startingDate,
      inceptionValue: 100,
      components: [
        {
          A: params["percentage2"],
          H: run2["CURVES"]["H"],
        },
      ],
    };

    let _params3: any = null;
    if (params["percentage3"] != null && run3?.["CURVES"]?.["H"] != null) {
      _params3 = {
        granularity: granularity,
        inceptionDay: startingDate,
        inceptionValue: 100,
        components: [
          {
            A: params["percentage3"],
            H: run3["CURVES"]["H"],
          },
        ],
      };
    }

    const requests = {
      combined1: this.preparePost(url, _params1),
      combined: this.preparePost(url, _paramsCombined),
      combined2: this.preparePost(url, _params2),
      combined3: _params3 != null ? this.preparePost(url, _params3) : null,
    };

    const responseCombined = await httpAll(requests);
    return this._decodeLongShort(
      startingDate,
      run1,
      run2,
      run3,
      run1Params,
      run2Params,
      run3Params,
      responseCombined
    );
  }

  /**
   * Retrives the total performances of a strategy
   *
   * @param {object}   params - request parameters
   * @param {object}   params.strategy - a strategy (output of get)
   *
   * @param {object}   params.strategyResult - strategy results (compute)
   * @param {object}   params.strategyResult.CURVES - history (H) and
   *      benchmark (B) if available
   *
   * @param {object[]} params.strategyResult.POS - positions
   * @param {number}   params.strategyResult.POS[].d - day start
   * @param {number}   params.strategyResult.POS[].de - day end
   * @param {number}   params.strategyResult.POS[].i - index value
   * @param {number}   params.strategyResult.POS[].i_g - index gain
   * @param {object[]} params.strategyResult.POS[].v - allocations
   * @param {number}   params.strategyResult.POS[].v[].A - weight (Allocation)
   * @param {string}   params.strategyResult.POS[].v[].D - day
   * @param {string}   params.strategyResult.POS[].v[].S - symbol
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async performances(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.strategies.performances;

    const _params = this._cleanupAllocationData(
      this._prepareParamsKeyFacts(params)
    );

    /* const _paramsCompressed = {
                     'content-encoding': 'LZW',
                     'content': LZW.compress((_params))
                 }; */
    const _paramsCompressed = _params;

    const response = await this.preparePost(url, _paramsCompressed);
    return this._decodePerformances(response);
  }

  /**
   *
   * @param {*} params - decoded params (UI params)
   */
  prepareParamsForNeutralStrategy(
    params: StrategyParams,
    legacyParamsAtRebalance?: any
  ): ServerStrategyParams {
    const encoded = this.encode(deepClone(params));

    encoded.pricing.benchmark = null;
    encoded.hedging = null;

    if (
      "justInTimeTops" in encoded.universe.search &&
      encoded.universe.search.justInTimeTops != null
    ) {
      const just = encoded.universe.search.justInTimeTops[0];
      encoded.strategy.selectionRules.maxPositions = just.n;
    } else {
      encoded.strategy.selectionRules.maxPositions =
        encoded.universe.search.page.rows;
    }

    encoded.strategy.selectionRules.constraints = [];
    if (encoded.universe.search.constraints != null) {
      encoded.strategy.selectionRules.constraints =
        encoded.universe.search.constraints;
    }
    delete encoded.universe.search.constraints;

    //!
    if (params.strategy.benchmark === this.SYMBOL_NEUTRAL_STRATEGY) {
      encoded.strategy.weightingRules = {
        weightCriteria: "PROPORTIONAL_MARKETCAP",
        weightRule: "REBALANCE",
      };
    }
    if (
      params.strategy.benchmark === this.SYMBOL_NEUTRAL_STRATEGY_EQUAL_WEIGHTED
    ) {
      encoded.strategy.weightingRules = {
        weightCriteria: "EQUAL_WEIGHTED",
        weightRule: "REBALANCE",
      };
    }
    //!
    encoded.strategy.rank = [];
    encoded.strategy.holdingRules = {};
    encoded.strategy.cappingRules = {
      capValue: 1.0,
      minAllocation: 1.0,
      maxAllocation: 1.0,
    };

    return this.legacyFixAtRebalance(encoded, legacyParamsAtRebalance);
  }

  /**
   *
   * @param {*} params - decoded params (UI params)
   */
  prepareParamsForRun(
    params: StrategyParams,
    legacyParamsAtRebalance: any
  ): ServerStrategyParams {
    const encoded = this.encode(deepClone(params));

    // bus id (event stream for progress bar)
    if ("busId" in params) {
      encoded["busId"] = params["busId"];
    }
    if ("busId_2" in params) {
      encoded["busId_2"] = params["busId_2"];
    }

    // additional rules manipulation (universe selection)
    if (
      "constraints" in encoded["universe"]["search"] &&
      encoded["universe"]["search"]["constraints"] != null
    ) {
      if (encoded["strategy"]["selectionRules"]["constraints"]) {
        encoded["strategy"]["selectionRules"]["constraints"] = encoded[
          "universe"
        ]["search"]["constraints"].concat(
          encoded["strategy"]["selectionRules"]["constraints"]
        );
      } else {
        encoded["strategy"]["selectionRules"]["constraints"] =
          encoded["universe"]["search"]["constraints"];
      }
    }

    delete encoded["universe"]["search"]["constraints"];

    return this.legacyFixAtRebalance(encoded, legacyParamsAtRebalance);
  }

  /**
   *
   * @param {*} strategy
   *
   * strategy.entity_type
   * strategy.id
   * strategy.name
   * strategy.ownerId
   * strategy.params
   * strategy.version
   */
  prepareStrategyForRationale(strategy: Strategy): ServerStrategy {
    // Version will be in any case overwritten later
    const _strategy = StrategyTranspiler.validateAndConvert(
      deepClone(strategy)
    );

    _strategy.params = this.prepareParamsForRun(_strategy.params, null);

    _strategy.version = "encoded (rationale)";

    return _strategy;
  }

  /**
   * Runs a strategy (price level)
   *
   * @param {object} params - TODO
   * @param {object[]} positions - POS calculated by backtest
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async priceLevel(params: any, positions: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.strategies.run;

    const _params = {
      currency: params.strategy.currency,
      includeFromDay: params.backtesting.includeFromDay,
      inceptionDay: params.pricing.inceptionDay,
      inceptionValue: params.pricing.inceptionValue,
      method: params.pricing.method,
      spanGranularity: params.backtesting.spanGranularity,
      POS: positions,
    };

    const response = await this.preparePost(url, _params);

    let error: any = null;
    if (response["status"] === "KO") {
      error = this.simulateHttpError(response);
    } else if ("WARNING" in response["data"]) {
      error = this.simulateHttpError(response);
    }

    if (error != null) {
      return error;
    }

    // Set explicit null value on B if absent
    return response.data.CURVES.H;
  }

  /**
   * Returns a rationale about a strategy allocation (transparency)
   *
   * @param {object} params
   * @param {object} params.allocation - the allocation for which generate
   *      the rationale
   * @param {number} params.day - date in Trendrating format
   * @param {object} params.properties - the properties to be
   *      fetched for instruments
   * @param {object} params.strategy - the strategy
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async rationale(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.strategies.rationales;

    const strategy = this.prepareStrategyForRationale(params.strategy);

    const paramsRationale = {
      date: params["day"],
      exAntePortfolio: {
        d: params["allocation"]["d"],
        v: params["allocation"]["v"],
      },
      strategy: strategy.params?.strategy,
      universe: strategy.params?.universe,
      verbose: true,
    };

    const response = await this.preparePost(url, paramsRationale);
    return this._normalizeRationale(
      strategy["params"],
      params["properties"],
      response
    );
  }

  // TODO - use _StoredObjects
  async remove(strategy: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.storedObjects.remove;

    const paramsRemove = {
      id: strategy.id,
      ownerId: this.environment.account.user?.id,
      type: this.TYPE,
    };

    const response = await this.prepareGet(url, paramsRemove, null);
    return this._remove(strategy.id, response);
  }

  /**
   * Runs a strategy
   *
   * Does call the backtest api and combine with the priceLevel call
   * to return the same object as the previous api call (lazy)
   *
   * @param {object} params - TODO
   * @param {object} params.backtest (optional) If there is already backtest data
   * @param {object} params.benchmarkBacktest (optional) If there is already backtest data
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async run(params: any, legacyParamsAtRebalance?: any) {
    function cleanPOS(POS: any) {
      if (POS == null) {
        return POS;
      }
      return POS.map((item: any) => ({
        d: item.d,
        v: item.v,
      }));
    }

    const encoded = this.prepareParamsForRun(params, legacyParamsAtRebalance);

    let paramsNeutral: any = null;
    // neutral strategy
    if (
      encoded["pricing"]["benchmark"] === this.SYMBOL_NEUTRAL_STRATEGY ||
      encoded["pricing"]["benchmark"] ===
        this.SYMBOL_NEUTRAL_STRATEGY_EQUAL_WEIGHTED
    ) {
      paramsNeutral = this.prepareParamsForNeutralStrategy(params);
      paramsNeutral["busId"] = encoded["busId_2"];

      delete encoded["busId_2"];
      delete paramsNeutral["busId_2"];
    }

    try {
      const response = await httpAll({
        strategy: params.backtest
          ? { data: { POS: params.backtest } }
          : this.backtest(encoded),
        benchmark: params.backtest
          ? null
          : paramsNeutral != null
          ? this.backtest(paramsNeutral)
          : null,
      });

      /**
       * response.strategy.POS
       * response.strategy.SPOS (optional)
       *
       * response.benchmark:
       * - null
       *  (or)
       * - response.benchmark = [] history precalcolated, POS
       *  (or)
       * - response.benchmark.POS
       *   response.benchmark.SPOS (optional)
       *
       */

      const positions = {
        POS: {
          H: response.strategy.data.POS,
          B: null,
        },
        SPOS: {
          H: response.strategy.data.SPOS,
          B: null,
        },
      };
      if (response.benchmark != null) {
        if (Array.isArray(response.benchmark)) {
          positions.POS.B = response.benchmark;
        } else if (
          response.benchmark.data != null &&
          response.benchmark.data.POS != null
        ) {
          positions.POS.B = response.benchmark.data.POS;
          positions.SPOS.B = response.benchmark.data.SPOS;
        }
      }

      let benchmarkRunPromise: any = null;
      if (paramsNeutral != null) {
        benchmarkRunPromise = this.priceLevel(
          paramsNeutral,
          cleanPOS(positions.POS.B)
        );
      } else if (encoded["pricing"]["benchmark"] != null) {
        //If is a blended benchmark let the priceLevel call (indexRun) to calculate the backtest
        //for the portfolio used as benchmark
        const blendedBenchmarkTag = "COLLECTION";
        if (encoded["pricing"]["benchmark"].includes(blendedBenchmarkTag)) {
          const params: any = deepClone(encoded);
          const splitBenchmarkValue = params["pricing"]["benchmark"].split(":");
          const backtestPositionsResult = positions.POS.H;
          const dateFromBacktest = backtestPositionsResult[0]?.["d"];

          const blendedBenchmarkId = parseInt(splitBenchmarkValue[1]);

          const apiList = this.environment.http.lists;
          const response = await apiList.getListAnalytics(
            [blendedBenchmarkId],
            ["positionsToday"]
          );

          const positionsToday = response?.[0]?.["positionsToday"] ?? null;

          const priceLevelPositions = positionsToday?.map((position) => ({
            A: position["weight"],
            S: position["symbol"],
            D: dateFromBacktest,
          }));

          const formatter = new Formatter();
          const date = formatter.date({
            options: {
              isMillisecond: false,
              notAvailable: {
                input: null,
                output: null,
              },
              separator: "-",
            },
            output: "TEXT",
            value: dateFromBacktest,
          });
          params["backtesting"]["includeFromDay"] = date;
          params["backtesting"]["spanGranularity"] =
            params["backtesting"]["reviewGranularity"];

          const POS = {
            d: dateFromBacktest,
            v: priceLevelPositions,
          };

          benchmarkRunPromise = this.priceLevel(params, [POS]);
        } else {
          benchmarkRunPromise = this.http["instruments"]
            .historyOf({
              symbol: encoded["pricing"]["benchmark"],
            })
            .then(async (response: any) => {
              if (response != null) {
                const currencyBenchmark = params.strategy.benchmarkCurrency;
                const strategyCurrency = encoded.strategy.currency;
                if (
                  strategyCurrency !== "local" &&
                  strategyCurrency !== currencyBenchmark
                ) {
                  // call endpoint to convert
                  const endPointRoot = this.getEndpointRoot(
                    this.environment.api.compute
                  );
                  const url =
                    endPointRoot +
                    endpoints["instruments"]["historiesConverted"];
                  const _params = {
                    H: response,
                    currencyFrom: currencyBenchmark,
                    currencyTo: strategyCurrency,
                  };

                  const result = await this.preparePost(url, _params, null);
                  const converted = result.data;
                  return converted.sort(function (a: any, b: any) {
                    return a.d > b.d ? 1 : b.d > a.d ? -1 : 0;
                  });
                }

                return response.sort(function (a: any, b: any) {
                  return a.d > b.d ? 1 : b.d > a.d ? -1 : 0;
                });
              } else {
                return null;
              }
            });
        }
      }

      const responsePrice = await httpAll({
        strategy: this.priceLevel(encoded, cleanPOS(positions.POS.H)),
        strategyShort:
          positions.SPOS.H != null
            ? this.priceLevel(encoded, cleanPOS(positions.SPOS.H))
            : null,
        benchmark: benchmarkRunPromise,
        benchmarkShort:
          response.benchmark != null && positions.SPOS.B != null
            ? this.priceLevel(encoded, cleanPOS(positions.SPOS.B))
            : null,
      });

      if (
        responsePrice.benchmark != null &&
        responsePrice.strategy.length > 0
      ) {
        let firstDate = responsePrice.strategy[0].d;
        // In neutral strategy:
        // Overwrite benchmark result cutting data to match
        // the strategy price level starting date
        responsePrice.benchmark = this._cutBenchmarkPriceLevel(
          firstDate,
          responsePrice.benchmark
        );
      }

      // For cutting use always strategy, not strategyShort
      if (
        responsePrice.benchmarkShort != null &&
        responsePrice.strategy.length > 0
      ) {
        let firstDate = responsePrice.strategy[0].d;
        // In neutral strategy:
        // Overwrite benchmark result cutting data to match
        // the strategy price level starting date
        responsePrice.benchmarkShort = this._cutBenchmarkPriceLevel(
          firstDate,
          responsePrice.benchmarkShort
        );
      }

      // At this point, the price levels are available
      // Combine it if there is a short

      let priceLevelStrategy = responsePrice.strategy;
      if (responsePrice.strategyShort != null) {
        priceLevelStrategy = this.hedging({
          priceLevel: responsePrice.strategy,
          hedgingPriceLevel: responsePrice.strategyShort,
          strategyParams: encoded,
        });
      }

      let priceLevelBenchmark = responsePrice.benchmark;
      if (responsePrice.benchmarkShort != null) {
        priceLevelBenchmark = this.hedging({
          priceLevel: responsePrice.benchmark,
          hedgingPriceLevel: responsePrice.benchmarkShort,
          strategyParams: encoded,
        });
      }

      const responsePriceCombined = await httpAll({
        strategy: priceLevelStrategy,
        benchmark: priceLevelBenchmark,
      });

      // TODO review response format for UI
      // BPOS = Benchmark POS, BSPOS = Benchmark SPOS
      return {
        data: {
          POS: positions.POS.H,
          SPOS: positions.SPOS.H,
          BPOS: positions.POS.B,
          BSPOS: positions.SPOS.B,
          CURVES: {
            H: responsePriceCombined.strategy,
            B: responsePriceCombined.benchmark,
          },
        },
        status: "OK",
      };
    } catch (error) {
      console.log("Error");
      console.log(error);
      return error;
    }
  }

  _cutBenchmarkPriceLevel(firstDate: any, benchmark: any) {
    if (firstDate == null) {
      return benchmark;
    }
    if (benchmark != null) {
      return benchmark
        .filter((item: any) => item.d >= firstDate)
        .map((item: any) => ({
          d: item.d,
          v: item.v,
        }));
    }
  }

  status(eventSourceId: any, callback: any) {
    const computeId = eventSourceId;
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);

    if (window.EventSource) {
      let url = endPointRoot + endpoints.strategies.status + computeId;
      let stream = new EventSource(url);

      const buildIndexListener = (stream: EventSource) => {
        return (response: any) => {
          const data = JSON.parse(response.data);
          if ("status" in data && data.status === "stop") {
            stream.close();
          }
          callback(data);
        };
      };
      const buildErrorListener = (stream: EventSource) => {
        return (error: any) => {
          console.error(error);
          stream.close();
        };
      };
      // TODO missing maybe removeEventListener ?
      stream.addEventListener("index", buildIndexListener(stream), false);
      stream.addEventListener("error", buildErrorListener(stream), false);
    } else {
      // IE 11
      let url = endPointRoot + endpoints.strategies.statusLegacy + computeId;
      let headers = {
        "Content-Type": "application/json",
      };
      let request = this.prepareGet(url, null, headers);

      if (this._statusPollingTimeout == null) {
        this._statusPollingTimeout = {};
      }

      if (callback) {
        if (this._statusPollingTimeout[computeId]) {
          clearTimeout(this._statusPollingTimeout[computeId]);
        }
        this._statusPollingTimeout[computeId] = setTimeout(() => {
          request.then(
            (response) => {
              const data = response.data;
              let isTheEnd = false;
              for (const datum of data) {
                if (datum.type === "index") {
                  if (datum.message.status === "stop") {
                    isTheEnd = true;
                  }
                  callback(datum.message);
                }
              }

              if (!isTheEnd) {
                this.status(eventSourceId, callback);
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }, 10);
      }

      return request;
    }
  }

  /**
   * Update a strategy
   *
   * @param {object} strategy
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async update(strategy: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.strategies.saveAndClearCache;

    const strategyToUpdate = deepClone(strategy);

    if (strategy.LEGACY) {
      console.warn("Unexpected field LEGACY in data structure");
      delete strategy.LEGACY;
    }

    strategyToUpdate.params = this.encode(strategyToUpdate.params);

    // created/modified
    const timestamp = new Date().toISOString();
    strategyToUpdate["created"] = // managing old without attribute
      strategyToUpdate["created"] == null ? null : strategyToUpdate["created"];
    strategyToUpdate["modified"] = timestamp;

    strategyToUpdate["ownerId"] = this.environment.account.user?.id;
    strategyToUpdate["type"] = this.TYPE;

    const response = await this.preparePost(url, strategyToUpdate, null);
    const error = this.simulateHttpError(response);
    if (error != null) {
      return error;
    }
    const strategyUpdated = deepClone(response["data"]["object"]);
    strategyUpdated["id"] = response["data"]["id"];
    strategyUpdated["ownerId"] = response["data"]["ownerId"];
    if (response["data"]["tags"] != null) {
      strategyUpdated["tags"] = response["data"]["tags"];
    }
    /* _fixIncoherence */
    const fixedResponse = this._fixMessyApi(strategyUpdated);
    return this._decodeGetHelper(fixedResponse);
  }

  // ----------------------------------------------------- private methods

  // remove irrelevant properties to reduce the size of request payload
  _cleanupAllocationData(params: any) {
    const optimized: any = [];
    const allocations = deepClone(params.allocations);

    // Can only clean an array
    if (!Array.isArray(allocations)) {
      return params;
    }

    for (const allocation of allocations) {
      const item: any = {
        d: allocation.d,
        de: allocation.de,
        i: allocation.i,
        i_g: allocation.i_g,
        v: [],
      };
      for (const pos of allocation.v) {
        if (pos.S === "CASH") {
          item.v.push({
            A: pos.A,
            S: pos.S,
            V: pos.V,
          });
        } else {
          item.v.push({
            A: pos.A,
            S: pos.S,
          });
        }
      }
      optimized.push(item);
    }

    params.allocations = optimized;

    return params;
  }
  // #region ---------------------------------------------------- decoders
  _decodeAnalytics(response: any) {
    response["aggregated"] = response["aggregated"]["error"]
      ? {
          STATS: {
            aggregated: {
              average: {
                benchmark: {
                  MaxDrowDown: null,
                  StdDev: null,
                },
                diff: {
                  MaxDrowDown: null,
                  StdDev: null,
                },
                equity: {
                  MaxDrowDown: null,
                  StdDev: null,
                },
              },
              total: {
                benchmark: {
                  AnnualizedRateOfReturn: null,
                  performances: {
                    DAILY: null,
                    MONTHLY: null,
                    MTD: null,
                    QTD: null,
                    QUARTERLY: null,
                    WEEKLY: null,
                    YEARLY: null,
                    YTD: null,
                  },
                },
                diff: {
                  AnnualizedRateOfReturn: null,
                  performances: {
                    DAILY: null,
                    MONTHLY: null,
                    MTD: null,
                    QTD: null,
                    QUARTERLY: null,
                    WEEKLY: null,
                    YEARLY: null,
                    YTD: null,
                  },
                },
                equity: {
                  AnnualizedRateOfReturn: null,
                  performances: {
                    DAILY: null,
                    MONTHLY: null,
                    MTD: null,
                    QTD: null,
                    QUARTERLY: null,
                    WEEKLY: null,
                    YEARLY: null,
                    YTD: null,
                  },
                },
              },
            },
          },
        }
      : response["aggregated"];

    const aggregated = response["aggregated"]["STATS"]["aggregated"];
    const detail = response["details"]["STATS"]["detail"];
    const detailMonth = response["details"]["STATS"]["detailMonth"];
    const detailQuarter = response["details"]["STATS"]["detailQuarter"];
    // #region -------------------------------------------------- yearly
    const yearly: any = [];
    for (const year in detail) {
      const stats = detail[year];
      const row = {
        timeFrame: year,

        strategyYearlyPerformance: stats.equity.TotalPerf,
        strategyMaxDrawdown: stats.equity.MaxDrowDown,
        strategyVolatility: stats.equity.StdDev,

        benchmarkYearlyPerformance: null,
        benchmarkMaxDrawdown: null,
        benchmarkVolatility: null,

        deltaYearlyPerformance: null,
        deltaMaxDrawdown: null,
        deltaVolatility: null,

        turnover: null,
      };

      // Turnover can be empty at the start of the year
      if (stats.equity.Turnover != null) {
        row["turnover"] = stats.equity.Turnover;
      }

      if (stats.benchmark != null) {
        row["benchmarkYearlyPerformance"] = stats.benchmark.TotalPerf;
        row["benchmarkMaxDrawdown"] = stats.benchmark.MaxDrowDown;
        row["benchmarkVolatility"] = stats.benchmark.StdDev;
      }

      if (stats.diff != null) {
        row["deltaYearlyPerformance"] = stats.diff.TotalPerf;
        row["deltaMaxDrawdown"] = stats.diff.MaxDrowDown;
        row["deltaVolatility"] = stats.diff.StdDev;
      }

      yearly.push(row);
    }
    // total average
    let rawAverage = aggregated.average;
    let rawTotal = aggregated.total;

    const row = {
      timeFrame: "Annualized",

      strategyYearlyPerformance: rawTotal.equity.AnnualizedRateOfReturn,
      strategyMaxDrawdown: rawAverage.equity.MaxDrowDown,
      strategyVolatility: rawAverage.equity.StdDev,

      benchmarkYearlyPerformance: null,
      benchmarkMaxDrawdown: null,
      benchmarkVolatility: null,

      deltaYearlyPerformance: null,
      deltaMaxDrawdown: null,
      deltaVolatility: null,

      turnover: rawAverage.equity.Turnover,
    };

    if (rawAverage.benchmark != null) {
      row["benchmarkYearlyPerformance"] =
        rawTotal.benchmark.AnnualizedRateOfReturn;
      row["benchmarkMaxDrawdown"] = rawAverage.benchmark.MaxDrowDown;
      row["benchmarkVolatility"] = rawAverage.benchmark.StdDev;
    }

    if (rawAverage.diff != null) {
      row["deltaYearlyPerformance"] = rawTotal.diff.AnnualizedRateOfReturn;
      row["deltaMaxDrawdown"] = rawAverage.diff.MaxDrowDown;
      row["deltaVolatility"] = rawAverage.diff.StdDev;
    }

    yearly.push(row);
    // #endregion ------------------------------------------------------
    // #region ------------------------------------------------- monthly
    const monthly: any = [];
    for (const yearMonth in detailMonth) {
      const stats = detailMonth[yearMonth];
      const row = {
        timeFrame: yearMonth,

        strategyMonthlyPerformance: stats.equity.TotalPerf,
        strategyMaxDrawdown: stats.equity.MaxDrowDown,
        strategyVolatility: stats.equity.StdDev,

        benchmarkMonthlyPerformance: null,
        benchmarkMaxDrawdown: null,
        benchmarkVolatility: null,

        deltaMonthlyPerformance: null,
        deltaMaxDrawdown: null,
        deltaVolatility: null,
      };

      if (stats.benchmark != null) {
        row["benchmarkMonthlyPerformance"] = stats.benchmark.TotalPerf;
        row["benchmarkMaxDrawdown"] = stats.benchmark.MaxDrowDown;
        row["benchmarkVolatility"] = stats.benchmark.StdDev;
      }

      if (stats.diff != null) {
        row["deltaMonthlyPerformance"] = stats.diff.TotalPerf;
        row["deltaMaxDrawdown"] = stats.diff.MaxDrowDown;
        row["deltaVolatility"] = stats.diff.StdDev;
      }

      monthly.push(row);
    }
    // total average
    rawAverage = detailMonth.average;
    rawTotal = aggregated.total;
    if (rawAverage && rawTotal) {
      const row = {
        timeFrame: "Annualized",

        strategyMonthlyPerformance: rawTotal.equity.AnnualizedRateOfReturn,
        strategyMaxDrawdown: rawAverage.equity.MaxDrowDown,
        strategyVolatility: rawAverage.equity.StdDev,

        benchmarkMonthlyPerformance: null,
        benchmarkMaxDrawdown: null,
        benchmarkVolatility: null,

        deltaMonthlyPerformance: null,
        deltaMaxDrawdown: null,
        deltaVolatility: null,
      };

      if (rawAverage.benchmark != null) {
        row["benchmarkMonthlyPerformance"] =
          rawTotal.benchmark.AnnualizedRateOfReturn;
        row["benchmarkMaxDrawdown"] = rawAverage.benchmark.MaxDrowDown;
        row["benchmarkVolatility"] = rawAverage.benchmark.StdDev;
      }

      if (rawAverage.diff != null) {
        row["deltaMonthlyPerformance"] = rawTotal.diff.AnnualizedRateOfReturn;
        row["deltaMaxDrawdown"] = rawAverage.diff.MaxDrowDown;
        row["deltaVolatility"] = rawAverage.diff.StdDev;
      }

      monthly.push(row);
    }
    // #endregion ------------------------------------------------------
    // #region ----------------------------------------------- quarterly
    const quarterly: any = [];
    for (const yearMonth in detailQuarter) {
      const stats = detailQuarter[yearMonth];
      const row = {
        timeFrame: yearMonth,

        strategyQuarterlyPerformance: stats.equity.TotalPerf,
        strategyMaxDrawdown: stats.equity.MaxDrowDown,
        strategyVolatility: stats.equity.StdDev,

        benchmarkQuarterlyPerformance: null,
        benchmarkMaxDrawdown: null,
        benchmarkVolatility: null,

        deltaQuarterlyPerformance: null,
        deltaMaxDrawdown: null,
        deltaVolatility: null,
      };

      if (stats.benchmark != null) {
        row["benchmarkQuarterlyPerformance"] = stats.benchmark.TotalPerf;
        row["benchmarkMaxDrawdown"] = stats.benchmark.MaxDrowDown;
        row["benchmarkVolatility"] = stats.benchmark.StdDev;
      }

      if (stats.diff != null) {
        row["deltaQuarterlyPerformance"] = stats.diff.TotalPerf;
        row["deltaMaxDrawdown"] = stats.diff.MaxDrowDown;
        row["deltaVolatility"] = stats.diff.StdDev;
      }

      quarterly.push(row);
    }
    // total average
    rawAverage = detailQuarter.average;
    rawTotal = aggregated.total;
    if (rawAverage && rawTotal) {
      const row = {
        timeFrame: "Annualized",

        strategyQuarterlyPerformance: rawTotal.equity.AnnualizedRateOfReturn,
        strategyMaxDrawdown: rawAverage.equity.MaxDrowDown,
        strategyVolatility: rawAverage.equity.StdDev,

        benchmarkQuarterlyPerformance: null,
        benchmarkMaxDrawdown: null,
        benchmarkVolatility: null,

        deltaQuarterlyPerformance: null,
        deltaMaxDrawdown: null,
        deltaVolatility: null,
      };

      if (rawAverage.benchmark != null) {
        row["benchmarkQuarterlyPerformance"] =
          rawTotal.benchmark.AnnualizedRateOfReturn;
        row["benchmarkMaxDrawdown"] = rawAverage.benchmark.MaxDrowDown;
        row["benchmarkVolatility"] = rawAverage.benchmark.StdDev;
      }

      if (rawAverage.diff != null) {
        row["deltaQuarterlyPerformance"] = rawTotal.diff.AnnualizedRateOfReturn;
        row["deltaMaxDrawdown"] = rawAverage.diff.MaxDrowDown;
        row["deltaVolatility"] = rawAverage.diff.StdDev;
      }

      quarterly.push(row);
    }
    // #endregion ------------------------------------------------------

    // #region -------------------------------------------------- totals
    const aggregatedTotals = {
      benchmarkAnnualizedRateOfReturn: null,
      benchmarkTotalPerformance: null,
      deltaAnnualizedRateOfReturn: null,
      deltaTotalPerformance: null,
      strategyAnnualizedRateOfReturn: null,
      strategyTotalPerformance: null,
      strategyTurnover: null,
    };
    const rawAggregatedTotals = aggregated.total;
    if (rawAggregatedTotals != null) {
      if (rawAggregatedTotals.benchmark != null) {
        aggregatedTotals["benchmarkAnnualizedRateOfReturn"] =
          rawAggregatedTotals.benchmark.AnnualizedRateOfReturn;
        aggregatedTotals["benchmarkTotalPerformance"] =
          rawAggregatedTotals.benchmark.TotalPerf;
      }
      if (rawAggregatedTotals.diff != null) {
        aggregatedTotals["deltaAnnualizedRateOfReturn"] =
          rawAggregatedTotals.diff.AnnualizedRateOfReturn;
        aggregatedTotals["deltaTotalPerformance"] =
          rawAggregatedTotals.diff.TotalPerf;
      }
      if (rawAggregatedTotals.equity != null) {
        aggregatedTotals["strategyAnnualizedRateOfReturn"] =
          rawAggregatedTotals.equity.AnnualizedRateOfReturn;
        aggregatedTotals["strategyTotalPerformance"] =
          rawAggregatedTotals.equity.TotalPerf;
        aggregatedTotals["strategyTurnover"] =
          rawAggregatedTotals.equity.Turnover;
      }
    }
    // #endregion ------------------------------------------------------

    // aggregated key is only used for systematic portfolios
    const analytics = {
      aggregated: response["aggregated"],
      aggregatedTotals: aggregatedTotals,
      monthly: monthly,
      quarterly: quarterly,
      yearly: yearly,
    };

    return analytics;
  }

  _decodeGet(response: any) {
    if ("rows" in response["data"]) {
      // multiple
      const data: any = [];
      for (const row of response["data"]["rows"]) {
        const strategy = this._decodeGetHelper(row);
        data.push(strategy);
      }

      const strategies = {
        data: data,
        dataTotalCount: data.length,
      };

      return strategies;
    } else {
      // single

      let error: any = null;
      if (response["data"]["preference"] == null) {
        error = this.simulateHttpError(response);
      }

      if (error != null) {
        return error;
      }

      let strategy = this._decodeGetHelper(response["data"]["preference"]);

      return strategy;
    }
  }

  _decodeGetHelper(objectStore: any) {
    let strategy = StrategyTranspiler.migrate(
      objectStore,
      this.environment.account.user?.group
    );

    // #region ---------------------------------------------------------
    //
    // 2021-11-10 __V19__
    //
    // Finish the refactor of the Alpha, SmartBeta and MacroRotation StrategyBuilder
    //

    // Remove LEGACY if there is still this saved in database
    if (strategy.LEGACY) {
      delete strategy.LEGACY;
    }

    // #endregion ------------------------------------------------------

    strategy = StrategyTranspiler.validateAndConvert(strategy);

    strategy["id"] = objectStore.id;
    strategy["params"] = this.decode(strategy["params"]);

    return strategy;
  }

  _decodeHedgingConstraints(constraints: any) {
    const hedgingTransformations =
      this.configurationStrategyBuilder["formAdvanced"]["enabled"] === true
        ? this.configurationStrategyBuilder["hedging"]["edit"][
            "transformations"
          ]
        : null;
    const hedging = new SelectionUi2Api({
      transformations: hedgingTransformations,
    });
    const decodedValue = hedging.decode(constraints);

    const decoded = {
      hedgingStrategy: "HEDGING_ADVANCED",
      value: decodedValue,
    };

    if (decodedValue.length === 0) {
      decoded["hedgingStrategy"] = "HEDGING_FULL";
    } else if (
      decodedValue.length === 1 &&
      decodedValue[0]["property"] === "rc" &&
      decodedValue[0]["operatorParams"]["value"]["A"] === false &&
      decodedValue[0]["operatorParams"]["value"]["B"] === false &&
      decodedValue[0]["operatorParams"]["value"]["C"] === true &&
      decodedValue[0]["operatorParams"]["value"]["D"] === true
    ) {
      decoded["hedgingStrategy"] = "HEDGING_SMART";
    }
    return decoded;
  }

  _decodeHoldings(response: any) {
    const contributions = response["contributions"]["contribution"]["v"];
    const instrumentsMap: any = {};
    for (const contribution of contributions) {
      if (contribution.S !== "CASH") {
        instrumentsMap[contribution.S] = contribution;
      }
    }

    const holdings: any = [];
    const instruments = response["instruments"]["data"];

    for (const item of instruments) {
      const symbol = item.symbol;
      item.dr = instrumentsMap[symbol].D;
      item.rc = instrumentsMap[symbol].R;
      item.weight = instrumentsMap[symbol].A;
      item.performance = instrumentsMap[symbol].G;
      item.contribution = instrumentsMap[symbol].C;

      holdings.push(item);
    }

    return holdings;
  }

  _decodeLongShort(
    startingDate: any,
    run1: any,
    run2: any,
    run3: any,
    run1Params: any,
    run2Params: any,
    run3Params: any,
    response: any
  ) {
    let error: any = null;
    if ("WARNING" in response["combined1"]["data"]) {
      error = this.simulateHttpError(response["combined1"]["data"]);

      if (error != null) {
        return error;
      }
    }
    if ("WARNING" in response["combined"]["data"]) {
      error = this.simulateHttpError(response["combined"]["data"]);

      if (error != null) {
        return error;
      }
    }
    if ("WARNING" in response["combined2"]["data"]) {
      error = this.simulateHttpError(response["combined2"]["data"]);

      if (error != null) {
        return error;
      }
    }
    if (response?.["combined3"] != null) {
      if ("WARNING" in response?.["combined3"]?.["data"]) {
        error = this.simulateHttpError(response?.["combined3"]["data"]);

        if (error != null) {
          return error;
        }
      }
    }

    const result: any = {
      combined1: {
        CURVES: {
          H: [],
        },
      },
      run1: run1,
      combined: {
        CURVES: {
          H: [],
        },
      },
      combined2: {
        CURVES: {
          H: [],
        },
      },
      run2: run2,
      combined3: {
        CURVES: {
          H: [],
        },
      },
      run3: run3,
      run1Params: run1Params,
      run2Params: run2Params,
      run3Params: run3Params,
      startingDate: startingDate,
    };
    if (response["combined1"]["status"] === "OK") {
      result["combined1"] = response["combined1"]["data"];
    }
    if (response["combined"]["status"] === "OK") {
      result["combined"] = response["combined"]["data"];
    }
    if (response["combined2"]["status"] === "OK") {
      result["combined2"] = response["combined2"]["data"];
    }
    if (response?.["combined3"]?.["status"] === "OK") {
      result["combined3"] = response?.["combined3"]?.["data"];
    }
    // Add benchmark to all
    if (run1["CURVES"]["B"] != null) {
      result["combined"]["CURVES"]["B"] = run1["CURVES"]["B"];
      result["combined1"]["CURVES"]["B"] = run1["CURVES"]["B"];
      result["combined2"]["CURVES"]["B"] = run1["CURVES"]["B"];
      result["combined3"]["CURVES"]["B"] = run1["CURVES"]["B"];
    }
    return result;
  }

  _decodePeerLevel(where: any, what: any) {
    if (where != null && what != null) {
      return where + this._peerLevelSeparator + what;
    } else if (where != null && what == null) {
      return where;
    } else if (where == null && what != null) {
      return what;
    }
    return null;
  }

  _decodePerformances(response: any) {
    const analyticsTotal = response["STATS"]["aggregated"]["total"];

    const performanceModelObject = {
      DAILY: null,
      LTD: null,
      MONTHLY: null,
      QUARTERLY: null,
      WEEKLY: null,
      YEARLY: null,
      YEARLY_10: null,
      YEARLY_10_N: null,
      YEARLY_3: null,
      YEARLY_3_N: null,
      YEARLY_5: null,
      YEARLY_5_N: null,
      MTD: null,
      QTD: null,
      YTD: null,
    };

    const performances = {
      benchmark:
        analyticsTotal["benchmark"] == null
          ? null
          : cloneObjectLiteral(
              performanceModelObject,
              analyticsTotal["benchmark"]["performances"]
            ),
      delta:
        analyticsTotal["diff"] == null
          ? null
          : cloneObjectLiteral(
              performanceModelObject,
              analyticsTotal["diff"]["performances"]
            ),
      strategy: cloneObjectLiteral(
        performanceModelObject,
        analyticsTotal["equity"]["performances"]
      ),
    };

    if (analyticsTotal["benchmark"] != null) {
      performances["benchmark"]["LTD"] =
        analyticsTotal["benchmark"]["AnnualizedRateOfReturn"];
    }
    if (analyticsTotal["diff"] != null) {
      performances["delta"]["LTD"] =
        analyticsTotal["diff"]["AnnualizedRateOfReturn"];
    }
    performances["strategy"]["LTD"] =
      analyticsTotal["equity"]["AnnualizedRateOfReturn"];

    return performances;
  }

  _decodePricingPerformance(value: any) {
    switch (value) {
      case "rebalance": {
        return "REBALANCE";
      }
      case "none":
      default: {
        return "NONE";
      }
    }
  }

  _decodeRebalance(value: any) {
    switch (value) {
      case "WEEKLY": {
        return "05_DAYS";
      }
      case "MONTHLY": {
        return "20_DAYS";
      }
      case "QUARTERLY": {
        return "60_DAYS";
      }
      default:
        break;
    }
  }

  _decodeRotationFactor(value: any) {
    switch (value) {
      case "abHistoryPerc": {
        return "FACTOR_MOMENTUM";
      }
      case "neutral": {
        return "FACTOR_MARKET_CAP_NEUTRAL";
      }
      case "upDownCumHistoryPercScale": {
        return "FACTOR_MOMENTUM_GROWTH";
      }
      default:
        break;
    }
  }

  _decodeSelectionConstraints(constraints: any) {
    const selectionTransformations =
      this.configurationStrategyBuilder["selection"]["edit"]["transformations"];
    const selection = new SelectionUi2Api({
      transformations: selectionTransformations,
    });

    return selection.decode(constraints);
  }

  _decodeWeightSchema(value: any) {
    switch (value) {
      case "EQUAL_WEIGHTED": {
        return "WEIGHT_EQUAL";
      }
      case "PROPORTIONAL_MARKETCAP": {
        return "WEIGHT_MARKET_CAP";
      }
      default:
        break;
    }
  }

  _decodeWeightSchemaExistingPositions(value: any) {
    switch (value) {
      case "KEEP_BALANCE_LOOSE": {
        return "WEIGHT_EXISTING_POSITIONS_KEEP";
      }
      case "REBALANCE": {
        return "WEIGHT_EXISTING_POSITIONS_REBALANCE";
      }
      default:
        break;
    }
  }
  // #endregion ----------------------------------------------------------

  // #region ---------------------------------------------------- encoders
  _encodePeerLevel(value: any) {
    const geometry: any = {
      what: null,
      where: null,
    };

    const _peerType: any = {
      Area: "where",
      Country: "where",
      Region: "where",

      "1 Industry": "what",
      "3 Sector": "what",
      "4 Subsector": "what",
    };

    // if tokens.length > 1 the first is the where and the second
    // is the what
    const tokens = value.split(this._peerLevelSeparator);
    let peerType: any = null;
    if (tokens.length === 1) {
      peerType = _peerType[tokens[0]];
      geometry[peerType] = tokens[0];
    } else if (tokens.length === 2) {
      peerType = _peerType[tokens[0]];
      geometry[peerType] = tokens[0];

      peerType = _peerType[tokens[1]];
      geometry[peerType] = tokens[1];
    }

    return geometry;
  }

  _encodePricingPerformance(value: any) {
    switch (value) {
      case "REBALANCE": {
        return "rebalance";
      }
      case "NONE":
      default: {
        return "none";
      }
    }
  }

  _encodeRebalance(value: any) {
    switch (value) {
      case "05_DAYS": {
        return "WEEKLY";
      }
      case "20_DAYS": {
        return "MONTHLY";
      }
      case "60_DAYS": {
        return "QUARTERLY";
      }
      default:
        break;
    }
  }

  _encodeRotationFactor(value: any) {
    switch (value) {
      case "FACTOR_MOMENTUM": {
        return "abHistoryPerc";
      }
      case "FACTOR_MARKET_CAP_NEUTRAL": {
        return "neutral";
      }
      case "FACTOR_MOMENTUM_GROWTH": {
        return "upDownCumHistoryPercScale";
      }
      default:
        break;
    }
  }

  _encodeWeightSchema(value: any) {
    switch (value) {
      case "WEIGHT_EQUAL": {
        return "EQUAL_WEIGHTED";
      }
      case "WEIGHT_MARKET_CAP": {
        return "PROPORTIONAL_MARKETCAP";
      }
      default:
        break;
    }
  }

  _encodeWeightSchemaExistingPositions(value: any) {
    switch (value) {
      case "WEIGHT_EXISTING_POSITIONS_KEEP": {
        return "KEEP_BALANCE_LOOSE";
      }
      case "WEIGHT_EXISTING_POSITIONS_REBALANCE": {
        return "REBALANCE";
      }
      default:
        break;
    }
  }
  // #endregion ----------------------------------------------------------

  //
  // Convert object response in a server-like pre-normalized format
  //
  _fixMessyApi(strategy: any) {
    const tags = strategy["tags"];

    return {
      id: strategy["id"],
      name: strategy["name"],
      object: strategy,
      ownerId: strategy["ownerId"],
      tags: tags,
      type: strategy["type"],
    };
  }

  // TODO - use _StoredObjects
  _remove(strategyId: any, response: any) {
    const error = this.simulateHttpError(response);

    if (error != null) {
      return error;
    }

    const trackedStrategies =
      this.http["accountPreferences"].getTrackedStrategies();

    if (trackedStrategies) {
      const trackedStrategiesUpdated = trackedStrategies.filter(
        (item: any) => item !== strategyId
      );

      this.http["accountPreferences"].setPreferenceKey(
        "indexes",
        trackedStrategiesUpdated
      );
    }

    return { removed: true };
  }
  // ----------------------------------------------------------- utilities
  _prepareParamsKeyFacts(params: any) {
    if (params == null) {
      return null;
    }
    const strategyParamsEncoded = this.encode(
      deepClone(params["strategy"]["params"])
    );

    const _params: any = {
      H: params?.strategyResult?.CURVES?.H ?? null,
      B: params?.strategyResult?.CURVES?.B ?? null,
      allocations: [],
      reviewGranularity: strategyParamsEncoded.backtesting.reviewGranularity,
    };

    // Needed for holdings
    if (params?.strategyResult?.POS != null) {
      if (Array.isArray(params.strategyResult.POS)) {
        _params["allocations"] = params.strategyResult.POS;
      }
    }

    return _params;
  }
}
