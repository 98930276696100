import { Mutex } from "../../../Utility/Mutex";
import { _Base } from "../../../api/_Base";
import { Analytics } from "../../../api/compute/Analytics/Analytics";
import { Instruments } from "../../../api/compute/Instruments";
import { Lists } from "../../../api/compute/Lists";
import { Strategies } from "../../../api/compute/Strategies";
import { SystematicProducts } from "../../../api/compute/SystematicProducts";
import { Subscriptions } from "../../../api/compute/Subscriptions";
import { endpoints } from "../../../api/endpoints";
import { deepClone } from "../../../deepClone";
import { TDate } from "../../../trendrating/date/TDate";
import { Formatter } from "../../../trendrating/formatter/Formatter";
import { Strategy, SystematicProduct } from "../../../types/Api";
import { AppEnvironment } from "../../../types/Defaults";
import { StrategyComputedTabType } from "../pages/strategies/builder/editors/Advanced/Result/Result";
import { ChartSerie } from "../utils";
import { widgetsConfiguration } from "../widgets/widgetsConfiguration";
import { StrategiesInterface, rawAnalytics } from "./StrategiesStorage";

type ProductUI = {
  analytics?: {
    preview: {
      H: {
        oneDay: number | null;
        oneWeek: number | null;
        oneMonth: number | null;
        threeMonths: number | null;
        MTD: number | null;
        QTD: number | null;
        YTD: number | null;
        oneYear: number | null;
        threeYears: number | null;
        fiveYears: number | null;
        annualized: number | null;
        total: number | null;
      };
      B?: {
        oneDay: number | null;
        oneWeek: number | null;
        oneMonth: number | null;
        threeMonths: number | null;
        MTD: number | null;
        QTD: number | null;
        YTD: number | null;
        oneYear: number | null;
        threeYears: number | null;
        fiveYears: number | null;
        annualized: number | null;
        total: number | null;
      };
      D?: {
        oneDay: number | null;
        oneWeek: number | null;
        oneMonth: number | null;
        threeMonths: number | null;
        MTD: number | null;
        QTD: number | null;
        YTD: number | null;
        oneYear: number | null;
        threeYears: number | null;
        fiveYears: number | null;
        annualized: number | null;
        total: number | null;
      };
    } | null;
  };
  history?: any;
  chart?: {
    benchmark: any;
    backtestEndsAt: any;
    list: any;
  };
  rebalanceInfo?: {
    next: any;
    previous: any;
    status: any;
    today: any;
  };
  summary?: {
    autorebalance: any;
    benchmark: any;
    closingDate: any;
    currency: any;
    dateEdit: any;
    performance: any;
    price: any;
    rebalance: any;
    expenseRatio: any;
  };
  snapShot?: {};
  holdings?: {};
};

type HoldingsAllocatinoObject = {
  d: number | null;
  A: number | null;
  P: number | null;
  CARD: number | null;
  ratings: {
    A: number | null;
    B: number | null;
    C: number | null;
    NA: number | null;
    D: number | null;
  };
  ratingWeights: {
    A: number | null;
    B: number | null;
    C: number | null;
    NA: number | null;
    D: number | null;
  };
};

const CURVE_DICT = {
  H: "strategy",
  B: "benchmark",
  D: "delta",
};

export class SystematicPortfoliosStorage extends _Base {
  smsAPI: SystematicProducts;
  subscriptionsApi: Subscriptions;
  appSetup: AppEnvironment;
  mutex: Mutex;

  constructor(environment: AppEnvironment) {
    super(environment);
    this.appSetup = environment;
    this.smsAPI = new SystematicProducts(this.appSetup);
    this.subscriptionsApi = new Subscriptions(environment);
    this.mutex = new Mutex();
  }

  /**
   * Cache management
   */
  private smsProductsList = null;
  private products = {};

  private setProducts(products) {
    this.smsProductsList = products;
  }

  public getProduct(id: number) {
    if (this.products[id]) {
      return this.products[id];
    }

    return undefined;
  }

  public async setProduct(productId: number) {
    const rawProduct = await this.smsAPI.fetch({
      ids: [productId],
      properties: [
        "autorebalance",
        "benchmark",
        "currency",
        "expenseRatio",
        "historicalPortfolioId",
        "id",
        "inceptionValue",
        "inceptionDate",
        "creationTime",
        "updateTime",
        "name",
        "ownerId",
        "rebalanceTime",
        "reviewGranularity",
        "strategyId",
      ],
    });

    const product = rawProduct[0];

    product["isReadOnly"] =
      product["ownerId"] !== this.environment.account.user?.id;

    product["type"] = "SYSTEMATIC_PRODUCT";

    this.products[productId] = new Product(this.environment, product);
    await this.products[productId].initProduct();
  }

  public invalidateProduct(id) {
    delete this.products[id];
  }

  public invalidateCache() {
    this.smsProductsList = null;
  }

  public async getProducts() {
    if (this.smsProductsList != null) {
      return this.smsProductsList;
    }

    const products = await this.getSystematicProduct();
    this.setProducts(products ?? null);

    return this.smsProductsList;
  }

  /*************************************************************************************************************** */

  /**
   *
   * @param {funcion} translate
   *
   * @returns the set of tab available based on use group
   */
  public getTabs(translate) {
    // const userGroup = this.appSetup.account.user?.group;
    // const key = userGroup === "INTERNAL" ? "internal" : "commonUser";
    const key = "internal";

    const tabs = {
      internal: [
        {
          label: translate("Custom_Systematic_Portfolios"),
          value: "userItems",
        },
        {
          label: translate("Subscribed_Systematic_Portfolios"),
          value: "subscribedItems",
        },
        {
          label: translate("Combined_Systematic_Portfolios"),
          value: "combinedItems",
        },
      ],
      commonUser: [
        {
          label: translate("Custom_Systematic_Portfolios"),
          value: "userItems",
        },
        {
          label: translate("Subscribed_Systematic_Portfolios"),
          value: "subscribedItems",
        },
      ],
    };

    return tabs[key];
  }

  public async rebalanceInfo(product) {
    return {
      get: async () => {
        const productStorage = new Product(this.environment, product);
        const productObject = await productStorage.rebalanceInfo();

        return productObject.rebalanceInfo;
      },
    };
  }

  public async getUserProducts() {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.systematicPortfolios.select;

    const payload = {
      searches: [
        {
          filters: [
            {
              dimension: "ownerId",
              segments: [this.environment.account.user?.id],
            },
          ],
        },
      ],
    };

    const response = await this.preparePost(url, payload);

    return response?.data?.ids;
  }

  public async getSubscribedProducts() {
    const response = await this.subscriptionsApi.get({
      type: "systematicPortfolio",
    });

    return response?.map((obj) => obj.id);
  }

  /**
   *
   * @returns The list of systematic product of the user
   */
  private async getSystematicProduct() {
    return this.mutex.runWithMutex(async () => {
      try {
        const getUserSysProducts = this.getUserProducts();
        const getUserSubsProducts = this.subscriptionsApi.get({
          type: "systematicPortfolio",
        });

        const response = await Promise.all([
          getUserSysProducts,
          getUserSubsProducts,
        ]);

        if (response) {
          const userLists = response[0];
          const subscribed = response[1]?.map((obj) => obj.id);

          return [...userLists, ...subscribed];
        }
      } catch (error) {
        console.log(error);
        throw new Error(error as any);
      }
    });
  }
}

export class Product implements StrategiesInterface {
  private analytics: Analytics | null;
  private listsAPI: Lists;
  private instrumentAPI: Instruments;
  private formatter: Formatter;
  private smsAPI: SystematicProducts;
  private strategiesAPI: Strategies;
  private benchmark: any;
  private product: ProductUI;
  private strategy: Strategy | null;
  private historicalPortfolio: any | null;
  private mu: Mutex;

  constructor(
    private environment: AppEnvironment,
    private systematicProduct: SystematicProduct
  ) {
    const appSetup = environment;

    this.analytics = null;
    this.listsAPI = new Lists(appSetup);
    this.instrumentAPI = new Instruments(appSetup);
    this.smsAPI = new SystematicProducts(appSetup);
    this.strategiesAPI = new Strategies(appSetup);
    this.formatter = new Formatter();
    this.mu = new Mutex();

    this.product = {};
    this.benchmark = null;
    this.strategy = null;
    this.historicalPortfolio = null;
  }

  public async getAnalytics(
    tab: StrategyComputedTabType,
    startDate?: number,
    endDate?: number
  ) {
    const self = this;
    return this.mu.runWithMutex(async function () {
      const factoryTag = self.analytics!.aTag;

      if (factoryTag == null) {
        console.error(
          "Cannot Build tags for analytics. factoryTag function is undefined it probably means that getAnalytics method was called before the processStrategy method"
        );
        return;
      }

      let analytics: string[] = [];
      const hasValidBenchmark =
        self.systematicProduct.benchmark != null &&
        self.analytics?.checkBenchmark();
      const parameterSet: any[][] = deepClone(rawAnalytics[tab].H.default);

      if (hasValidBenchmark === true) {
        parameterSet.push(
          ...rawAnalytics[tab].B.default,
          ...rawAnalytics[tab].D.default
        );

        if ("withBenchmark" in rawAnalytics[tab].H) {
          parameterSet.push(...rawAnalytics[tab].H.withBenchmark);
        }
      }

      let analytic: string | null = null;

      const decodeMap = {};

      for (const set of parameterSet) {
        analytic = factoryTag(set[0], set[1], set[2], set?.[3]);

        if (analytic != null) {
          decodeMap[analytic] = null;
          analytics.push(analytic);
        }
      }

      let response: any = null;

      // removes duplicated analytics
      analytics = [...new Set(analytics)];

      response = await self.analytics!.getAnalytics(
        analytics,
        startDate,
        endDate
      );

      if ("status" in response && response.status !== 200) {
        // An error occured
        throw response;
      }

      if (response.data) {
        for (const [key, value] of Object.entries(response.data)) {
          decodeMap[key] = value;
        }

        const result = {};

        for (const analytic of parameterSet) {
          result[analytic[analytic.length - 1]] =
            decodeMap[
              factoryTag(analytic[0], analytic[1], analytic[2], analytic?.[3])
            ];
        }

        response = result;

        return self.responseTranformer(tab, response);
      }
    });
  }

  public analyticsCacheInvalidation() {
    this.analytics?.invalidate("H", "H_PRICES");
    this.analytics?.invalidate("H", "H_POS");
    this.analytics?.invalidate("B", "H_PRICES");
    this.analytics?.invalidate("B", "H_POS");
  }

  public async getCurves() {
    return {
      CURVES: {
        H: await this.getCurve("H", "prices"),
        B: await this.getCurve("B", "prices"),
        long: [],
        short: [],
      },
      POS: {
        H: await this.getCurve("H", "allocations"),
        B: await this.getCurve("B", "allocations"),
      },
    };
  }

  public getBenchmark() {
    return this.benchmark;
  }

  public async holdings() {
    if (this.product && this.product.holdings) {
      return this.product;
    }

    const product =
      this.product && this.product.snapShot != null
        ? this.product
        : await this.snapshot();
    const listHistory = await this.getHistoricalPortfolio();

    const allocations = listHistory?.["POS"];
    if (allocations.length === 0) {
      let snapshot = product["snapShot"];
      snapshot["positions"] = [];

      return snapshot;
    }

    let lastAllocationIndex = allocations.length - 1;
    let lastAllocationDay = allocations[lastAllocationIndex]["d"];

    const paramsAllocationAt = {
      cutoff: "last",
      date: lastAllocationDay,
      listHistory,
      product: this.systematicProduct,
    };

    const allocationAt = await this.smsAPI.allocationAt(paramsAllocationAt);

    const snapshot = product["snapShot"];

    // snapshot holdings weight are actualized at
    // today
    const snapshotHoldingsMap = {};
    let holding: any = null;
    for (var i = 0, length = snapshot["positions"].length; i < length; i++) {
      holding = snapshot["positions"][i];
      snapshotHoldingsMap[holding["symbol"]] = holding;
    }
    // merging actualized weights with contributions
    // data
    const holdings: any = [];
    for (let i = 0; i < allocationAt["positions"].length; i++) {
      holding = allocationAt["positions"][i];
      // skip expired/unavailable
      if (holding["symbol"] in snapshotHoldingsMap) {
        holding["weight"] = snapshotHoldingsMap[holding["symbol"]]["weight"];

        holdings.push(holding);
      }
    }

    this.product.holdings = holdings;

    return this.product;
  }

  public async chart(params: { years: number }) {
    if (this.product.chart) {
      return this.product;
    }

    const priceList = await this.getCurve("H", "prices");
    const benchmarkPriceList = (await this.getCurve("B", "prices")) ?? null;

    const product = this.systematicProduct;
    const hasBenchmark = benchmarkPriceList != null;

    const yearsInDays = 265 * params["years"];

    const listHistory = await this.getHistoricalPortfolio();

    const data: any = {
      benchmark: null,
      backtestEndsAt: null,
      list: null,
      response: listHistory,
    };

    if (priceList) {
      let lastDate = priceList[priceList.length - 1]["d"];
      let firstDate =
        priceList[Math.max(priceList.length - yearsInDays, 0)]["d"];

      const H = priceList
        .filter((item) => {
          return item.d <= lastDate && item.d >= firstDate;
        })
        .sort((a, b) => (b.d > a.d ? -1 : b.d < a.d ? 1 : 0));

      let rescaleIdx = 0;

      let B = benchmarkPriceList;

      if (hasBenchmark) {
        B = benchmarkPriceList
          .filter((item) => {
            return item.d <= lastDate && item.d >= firstDate;
          })
          .sort((a, b) => (b.d > a.d ? -1 : b.d < a.d ? 1 : 0));

        if (B.length > 0) {
          var firstDateBenchmark = B[0].d;
          for (let i = 0; i < H.length; i++) {
            if (H[i].d >= firstDateBenchmark) {
              rescaleIdx = i;
              break;
            }
          }
        }
      }

      // For setting the backtesting line

      // empty history
      if (listHistory["POS"].length === 0) {
        return data;
      }

      if (listHistory["creationTime"] > listHistory["POS"][0]["d"]) {
        data["backtestEndsAt"] = listHistory["creationTime"];

        // if crationTime is ahead of last POS, we set last POS day
        // as backtestEndsAt
        if (
          listHistory["creationTime"] >
          listHistory["POS"][listHistory["POS"].length - 1]["d"]
        ) {
          data["backtestEndsAt"] =
            listHistory["POS"][listHistory["POS"].length - 1]["d"];
        }
      }

      var chartSerie = new ChartSerie(); // Utilities

      var serieList = chartSerie.trendrating2HighchartSerie(H);
      data["list"] = {
        lastAllocationDate:
          listHistory["POS"][listHistory["POS"].length - 1]["d"],
        name: product["name"],
        serie: serieList,
        today: H[H.length - 1]["d"],
      };
      // benchmark
      if (hasBenchmark && B && B.length) {
        var serieBenchmark = chartSerie.trendrating2HighchartSerie(B);

        var resclalePoint = {
          x: serieList[rescaleIdx][0],
          y: serieList[rescaleIdx][1],
        };
        var serieBenchmarkRescaled = chartSerie.rescale(
          serieBenchmark,
          resclalePoint
        );

        data["benchmark"] = {
          lastAllocationDate:
            listHistory["POS"][listHistory["POS"].length - 1]["d"],
          name: this.benchmark["name"],
          serie: serieBenchmarkRescaled,
          today: B[B.length - 1]["d"],
        };
      }

      if (!("chart" in this.product)) {
        this.product.chart = {
          list: null,
          benchmark: null,
          backtestEndsAt: null,
        };
      }

      this.product.chart = data;

      return this.product;
    }
  }

  public async history() {
    const listHistory = await this.getHistoricalPortfolio();

    const data: any = {
      prepared: null,
      raw: {
        instruments: null,
        listHistory,
      },
    };

    const properties =
      widgetsConfiguration["widgets/analysis/collection/edit"]["properties"];

    let symbols: any = [];

    for (const POS of listHistory["POS"]) {
      for (const allocation of POS.v) {
        symbols.push(allocation.S);
      }
    }

    // remove duplicates
    symbols = [...new Set(symbols)];

    try {
      const fetchResponse = await this.instrumentAPI.fetch({
        properties: properties,
        type: "security",
        symbols: symbols,
      });

      data.raw.instruments = fetchResponse;
      const instruments = fetchResponse["data"];

      // active instruments
      let instrument: any = null;
      let patternExpired = /^Expired/gi;
      for (let i = 0, length = instruments.length; i < length; i++) {
        instrument = instruments[i];
        if (patternExpired.test(instrument["type"])) {
          instrument["status"] = "expired";
        } else {
          instrument["status"] = "active";
        }
      }

      instruments.sort(function (a, b) {
        var property = "name";

        if (a[property] > b[property]) {
          return 1;
        }

        if (a[property] < b[property]) {
          return -1;
        }

        return 0;
      });

      const storeInstruments = instruments;

      const getSync = (symbol) => {
        return storeInstruments.find((item) => item.symbol === symbol);
      };

      const addSync = (obect) => {
        storeInstruments.push(obect);
      };

      const putSync = (object) => {
        const objectIndex = storeInstruments.indexOf(object);

        storeInstruments[objectIndex] = object;
      };

      // unavailable instruments
      let unavailable: any = null;
      for (let symbol of symbols) {
        if (symbol !== "CASH" && getSync(symbol) === undefined) {
          unavailable = {
            name: symbol,
            status: "unavailable",
            symbol: symbol,
            ticker: "unavailable_" + symbol,
          };

          addSync(unavailable);
        }
      }

      const allocations = listHistory["POS"];
      let dateProperty: any = null;
      let dateProperties: any = [];
      let allocation: any = null;
      let allocationsStatus: any = {};
      // init weight value to 0
      for (let i = 0, lengthI = allocations.length; i < lengthI; i++) {
        allocation = allocations[i];
        dateProperty = "date_" + allocation["d"];
        dateProperties.push(dateProperty);
        for (var j = 0, lengthJ = instruments.length; j < lengthJ; j++) {
          instruments[j][dateProperty] = 0;
        }

        allocationsStatus[dateProperty] = {
          date: allocation["d"],
          status: "rebalanced",
        };

        if (allocation["creationDate"] !== allocation["modificationDate"]) {
          allocationsStatus[dateProperty]["status"] = "edited";
        }
      }

      // setting weights
      let symbol: any = null;
      for (let i = 0, lengthI = allocations.length; i < lengthI; i++) {
        allocation = allocations[i];
        for (let j = 0, lengthJ = allocation["v"].length; j < lengthJ; j++) {
          dateProperty = "date_" + allocations[i]["d"];
          symbol = allocation["v"][j]["S"];
          if (symbol !== "CASH") {
            instrument = getSync(symbol);

            instrument[dateProperty] = allocation["v"][j]["A"];

            putSync(instrument);
          }
        }
      }

      const prepared = {
        allocationLast:
          allocations.length > 0
            ? allocations[allocations.length - 1]["d"]
            : null,
        allocationsStatus: allocationsStatus,
        dateProperties,
        collection: storeInstruments,
        columns: null,
      };

      data["prepared"] = prepared;

      this.product.history = data;

      return this.product;
    } catch (error) {
      this.product.history = undefined;

      return this.product;
    }
  }

  public async initProduct() {
    const product: any = this.systematicProduct;

    if (
      product.benchmark === "TRENDRATING_NEUTRAL_STRATEGY" ||
      product.benchmark === "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED"
    ) {
      product.benchmark = null;
    }

    let hasBenchmark = "benchmark" in product && product.benchmark != null;

    if (hasBenchmark) {
      const benchmark = product.benchmark;
      const blendedBenchmarkTag = "COLLECTION:";

      if (benchmark.startsWith(blendedBenchmarkTag)) {
        const explodedTag = benchmark.split(":");
        const listId = explodedTag[1];

        const list = await this.listsAPI.get(parseInt(listId));
        this.benchmark = list;

        this.analytics = await Analytics.initialize(
          this.environment,
          { type: "SMS", entity: this.systematicProduct },
          {
            type: "LIST",
            includeFromDay: undefined,
            entity: {
              portfolio: list,
              params: {
                currency: product.currency,
                // startDate: formattedDate,
                inceptionValue: product.inceptionValue,
                spanGranularity: product.reviewGranularity,
              },
            },
          }
        );
      } else {
        const symbol = benchmark;
        const paramsBenchmark: any = {
          properties:
            widgetsConfiguration[
              "widgets/portfolio/analysis/overview/keyDataBenchmark"
            ]["properties"],
          symbols: [symbol],
          type: "security",
        };

        const response = await this.instrumentAPI.fetch(paramsBenchmark);

        const instrument = response["data"][0];

        this.benchmark = instrument;

        this.analytics = await Analytics.initialize(
          this.environment,
          {
            type: "SMS",
            entity: this.systematicProduct,
          },
          {
            type: "INSTRUMENT",
            entity: {
              symbol: benchmark,
              params: {
                currency: product.currency,
                inceptionValue: product.inceptionValue,
                spanGranularity: product.reviewGranularity,
              },
            },
            includeFromDay: undefined,
          }
        );
      }
    } else {
      this.analytics = await Analytics.initialize(this.environment, {
        type: "SMS",
        entity: this.systematicProduct,
      });
    }

    this.product = {};

    return this.product;
  }

  public async rebalanceInfo() {
    if (this.product.rebalanceInfo) {
      return this.product;
    }

    const self = this;
    return this.mu.runWithMutex(async function () {
      const response = await self.smsAPI.fetch({
        ids: [self.systematicProduct.id],
        properties: ["calendarRebalance"],
      });
      const rebalanceInfo = self.prepareRebalanceInfo(
        response?.[0]?.calendarRebalance ?? undefined
      );

      if (!("rebalanceInfo" in self.product)) {
        self.product.rebalanceInfo = {
          next: null,
          previous: null,
          status: null,
          today: null,
        };

        self.product.rebalanceInfo = rebalanceInfo;
      }

      return self.product;
    });
  }

  public async summary() {
    if (this.product.summary) {
      return this.product;
    }

    const prices = await this.getCurve("H", "prices");

    const summary: any = {
      autorebalance: null,
      benchmark: null,
      closingDate: null,
      currency: null,
      dateEdit: null,
      performance: null,
      price: null,
      rebalance: null,
      expenseRatio: null,
    };

    const productStrategy = await this.getProductStrategy();

    const lastPrice = prices?.[prices.length - 1];
    summary["closingDate"] = lastPrice["d"];
    summary["price"] = lastPrice["v"];
    summary["autorebalance"] = this.systematicProduct["autorebalance"];
    summary["benchmark"] = this.benchmark;
    summary["currency"] = this.systematicProduct["currency"];
    summary["dateEdit"] = this.systematicProduct["updateTime"];
    summary["expenseRatio"] = this.systematicProduct["expenseRatio"];
    summary["rebalance"] = this.systematicProduct["reviewGranularity"];
    summary["performance"] = productStrategy
      ? productStrategy.params.strategy.performance
      : null;

    if (!("summary" in this.product)) {
      this.product.summary = summary;
    }

    return this.product;
  }

  info() {
    return this.systematicProduct;
  }

  public async addAllocation(updatedAllocation) {
    const value = updatedAllocation;

    var property = value["property"];
    var _value: any = {
      data: [],
      date: parseInt(property.replace("date_", "")),
    };
    let instrument: any = null;
    const instruments = value["collection"];
    for (let i = 0, length = instruments.length; i < length; i++) {
      instrument = instruments[i];
      _value["data"].push({
        symbol: instrument["symbol"],
        weight: instrument[property],
      });
    }

    const params = {
      editorDimension: "column",
      product: this.systematicProduct,
      value: _value,
    };

    // Cancel the cache because the historicalPortfolio has been updated
    this.historicalPortfolio = null;

    return await this.smsAPI.listAdd(params);
  }

  public async updateAllocation(updatedAllocation) {
    const value = updatedAllocation;

    var property = value["property"];
    var _value: any = {
      data: [],
      date: parseInt(property.replace("date_", "")),
    };
    let instrument: any = null;
    const instruments = value["collection"];
    for (let i = 0, length = instruments.length; i < length; i++) {
      instrument = instruments[i];
      _value["data"].push({
        symbol: instrument["symbol"],
        weight: instrument[property],
      });
    }

    const params = {
      editorDimension: "column",
      product: this.systematicProduct,
      value: _value,
    };

    // Cancel the cache because the historicalPortfolio has been updated
    this.historicalPortfolio = null;

    return await this.smsAPI.listUpdate(params);
  }

  public async removeAllocation(date) {
    //
    //
    // We need to check if the allocation to be removed it is the last
    // one (length - 1).
    //
    // If it is the last and creationDate === modificationDate, this
    // means it is an automatic allocation. In this case we have to
    // remove it and update the rebalanceTime of the product with the
    // modificationDate of length - 2 (the new last)
    //
    // If it is the last and creationDate !== modificationDate, this
    // means the user performed a manual operation. In this case we have
    // only to remove it without update the product
    //
    //
    const listHistory = await this.getHistoricalPortfolio();

    const allocations = listHistory["POS"];
    const allocationsLength = allocations.length;

    const dateToDays = parseInt(date.replace("date_", ""));

    const lastAllocation = allocations[allocationsLength - 1];
    if (dateToDays === lastAllocation["d"]) {
      if (
        lastAllocation["creationDate"] === lastAllocation["modificationDate"]
      ) {
        this.historicalPortfolio = null;
        return await this.smsAPI.listRemove({
          date: dateToDays,
          product: this.systematicProduct,
        });
      }
    }

    this.historicalPortfolio = null;
    return await this.smsAPI.listRemove({
      date: dateToDays,
      product: this.systematicProduct,
    });
  }

  public async replaceInstrument(source, target) {
    var params = {
      editorDimension: "row",
      product: this.systematicProduct,
      sourceInstrument: source,
      targetInstrument: target,
    };

    this.historicalPortfolio = null;
    return await this.smsAPI.listUpdateInstrument(params);
  }

  public async previewAnalytics() {
    if (this.product.analytics && this.product.analytics.preview) {
      return this.product;
    }

    const self = this;
    return this.mu.runWithMutex(async function () {
      const analiticEncoder = self.analytics?.aTag;

      if (analiticEncoder) {
        const hasBenchmark =
          self.systematicProduct.benchmark != null &&
          self.analytics?.checkBenchmark();

        const tagsMap = {
          H: {
            oneDay: analiticEncoder("keyFacts", "D:1", "H"),
            oneWeek: analiticEncoder("keyFacts", "W:1", "H"),
            oneMonth: analiticEncoder("keyFacts", "M:1", "H"),
            threeMonths: analiticEncoder("keyFacts", "M:3", "H"),
            MTD: analiticEncoder("current", "MTD", "H"),
            QTD: analiticEncoder("current", "QTD", "H"),
            YTD: analiticEncoder("current", "YTD", "H"),
            oneYear: analiticEncoder("keyFacts", "Y:1", "H"),
            threeYears: analiticEncoder("keyFacts", "Y:3", "H", "Y", true),
            fiveYears: analiticEncoder("keyFacts", "Y:5", "H", "Y", true),
            annualized: analiticEncoder("keyFacts", "perf", "H"),
            total: analiticEncoder("keyFacts", "totalperf", "H"),
          },
          B: {
            oneDay: analiticEncoder("keyFacts", "D:1", "B"),
            oneWeek: analiticEncoder("keyFacts", "W:1", "B"),
            oneMonth: analiticEncoder("keyFacts", "M:1", "B"),
            threeMonths: analiticEncoder("keyFacts", "M:3", "B"),
            MTD: analiticEncoder("current", "MTD", "B"),
            QTD: analiticEncoder("current", "QTD", "B"),
            YTD: analiticEncoder("current", "YTD", "B"),
            oneYear: analiticEncoder("keyFacts", "Y:1", "B"),
            threeYears: analiticEncoder("keyFacts", "Y:3", "B", "Y", true),
            fiveYears: analiticEncoder("keyFacts", "Y:5", "B", "Y", true),
            annualized: analiticEncoder("keyFacts", "perf", "B"),
            total: analiticEncoder("keyFacts", "totalperf", "B"),
          },
          D: {
            oneDay: analiticEncoder("keyFacts", "D:1", "D"),
            oneWeek: analiticEncoder("keyFacts", "W:1", "D"),
            oneMonth: analiticEncoder("keyFacts", "M:1", "D"),
            threeMonths: analiticEncoder("keyFacts", "M:3", "D"),
            MTD: analiticEncoder("current", "MTD", "D"),
            QTD: analiticEncoder("current", "QTD", "D"),
            YTD: analiticEncoder("current", "YTD", "D"),
            oneYear: analiticEncoder("keyFacts", "Y:1", "D"),
            threeYears: analiticEncoder("keyFacts", "Y:3", "D", "Y", true),
            fiveYears: analiticEncoder("keyFacts", "Y:5", "D", "Y", true),
            annualized: analiticEncoder("keyFacts", "perf", "D"),
            total: analiticEncoder("keyFacts", "totalperf", "D"),
          },
        };

        const analyticsList: any = [];

        analyticsList.push(...Object.values(tagsMap["H"]));

        if (hasBenchmark) {
          analyticsList.push(...Object.values(tagsMap["B"]));
          analyticsList.push(...Object.values(tagsMap["D"]));
        }

        const response = await self.analytics?.getAnalytics(analyticsList);

        if (response?.data) {
          const analytics = {
            H: {
              oneDay: null,
              oneWeek: null,
              oneMonth: null,
              threeMonths: null,
              MTD: null,
              QTD: null,
              YTD: null,
              oneYear: null,
              threeYears: null,
              fiveYears: null,
              annualized: null,
              total: null,
            },
          };

          for (const [key, value] of Object.entries(tagsMap["H"])) {
            if (value in response?.data) {
              analytics["H"][key] = Object.values(response?.data?.[value])?.[0];
            }
          }

          if (hasBenchmark) {
            analytics["B"] = {};
            analytics["D"] = {};

            for (const [key, value] of Object.entries(tagsMap["B"])) {
              if (value in response?.data) {
                analytics["B"][key] = Object.values(
                  response?.data?.[value]
                )?.[0];
              }
            }

            for (const [key, value] of Object.entries(tagsMap["D"])) {
              if (value in response?.data) {
                analytics["D"][key] = Object.values(
                  response?.data?.[value]
                )?.[0];
              }
            }
          }

          if (self.product.analytics == null) {
            self.product.analytics = {
              preview: null,
            };
          }

          self.product.analytics.preview = analytics;
        }

        return self.product;
      }
    });
  }

  /**
   * This is a special get analytics function specific for the report widget AS_OF_TODAY_PERFORMANCE
   *
   * this function fetch the needed analytics based on what the user has selected in the configuration panel of the
   * report section. In particular it ensure the distinction between SOLAR and ROLLING analytics.
   *
   * This function will be used only by the InputManager class under the AS_OF_TODAY_PERFORMACE section
   */
  public async getAsOfTodayPerfAnalytics(
    section,
    hasBenchmark,
    startDate,
    endDate
  ) {
    const analiticEncoder = this.analytics?.aTag;

    const sectionContent = section.content;

    // Defaults
    const performances = {
      1: {
        annualized: sectionContent?.performance1Year?.annualized ?? true,
        strict: sectionContent?.performance1Year?.strict ?? true,
      },
      3: {
        annualized: sectionContent?.performance3Years?.annualized ?? true,
        strict: sectionContent?.performance3Years?.strict ?? true,
      },
      5: {
        annualized: sectionContent?.performance5Years?.annualized ?? true,
        strict: sectionContent?.performance5Years?.strict ?? false,
      },
      10: {
        annualized: sectionContent?.performance10Year?.annualized ?? true,
        strict: sectionContent?.performance10Years?.strict ?? false,
      },
      LTD: {
        annualized: sectionContent?.performanceLTD?.annualized ?? true,
      },
    };

    if (analiticEncoder) {
      const tagsMap = {
        H: {
          DAILY: sectionContent.performance1Day.isEnabled
            ? analiticEncoder("keyFacts", "D:1", "H")
            : undefined,
          WEEKLY: sectionContent.performance1Week.isEnabled
            ? analiticEncoder("keyFacts", "W:1", "H")
            : undefined,
          MONTHLY: sectionContent.performance1Month.isEnabled
            ? analiticEncoder("keyFacts", "M:1", "H", undefined, false)
            : undefined,
          QUARTERLY: sectionContent.performance3Months.isEnabled
            ? analiticEncoder("keyFacts", "M:3", "H", undefined, false)
            : undefined,
          YEARLY: sectionContent.performance1Year.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:1",
                "H",
                performances["1"].annualized ? "Y" : undefined,
                !performances["1"].strict
              )
            : undefined,
          YEARLY_3_N: sectionContent.performance3Years.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:3",
                "H",
                performances["3"].annualized ? "Y" : undefined,
                !performances["3"].strict
              )
            : undefined,
          YEARLY_5_N: sectionContent.performance5Years.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:5",
                "H",
                performances["5"].annualized ? "Y" : undefined,
                !performances["5"].strict
              )
            : undefined,
          YEARLY_10_N: sectionContent.performance10Years.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:10",
                "H",
                performances["10"].annualized ? "Y" : undefined,
                !performances["10"].strict
              )
            : undefined,
          MTD: sectionContent.performanceMTD.isEnabled
            ? analiticEncoder("current", "MTD", "H")
            : undefined,
          QTD: sectionContent.performanceQTD.isEnabled
            ? analiticEncoder("current", "QTD", "H")
            : undefined,
          YTD: sectionContent.performanceYTD.isEnabled
            ? analiticEncoder("current", "YTD", "H")
            : undefined,
          LTD: sectionContent.performanceLTD.isEnabled
            ? performances["LTD"].annualized
              ? analiticEncoder("keyFacts", "perf", "H")
              : analiticEncoder("keyFacts", "totalperf", "H")
            : undefined,
        },
        B: {
          DAILY: sectionContent.performance1Day.isEnabled
            ? analiticEncoder("keyFacts", "D:1", "B")
            : undefined,
          WEEKLY: sectionContent.performance1Week.isEnabled
            ? analiticEncoder("keyFacts", "W:1", "B")
            : undefined,
          MONTHLY: sectionContent.performance1Month.isEnabled
            ? analiticEncoder("keyFacts", "M:1", "B", undefined, false)
            : undefined,
          QUARTERLY: sectionContent.performance3Months.isEnabled
            ? analiticEncoder("keyFacts", "M:3", "B", undefined, false)
            : undefined,
          YEARLY: sectionContent.performance1Year.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:1",
                "B",
                performances["1"].annualized ? "Y" : undefined,
                !performances["1"].strict
              )
            : undefined,
          YEARLY_3_N: sectionContent.performance3Years.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:3",
                "B",
                performances["3"].annualized ? "Y" : undefined,
                !performances["3"].strict
              )
            : undefined,
          YEARLY_5_N: sectionContent.performance5Years.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:5",
                "B",
                performances["5"].annualized ? "Y" : undefined,
                !performances["5"].strict
              )
            : undefined,
          YEARLY_10_N: sectionContent.performance10Years.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:10",
                "B",
                performances["10"].annualized ? "Y" : undefined,
                !performances["10"].strict
              )
            : undefined,
          MTD: sectionContent.performanceMTD.isEnabled
            ? analiticEncoder("current", "MTD", "B")
            : undefined,
          QTD: sectionContent.performanceQTD.isEnabled
            ? analiticEncoder("current", "QTD", "B")
            : undefined,
          YTD: sectionContent.performanceYTD.isEnabled
            ? analiticEncoder("current", "YTD", "B")
            : undefined,
          LTD: sectionContent.performanceLTD.isEnabled
            ? performances["LTD"].annualized
              ? analiticEncoder("keyFacts", "perf", "B")
              : analiticEncoder("keyFacts", "totalperf", "B")
            : undefined,
        },
        D: {
          DAILY: sectionContent.performance1Day.isEnabled
            ? analiticEncoder("keyFacts", "D:1", "D")
            : undefined,
          WEEKLY: sectionContent.performance1Week.isEnabled
            ? analiticEncoder("keyFacts", "W:1", "D")
            : undefined,
          MONTHLY: sectionContent.performance1Month.isEnabled
            ? analiticEncoder("keyFacts", "M:1", "D", undefined, false)
            : undefined,
          QUARTERLY: sectionContent.performance3Months.isEnabled
            ? analiticEncoder("keyFacts", "M:3", "D", undefined, false)
            : undefined,
          YEARLY: sectionContent.performance1Year.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:1",
                "D",
                performances["1"].annualized ? "Y" : undefined,
                !performances["1"].strict
              )
            : undefined,
          YEARLY_3_N: sectionContent.performance3Years.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:3",
                "D",
                performances["3"].annualized ? "Y" : undefined,
                !performances["3"].strict
              )
            : undefined,
          YEARLY_5_N: sectionContent.performance5Years.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:5",
                "D",
                performances["5"].annualized ? "Y" : undefined,
                !performances["5"].strict
              )
            : undefined,
          YEARLY_10_N: sectionContent.performance10Years.isEnabled
            ? analiticEncoder(
                "keyFacts",
                "Y:10",
                "D",
                performances["10"].annualized ? "Y" : undefined,
                !performances["10"].strict
              )
            : undefined,
          MTD: sectionContent.performanceMTD.isEnabled
            ? analiticEncoder("current", "MTD", "D")
            : undefined,
          QTD: sectionContent.performanceQTD.isEnabled
            ? analiticEncoder("current", "QTD", "D")
            : undefined,
          YTD: sectionContent.performanceYTD.isEnabled
            ? analiticEncoder("current", "YTD", "D")
            : undefined,
          LTD: sectionContent.performanceLTD.isEnabled
            ? performances["LTD"].annualized
              ? analiticEncoder("keyFacts", "perf", "D")
              : analiticEncoder("keyFacts", "totalperf", "D")
            : undefined,
        },
      };

      const analyticsList: any = [];

      analyticsList.push(...Object.values(tagsMap["H"]));

      if (hasBenchmark && this.analytics?.checkBenchmark()) {
        analyticsList.push(...Object.values(tagsMap["B"]));
        analyticsList.push(...Object.values(tagsMap["D"]));
      }

      const analyticsToFetch = analyticsList.filter((item) => item != null);
      const response = await this.analytics?.getAnalytics(
        analyticsToFetch,
        startDate,
        endDate
      );

      const analytics: any = {
        H: {
          DAILY: null,
          WEEKLY: null,
          MONTHLY: null,
          QUARTERLY: null,
          YEARLY: null,
          YEARLY_3_N: null,
          YEARLY_5_N: null,
          YEARLY_10_N: null,
          MTD: null,
          QTD: null,
          YTD: null,
          LTD: null,
        },
      };

      if (response?.data) {
        for (const [key, value] of Object.entries(tagsMap["H"])) {
          if (value) {
            analytics["H"][key] = Object.values(response?.data?.[value])?.[0];
          }
        }

        if (hasBenchmark && this.analytics?.checkBenchmark()) {
          analytics["B"] = {};
          analytics["D"] = {};

          for (const [key, value] of Object.entries(tagsMap["B"])) {
            if (value) {
              analytics["B"][key] = Object.values(response?.data?.[value])?.[0];
            }
          }

          for (const [key, value] of Object.entries(tagsMap["D"])) {
            if (value) {
              analytics["D"][key] = Object.values(response?.data?.[value])?.[0];
            }
          }
        }
      }

      return {
        strategy: analytics?.H ? { ...analytics?.H } : undefined,
        benchmark: analytics?.B ? { ...analytics.B } : undefined,
        delta: analytics?.D ? { ...analytics.D } : undefined,
      };
    }
  }

  public async getStrategy() {
    return await this.getProductStrategy();
  }

  public async snapshot() {
    if (this.product.snapShot != null) {
      return this.product;
    }

    const listHistory = await this.getCurve("H", "allocations");

    const params = {
      POS: listHistory,
      currency: this.systematicProduct.currency,
      id: this.systematicProduct.id,
    };

    const self = this;
    return this.mu.runWithMutex(async function () {
      const actualizedSnapshot = await self.smsAPI.snapshotActualizer(params);

      self.product.snapShot = actualizedSnapshot;

      return self.product;
    });
  }

  private async getCurve(
    who: "H" | "B",
    what: "prices" | "allocations" | "components"
  ) {
    const self = this;
    return this.mu.runWithMutex(async function () {
      return await self.analytics!.get(who, what);
    });
  }

  public async getHistoricalPortfolio() {
    if (this.historicalPortfolio) {
      return this.historicalPortfolio;
    }

    const self = this;
    return this.mu.runWithMutex(async function () {
      const historicalPortfolio = await self.smsAPI.listHistory({
        id: self.systematicProduct.historicalPortfolioId,
      });

      self.historicalPortfolio = historicalPortfolio;

      return self.historicalPortfolio;
    });
  }

  /**
   *
   * @param response data containing rebalance info about a product
   *
   * @returns organized data
   */
  private prepareRebalanceInfo(response) {
    if (!response) {
      return undefined;
    }

    var data = {
      next: response["nextRebalance"],
      previous: response["previousRebalance"],
      status: response["rebalanceStatus"].toLowerCase(),
      today: response["today"],
    };

    return data;
  }

  private async getProductStrategy() {
    if (this.strategy) {
      return this.strategy;
    }

    const self = this;
    return this.mu.runWithMutex(async function () {
      const productStrategy = await self.strategiesAPI.getById(
        self.systematicProduct.strategyId
      );

      self.strategy = productStrategy;

      return self.strategy;
    });
  }

  private responseTranformer(tab: StrategyComputedTabType, input) {
    switch (tab) {
      case "keyFacts":
        return { [tab]: this.transformToKeyFacts(input) };

      case "analytics": {
        return this.transformToAnalytics(input);
      }

      case "holdings": {
        return this.transformToHoldings(input);
      }

      case "strategyPerformances": {
        return this.transformToPerformances(input);
      }

      default:
        return input;
    }
  }

  private transformToAnalytics(input) {
    let dataList: any = [];

    for (const key in input) {
      let [analytic, period, curve] = key.split("|");

      input[key] = Object.entries(input?.[key] ?? {}).map(([key, value]) => ({
        timeframe: key,
        value,
        analytic,
        period,
        curve,
      }));

      dataList = dataList.concat(input[key]);
    }

    const prototype: any = {
      yearly: [],
      monthly: [],
      quarterly: [],
    };

    let annualizedYearly = {
      timeFrame: "Annualized",
      strategyYearlyPerformance:
        input["performance|annualized|H"]?.[0]?.["value"] ?? null,
      strategyMaxDrawdown: null,
      strategyVolatility: null,
      benchmarkYearlyPerformance:
        input["performance|annualized|B"]?.[0]?.["value"] ?? null,
      benchmarkMaxDrawdown: null,
      benchmarkVolatility: null,
      deltaYearlyPerformance:
        input["performance|annualized|D"]?.[0]?.["value"] ?? null,
      deltaMaxDrawdown: null,
      deltaVolatility: null,
      turnover: null,
    };

    let avgYearlyRow = {
      timeFrame: "average",
      strategyYearlyPerformance:
        input["performanceAvg|yearly|H"]?.[0]?.["value"] ?? null,
      strategyMaxDrawdown:
        input["drawdownAvg|yearly|H"]?.[0]?.["value"] ?? null,
      strategyVolatility:
        input["volatilityAvg|yearly|H"]?.[0]?.["value"] ?? null,
      benchmarkYearlyPerformance:
        input["performanceAvg|yearly|B"]?.[0]?.["value"] ?? null,
      benchmarkMaxDrawdown:
        input["drawdownAvg|yearly|B"]?.[0]?.["value"] ?? null,
      benchmarkVolatility:
        input["volatilityAvg|yearly|B"]?.[0]?.["value"] ?? null,
      deltaYearlyPerformance:
        input["performanceAvg|yearly|D"]?.[0]?.["value"] ?? null,
      deltaMaxDrawdown: input["drawdownAvg|yearly|D"]?.[0]?.["value"] ?? null,
      deltaVolatility: input["volatilityAvg|yearly|D"]?.[0]?.["value"] ?? null,
      turnover: null,
    };

    let avgMonthlyRow = {
      timeFrame: "average",
      strategyMonthlyPerformance:
        input["performanceAvg|monthly|H"]?.[0]?.["value"] ?? null,
      strategyMaxDrawdown:
        input["drawdownAvg|monthly|H"]?.[0]?.["value"] ?? null,
      strategyVolatility:
        input["volatilityAvg|monthly|H"]?.[0]?.["value"] ?? null,
      benchmarkMonthlyPerformance:
        input["performanceAvg|monthly|B"]?.[0]?.["value"] ?? null,
      benchmarkMaxDrawdown:
        input["drawdownAvg|monthly|B"]?.[0]?.["value"] ?? null,
      benchmarkVolatility:
        input["volatilityAvg|monthly|B"]?.[0]?.["value"] ?? null,
      deltaMonthlyPerformance:
        input["performanceAvg|monthly|D"]?.[0]?.["value"] ?? null,
      deltaMaxDrawdown: input["drawdownAvg|monthly|D"]?.[0]?.["value"] ?? null,
      deltaVolatility: input["volatilityAvg|monthly|D"]?.[0]?.["value"] ?? null,
      turnover: null,
    };

    let avgQuarterlyRow = {
      timeFrame: "average",
      strategyQuarterlyPerformance:
        input["performanceAvg|quarterly|H"]?.[0]?.["value"] ?? null,
      strategyMaxDrawdown:
        input["drawdownAvg|quarterly|H"]?.[0]?.["value"] ?? null,
      strategyVolatility:
        input["volatilityAvg|quarterly|H"]?.[0]?.["value"] ?? null,
      benchmarkQuarterlyPerformance:
        input["performanceAvg|quarterly|B"]?.[0]?.["value"] ?? null,
      benchmarkMaxDrawdown:
        input["drawdownAvg|quarterly|B"]?.[0]?.["value"] ?? null,
      benchmarkVolatility:
        input["volatilityAvg|quarterly|B"]?.[0]?.["value"] ?? null,
      deltaQuarterlyPerformance:
        input["performanceAvg|quarterly|D"]?.[0]?.["value"] ?? null,
      deltaMaxDrawdown: input["drawdownAvg|yearly|D"]?.[0]?.["value"] ?? null,
      deltaVolatility: input["volatilityAvg|yearly|D"]?.[0]?.["value"] ?? null,
      turnover: null,
    };

    let annualizedMonthly = {
      timeFrame: "Annualized",
      strategyMonthlyPerformance:
        input["performance|annualized|H"]?.[0]?.["value"] ?? null,
      strategyMaxDrawdown: null,
      strategyVolatility: null,
      benchmarkMonthlyPerformance:
        input["performance|annualized|B"]?.[0]?.["value"] ?? null,
      benchmarkMaxDrawdown: null,
      benchmarkVolatility: null,
      deltaMonthlyPerformance:
        input["performance|annualized|D"]?.[0]?.["value"] ?? null,
      deltaMaxDrawdown: null,
      deltaVolatility: null,
      turnover: null,
    };
    let annualizedQuarterly = {
      timeFrame: "Annualized",
      strategyQuarterlyPerformance:
        input["performance|annualized|H"]?.[0]?.["value"] ?? null,
      strategyMaxDrawdown: null,
      strategyVolatility: null,
      benchmarkQuarterlyPerformance:
        input["performance|annualized|B"]?.[0]?.["value"] ?? null,
      benchmarkMaxDrawdown: null,
      benchmarkVolatility: null,
      deltaQuarterlyPerformance:
        input["performance|annualized|D"]?.[0]?.["value"] ?? null,
      deltaMaxDrawdown: null,
      deltaVolatility: null,
      turnover: null,
    };

    for (const row of dataList) {
      if (row.period && prototype[row.period] && row.analytic !== "turnover") {
        prototype[row.period].push(row);
      }
    }

    for (const key in prototype) {
      prototype[key] = this.transformToUISchema(
        this.groupByTimeframe(prototype[key])
      );
    }

    prototype.yearly.push(avgYearlyRow);
    prototype.monthly.push(avgMonthlyRow);
    prototype.quarterly.push(avgQuarterlyRow);

    prototype.yearly.push(annualizedYearly);
    prototype.monthly.push(annualizedMonthly);
    prototype.quarterly.push(annualizedQuarterly);

    //Assign to yearly data the turnovers
    if (input["turnover|yearly|H"] && input["turnover|yearly|H"].length) {
      const turnoverMap = {};

      for (const turnoverObj of input["turnover|yearly|H"]) {
        const formattedKey = TDate.dateToIso8601(
          TDate.daysToDate(parseInt(turnoverObj.timeframe))
        );
        const [year, ,] = formattedKey.split("-");

        turnoverMap[year] = turnoverObj?.value ?? null;
      }

      turnoverMap["average"] =
        input["turnover|avgTurnover|H"]?.[0]?.value ?? null;

      prototype.yearly = prototype.yearly.map((item) => ({
        ...item,
        turnover: turnoverMap?.[item.timeFrame] ?? null,
      }));
    }

    return prototype;
  }

  private transformToKeyFacts(input) {
    const prototype = {
      performance: {
        cumulative: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
        annualized: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
        yearlyAverage: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
        winningPeriods: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
      },
      risk: {
        maxDrawdown: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
        avgYearlyDrawdown: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
        monthlyStdDev: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
        maxConsecutiveLoosingPeriod: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
      },
      keyRatios: {
        avgTurnover: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
        sharpeRatio: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
        sterlingRatio: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
        sortinoRatio: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
        beta: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
        trackingError: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
        infoRatio: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
        treynorRatio: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
        percentagePositivePeriod: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
        winningPeriod: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
        winningAvgPeriod: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
        losingPeriod: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
        losingAvgPeriod: {
          strategy: null,
          benchmark: null,
          delta: null,
        },
      },
      period: null,
      hasBenchmark: false,
    };

    return this.transformer(prototype, input);
  }

  private transformToUISchema(map) {
    const result: any = [];

    const listFromMap: any = Object.values(map);

    const dateMap = {};

    listFromMap.forEach((item) => {
      let data = item.reduce((prev: any, current: any) => {
        const date = TDate.dateToIso8601(TDate.daysToDate(current.timeframe));

        const [years, months] = date.split("-");

        const dateForUI =
          current.period !== "yearly" ? `${years}_${months}` : years;

        prev["timeFrame"] = dateForUI;

        const keyBuilderInfo = {
          analytic: current.analytic,
          period: current.period,
          curve: current.curve,
        };

        const key = this.buildAnalyticsObjKey(keyBuilderInfo);

        // Keys about average and annualization are handled differently
        if (key) {
          prev[key] = current.value;
        }

        return prev;
      }, {});

      // This operation is needed to avoid entries duplication cause by different dates between the benchmark and the strategy curve
      // this situation might be caused if the strategy use the price of today but the benchmark price is not updated for some reason
      // (market extraordinary closure).
      // In this scenario the POS of the benchmark and the POS of the main history can differ by some days but the year still be the
      // same so the entry is duplicated and has to be reduced in a single one.
      if (dateMap[data.timeFrame]) {
        dateMap[data.timeFrame]++;
      } else {
        dateMap[data.timeFrame] = 1;
      }

      result.push(data);
    });

    const adjustedEntries: any = [];
    let multipleEntries: any = null;
    let singleEntry: any = {};

    for (const el of result) {
      const timeframe = el.timeFrame;
      const elementsPerDate = dateMap[timeframe];
      if (elementsPerDate > 0) {
        if (elementsPerDate > 1) {
          multipleEntries = result.filter(
            (item) => item.timeFrame === timeframe
          );

          if (multipleEntries && multipleEntries.length) {
            singleEntry = multipleEntries.reduce((prev, current) => {
              return { ...prev, ...current };
            }, {});
          }

          adjustedEntries.push({ ...singleEntry });
          dateMap[timeframe] = 0;
          singleEntry = {};
          multipleEntries = null;
        } else {
          adjustedEntries.push(el);
          dateMap[timeframe]--;
        }
      }
    }

    return adjustedEntries;
  }

  private transformToHoldings(input) {
    const TAGS = {
      constituentsCard: "constituents|count",
      constituentsWeight: "constituents|weight",
      rating_A_number: "rating|A_card",
      rating_B_number: "rating|B_card",
      rating_C_number: "rating|C_card",
      rating_D_number: "rating|D_card",
      rating_A_perc: "rating|A_perc",
      rating_B_perc: "rating|B_perc",
      rating_C_perc: "rating|C_perc",
      rating_D_perc: "rating|D_perc",
      perf: "performance",
    };

    const TAGS_SHORT = {
      constituentsWeight: "constituents|weight|short",
      rating_A_perc: "rating|A_perc|short",
      rating_B_perc: "rating|B_perc|short",
      rating_C_perc: "rating|C_perc|short",
      rating_D_perc: "rating|D_perc|short",
      perf: "performance|short",
    };

    const TAGS_LONG = {
      constituentsWeight: "constituents|weight|long",
      rating_A_perc: "rating|A_perc|long",
      rating_B_perc: "rating|B_perc|long",
      rating_C_perc: "rating|C_perc|long",
      rating_D_perc: "rating|D_perc|long",
      perf: "performance|long",
    };

    if (!input) {
      return [];
    }

    const holdingsData = this.holdingTransformerHelper(input, TAGS);

    const result: any = {
      main: holdingsData,
      long: [],
      short: [],
    };

    const shortPortfolioData = this.holdingTransformerHelper(input, TAGS_SHORT);
    const longPortfolioData = this.holdingTransformerHelper(input, TAGS_LONG);

    result["long"] = longPortfolioData;
    result["short"] = shortPortfolioData;

    let hasShortPositions = false;

    for (const pos of shortPortfolioData) {
      if (pos.A && pos.A < 0) {
        hasShortPositions = true;

        break;
      }
    }

    if (hasShortPositions === true) {
      return result;
    } else {
      result["long"] = [];
      result["short"] = [];

      return result;
    }
  }

  private holdingTransformerHelper(data, TAGS) {
    const input = deepClone(data);
    const allocation: HoldingsAllocatinoObject = {
      d: null,
      A: null,
      P: null,
      CARD: null,
      ratings: {
        A: null,
        B: null,
        C: null,
        NA: null,
        D: null,
      },

      ratingWeights: {
        A: null,
        B: null,
        C: null,
        NA: null,
        D: null,
      },
    };

    const prototype: HoldingsAllocatinoObject[] = [];

    for (const [date, performance] of Object.entries<any>(
      input[TAGS["perf"]]
    )) {
      allocation["d"] = parseInt(date);
      allocation["P"] = performance;
      allocation["CARD"] = input?.[TAGS?.["constituentsCard"]]?.[date] ?? null;
      allocation["A"] = input?.[TAGS?.["constituentsWeight"]]?.[date] ?? null;

      // Ratings cardinality
      allocation["ratings"]["A"] =
        input?.[TAGS?.["rating_A_number"]]?.[date] ?? null;
      allocation["ratings"]["B"] =
        input?.[TAGS?.["rating_B_number"]]?.[date] ?? null;
      allocation["ratings"]["C"] =
        input?.[TAGS?.["rating_C_number"]]?.[date] ?? null;
      allocation["ratings"]["D"] =
        input?.[TAGS?.["rating_D_number"]]?.[date] ?? null;

      // Ratings percentage
      allocation["ratingWeights"]["A"] =
        input?.[TAGS?.["rating_A_perc"]]?.[date] ?? null;
      allocation["ratingWeights"]["B"] =
        input?.[TAGS?.["rating_B_perc"]]?.[date] ?? null;
      allocation["ratingWeights"]["C"] =
        input?.[TAGS?.["rating_C_perc"]]?.[date] ?? null;
      allocation["ratingWeights"]["D"] =
        input?.[TAGS?.["rating_D_perc"]]?.[date] ?? null;

      // Clone to not push a reference to original object
      prototype.push(deepClone(allocation));
    }

    return prototype;
  }

  private transformer(prototype, input) {
    for (const [key, value] of Object.entries(input)) {
      const { page, section, curve } = this.resolvePathToObject(key);

      prototype[page][section][curve] =
        value != null ? Object.values(value as any)[0] : null;
    }

    return prototype;
  }

  private resolvePathToObject(path: string) {
    const params: any = { page: null, section: null, curve: null };
    const urlNodes = path.split("|");

    if (urlNodes.length > 0) {
      params["page"] = urlNodes[0];
      params["section"] = urlNodes[1];
      params["curve"] = CURVE_DICT[urlNodes[2]];
    }

    return params;
  }

  private buildAnalyticsObjKey({
    curve,
    period,
    analytic,
  }: {
    curve: "H" | "B" | "D";
    period: "yearly" | "quarterly" | "monthly" | "annualized";
    analytic: "performance" | "drawdown" | "volatility";
  }) {
    const DICT_KEY = {
      analytic: {
        performance: "Performance",
        drawdown: "MaxDrawdown",
        volatility: "Volatility",
      },
      period: {
        yearly: "Yearly",
        monthly: "Monthly",
        quarterly: "Quarterly",
        annualized: "Annualized",
      },
      curve: {
        H: "strategy",
        B: "benchmark",
        D: "delta",
      },
    };

    if (period === "annualized") {
      return DICT_KEY["period"][period];
    }

    if (analytic !== "performance") {
      if (DICT_KEY["curve"][curve] && DICT_KEY["analytic"][analytic]) {
        return DICT_KEY["curve"][curve] + DICT_KEY["analytic"][analytic];
      } else {
        return undefined;
      }
    } else {
      if (
        DICT_KEY["curve"][curve] &&
        DICT_KEY["period"][period] &&
        DICT_KEY["analytic"][analytic]
      ) {
        return (
          DICT_KEY["curve"][curve] +
          DICT_KEY["period"][period] +
          DICT_KEY["analytic"][analytic]
        );
      } else {
        return undefined;
      }
    }
  }

  private groupByTimeframe(arr) {
    const property = "timeframe";
    let timeframe: any = null;

    return arr.reduce((prev, current) => {
      timeframe = current[property];

      if (!prev[timeframe]) {
        prev[timeframe] = [];
      }

      prev[timeframe].push(current);

      return prev;
    }, {});
  }

  private transformToPerformances(input) {
    const getPerfBySerie = (serie: "H" | "B" | "D") => {
      return {
        DAILY: Object.values(input?.[`performance|D:1|${serie}`] ?? {})?.[0],
        LTD: Object.values(input?.[`performance|LTD|${serie}`] ?? {})?.[0],
        MONTHLY: Object.values(input?.[`performance|M:1|${serie}`] ?? {})?.[0],
        QUARTERLY: Object.values(
          input?.[`performance|Q:1|${serie}`] ?? {}
        )?.[0],
        WEEKLY: Object.values(input?.[`performance|W:1|${serie}`] ?? {})?.[0],
        YEARLY: Object.values(input?.[`performance|Y:1|${serie}`] ?? {})?.[0],
        YEARLY_10_N: Object.values(
          input?.[`performance|Y:10|${serie}`] ?? {}
        )?.[0],
        YEARLY_3_N: Object.values(
          input?.[`performance|Y:3|${serie}`] ?? {}
        )?.[0],
        YEARLY_5_N: Object.values(
          input?.[`performance|Y:5|${serie}`] ?? {}
        )?.[0],
        MTD: Object.values(input?.[`performance|MTD|${serie}`] ?? {})?.[0],
        QTD: Object.values(input?.[`performance|QTD|${serie}`] ?? {})?.[0],
        YTD: Object.values(input?.[`performance|YTD|${serie}`] ?? {})?.[0],
      };
    };

    const performances = {
      benchmark: getPerfBySerie("B"),
      delta: getPerfBySerie("D"),
      strategy: getPerfBySerie("H"),
    };

    return performances;
  }
}
